import React from 'react';
import { StoreContext } from '../storeProvider';

// Hook to access and set program color
export const useProgramColor = () => {
  const { programColor, setProgramColor } = React.useContext(StoreContext);
  return {
    programColor,
    setProgramColor
  };
};

// Hook to set program color with cleanup
export const useSetProgramColor = () => {
  const { programColor, setProgramColor } = React.useContext(StoreContext);
  
  React.useEffect(() => {
    // Only cleanup when component unmounts
    return () => {
      if (programColor) {  // Only clear if there was a color set
        setProgramColor('bg-gray-100');
      }
    };
  }, []); // Empty dependency array means this only runs on mount/unmount

  return {
    programColor,
    setProgramColor
  };
};