import React, { useState } from "react";
import Modal from "react-modal";
import { useMutation, gql } from "@apollo/client";
import { toast } from "react-toastify";

const ADD_ENROLLMENT = gql`
  mutation AddEnrollment(
    $participant: Integer!,
    $enrollment: AddEnrollmentInput!
  ) {
    addEnrollment(
      participant: $participant,
      enrollment: $enrollment
    ) {
      message
    }
  }
`;

const leadSiteOptions = [
  { value: "sla_lb", label: "SLA/LB" },
  { value: "hw", label: "HW" },
  { value: "rampart", label: "Rampart" },
  { value: "ela", label: "ELA" },
  { value: "dtla", label: "DTLA" },
  { value: "venice", label: "Venice" },
  { value: "av", label: "AV" }
];

const referralTypeOptions = [
  { value: "social_contact", label: "Social Contact" },
  { value: "prebooking", label: "Prebooking" },
  { value: "community_referral", label: "Community Referral" }
];

const exitReasonOptions = [
  { value: "no_contact", label: "No contact" },
  { value: "moved_out_of_la", label: "Moved out of LA County" },
  { value: "voluntary_withdrawal", label: "Voluntary withdrawal" },
  { value: "sentence_16_mo_1_day", label: "Sentence > 16 mo, 1 day" },
  { value: "transfer_to_other_diversion_program", label: "Transfer to other diversion program" },
  { value: "danger_to_staff_participants", label: "Danger to staff/participants" },
  { value: "transfer_to_ice_custody", label: "Transfer to ICE custody" },
  { value: "deceased", label: "Deceased" },
  { value: "other", label: "Other" }
];

export const CreateEnrollment = ({ participant, onClose }: { participant: number, onClose: () => void }) => {
  const [enrollmentDate, setEnrollmentDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [exitDate, setExitDate] = useState<string>("");
  const [site, setSite] = useState<string>("");
  const [referringAgency, setReferringAgency] = useState<string>("");
  const [referringIndividual, setReferringIndividual] = useState<string>("");
  const [referralType, setReferralType] = useState<string>("");
  const [exitReason, setExitReason] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [errors, setErrors] = useState<Record<string, string>>({});

  const [addEnrollment, { loading }] = useMutation(ADD_ENROLLMENT, {
    onCompleted: (data) => {
      toast.success(data.addEnrollment.message || "Enrollment record added successfully");
      onClose();
    },
    onError: (error) => {
      toast.error(`Error: ${error.message}`);
    },
  });

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!enrollmentDate) newErrors.enrollmentDate = "Enrollment date is required";
    if (!site) newErrors.site = "LEAD site is required";
    if (!referringAgency) newErrors.referringAgency = "Referring agency is required";
    if (!referringIndividual) newErrors.referringIndividual = "Referring individual is required";
    if (!referralType) newErrors.referralType = "Referral type is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) return;

    const enrollmentInput = {
      enrollmentDate,
      site,
      referringAgency,
      referringIndividual,
      referralType,
      exitDate: exitDate || null,
      exitReason,
      notes,
    };

    addEnrollment({
      variables: {
        participant: participant, // Both parameters should be the same
        enrollment: enrollmentInput
      },
    });
  };

  const customModalStyles = {
    content: {
      width: '100%',
      maxWidth: '800px',
      margin: '40px auto',
      background: 'white',
      border: '1px solid #ccc',
      borderRadius: '4px',
      padding: '20px'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    }
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      style={customModalStyles}
    >
      <div className="w-full bg-white rounded-lg">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-xl font-semibold text-gray-900">Add Enrollment Record</h3>
            <button onClick={onClose} className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
              Close
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Enrollment Date</label>
                <input
                  type="date"
                  value={enrollmentDate}
                  onChange={(e) => setEnrollmentDate(e.target.value)}
                  className={`w-full px-3 py-2 border ${errors.enrollmentDate ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                  required
                />
                {errors.enrollmentDate && (
                  <p className="mt-1 text-sm text-red-600">{errors.enrollmentDate}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">LEAD Site</label>
                <select
                  value={site}
                  onChange={(e) => setSite(e.target.value)}
                  className={`w-full px-3 py-2 border ${errors.site ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                  required
                >
                  <option value="">Select LEAD site</option>
                  {leadSiteOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.site && (
                  <p className="mt-1 text-sm text-red-600">{errors.site}</p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Referring Agency</label>
                <input
                  type="text"
                  value={referringAgency}
                  onChange={(e) => setReferringAgency(e.target.value)}
                  className={`w-full px-3 py-2 border ${errors.referringAgency ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                  maxLength={45}
                  required
                />
                {errors.referringAgency && (
                  <p className="mt-1 text-sm text-red-600">{errors.referringAgency}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Referring Individual</label>
                <input
                  type="text"
                  value={referringIndividual}
                  onChange={(e) => setReferringIndividual(e.target.value)}
                  className={`w-full px-3 py-2 border ${errors.referringIndividual ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                  maxLength={45}
                  required
                />
                {errors.referringIndividual && (
                  <p className="mt-1 text-sm text-red-600">{errors.referringIndividual}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Referral Type</label>
              <select
                value={referralType}
                onChange={(e) => setReferralType(e.target.value)}
                className={`w-full px-3 py-2 border ${errors.referralType ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                required
              >
                <option value="">Select referral type</option>
                {referralTypeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.referralType && (
                <p className="mt-1 text-sm text-red-600">{errors.referralType}</p>
              )}
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Exit Date</label>
                <input
                  type="date"
                  value={exitDate}
                  onChange={(e) => setExitDate(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Exit Reason</label>
                <select
                  value={exitReason}
                  onChange={(e) => setExitReason(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  disabled={!exitDate}
                >
                  <option value="">Select exit reason</option>
                  {exitReasonOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Notes</label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="w-full h-32 px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                disabled={loading}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
                disabled={loading}
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
