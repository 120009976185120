import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import ReactQuill from 'react-quill';
import ReactModal from 'react-modal';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';

const GET_SERVER_CONFIG = gql`
    query GetServerConfig {
        getServerConfig {
            inactiveUserEmailWarning
            inactiveUserEmailDisabled
            passwordExpiringEmailWarning
            inactiveUserSystemEnabled
            passwordExpiringSystemEnabled
            twoFactorEmailMessage
            emailSupportAddress
            emailSupportName
            emailSenderAddress
            emailSenderName
        }
    }
`;

const UPDATE_SERVER_CONFIG = gql`
    mutation UpdateServerConfig($updates: ConfigSettingsInput) {
        updateServerConfig(updates: $updates) {
            message
            responseCode
        }
    }
`;

const ModalHeader = ({ title, onClose }: { title: string; onClose: () => void }) => {
    return (
        <div className="border-b border-gray-200 px-6 py-4">
            <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
                <button
                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    onClick={onClose}
                >
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
        </div>
    );
};

const HTMLInput = (params: any) => {
    const { name, value, onChange, title } = params;
    const [html, setHtml] = useState('');
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        setHtml(value);
    }, [value]);

    if (!enabled) {
        return (
            <div className="mt-2">
                <button
                    onClick={() => setEnabled(true)}
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    Edit Template
                </button>
            </div>
        );
    }

    return (
        <ReactModal
            isOpen={enabled}
            onRequestClose={() => setEnabled(false)}
            className="fixed inset-0 z-50 flex items-center justify-center px-4 sm:px-0"
            overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        >
            <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl">
                <ModalHeader title={title} onClose={() => setEnabled(false)} />
                <div className="p-6">
                    <div className="mb-6">
                        <ReactQuill value={html} onChange={setHtml} />
                    </div>
                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={() => setEnabled(false)}
                            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => {
                                setEnabled(false);
                                onChange({ target: { name: name, value: html } });
                            }}
                            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

export const ServerConfig = () => {
    const { loading, error, data } = useQuery(GET_SERVER_CONFIG);
    const [updateServerConfig] = useMutation(UPDATE_SERVER_CONFIG);
    const [config, setConfig] = useState({
        inactiveUserEmailWarning: '',
        inactiveUserEmailDisabled: '',
        passwordExpiringEmailWarning: '',
        inactiveUserSystemEnabled: false,
        passwordExpiringSystemEnabled: false,
        emailSupportAddress: '',
        emailSupportName: '',
        emailSenderAddress: '',
        emailSenderName: '',
        twoFactorEmailMessage: '',
    });

    useEffect(() => {
        if (data) {
            setConfig(data.getServerConfig);
        }
    }, [data]);

    const handleInputChange = (event: any) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setConfig({
            ...config,
            [event.target.name]: value
        });
    };

    const updateConfig = async (configData: any) => {
        const { __typename, ...cleanConfig } = configData;
        cleanConfig.inactiveUserSystemEnabled = Boolean(cleanConfig.inactiveUserSystemEnabled);
        cleanConfig.passwordExpiringSystemEnabled = Boolean(cleanConfig.passwordExpiringSystemEnabled);

        const { data } = await updateServerConfig({ variables: { updates: cleanConfig } });

        if (data.updateServerConfig.responseCode === 0) {
            toast.success('Server Configuration Updated Successfully');
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        updateConfig(config);
    };

    const handleInputChangeNow = (event: any) => {
        handleInputChange(event);
        updateConfig({
            ...config,
            [event.target.name]: event.target.value
        });
    };

    if (loading) return (
        <div className="flex h-screen items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
    );
    
    if (error) return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="bg-red-50 border-l-4 border-red-400 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">Error loading configuration</h3>
                        <div className="mt-2 text-sm text-red-700">{error.message}</div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="space-y-8">
                <div>
                    <h1 className="text-2xl font-bold text-gray-900">Server Configuration</h1>
                    <p className="mt-2 text-sm text-gray-500">Manage your server's email and security settings.</p>
                </div>

                <form onSubmit={handleSubmit} className="space-y-8">
                    <div className="bg-white shadow rounded-lg p-6">
                        <h2 className="text-lg font-medium text-gray-900 mb-6">Security Email Templates</h2>
                        
                        <div className="space-y-6">
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    id="inactiveUserSystemEnabled"
                                    name="inactiveUserSystemEnabled"
                                    checked={config.inactiveUserSystemEnabled}
                                    onChange={handleInputChange}
                                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                />
                                <label htmlFor="inactiveUserSystemEnabled" className="ml-2 text-sm text-gray-900">
                                    Enable Inactive User System
                                </label>
                            </div>

                            <div className="space-y-4">
                                <label className="block">
                                    <span className="text-sm font-medium text-gray-700">Inactive User Warning Email</span>
                                    <HTMLInput
                                        name="inactiveUserEmailWarning"
                                        title="Inactive User Warning Email Template"
                                        value={config.inactiveUserEmailWarning || ''}
                                        onChange={handleInputChangeNow}
                                    />
                                </label>

                                <label className="block">
                                    <span className="text-sm font-medium text-gray-700">Disabled User Email</span>
                                    <HTMLInput
                                        name="inactiveUserEmailDisabled"
                                        title="Disabled User Email Template"
                                        value={config.inactiveUserEmailDisabled || ''}
                                        onChange={handleInputChangeNow}
                                    />
                                </label>
                            </div>

                            <div className="pt-6">
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="passwordExpiringSystemEnabled"
                                        name="passwordExpiringSystemEnabled"
                                        checked={config.passwordExpiringSystemEnabled}
                                        onChange={handleInputChange}
                                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                    />
                                    <label htmlFor="passwordExpiringSystemEnabled" className="ml-2 text-sm text-gray-900">
                                        Enable Password Expiration System
                                    </label>
                                </div>

                                <div className="mt-4">
                                    <label className="block">
                                        <span className="text-sm font-medium text-gray-700">Password Expiring Warning Email</span>
                                        <HTMLInput
                                            name="passwordExpiringEmailWarning"
                                            title="Password Expiring Warning Template"
                                            value={config.passwordExpiringEmailWarning || ''}
                                            onChange={handleInputChangeNow}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div>
                                <label className="block">
                                    <span className="text-sm font-medium text-gray-700">Two-Factor Authentication Email</span>
                                    <HTMLInput
                                        name="twoFactorEmailMessage"
                                        title="Two-Factor Authentication Email Template"
                                        value={config.twoFactorEmailMessage || ''}
                                        onChange={handleInputChangeNow}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white shadow rounded-lg p-6">
                        <h2 className="text-lg font-medium text-gray-900 mb-6">Email Settings</h2>
                        
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            <div>
                                <label className="block">
                                    <span className="text-sm font-medium text-gray-700">Sender Email Address</span>
                                    <input
                                        type="email"
                                        name="emailSenderAddress"
                                        value={config.emailSenderAddress}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    />
                                </label>
                            </div>

                            <div>
                                <label className="block">
                                    <span className="text-sm font-medium text-gray-700">Sender Name</span>
                                    <input
                                        type="text"
                                        name="emailSenderName"
                                        value={config.emailSenderName}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    />
                                </label>
                            </div>

                            <div>
                                <label className="block">
                                    <span className="text-sm font-medium text-gray-700">Support Email Address</span>
                                    <input
                                        type="email"
                                        name="emailSupportAddress"
                                        value={config.emailSupportAddress}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    />
                                </label>
                            </div>

                            <div>
                                <label className="block">
                                    <span className="text-sm font-medium text-gray-700">Support Team Name</span>
                                    <input
                                        type="text"
                                        name="emailSupportName"
                                        value={config.emailSupportName}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus
                            focus:ring-blue-500"
                        >
                            Save Configuration
                        </button>
                    </div>
                </form>
            </div>

          
        </div>
    );
};

export default ServerConfig;