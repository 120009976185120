import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { isoDateToMDY } from "../../utils/common";

const GET_INCIDENTS_DATA = gql`
  query GetIncidentsData($programNumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $programNumber) {
      incidents {
        id
        incidentClientsCid
        incidentClientsNames
        incidentDate
        incidentLocationName
        incidentNumber
        incidentTime
        loginName
        organizationName
        primaryContactName
        program
        reportCategory
      }
    }
  }
`;

export const Incidents = ({ programNumber }: { programNumber: number }) => {
  const { loading, error, data } = useQuery(GET_INCIDENTS_DATA, {
    variables: { programNumber },
    skip: !programNumber,
  });

  if (loading) return <p>Loading incidents data...</p>;
  if (error) return <p>Error loading incidents data :(</p>;
  if (!data) return null;

  const incidentsData = data.getParticipantByODRNumber.incidents||[];
console.log(incidentsData)
  if (!Array.isArray(incidentsData) || incidentsData.length === 0) {
    return (
      <div style={{ textAlign: "center", padding: "1rem" }}>
        <span>No incidents found for this participant.</span>
      </div>
    );
  }
  const style = {
    display: "grid",
    gridTemplateColumns: "10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem",
    gap: "0.5rem",
  };

  return (
    <div>
        <div style={{ ...style, fontWeight: "bold", marginBottom: "0.5rem" }}>
            <div>Date of Incident</div>
            <div>Time of Incident</div>
            <div>Program</div>
            <div>Incident Report Category</div>
            <div>Organization Name</div>
            <div>Name of Incident Location</div>
            <div>Reported by</div>
            <div>Primary Contact Name</div>
            <div>Name(s) of Clients Involved</div>
            <div>CID's</div>
            <div></div>
          </div>
          {incidentsData.map((incident, index) => (
            <div key={index} style={style}>
              <div>{isoDateToMDY(incident.incidentDate) || "N/A"}</div>
              <div>{incident.incidentTime || "N/A"}</div>
              <div>{incident.program || "N/A"}</div>
              <div>{incident.reportCategory || "N/A"}</div>
              <div>{incident.organizationName || "N/A"}</div>
              <div>{incident.incidentLocationName || "N/A"}</div>
              <div>{incident.loginName || "N/A"}</div>
              <div>{incident.primaryContactName || "N/A"}</div>
              <div>{incident.incidentClientsNames || "N/A"}</div>
              <div>{incident.incidentClientsCid || "N/A"}</div>
              <div>
                <Link to={`/app/components/inrep/candidates/candidates.php?action=view&id=${incident.id}`}>
                  <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                    <path fill="#000000" d="M21.7,13.35L20.7,14.35L18.65,12.3L19.65,11.3C19.86,11.09 20.21,11.09 20.42,11.3L21.7,12.58C21.91,12.79 21.91,13.14 21.7,13.35M12,18.94L18.06,12.88L20.11,14.93L14.06,21H12V18.94M12,14C7.58,14 4,15.79 4,18V20H10V18.11L14,14.11C13.34,14.03 12.67,14 12,14M12,4A4,4 0 0,0 8,8A4,4 0 0,0 12,12A4,4 0 0,0 16,8A4,4 0 0,0 12,4Z" />
                  </svg>
                </Link>
              </div>
            </div>
          ))}
      
      
    </div>
  );
};