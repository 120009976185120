import React from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { isoDateToMDY } from "../../utils/common";
import { CreateEnrollment } from "./modals/CreateEnrollment";
import { EditEnrollment } from "./modals/EditEnrollment";
import { Button } from "@/components/ui/button";
import { Plus, Trash2 } from "lucide-react";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

const GET_ENROLLMENT_DATA = gql`
  query GetParticipantEnrollments($programNumber: Integer!) {
    getParticipantEnrollments(programNumber: $programNumber) {
      id
      site
      enrollmentDate
      exitDate
      exitReason
      notes
      referralType
      referringIndividual
      referringAgency
      entryBy
      entryDate
      updatedBy
      updatedDate
    }
  }
`;

const DELETE_ENROLLMENT = gql`
  mutation DeleteEnrollment($id: Integer!) {
    deleteEnrollment(id: $id) {
      message
    }
  }
`;

const LoadingState = () => (
  <div className="space-y-4">
    <Skeleton className="h-8 w-[200px]" />
    <div className="grid grid-cols-3 gap-4">
      <Skeleton className="h-[100px]" />
      <Skeleton className="h-[100px]" />
      <Skeleton className="h-[100px]" />
    </div>
  </div>
);

export const Enrollment = ({ programNumber }: { programNumber: number }) => {
  const [showCreateEnrollment, setShowCreateEnrollment] = React.useState({
    show: false,
    data: null,
    method: "none",
  });
  const [enrollmentToDelete, setEnrollmentToDelete] = React.useState<number | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

  const { loading, error, data, refetch } = useQuery(GET_ENROLLMENT_DATA, {
    variables: { programNumber },
    skip: !programNumber,
  });

  const [deleteEnrollment] = useMutation(DELETE_ENROLLMENT, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleDeleteEnrollment = async () => {
    if (enrollmentToDelete) {
      await deleteEnrollment({ variables: { id: enrollmentToDelete } });
      setIsDeleteDialogOpen(false);
      setEnrollmentToDelete(null);
    }
  };

  if (loading) return <LoadingState />;
  if (error) return <p>Error loading enrollment data: {error.message}</p>;
  if (!data) return null;

  const enrollmentData = data.getParticipantEnrollments || [];

  return (
    <div className="space-y-8">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-2xl font-bold">Enrollment History</h1>
        </div>
        <Button
          variant="outline"
          className="mt-4 mr-2 text-blue-600 hover:bg-blue-800 bg-blue-600 text-white whitespace-nowrap"
          onClick={() =>
            setShowCreateEnrollment({ show: true, data: null, method: "create" })
          }
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Enrollment
        </Button>
      </div>

      <Card>
        <CardContent>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-neutral-200">
              <thead className="bg-neutral-50">
                <tr>
                  {[
                    "Enrollment Date",
                    "Site",
                    "Referring Agency",
                    "Referring Individual",
                    "Referral Type",
                    "Exit Date",
                    "Notes",
                    "Actions",
                  ].map((header) => (
                    <th
                      key={header}
                      className="px-6 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-neutral-200">
                {enrollmentData.length === 0 && (
                  <tr>
                    <td colSpan={8} className="px-6 py-4 text-center text-sm text-neutral-500">
                      No enrollment records found.
                    </td>
                  </tr>
                )}
                {enrollmentData.map((enrollment: any) => (
                  <tr key={enrollment.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-neutral-900">
                      {isoDateToMDY(enrollment.enrollmentDate)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-neutral-900">
                      {enrollment.site}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-neutral-900">
                      {enrollment.referringAgency}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-neutral-900">
                      {enrollment.referringIndividual}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-neutral-900">
                      {enrollment.referralType}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-neutral-900">
                      {enrollment.exitDate ? isoDateToMDY(enrollment.exitDate) : ''}
                    </td>
                    <td className="px-6 py-4 text-sm text-neutral-900">
                      {enrollment.notes}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-neutral-900">
                      <div className="flex space-x-2">
                        <Button
                          variant="ghost"
                          className="text-blue-600"
                          onClick={() =>
                            setShowCreateEnrollment({
                              show: true,
                              data: enrollment,
                              method: "edit",
                            })
                          }
                        >
                          Edit
                        </Button>
                        <Button
                          variant="ghost"
                          className="text-red-600"
                          onClick={() => {
                            setEnrollmentToDelete(enrollment.id);
                            setIsDeleteDialogOpen(true);
                          }}
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardContent>
      </Card>

      {showCreateEnrollment.method === "create" && (
        <CreateEnrollment
          participant={programNumber}
          onClose={() => {
            setShowCreateEnrollment({ show: false, data: null, method: "none" });
            refetch();
          }}
        />
      )}
      
      {showCreateEnrollment.method === "edit" && (
        <EditEnrollment
          participant={programNumber}
          onClose={() => {
            setShowCreateEnrollment({ show: false, data: null, method: "none" });
            refetch();
          }}
          data={showCreateEnrollment.data}
          // refetch={refetch}
        />
      )}

      <AlertDialog
        open={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
      >
        <AlertDialogContent className="bg-white p-6 rounded-lg shadow-lg">
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Enrollment Record</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this enrollment record? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setIsDeleteDialogOpen(false)}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction 
              className="bg-red-600 hover:bg-red-700"
              onClick={handleDeleteEnrollment}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};