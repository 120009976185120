import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useMutation, gql } from "@apollo/client";
import { AlertCircle } from "lucide-react";

const DELETE_BOOKING = gql`
  mutation DeleteBooking(
    $odrnumber: Integer!,
    $participant: Integer!,
    $bookingNumber: String!
  ) {
    deleteBooking(
      odrnumber: $odrnumber,
      participant: $participant,
      bookingNumber: $bookingNumber
    ) {
      message
    }
  }
`;

interface BookingData {
  bookingNumber: string;
}

interface DeleteBookingModalProps {
  isOpen: boolean;
  onClose: () => void;
  odrNumber: number;
  refetch: () => void;
  selectedProgram: string;
  bookingData: BookingData | null;
}

export const DeleteBookingModal = ({
  isOpen,
  onClose,
  odrNumber,
  refetch,
  selectedProgram,
  bookingData
}: DeleteBookingModalProps) => {
  const [deleteBooking] = useMutation(DELETE_BOOKING);

  const handleDelete = async () => {
    if (!bookingData) return;
    
    try {
      await deleteBooking({
        variables: {
          odrnumber: odrNumber,
          participant: parseInt(selectedProgram),
          bookingNumber: bookingData.bookingNumber,
        },
      });
      refetch();
      onClose();
    } catch (error) {
      console.error("Error deleting booking:", error);
    }
  };

  if (!bookingData) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-xl font-bold text-destructive">
            <AlertCircle className="h-6 w-6" />
            Delete Booking
          </DialogTitle>
          <DialogDescription className="text-base pt-2">
            Are you sure you want to delete booking{" "}
            <span className="font-medium">{bookingData.bookingNumber}</span>?
            This action cannot be undone.
          </DialogDescription>
        </DialogHeader>

        <div className="flex justify-end space-x-2 pt-4">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="destructive"
            onClick={handleDelete}
          >
            Delete Booking
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};