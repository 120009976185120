import React from "react";
import {
 Dialog,
 DialogContent,
 DialogHeader,
 DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useMutation, gql } from "@apollo/client";

const DELETE_HEARING = gql`
 mutation DeleteHearing($participant: Integer!, $hearingId: Integer!) {
   deleteHearing(participant: $participant, hearingId: $hearingId) {
     message
   }
 }
`;

export const DeleteHearingModal = ({
 isOpen,
 onClose,
 hearing,
 participant,
 refetch,
}: {
 isOpen: boolean;
 onClose: () => void;
 hearing: any;
 participant: number;
 refetch: () => void;
}) => {
 const [deleteHearing] = useMutation(DELETE_HEARING);

 const handleDelete = async () => {
   try {
     await deleteHearing({
       variables: {
         participant,
         hearingId: hearing?.hearingID,
       },
     });
     refetch();
     onClose();
   } catch (error) {
     console.error("Error deleting hearing:", error);
   }
 };

 return (
   <Dialog open={isOpen} onOpenChange={onClose}>
     <DialogContent className="max-w-md">
       <DialogHeader>
         <DialogTitle className="text-center text-2xl font-bold">
           Delete Hearing
         </DialogTitle>
       </DialogHeader>
       <div className="py-4 text-center">
         <p>Are you sure you want to delete this hearing?</p>
         <p className="text-sm text-muted-foreground mt-1">
           This action cannot be undone.
         </p>
       </div>
       <div className="flex justify-end space-x-2">
         <Button variant="outline" onClick={onClose}>
           Cancel
         </Button>
         <Button variant="destructive" onClick={handleDelete}>
           Delete
         </Button>
       </div>
     </DialogContent>
   </Dialog>
 );
};