import { useHelpSidebarVisibility } from '../store/useHelpSidebarVisibility';
import { useProgramColor } from '../store/useProgramColor';

export const Layout = ( {Header , Body, Sidbar, Footer, Help, settings} : any) => {    

    const {isHelpSidebarVisible} = useHelpSidebarVisibility();
    const style = isHelpSidebarVisible ? {display: 'grid', gridTemplateColumns: '1fr 450px'} : {};
    const { programColor } = useProgramColor();

    return (
        <div className={programColor}>
        <Header settings={settings}/>
        <div style={style}>            
            <Body/>
            {isHelpSidebarVisible && <Help/>}
        </div>
        <Footer/>
        </div>
    );
} 
