import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"

const GET_STATUS = gql`
    query GetStatus {
        status {
            serverName
            userAuthenticated
            userName
            version
        }
    }
`;

export function StatusPage() {
    const { loading, error, data } = useQuery(GET_STATUS);
    const [authCookie, setAuthCookie] = useState<string>('');

    // Get cookie on component mount
    React.useEffect(() => {
        const cookies = document.cookie.split(';');
        const auth = cookies.find(cookie => cookie.trim().startsWith('Authorization='));
        setAuthCookie(auth ? auth.split('=')[1] : 'Not found');
    }, []);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(authCookie);
    };

    if (loading) return <div className="flex justify-center p-4">Loading...</div>;
    if (error) return <div className="flex justify-center p-4 text-red-500">Error :(</div>;

    const { serverName, userAuthenticated, userName, version } = data.status;

    return (
        <div className="p-4">
            <Card>
                <CardHeader>
                    <CardTitle>Status</CardTitle>
                </CardHeader>
                <CardContent className="space-y-2">
                    <div className="grid gap-1">
                        <p className="text-sm font-medium">Server Name: {serverName}</p>
                        <p className="text-sm font-medium">User Authenticated: {userAuthenticated ? 'Yes' : 'No'}</p>
                        <p className="text-sm font-medium">User Name: {userName}</p>
                        <p className="text-sm font-medium">Version: {version}</p>
                        <div className="flex items-center gap-2">
                            <p className="text-sm font-medium max-w-[40%] break-words">Auth Cookie: {authCookie}</p>
                            <Button 
                                variant="default" 
                                size="sm" 
                                onClick={copyToClipboard}
                            >
                                Copy
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}

