import { gql } from '@apollo/client';

export interface ParticipantRecord {
  uniqueid: string;
  entryby: string;
  entrydate: string;
  program?: string;
}

export interface BaseParticipantData extends ParticipantRecord {
  status?: string;
  champid?: string;
  location?: string;
  notes?: string;
  mrun?: string;
  firstname?: string;
  lastname?: string;
  cid?: string;
  cii?: string;
  cin?: string;
  fin?: string;
  ssn?: string;
  acuity?: string;
  mainfour?: string;
  icd10code?: string;
  clinicempaneled?: string;
  note?: string;
  phone_number?: string;
  address?: string;
}

export interface ParticipantData {
  ab109AP: BaseParticipantData[];
  acuityAP: BaseParticipantData[];
  champidAP: BaseParticipantData[];
  cidAP: BaseParticipantData[];
  ciiAP: BaseParticipantData[];
  cinAP: BaseParticipantData[];
  consentsAP: BaseParticipantData[];
  ecmContactsAP: BaseParticipantData[];
  finAP: BaseParticipantData[];
  healthPlanAP: BaseParticipantData & {
    dhsempaneled?: string;
    ecm_assignment_date?: string;
    ecmmanager?: string;
    ecmprovider?: string;
    healthPlan?: string;
    healthplanabbr?: string;
    healthplancode?: string;
    pcpname?: string;
  }[];
  icd10AP: BaseParticipantData[];
  mainAP: BaseParticipantData[];
  medicalDemographicsNotesAP: BaseParticipantData[];
  mrunAP: BaseParticipantData[];
  ssnAP: BaseParticipantData[];
  wpcAP: BaseParticipantData[];
  
  ab109PP: BaseParticipantData[];
  adherencePP: BaseParticipantData & { adherence?: string }[];
  arrestDatePP: BaseParticipantData & { arrestdate?: string }[];
  dispositionPP: BaseParticipantData & { category?: string }[];
  champidPP: BaseParticipantData[];
  clinicalpresentationPP: BaseParticipantData & { clinicalpresentation?: string }[];
  completionDate: BaseParticipantData[];
  dobPP: BaseParticipantData & { dob?: string }[];
  dsmPP: BaseParticipantData & { dsm5dx?: string }[];
  isnumberPP: BaseParticipantData[];
  istPP: BaseParticipantData[];
  locationPP: BaseParticipantData[];
  maxdatePP: BaseParticipantData & { maxdate?: string }[];
  medicalProblemsPP: BaseParticipantData & { medicalproblems?: string }[];
  mrunPP: BaseParticipantData[];
  namesPP: BaseParticipantData[];
  notesPP: BaseParticipantData[];
  otherMedsPP: BaseParticipantData & { othermeds?: string }[];
  pathwayPP: BaseParticipantData & { pathway?: string }[];
  providerPP: BaseParticipantData & { provider?: string }[];
  psychHistoryPP: BaseParticipantData & { psychhistory?: string }[];
  psychotropicmedsPP: BaseParticipantData & { psychotropicmeds?: string }[];
  releasedPP: BaseParticipantData & { released?: boolean }[];
  releaseDatePP: BaseParticipantData & { releasedate?: string }[];
  sexoffenderPP: BaseParticipantData & { sexoffender?: boolean }[];
  substanceHistoryPP: BaseParticipantData[];
}

export const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumberAdmin($odrnumber: Integer!) {
    getParticipantByODRNumberAdmin(odrnumber: $odrnumber) {
      ab109AP {uniqueid status entryby entrydate}
      acuityAP {uniqueid acuity entryby entrydate}
      champidAP {uniqueid champid entryby entrydate}
      cidAP {uniqueid cid entryby entrydate}
      ciiAP {uniqueid cii entryby entrydate}
      cinAP {uniqueid cin entryby entrydate}
      consentsAP {uniqueid entryby entrydate}
      ecmContactsAP {uniqueid phone_number address entryby entrydate}
      finAP {uniqueid fin entryby entrydate}
      healthPlanAP {uniqueid clinicempaneled dhsempaneled ecm_assignment_date ecmmanager ecmprovider healthPlan healthplanabbr healthplancode pcpname entryby entrydate}
      icd10AP {uniqueid icd10code entryby entrydate}
      mainAP {uniqueid mainfour entryby entrydate}
      medicalDemographicsNotesAP {uniqueid note entryby entrydate}
      mrunAP {uniqueid mrun entryby entrydate}
      ssnAP {uniqueid ssn entryby entrydate}
      wpcAP {uniqueid wpc entryby entrydate}
      
      ab109PP {uniqueid status program entryby entrydate}
      adherencePP {uniqueid adherence program entryby entrydate}
      arrestDatePP {uniqueid arrestdate program entryby entrydate}
      dispositionPP {uniqueid category program entryby entrydate}
      champidPP {uniqueid champid entryby program entrydate}
      clinicalpresentationPP {uniqueid clinicalpresentation program entryby entrydate}
      completionDate {uniqueid program entryby entrydate}
      dobPP {uniqueid dob program entryby entrydate}
      dsmPP {uniqueid dsm5dx program entryby entrydate}
      isnumberPP {uniqueid program entryby entrydate}
      istPP {uniqueid program entryby entrydate}
      locationPP {uniqueid location program entryby entrydate}
      maxdatePP {uniqueid maxdate program entryby entrydate}
      medicalProblemsPP {uniqueid medicalproblems program entryby entrydate}
      mrunPP {uniqueid mrun program entryby entrydate}
      namesPP {uniqueid firstname lastname program entryby entrydate}
      notesPP {uniqueid notes program entryby entrydate}
      otherMedsPP {uniqueid othermeds program entryby entrydate}
      pathwayPP {uniqueid pathway program entryby entrydate}
      providerPP {uniqueid provider program entryby entrydate}
      psychHistoryPP {uniqueid psychhistory program entryby entrydate}
      psychotropicmedsPP {uniqueid psychotropicmeds program entryby entrydate}
      releasedPP {uniqueid released program entryby entrydate}
      releaseDatePP {uniqueid releasedate program entryby entrydate}
      sexoffenderPP {uniqueid sexoffender program entryby entrydate}
      substanceHistoryPP {uniqueid entryby entrydate}
    }
  }
`; 
