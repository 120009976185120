import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { isoDateToMDY } from "../../utils/common";
import { EditHearingModal } from "./modals/EditHearing";
import { AddBookingModal } from "./modals/AddBooking";
import { AddCaseModal } from "./modals/AddCase";
import { AddHearingModal } from "./modals/AddHearing";
import { EditCaseModal } from "./modals/EditCase";
import { DeleteCaseModal } from "./modals/DeleteCase";
import { EditBookingModal } from "./modals/EditBooking";
import { DeleteBookingModal } from "./modals/DeleteBooking";
import {
	PenSquare,
	Calendar,
	ClipboardList,
	Scale,
	AlertCircle,
	Building,
	Plus,
	Edit,
	FileText,
	Gavel,
	ChevronDown,
	ChevronUp,
	Trash,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import {
	Table,
	TableHeader,
	TableBody,
	TableRow,
	TableHead,
	TableCell,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Skeleton } from "@/components/ui/skeleton";
import {
	Accordion,
	AccordionItem,
	AccordionTrigger,
	AccordionContent,
} from "@/components/ui/accordion";
import { DeleteHearingModal } from "./modals/DeleteHearing";

interface EditBookingState {
	modalOpen: boolean;
	bookingData: {
		bookingNumber: string;
		arrestDate: string;
		releaseDate: string;
	} | null;
}

interface DeleteBookingState {
	modalOpen: boolean;
	bookingData: {
		bookingNumber: string;
	} | null;
}


const GET_LEGAL_STATUS_DATA = gql`
  query GetLegalStatusData($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
      bookingDetails {
        booking
        bookingNumber
        diversionDate
        releaseDate
        arrestDate
        location
        race
        cases {
          caseNumber
          program
          entryDate
          hearings {
            hearingDate
			hearingID
            details
            type
            program
            courtOrders
            courtOther
            minutes
          }
          charges {
            charge
            charge_entrydate
          }
        }
        arrestDate
      }
      bookingNumber
      bookingHistory {
        value
        program
      }
      casesHistory {
        caseOpen
        caseNumber
        bookingNumber
        entryDate
        program
      }
      hearings {
        hearingDate
        details
        type
        program
        courtOrders
        courtOther
        minutes
      }
      arrestDate
      arrestDateHistory {
        value
        program
      }
      istStatus {
        status
        statusDate
      }
      maxDate
      rso
      mainNumber
      commitmentDate
      diversionDate
      releasedDate
    }
  }
`;

const LoadingState = () => (
	<div className="space-y-4">
		<Skeleton className="h-8 w-[200px]" />
		<div className="grid grid-cols-3 gap-4">
			<Skeleton className="h-[100px]" />
			<Skeleton className="h-[100px]" />
			<Skeleton className="h-[100px]" />
		</div>
	</div>
);

export const LegalStatus = ({ odrNumber, programNumber }: { odrNumber: number, programNumber: number }) => {
	const [expandedBookings, setExpandedBookings] = useState<string[]>([]);
	const [deleteHearingData, setDeleteHearingData] = useState({
		modalOpen: false,
		hearing: null
	});
	const [isAddBookingModalOpen, setIsAddBookingModalOpen] = useState(false);
	const [isAddCaseModalOpen, setIsAddCaseModalOpen] = useState({ modelOpen: false, bookingNumber: null });
	const [isAddHearingModalOpen, setIsAddHearingModalOpen] = useState({ modalOpen: false, bookingNumber: null, caseNumber: null });
	const { loading, error, data, refetch } = useQuery(GET_LEGAL_STATUS_DATA, {
		variables: { odrnumber: odrNumber },
		skip: !programNumber,
		fetchPolicy: "network-only",
	});
	const [editHearingData, setEditHearingData] = useState({
		modalOpen: false,
		hearing: null,
		bookingNumber: null,
		caseNumber: null
	});
	const [editCaseData, setEditCaseData] = useState({
		modalOpen: false,
		caseData: null,
		bookingNumber: null
	});

	const [deleteCaseData, setDeleteCaseData] = useState({
		modalOpen: false,
		caseData: null
	});
	const [editBookingData, setEditBookingData] = useState<EditBookingState>({
		modalOpen: false,
		bookingData: null
	});
	const [deleteBookingData, setDeleteBookingData] = useState<DeleteBookingState>({
		modalOpen: false,
		bookingData: null
	});


	console.log(programNumber, odrNumber, 'odrNumber', 'programnumber')

	console.log('odrNumber', !data?.getParticipantByODRNumber)

	if (loading) return <LoadingState />;

	if (error || !data?.getParticipantByODRNumber)
		return (
			<Card className="bg-destructive/10">
				<CardContent className="p-4 flex items-center">
					<AlertCircle className="h-5 w-5 mr-2 text-destructive" />
					<span className="text-destructive">
						Error loading legal status data, please select a program
					</span>
				</CardContent>
			</Card>
		);

	if (!data) return null;

	const toggleBooking = (bookingNumber: string) => {
		setExpandedBookings((prev) =>
			prev.includes(bookingNumber)
				? prev.filter((b) => b !== bookingNumber)
				: [...prev, bookingNumber]
		);
	};

	const legalData = data?.getParticipantByODRNumber;

	return (
		<div className="space-y-8">
			<div className="flex justify-between">
				<div className="flex items-center gap-2">
					<Scale className="h-6 w-6" />
					<h1 className="text-2xl font-bold">Legal Status</h1>
				</div>
				<Button
					variant="outline"
					className="mt-4 mr-2 text-blue-600  hover:bg-blue-800 bg-blue-600 text-white  whitespace-nowrap"
					onClick={() => setIsAddBookingModalOpen(true)}
				>
					<Plus className="h-4 w-4 mr-2" />
					Add Booking
				</Button>
			</div>
			<div className="space-y-4">
				{legalData?.bookingDetails?.map((booking: any) => (
					<div
						key={booking.bookingNumber}
						className="border border-gray-500 rounded-md bg-gray-50"
					>
						<div className="flex justify-between p-4">
							<div className="grid grid-cols-4 gap-4 w-full">
								<div>
									<div className="text-sm font-bold">Program</div>
									<div className="text-blue-500 font-bold uppercase">{legalData?.bookingHistory
										?.filter((caseItem: { value: any; }) =>
											caseItem.value === booking.bookingNumber
										)
										.map((caseItem: { program: any; }) => caseItem.program)
										.join(', ')}</div>
								</div>
								<div>
									<div className="text-sm font-bold">Booking</div>
									<div className="text-gray-500">{booking.bookingNumber}</div>
								</div>
								<div>
									<div className="text-sm font-bold">MAIN last 4</div>
									<div className="text-gray-500">{legalData?.mainNumber}</div>
								</div>
								<div>
									<div className="text-sm font-bold">Arrest date</div>
									<div className="text-gray-500">{new Date(legalData.arrestDate).toLocaleDateString()}</div>
								</div>
								<div>
									<div className="text-sm font-bold">Release Date</div>
									<div className="text-gray-500">{new Date(booking.releaseDate).toLocaleDateString()}</div>
								</div>
								<div>
									<div className="text-sm font-bold">Declared IST</div>
									<div>{legalData.istStatus.status === '1' ? 'Competent to stand trial' : 'Incompetent to stand trial'}</div>
									<div className="text-gray-500">{new Date(legalData.istStatus.statusDate).toLocaleDateString()}</div>
								</div>
								<div>
									<div className="text-sm font-bold">Max Date</div>
									<div className="text-gray-500">{new Date(legalData.maxDate).toLocaleDateString()}</div>
								</div>
								<div>
									<div className="text-sm font-bold">RSO</div>
									<div className="text-gray-500">{legalData.rso === '1' ? 'Yes' : 'No'}</div>
								</div>

							</div>
							<div>
								{expandedBookings.includes(booking.bookingNumber) ? (
									<div className="flex gap-2">
										<Button
											variant="ghost"
											className="text-blue-600 min-w-[120px] text-xs"
											onClick={() => setIsAddCaseModalOpen({ modelOpen: true, bookingNumber: booking.bookingNumber })}
										>
											<Plus className="h-4 w-4 mr-1" />
											Add Case
										</Button>
										<Button
											variant="ghost"
											className="text-blue-600 min-w-[120px] text-xs whitespace-nowrap"
											onClick={() => setEditBookingData({
												modalOpen: true,
												bookingData: {
													bookingNumber: booking.bookingNumber,
													arrestDate: booking.arrestDate,
													releaseDate: booking.releaseDate
												}
											})}
										>
											<Edit className="h-4 w-4 mr-1" />
											Edit Booking
										</Button>

										<Button
											variant="ghost"
											className="text-red-600 min-w-[120px] text-xs whitespace-nowrap"
											onClick={() => setDeleteBookingData({
												modalOpen: true,
												bookingData: {
													bookingNumber: booking.bookingNumber
												}
											})}
										>
											<Trash className="h-4 w-4 mr-1" />
											Delete Booking
										</Button>

										<Button
											variant="ghost"
											className="text-blue-600 min-w-[120px] text-xs whitespace-nowrap"
											onClick={(e) => {
												e.stopPropagation();
												toggleBooking(booking.bookingNumber);
											}}
										>
											<ChevronUp className="h-4 w-4 mr-1" />
											Hide Details
										</Button>
									</div>
								) : (
									<Button
										variant="ghost"
										className="text-blue-600 min-w-[160px] text-xs"
										onClick={() => toggleBooking(booking.bookingNumber)}
									>
										<ChevronDown className="h-4 w-4 mr-1" />
										Click for details
									</Button>
								)}
							</div>
						</div>

						{expandedBookings.includes(booking.bookingNumber) && (
							<div className="border-t border-gray-200">
								{booking.cases?.map((courtCase: any) => (
									<div
										key={courtCase.caseNumber}
										className="pb-6 bg-white border-t border-gray-500"
									>
										<div className="flex justify-between">
											<div className="grid grid-cols-3 gap-4 text-sm mb-2 ml-2">
												<span>
													<span className="text-sm mr-4 font-bold">
														Case Number:
													</span>
													<span className="text-sm mr-4 text-gray-500">
														{courtCase.caseNumber}
													</span>
													<span className="text-sm mr-4 text-gray-500">
														OPEN
													</span>
												</span>
												<span>
													<span className="text-sm font-bold mr-4">
														Charges:
													</span>
													<span className="text-sm mr-4 text-gray-500">
														{courtCase.charges
															?.map((charge: any) => charge.charge)
															.join(", ")}
													</span>
												</span>
												<span></span>
											</div>
											<div className="flex justify-end">
												<div className="ml-2 mr-2">
													<Button
														variant="ghost"
														className="p-1 inline-flex items-center text-xs rounded-md text-blue-600   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-1"
														onClick={() => setIsAddHearingModalOpen({ modalOpen: true, bookingNumber: booking.bookingNumber, caseNumber: courtCase.caseNumber })}
													>
														<Plus className="h-4 w-4 mr-1 text-xs" />
														Add Hearing
													</Button>
												</div>
												<div className="ml-2 mr-2">
													<Button
														variant="ghost"
														className="p-1 inline-flex items-center text-xs rounded-md text-blue-600   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-1"
														onClick={() => setEditCaseData({
															modalOpen: true,
															caseData: courtCase,
															bookingNumber: booking.bookingNumber
														})}
													>
														<FileText className="h-4 w-4 mr-1 text-xs" />
														Edit Case
													</Button>
													<Button
														variant="ghost"
														className="p-1 inline-flex items-center text-xs rounded-md text-red-600   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mt-1"
														onClick={() => setDeleteCaseData({
															modalOpen: true,
															caseData: courtCase
														})}
													>
														<Trash className="h-4 w-4 mr-1 text-xs" />
														Delete Case
													</Button>
												</div>
											</div>
										</div>
										<div className="ml-2 pt-2">
											<div className="flex flex-col gap-2">
												<div className="grid grid-cols-[1fr_auto]">
													<div className="grid grid-cols-5">
														<div className="text-sm font-bold">
															Next Hearing Date
														</div>
														<div className="text-sm font-bold">
															Hearing Details
														</div>
														<div className="text-sm font-bold">
															Hearing Type
														</div>
														<div className="text-sm font-bold">
															Court Orders
														</div>
														<div className="text-sm font-bold">Minutes</div>
													</div>
													<div className="w-[180px]"></div>
												</div>

												<div className="flex flex-col">
													{courtCase?.hearings?.map((hearing: any) => (
														<div
															key={hearing.hearingDate}
															className={`grid grid-cols-[2fr_auto]  rounded-md `}
														>
															<div className="grid grid-cols-5">
																<div className="text-sm text-gray-500">
																	{new Date(Number(hearing.hearingDate)).toLocaleDateString().split('T')[0]}
																</div>
																<div className="text-sm text-gray-500">
																	{hearing.details}
																</div>
																<div className="text-sm text-gray-500">
																	{hearing.type}
																</div>
																<div className="text-sm text-gray-500">
																	{hearing.courtOrders?.join(", ")}
																</div>
																<div className="text-sm text-gray-500">
																	{hearing.minutes}
																</div>
															</div>
															<div className="w-[180px] flex">
																<button
																	className="text-xs text-blue-600   hover:rounded-md w-[120px] whitespace-nowrap flex items-center justify-center font-medium"
																	onClick={() => setEditHearingData({
																		modalOpen: true,
																		hearing,
																		bookingNumber: booking.bookingNumber,
																		caseNumber: courtCase.caseNumber
																	})}
																>
																	<Edit className="h-4 w-4 mr-1" />
																	Edit Hearing
																</button>
																<button
																	className="text-xs text-red-600   hover:rounded-md w-[120px] whitespace-nowrap flex items-center justify-center font-medium"
																	onClick={() => setDeleteHearingData({
																		modalOpen: true,
																		hearing
																	})}
																>
																	<Trash className="h-4 w-4 mr-1" />
																	Delete
																</button>

															</div>
														</div>
													))}
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						)}
					</div>
				))}
			</div>
			<AddBookingModal
				isOpen={isAddBookingModalOpen}
				onClose={() => setIsAddBookingModalOpen(false)}
				odrNumber={odrNumber}
				refetch={() => { refetch() }}
				selectedProgram={programNumber.toString()}
			/>
			<AddCaseModal
				isOpen={isAddCaseModalOpen.modelOpen}
				onClose={() => setIsAddCaseModalOpen({ modelOpen: false, bookingNumber: null })}
				refetch={() => { refetch() }}
				odrNumber={odrNumber}
				bookingNumber={isAddCaseModalOpen.bookingNumber ? isAddCaseModalOpen.bookingNumber : ''}
				participant={programNumber}
			/>
			<AddHearingModal
				isOpen={isAddHearingModalOpen.modalOpen}
				onClose={() => setIsAddHearingModalOpen({ modalOpen: false, bookingNumber: null, caseNumber: null })}
				refetch={() => { refetch() }}
				odrNumber={odrNumber}
				bookingNumber={isAddHearingModalOpen.bookingNumber ? isAddHearingModalOpen.bookingNumber : ''}
				caseNumber={isAddHearingModalOpen.caseNumber ? isAddHearingModalOpen.caseNumber : ''}
				participant={programNumber}
			/>
			<EditHearingModal
				isOpen={editHearingData.modalOpen}
				onClose={() => setEditHearingData({
					modalOpen: false,
					hearing: null,
					bookingNumber: null,
					caseNumber: null
				})}
				hearing={editHearingData.hearing}
				participant={programNumber}
				caseNumber={editHearingData.caseNumber ? editHearingData.caseNumber : ''}
				refetch={refetch}
			/>
			<DeleteHearingModal
				isOpen={deleteHearingData.modalOpen}
				onClose={() => setDeleteHearingData({
					modalOpen: false,
					hearing: null
				})}
				hearing={deleteHearingData.hearing}
				participant={programNumber}
				refetch={refetch}
			/>
			<EditCaseModal
				isOpen={editCaseData.modalOpen}
				onClose={() => setEditCaseData({
					modalOpen: false,
					caseData: null,
					bookingNumber: null
				})}
				caseData={editCaseData.caseData}
				participant={programNumber}
				bookingNumber={editCaseData.bookingNumber || ''}
				refetch={refetch}
			/>
			<DeleteCaseModal
				isOpen={deleteCaseData.modalOpen}
				onClose={() => setDeleteCaseData({
					modalOpen: false,
					caseData: null
				})}
				caseData={deleteCaseData.caseData}
				participant={programNumber}
				refetch={refetch}
			/>
			<EditBookingModal
				isOpen={editBookingData.modalOpen}
				onClose={() => setEditBookingData({
					modalOpen: false,
					bookingData: null
				})}
				odrNumber={odrNumber}
				refetch={refetch}
				selectedProgram={programNumber.toString()}
				bookingData={editBookingData.bookingData}
			/>
			<DeleteBookingModal
				isOpen={deleteBookingData.modalOpen}
				onClose={() => setDeleteBookingData({
					modalOpen: false,
					bookingData: null
				})}
				odrNumber={odrNumber}
				refetch={refetch}
				selectedProgram={programNumber.toString()}
				bookingData={deleteBookingData.bookingData}
			/>
		</div>
	);
};
