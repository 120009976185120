import React, { useState, useEffect } from 'react';
import "./formRender.css";
import { FormValidator } from './validate';

// Updated to use Tailwind grid classes instead
const getColumnsClass = (columns: string) => {
  if (columns === "2") return "grid grid-cols-2 gap-4";
  if (columns === "3") return "grid grid-cols-3 gap-4";
  return "";
};

const renderChildrenReadOnly = (formTemplate: any, formState: any, setFormState: any, onSubmitForm: any, depth = 0) => {
  return formTemplate.map((item: any, index: number) => {
    if (item.type === 'section') {
      const parent = depth ? '' : 'mb-6';
      const columnsClass = getColumnsClass(item.columns);

      return (
        <div key={index} className={`mb-4 ${parent}`}>
          <h3 className="text-sm font-medium text-gray-700 mb-2">{item.text || item.label}</h3>
          <div className={columnsClass}>
            {renderChildrenReadOnly(item.children, formState, setFormState, 1)}
          </div>
        </div>
      );
    }
    
    if (item.type === 'text') 
      return <div key={index} className="text-sm text-gray-600 mb-2">{item.text}</div>;

    if (item.type === 'bullet') 
      return <li key={index} className="text-sm text-gray-600">{item.text}</li>;

    if (item.type === 'bulletList') {
      return (
        <ul className="list-disc pl-5 mb-3 text-sm text-gray-600">
          {item.items.map((item: any, index: any) => {
            return <li key={index}>{item}</li>;
          })}
        </ul>
      );
    }

    if (item.type === 'paragraph') {
      return <p key={index} className="text-sm text-gray-600 mb-3">{item.text}</p>;
    }

    if (item.type === 'heading') {
      return <h2 key={index} className="text-base font-medium text-gray-700 mb-2">{item.text}</h2>;
    }

    if (item.type === 'textInput') {
      return (
        <div key={index} className="mb-3">
          <label id={`label${item.key}`} className="block text-xs font-medium text-gray-600 mb-1" htmlFor={item.key}>
            {item.label}
          </label>
          <div className="text-sm border border-gray-200 rounded px-2 py-1.5 bg-gray-50">
            {formState[item.key] || '—'}
          </div>
        </div>
      );
    }

    if (item.type === 'staticInput') {
      return (
        <div key={index} className="mb-3">
          <label className="block text-xs font-medium text-gray-600 mb-1" htmlFor={item.key}>
            {item.label}
          </label>
          <div className="text-sm border border-gray-200 rounded px-2 py-1.5 bg-gray-50">
            {formState[item.key] || '—'}
          </div>
        </div>
      );
    }

    if (item.type === 'textAreaInput') {
      return (
        <div key={index} className="mb-3">
          <label className="block text-xs font-medium text-gray-600 mb-1" htmlFor={item.key}>
            {item.label}
          </label>
          <div className="text-sm border border-gray-200 rounded px-2 py-1.5 bg-gray-50 min-h-[60px]">
            {formState[item.key] || '—'}
          </div>
        </div>
      );
    }

    if (item.type === 'yesnoInput') {
      return (
        <div key={index} className="flex items-center mb-3">
          <div className="w-5 h-5 mr-2 border border-gray-300 rounded flex items-center justify-center bg-gray-50">
            {formState[item.key] ? "✓" : ""}
          </div>
          <label className="text-xs font-medium text-gray-600" htmlFor={item.key}>
            {item.label}
          </label>
        </div>
      );
    }

    if (item.type === 'listInput') {
      return (
        <div key={index} className="mb-3">
          <label className="block text-xs font-medium text-gray-600 mb-1" htmlFor={`label${item.key}`}>
            {item.label}
          </label>
          <div className="text-sm border border-gray-200 rounded px-2 py-1.5 bg-gray-50">
            {item.options.find((option: any) => option.value === formState[item.key])?.label || '—'}
          </div>
        </div>
      );
    }

    if (item.type === 'dateInput') {
      return (
        <div key={index} className="mb-3">
          <label className="block text-xs font-medium text-gray-600 mb-1" htmlFor={item.key}>
            {item.label}
          </label>
          <div className="text-sm border border-gray-200 rounded px-2 py-1.5 bg-gray-50">
            {formState[item.key] || '—'}
          </div>
        </div>
      );
    }
    
    return <React.Fragment key={index} />;
  });
};

const renderChildren = (formTemplate: any, formState: any, handleChange: any, onSubmitForm: any, depth = 0) => {
  return formTemplate.map((item: any, index: number) => {
    if (item.type === 'section') {
      const outerClass = item.outercss || "mb-4";
      const innerClass = item.innercss || getColumnsClass(item.columns);
      
      return (
        <div key={index} className={outerClass}>
          <h3 className="text-sm font-medium text-gray-700 mb-2">{item.label}</h3>
          <div className={innerClass}>
            {renderChildren(item.children, formState, handleChange, 1)}
          </div>
        </div>
      );
    }
    
    if (item.type === 'text') 
      return <div key={index} className="text-sm text-gray-600 mb-2">{item.text}</div>;

    if (item.type === 'bullet') 
      return <li key={index} className="text-sm text-gray-600">{item.text}</li>;

    if (item.type === 'bulletList') {
      return (
        <ul className="list-disc pl-5 mb-3 text-sm text-gray-600">
          {item.items.map((item: any, index: any) => {
            return <li key={index}>{item.text}</li>;
          })}
        </ul>
      );
    }

    if (item.type === 'paragraph') {
      return <p key={index} className="text-sm text-gray-600 mb-3">{item.text}</p>;
    }

    if (item.type === 'heading') {
      return <h2 key={index} className="text-base font-medium text-gray-700 mb-2">{item.text}</h2>;
    }

    if (item.type === 'textInput') {
      return (
        <div key={index} className="mb-3">
          <label id={`label${item.key}`} className="block text-xs font-medium text-gray-600 mb-1" htmlFor={item.key}>
            {item.label}
          </label>
          <input 
            name={item.key}
            type="text"
            id={item.key} 
            value={formState[item.key] || ''} 
            onChange={handleChange}
            className="w-full text-sm border border-gray-200 rounded px-2 py-1.5 focus:outline-none focus:ring-1 focus:ring-blue-300 focus:border-blue-300"
          />
        </div>
      );
    }

    if (item.type === 'staticInput') {
      return (
        <div key={index} className="mb-3">
          <label className="block text-xs font-medium text-gray-600 mb-1" htmlFor={item.key}>
            {item.label}
          </label>
          <input 
            name={item.key}
            type="text"
            id={item.key} 
            value={formState[item.key] || ''} 
            readOnly={true}
            onChange={() => {}}
            className="w-full text-sm border border-gray-200 rounded px-2 py-1.5 bg-gray-50"
          />
        </div>
      );
    }

    if (item.type === 'textAreaInput') {
      return (
        <div key={index} className="mb-3">
          <label className="block text-xs font-medium text-gray-600 mb-1" htmlFor={item.key}>
            {item.label}
          </label>
          <textarea 
            id={item.key} 
            name={item.key}
            value={formState[item.key] || ''} 
            onChange={handleChange}
            className="w-full text-sm border border-gray-200 rounded px-2 py-1.5 min-h-[60px] focus:outline-none focus:ring-1 focus:ring-blue-300 focus:border-blue-300"
          />
        </div>
      );
    }

    if (item.type === 'yesnoInput') {
      return (
        <div key={index} className="flex items-center mb-3">
          <input 
            type="checkbox" 
            id={item.key} 
            name={item.key}
            checked={formState[item.key] == 1}
            onChange={handleChange}
            className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-400"
          />
          <label className="ml-2 text-xs font-medium text-gray-600" htmlFor={item.key}>
            {item.label}
          </label>
        </div>
      );
    }

    if (item.type === 'listInputDowndown') {
      return (
        <div key={index} className="mb-3">
          <label className="block text-xs font-medium text-gray-600 mb-1" htmlFor={`label${item.key}`}>
            {item.label}
          </label>
          <select
            id={item.key}
            name={item.key}
            value={formState[item.key] || ''}
            onChange={handleChange}
            className="w-full text-sm border border-gray-200 rounded px-2 py-1.5 focus:outline-none focus:ring-1 focus:ring-blue-300 focus:border-blue-300"
          >
            <option value={''}>-- Select --</option>
            {item.options.map((option: any, optionIndex: number) => (
              <option key={optionIndex} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      );
    }

    if (item.type === 'listInput') {
      return (
        <div key={index} className="mb-3">
          <label className="block text-xs font-medium text-gray-600 mb-1" htmlFor={`label${item.key}`}>
            {item.label}
          </label>
          <div className="space-y-1">
            {item.options.map((option: any, optionIndex: number) => (
              <div key={optionIndex} className="flex items-center">
                <input 
                  type="radio" 
                  id={option.id}  
                  name={item.key}
                  value={option.value}
                  checked={formState[item.key] === option.value} 
                  onChange={handleChange}
                  className="h-4 w-4 text-blue-500 border-gray-300 focus:ring-blue-400"
                />
                <label className="ml-2 text-xs text-gray-600" htmlFor={option.id}>{option.label}</label>
              </div>
            ))}
          </div>
        </div>
      );
    }

    if (item.type === 'dateInput') {
      const formatedDate = (formState[item.key]) ? new Date(formState[item.key]).toISOString().split('T')[0] : '';
      return (
        <div key={index} className="mb-3">
          <label className="block text-xs font-medium text-gray-600 mb-1" htmlFor={item.key}>
            {item.label}
          </label>
          <input 
            type="date" 
            name={item.key}
            id={item.key} 
            value={formatedDate}
            onChange={handleChange}
            className="w-full text-sm border border-gray-200 rounded px-2 py-1.5 focus:outline-none focus:ring-1 focus:ring-blue-300 focus:border-blue-300"
          />
        </div>
      );
    }

    if (item.type === 'submit') {
      return (
        <div key={index} className="mb-3 mt-4">
          <button 
            type="submit" 
            onClick={onSubmitForm}
            className="px-3 py-1.5 bg-blue-500 text-white text-sm rounded hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-300"
          >
            {item.label}
          </button>
        </div>
      );
    }
    
    return <React.Fragment key={index} />;
  });
};

export const FormRenderer = (params: any) => {
  const { formTemplate, formState, setFormState, onSubmit, readOnly } = params;
  
  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      FormValidator(formTemplate, formState);
      onSubmit();
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event: any) => {
    const { id, value, type, checked } = event.target;
    
    if (type === 'checkbox') {
      setFormState({
        ...formState,
        [id]: checked ? 1 : 0,
      });
      return;
    }
    
    if (type === 'radio') {
      setFormState({
        ...formState,
        [event.target.name]: value,
      });
      return;
    }
    
    setFormState({
      ...formState,
      [id]: value,
    });
  };

  return (
    <form onSubmit={onSubmitForm}>
      <div className={readOnly ? 'p-4' : 'p-4 border border-gray-200 rounded-md bg-white shadow-sm'}>
        {readOnly 
          ? renderChildrenReadOnly(formTemplate, formState, handleChange, () => {}) 
          : renderChildren(formTemplate, formState, handleChange, onSubmitForm)
        }
      </div>
    </form>
  );
};

export const FormInput = FormRenderer;