import * as React from 'react';
import { toast } from 'react-toastify';
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'

import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,    
    split,
    HttpLink,
    ApolloLink,    
} from "@apollo/client";

import { onError } from '@apollo/client/link/error';

//import { getMainDefinition } from '@apollo/client/utilities'
//import { WebSocketLink } from '@apollo/client/link/ws'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      //Handle GraphQL errors

      if (error.message=="Token expired"){        
        window.location.href = "/login"
        return
      }
      
      toast.error(error.message);
      
        
    });
  }
  if (networkError) {
    // Handle network errors
    toast.error('Network error occurred. Please try again.');
  }
});


const createWebConnection = (server: any, auth: any) =>{

  const httpLink = new HttpLink({
    uri: server.http,
    headers:  {
      authorization: `Bearer ${auth}`
    }
  });

  const wsLink = new WebSocketLink({
    uri: server.ws,
    options: {
      reconnect: true,
      connectionParams: (!auth) ? undefined : {
        headers : {authorization: `JWT ${auth}`}
      }
    }
  });
  
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );
  
  
  return splitLink
}


const ApolloAuthContext = React.createContext (null) as any

export const Apollo = (params: any) =>{
    const {children, server,  auth} = params    
    const [client, setClient] = React.useState(null) as any
    
    React.useEffect (()=>{
      
      const client = (auth)
      ? new ApolloClient({  
        cache: new InMemoryCache(),
        link: ApolloLink.from ([errorLink,createWebConnection(server, auth)]),
      })            
      : new ApolloClient({
        cache: new InMemoryCache(),
            uri: server.http            
      })

      setClient(client)      
    },[auth, server])

   

    const value = {      
      client : client,    
    }

    if (client==null)
      return (<></>)

    return (
        <ApolloProvider client={client}>
            <ApolloAuthContext.Provider value={value}>
              {children}
            </ApolloAuthContext.Provider>
        </ApolloProvider>
    )
}

const handleApolloError = (error: any) => {
  // Display toast error for each error in the ApolloError array
  if (error.graphQLErrors) {
    const errorMessage = error.graphQLErrors
      .map((graphQLError:any) => graphQLError.message)
      .join('\n'); // Combine multiple errors into a single string

    toast.error(errorMessage);
  } else {
    // Handle network errors or other non-GraphQL errors
    toast.error('An error occurred. Please try again.');
  }
};