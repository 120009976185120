import { gql, useQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { Download } from 'lucide-react';
import { toast } from 'react-toastify';
import { FORMATS } from './reportsTable';
import Modal from 'react-modal';



interface Report {
  section: string;
  id: string;
  description: string;
  menuorder: number;
  name: string;
}

interface SectionMap {
  [key: string]: Report[];
}

interface ReportDetails {
  statement: string;
  documentData: string;
}

const CREATE_REPORT = gql`
  mutation CreateReport($input: CreateReportInput!) {
    createReport(input: $input) {
      id
    }
  }
`;

const GET_PROGRAM_REPORT = gql`
  query GetProgramReport($report: String!, $format: String!) {
    getProgramReport(report: $report, format: $format) {
      documentType
      documentData
    }
  }
`;

const GET_PROGRAM_REPORT_DETAILS = gql`
  query GetProgramReportDetails($report: String!) {
    getProgramReportDetails(report: $report) {
      section
      id
      name
      menuorder
      statement
      
    }
  }
`;


const GET_REPORTS = gql`
  query GetReports {
    getReports {
      section
      id
      name
      menuorder      
    }
  }
`;

interface CreateReportData {
  section: string;
  name: string;
  menuorder: string;
  statement: string;
}

const UPDATE_REPORT_DETAILS = gql`
  mutation UpdateReportDetails($report: String!, $statement: String!) {
    updateReportDetails(report: $report, statement: $statement) {
      id
      statement
    }
  }
`;

export const ReportsCenter = () => {
  const [selectedSection, setSelectedSection] = useState<string>('');
  const [selectedReport, setSelectedReport] = useState<string>('');
  const [selectedFormat, setSelectedFormat] = useState<string>('pdf');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statement, setStatement] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [createFormData, setCreateFormData] = useState<CreateReportData>({
    section: '',
    name: '',
    menuorder: '',
    statement: ''
  });

  const { data: reportsData } = useQuery(GET_REPORTS);

  const sections = reportsData?.getReports.reduce((acc: SectionMap, report: Report) => {
    if (!acc[report.section]) {
      acc[report.section] = [];
    }
    acc[report.section].push(report);
    return acc;
  }, {} as SectionMap);

  Object.values(sections || {}).forEach((reports: any) => {
    reports.sort((a: any, b: any) => a.menuorder - b.menuorder);
  });

  const handleDownload = () => {
    if (!selectedReport) {
      toast.error('Please select a report');
      return;
    }

    getReport({
      variables: {
        report: selectedReport,
        format: selectedFormat
      }
    });
  };

  const { loading, refetch: getReport } = useQuery(GET_PROGRAM_REPORT, {
    skip: true,
    variables: {
      report: selectedReport,
      format: selectedFormat
    },
    onCompleted: (data) => {
      try {
        const { documentData, documentType } = data.getProgramReport;
        if (!documentData || !documentType) {
          throw new Error('Invalid report data received');
        }

        const binaryStr = atob(documentData);
        const bytes = new Uint8Array(binaryStr.length);
        for (let i = 0; i < binaryStr.length; i++) {
          bytes[i] = binaryStr.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: documentType });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${selectedReport}.${selectedFormat}`;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (err) {
        toast.error('Failed to download report');
        console.error('Download error:', err);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    }
  }) as any;

  const { data: reportDetails, loading: detailsLoading } = useQuery(GET_PROGRAM_REPORT_DETAILS, {
    skip: !selectedReport,
    variables: { report: selectedReport },
    onCompleted: (data) => {
      setStatement(data.getProgramReportDetails.statement || '');
    }
  });

  const [createReport, { loading: createLoading }] = useMutation(CREATE_REPORT, {
    onCompleted: () => {
      toast.success('Report created successfully');
      handleCloseCreateModal();
      //refetch();
    },
    onError: (error) => {
      toast.error(`Failed to create report: ${error.message}`);
    }
  });

  const [updateReport, { loading: updateLoading }] = useMutation(UPDATE_REPORT_DETAILS, {
    onCompleted: () => {
      toast.success('Report updated successfully');
      setIsModalOpen(false);
      // Refetch the reports list if needed
      //refetch();
    },
    onError: (error) => {
      toast.error(`Failed to update report: ${error.message}`);
    }
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCreateFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleStatementChange = (value: string) => {
    setCreateFormData(prev => ({
      ...prev,
      statement: value
    }));
  };

  const handleCreateReport = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      await createReport({
        variables: {
          input: {
            ...createFormData,
            menuorder: parseInt(createFormData.menuorder, 10)
          }
        }
      });
    } catch (error) {
      console.error('Error creating report:', error);
    }
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
    setCreateFormData({
      section: '',
      name: '',
      menuorder: '',
      statement: ''
    });
  };

  const handleEditStatement = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveChanges = async () => {
    if (!selectedReport) return;

    try {
      await updateReport({
        variables: {
          report: selectedReport,
          statement: statement
        }
      });
    } catch (error) {
      console.error('Error updating report:', error);
    }
  };

  const editButton = (
    <button
      onClick={handleEditStatement}
      className="w-full flex items-center justify-center px-4 py-2 mb-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    >
      Edit Report Details
    </button>
  );

  const modalContent = (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleCloseModal}
      className="fixed inset-0 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900">Edit Report Details</h2>
        </div>
        <div className="p-6">
          <div className="space-y-4">
            <label className="block text-sm font-medium text-gray-700">
              Statement
            </label>
            <textarea
              rows={10}
              value={statement}
              onChange={(e) => setStatement(e.target.value)}
              className="w-full"
              readOnly={updateLoading}
            />
          </div>
        </div>
        <div className="px-6 py-4 border-t border-gray-200 flex justify-end space-x-3">
          <button
            onClick={handleCloseModal}
            disabled={updateLoading}
            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveChanges}
            disabled={updateLoading}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {updateLoading ? (
              <>
                <span className="mr-2">Saving...</span>
                <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </>
            ) : (
              'Save Changes'
            )}
          </button>
        </div>
      </div>
    </Modal>
  );

  const createModalContent = (
    <Modal
      isOpen={isCreateModalOpen}
      onRequestClose={handleCloseCreateModal}
      className="fixed inset-0 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900">Create New Report</h2>
        </div>
        <form onSubmit={handleCreateReport}>
          <div className="p-6 space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Section</label>
              <input
                type="text"
                name="section"
                value={createFormData.section}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                value={createFormData.name}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Menu Order</label>
              <input
                type="number"
                name="menuorder"
                value={createFormData.menuorder}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Statement</label>
              <textarea
                value={createFormData.statement}
                onChange={(e) => handleStatementChange(e.target.value)}
                className="w-full"
                readOnly={createLoading}
              />
            </div>
          </div>

          <div className="px-6 py-4 border-t border-gray-200 flex justify-end space-x-3">
            <button
              type="button"
              onClick={handleCloseCreateModal}
              disabled={createLoading}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={createLoading}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {createLoading ? (
                <>
                  <span className="mr-2">Creating...</span>
                  <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </>
              ) : (
                'Create Report'
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="mb-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Create New Report
        </button>
        <div className="bg-white shadow-sm rounded-lg">
          <div className="px-6 py-4 border-b border-gray-200">
            <h1 className="text-2xl font-semibold text-gray-900">Reports Center</h1>
          </div>
          
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <h2 className="text-lg font-semibold text-gray-900 mb-4">
                  Select Section
                </h2>
                <div className="space-y-1">
                  {Object.keys(sections || {}).map((section) => (
                    <button
                      key={section}
                      onClick={() => setSelectedSection(section)}
                      className={`w-full text-left px-3 py-1.5 rounded-md transition-colors duration-150
                        ${selectedSection === section 
                          ? 'bg-blue-50 text-blue-700 font-medium' 
                          : 'text-gray-700 hover:bg-gray-50'}`}
                    >
                      {section}
                    </button>
                  ))}
                </div>
              </div>

              <div>
                {selectedSection && (
                  <>
                    <h2 className="text-lg font-semibold text-gray-900 mb-4">
                      Select Report
                    </h2>
                    <div className="space-y-1">
                      {sections[selectedSection].map((report: any) => (
                        <button
                          key={report.id}
                          onClick={() => setSelectedReport(report.id)}
                          className={`w-full text-left px-3 py-1.5 rounded-md transition-colors duration-150
                            ${selectedReport === report.id 
                              ? 'bg-blue-50 text-blue-700 font-medium' 
                              : 'text-gray-700 hover:bg-gray-50'}`}
                        >
                          {report.name}
                        </button>
                      ))}
                    </div>
                  </>
                )}
              </div>

              <div>
                {selectedReport && (
                  <>
                    <h2 className="text-lg font-semibold text-gray-900 mb-4">
                      Format & Download
                    </h2>
                    <div className="space-y-4">
                      <div className="space-y-2">
                        {FORMATS.map((format) => (
                          <button
                            key={format.value}
                            onClick={() => setSelectedFormat(format.value)}
                            className={`w-full text-left px-3 py-1.5 rounded-md transition-colors duration-150
                              ${selectedFormat === format.value 
                                ? 'bg-blue-50 text-blue-700 font-medium' 
                                : 'text-gray-700 hover:bg-gray-50'}`}
                          >
                            {format.label}
                          </button>
                        ))}
                      </div>
                      
                      {editButton}
                      <button
                        onClick={handleDownload}
                        disabled={loading}
                        className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        {loading ? (
                          'Generating...'
                        ) : (
                          <>
                            <Download className="w-4 h-4 mr-2" />
                            Download Report
                          </>
                        )}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalContent}
      {createModalContent}
    </div>
  );
};

export default ReportsCenter;