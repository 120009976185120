import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { PlusCircle, X, Upload } from "lucide-react";
import { useMutation, gql } from "@apollo/client";
import { Textarea } from "@/components/ui/textarea";

const EDIT_HEARING = gql`
  mutation EditHearing(
    $participant: Integer!
    $hearingId: Integer!
    $caseNumber: String!
    $hearing: EditHearingInput!
  ) {
    editHearing(
      participant: $participant
      hearingId: $hearingId
      caseNumber: $caseNumber
      hearing: $hearing
    ) {
      message
    }
  }
`;

enum HearingDetailType {
  SUITABILITY = "suitability",
  REINSTATEMENT = "reinstatement",
  PROGRESS_REPORT = "progress_report",
}

enum HearingType {
  IN_PERSON = "in_person",
  VIRTUAL = "virtual",
  NON_APPEARANCE = "non_appearance",
  PROVIDER_CHOICE = "provider_choice",
}

export const EditHearingModal = ({
  isOpen,
  onClose,
  hearing,
  participant,
  caseNumber,
  refetch,
}: {
  isOpen: boolean;
  onClose: () => void;
  hearing: any;
  participant: number;
  caseNumber: string;
  refetch: () => void;
}) => {
  const [formData, setFormData] = useState({
    hearingDate: "",
    hearingDetails: "",
    detailType: "",
    hearingType: "",
    minutes: null as File | null,
    courtOrders: [""],
  });

  const [editHearing] = useMutation(EDIT_HEARING);

  useEffect(() => {
	if (hearing) {
	  const formattedDate = hearing.hearingDate ? 
		new Date(Number(hearing.hearingDate)).toISOString().split('T')[0] : "";
		
	  setFormData({
		hearingDate: formattedDate,
		hearingDetails: hearing.details || "",
		detailType: hearing.detailType || HearingDetailType.SUITABILITY,
		hearingType: hearing.type || HearingType.IN_PERSON,
		minutes: null,
		courtOrders: hearing.courtOrders?.length ? hearing.courtOrders : [""],
	  });
	}
   }, [hearing]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (name: string, value: string) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData((prev) => ({ ...prev, minutes: file }));
    }
  };

  const handleCourtOrderChange = (index: number, value: string) => {
    setFormData((prev) => {
      const newCourtOrders = [...prev.courtOrders];
      newCourtOrders[index] = value;
      return { ...prev, courtOrders: newCourtOrders };
    });
  };

  const addCourtOrder = () => {
    setFormData((prev) => ({
      ...prev,
      courtOrders: [...prev.courtOrders, ""],
    }));
  };

  const removeCourtOrder = (index: number) => {
    if (formData.courtOrders.length > 1) {
      setFormData((prev) => ({
        ...prev,
        courtOrders: prev.courtOrders.filter((_, i) => i !== index),
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      let minutesBase64 = null;
      if (formData.minutes) {
        const reader = new FileReader();
        minutesBase64 = await new Promise((resolve) => {
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(formData.minutes as File);
        });
      }

      const filteredCourtOrders = formData.courtOrders.filter(
        (order) => order.trim() !== ""
      );

      await editHearing({
        variables: {
          participant,
          hearingId: hearing.hearingID,
          caseNumber,
          hearing: {
            hearingDate: formData.hearingDate,
            details: formData.hearingDetails,
            type: formData.hearingType,
            detailType: formData.detailType,
            minutes: minutesBase64 || hearing.minutes,
            courtOrders: filteredCourtOrders,
          },
        },
      });
      refetch();
      onClose();
    } catch (error) {
      console.error("Error editing hearing:", error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle className="text-center text-2xl font-bold">
            Edit Hearing
          </DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="hearingDate">Hearing Date</Label>
              <Input
                type="date"
                id="hearingDate"
                name="hearingDate"
                value={formData.hearingDate}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="detailType">Hearing Detail Type</Label>
              <Select
                value={formData.detailType}
                onValueChange={(value) => handleSelectChange("detailType", value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={HearingDetailType.SUITABILITY}>
                    Suitability
                  </SelectItem>
                  <SelectItem value={HearingDetailType.REINSTATEMENT}>
                    Reinstatement
                  </SelectItem>
                  <SelectItem value={HearingDetailType.PROGRESS_REPORT}>
                    Progress Report
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="hearingType">Hearing Type</Label>
              <Select
                value={formData.hearingType}
                onValueChange={(value) => handleSelectChange("hearingType", value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={HearingType.IN_PERSON}>In Person</SelectItem>
                  <SelectItem value={HearingType.VIRTUAL}>Virtual</SelectItem>
                  <SelectItem value={HearingType.NON_APPEARANCE}>
                    Non-Appearance
                  </SelectItem>
                  <SelectItem value={HearingType.PROVIDER_CHOICE}>
                    Provider Choice
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="minutes">Minutes (File Upload)</Label>
              <div className="flex items-center gap-2">
                <Input
                  type="file"
                  id="minutes"
                  name="minutes"
                  onChange={handleFileChange}
                  className="hidden"
                />
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => document.getElementById("minutes")?.click()}
                  className="w-full"
                >
                  <Upload className="h-4 w-4 mr-2" />
                  {formData.minutes ? formData.minutes.name : (hearing?.minutes || "Upload Minutes")}
                </Button>
              </div>
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="hearingDetails">Hearing Details</Label>
            <Textarea
              id="hearingDetails"
              name="hearingDetails"
              value={formData.hearingDetails}
              onChange={handleInputChange}
              className="min-h-[100px]"
              required
            />
          </div>

          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <Label>Court Orders</Label>
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={addCourtOrder}
                className="flex items-center gap-2"
              >
                <PlusCircle className="h-4 w-4" />
                Add Court Order
              </Button>
            </div>

            <div className="space-y-2">
              {formData.courtOrders.map((order, index) => (
                <div key={index} className="flex gap-2 items-start">
                  <Input
                    value={order}
                    onChange={(e) => handleCourtOrderChange(index, e.target.value)}
                    placeholder="Enter court order"
                    className="flex-1"
                  />
                  {formData.courtOrders.length > 1 && (
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={() => removeCourtOrder(index)}
                      className="text-destructive hover:text-destructive"
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-end space-x-2 pt-4">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Update Hearing</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};