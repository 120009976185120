import React, { useMemo, useState } from "react";
import { isoDateToMDY } from "../../../utils/common";
import { ProgramDetailsModal } from "../modals/ProgramDetails";

import { Edit, MousePointer2, RefreshCcw, List } from "lucide-react";
import { EditProgram as EditParticipant } from "../modals/EditProgram";
import { ProgramItemDetails, ProgramItemHeader } from "../components/ProgramItemDetails";

export const ProgramDetailsGrid = ({
  data,
  odrnumber,
  refetch,
  color,
  selectedProgram,
  setSelectedProgram,
  selectedProgramForModal,
  setSelectedProgramForModal
}: {
  data: any;
  odrnumber: any;
  refetch: any;
  color: string;
  selectedProgram: any;
  setSelectedProgram: any;
  selectedProgramForModal:  string | null;
  setSelectedProgramForModal: any
}) => {
 

  const programDetails = data?.getParticipantByODRNumber?.programDetails || [];
  const [editParticipant, setEditParticipant] = useState<any>({
    show: false,
    program: {},
  });
  const [showAll, setShowAll] = useState<boolean>(false);
  const numberOfActivePrograms = useMemo(
    () =>
      programDetails.length,
    [programDetails, selectedProgram]
  );

 console.log('selectedProgramForModal', selectedProgramForModal)

 if (programDetails==null || programDetails.length==0 || selectedProgram==null || selectedProgram.id=="select") {
  return null;
}

  let adjustedColor = color || "green";
  if (color === "emerald") adjustedColor = "green";
  if (color == "orange") adjustedColor = "yellow";
  if (color == "purple") adjustedColor = "purple";
  if (color == "blue") adjustedColor = "blue";
  if (color == "red") adjustedColor = "red";

  const isActive = selectedProgram.active;
  const style = showAll
    ? isActive
      ? ` text-black`
      : "text-gray-500 bg-gray-100"
                : isActive
                ? ``
                : "";
  
  return (
    <>
      <div className="overflow-hidden justify-left content-left">
        <div className="flex justify-between px-2 py-2">
          <div className="grid grid-cols-7 gap-5 text-sm font-medium text-gray-600">
            <div className="ml-2" >
              <div className="font-bold">Status</div>
              <div className="text-sm">
                {selectedProgram.active ? (
                  <span className="text-green-600 font-medium">Active</span>
                ) : (
                  <span className="text-gray-500">Inactive</span>
                )}
              </div>
            </div>
            <div className="ml-2" >
              <div className="font-bold">Program</div>
              <div
                className={`text-sm cursor-pointer text-blue-600 hover:text-blue-800`}
                onClick={() => setSelectedProgramForModal(selectedProgram.programParticipantNumber)}
              >
                {selectedProgram.programDescription.toUpperCase()}
              </div>
            </div>
            <div className="ml-2" >
              <div className="font-bold">Entry Date</div>
              <div className="text-sm text-gray-600">
                {isoDateToMDY(selectedProgram.entryDate)}
              </div>
            </div>
            <div className="ml-2" >
              <div className="font-bold">Exit Date</div>
              <div className="text-sm text-gray-600">
                {isoDateToMDY(selectedProgram.exitDate)}
              </div>
            </div>
            <div className="ml-2" >
              <div className="font-bold">Disposition</div>
              <div className="text-sm text-gray-600">
                {selectedProgram.disposition}
              </div>
            </div>
            <div className="ml-2" >
              <div className="font-bold">Provider</div>
              <div className="text-sm text-gray-600">
                {selectedProgram.provider}
              </div>
            </div>
            <div className="ml-2" >
              <div className="font-bold">Pathway</div>
              <div className="text-sm text-gray-600">
                {selectedProgram.pathway}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <button 
              onClick={() => {
                setEditParticipant({
                  show: true,
                  program: { ...selectedProgram },
                  active: selectedProgram.active,
                });
                setSelectedProgramForModal(null);
              }}
              className="inline-flex items-center text-blue-600 hover:text-white hover:bg-blue-800 px-2 rounded-md"
            >
              <Edit className="mr-2 h-4 w-4" />
              <div >Edit Program</div>
            </button>
          
            <button
              onClick={() => setSelectedProgram({id: "select", description: ""})}
              className="inline-flex items-center text-blue-600 hover:text-white hover:bg-blue-800 px-2 rounded-md"
            >
              <RefreshCcw className="mr-2 h-4 w-4" />
              Change Program
            </button>
          </div>
        </div>
      </div>

      {editParticipant?.show && (
        <EditParticipant
          isOpen={editParticipant.show}
          onClose={() =>
            setEditParticipant({ show: false, program: null, active: false })
          }
          program={editParticipant.program}
          odrnumber={parseInt(odrnumber)}
          data={data}
          programOnly
          active={editParticipant.active}
          refetch={refetch}
        />
      )}

      {selectedProgramForModal && (
        <ProgramDetailsModal
          isOpen={!!selectedProgramForModal}
          onClose={() => setSelectedProgramForModal(null)}
          programNumber={selectedProgramForModal}
          odrnumber={odrnumber}
        />
      )}
    </>
  );
};

export default ProgramDetailsGrid;
