import { gql, useQuery } from '@apollo/client';
import { Card, CardContent } from "../../../components/ui/card"
import { ScrollArea } from "../../../components/ui/scroll-area"
import React from 'react';
import { Button } from "../../../components/ui/button"
import { toast } from "react-toastify"

// Define the Result type
interface Result {
  message: string;
  responseCode: number;
  responseID: number;
  extra: string;
}

// GraphQL query
const GET_DATABASE_SCHEMA = gql`
  query GetDatabaseSchema {
    databaseSchema {
      message
      responseCode
      responseID
      extra
    }
  }
`;

export function DatabaseSchemaPage() {
  const { loading, error, data } = useQuery<{ databaseSchema: Result }>(GET_DATABASE_SCHEMA);
  
  const copyToClipboard = () => {
    try {
      const formattedJson = JSON.stringify(JSON.parse(data?.databaseSchema.extra || ''), null, 2);
      navigator.clipboard.writeText(formattedJson);
      toast.success("Schema copied to clipboard");
    } catch {
      toast.error("Failed to copy schema");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-bold">Database Schema</h1>
        {data && (
          <Button 
            variant="outline"
            onClick={copyToClipboard}
            className="flex items-center gap-2"
          >
            <span>Copy Schema</span>
          </Button>
        )}
      </div>

      {loading && <div className="animate-pulse">Loading...</div>}
      
      {error && (
        <Card className="bg-destructive/10">
          <CardContent className="p-4">Error: {error.message}</CardContent>
        </Card>
      )}

      {data && (
        <ScrollArea className="h-[500px] rounded-md border p-4">
          <pre className="whitespace-pre-wrap font-mono text-sm">
            {JSON.stringify(JSON.parse(data.databaseSchema.extra), null, 2)}
          </pre>
        </ScrollArea>
      )}
    </div>
  );
} 
