import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHelpTopics } from "../../store/useHelpTopics";
import { ParticipantDataSection } from "./ParticipantDataSection";
//import { ProgramDataSection } from "./ProgramDataSection";
//import { EnrolledProgramsAccordion } from "./components/EnrolledProgramsAccordion";
import { LegalStatus } from "./LegalStatus";
import { MedicalStatus } from "./MedicalStatus";
import { PlanAndLocation } from "./PlanAndLocation";
import { CalAIM } from "./CalAIM";
import { Notes } from "./Notes";
import { Notifications } from "./Notifications";
import { Enrollment } from "./Enrollment";
import { Incidents } from "./Incidents";
import { Scans } from "./Scans";
//import { Scans as ScansTab } from "./ScansTab";
import { ProgramDetailsGrid } from "./components/ProgramDetailsGrid";
import { Summary } from "./modals/summary";
import { CandidateModal } from "./CandidatesData";
import { DuplicateCount } from "./Duplicates";
import { useSetProgramColor } from "../../store/useProgramColor";
//import { Edit } from "lucide-react";
//import { toast } from "react-toastify";
//import { EditParticipant } from "./modals/EditParticipant";
import { ParticipantDataSectionFull } from "./modals/ParticipantDataSectionFull";
import { ActiveProgramModal } from "./modals/ActiveProgramModal";
import { SelectProgramModal } from "./modals/SelectProgram";

import {
	FileText,
	Users,
	Scale,
	Heart,
	MapPin,
	Calendar,
	Bell,
	AlertTriangle,
	ScanEye,
	Clock,
	Wrench,
	GitMerge,
	Scissors,
} from "lucide-react";
import { useQuery, gql } from "@apollo/client";
import { programNameFromID, isoDateToMDY } from "../../utils/common";
import { TimelineModal } from "./modals/TimelineModal";
import { SelectItemIndicator } from "@radix-ui/react-select";
import ProgramDetailsModal from "./modals/ProgramDetails";

const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
      rso
      pcodeHistory {

        date
        pcode
      }
      candidatesData {
        id
        firstName
        lastName
      }
      scans {
        id
        filename
        filetype
        filelabel
        filedate
      }
      nameHistory {
        lastName
        firstName
        program
        entrydate
      }
      name {
        firstName
        lastName
      }
      dobHistory {
        value
        program
        entrydate
      }
      programs
      mrun
      mrunHistory {
        value
        program
        entrydate
      }
      champid
      champidHistory {
        value
        program
        entrydate
      }
      genderHistory {
        value
        program
        entrydate
      }
      mrunHistory {
        value
        program
        entrydate
      }
      isNumber
      isNumberHistory {
        value
        program
        entrydate
      }
      disposition {
        disposition
      }
      dispositionHistory {
        disposition
        dispositionDate
        program
      }
      pathwayHistory {
        value
        program
        entrydate
      }
      providerHistory {
        value
        program
        entrydate
      }
      releasedDate 
      releasedDateHistory {
        value
        program
      }
      jcitHistory {
        value
        program
        entrydate
      }
      icd10
      icd10History {
        value
        program
        entrydate
      }
      race
      probationHistory {
        value
        program
        entrydate
      }
      programDetails {
        programDescription
        programParticipantNumber
        ab109
        commitmentDate
        completionDate
        dischargeDate
        disposition
        entryDate
        exitDate
        maxDate
        istStatus
        pathway
        provider
        releaseDate
        probation
        jcit
        active
      }
      fin
      probation      
      cii
      ssn
      medicalProblems
      psychotropicMeds
      otherMeds
	  currentMeds
	  dsm5dx
      clinicalPresentation
      psychHistory
      substanceHistory
    }
  }
`;

const programFullNames = {
	mist: "Misdemeanor Incompetent to Stand Trial Diversion",
	fist: "Felony Incompetent to Stand Trial Program",
	odr: "Office of Diversion and Reentry Housing",
	prg_wmn: "Maternal Health",
	lead: "Let Everyone Advance with Dignity / Law Enforcement Assisted Diversion",
	"NO ACTIVE PROGRAM": "",
} as any;

const bkcolors = {
	mist: "bg-blue-50",
	fist: "bg-red-50",
	odr: "bg-green-50",
	prg_wmn: "bg-purple-50",
	lead: "yellow",
	dsh: "red",
	"NO ACTIVE PROGRAM": "gray-100",
} as any;

const defaultSections = {
	full: false,
	programs: false,
	enrollment: false,
	legal: false,
	medical: false,
	disposition: false,
	notifications: false,
	calaim: false,
	notes: false,
	incidents: false,
	scans: true,
};

// Map of tab icons and labels
const tabConfig = {
	//programs: { icon: FileText, label: "Programs" },
	scans: { icon: ScanEye, label: "Scans" },
	//full: { icon: FileText, label: "Full" },
	enrollment: { icon: Users, label: "Enrollment" },
	legal: { icon: Scale, label: "Legal" },
	medical: { icon: Heart, label: "Medical" },
	disposition: { icon: MapPin, label: "Plan and Location" },
	notifications: { icon: Bell, label: "Notifications" },
	calaim: { icon: Calendar, label: "CalAIM" },
	notes: { icon: FileText, label: "Notes" },
	incidents: { icon: AlertTriangle, label: "Incidents" },
};

const ToolsDropdown = ({
	onAuditLog,
	onMerge,
	onSeparate,
	onCandidate,
}: {
	onAuditLog: () => void;
	onMerge: () => void;
	onSeparate: () => void;
	onCandidate: () => void;
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [editParticipant, setEditParticipant] = useState(false);

	return (
		<div className="relative">
			<button
				onClick={() => setIsOpen(!isOpen)}
				onBlur={() => setTimeout(() => setIsOpen(false), 200)}
				className="mr-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
			>
				<Wrench className="mr-2 h-4 w-4" />
				Tools
			</button>

			{isOpen && (
				<div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
					<div className="py-1" role="menu">
						<button
							onClick={onAuditLog}
							className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
							role="menuitem"
						>
							<ScanEye className="mr-2 h-4 w-4" />
							Audit Log
						</button>
						<button
							onClick={onCandidate}
							className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
							role="menuitem"
						>
							<ScanEye className="mr-2 h-4 w-4" />
							Candidate
						</button>
						<button
							onClick={onMerge}
							className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
							role="menuitem"
						>
							<GitMerge className="mr-2 h-4 w-4" />
							Merge Tool
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export const ParticipantsManager = (params: any) => {
	const { participant: participantReactParams } = params;
	const { participant: participantURLParams }: any = useParams();
	const participant = participantReactParams || participantURLParams;
	const [selectedProgram, _setSelectedProgram] = useState({ id: "", description: "", programParticipantNumber: "" });
	const [activeProgramClose, setActiveProgramClose] = useState(false);
	//const [isProgramDataLoaded, setIsProgramDataLoaded] = useState(false);
	const { pushHelpTopic, popHelpTopic } = useHelpTopics();
	const [displaySections, setDisplaySections] = useState({
		...defaultSections,
		programs: true,
	});
	const { setProgramColor } = useSetProgramColor();

	const setSelectedProgram = (program: any) => {
		_setSelectedProgram(program);
		setProgramColor(bkcolors[program.programDescription] || 'bg-gray-50');		
	}

	const [selectedProgramForModal, setSelectedProgramForModal] = useState<
		string | null
	>(null);

	const [displaySummary, setDisplaySummary] = useState(false);
	const [displayCandidate, setDisplayCandidate] = useState(false);
	const [activeTab, setActiveTab] =
		useState<keyof typeof defaultSections>("scans");
	const [isTimelineOpen, setIsTimelineOpen] = useState(false);


	const { loading, error, data, refetch } = useQuery(
		GET_PARTICIPANT_BY_ODR_NUMBER,
		{
			variables: { odrnumber: parseInt(participant) },
			onCompleted: (data) => {
				const activePrograms = data?.getParticipantByODRNumber?.programDetails?.filter(
					(program: any) => program.active
				);
				
				const activeProgramCount = activePrograms?.length;
				const activeProgramId = activeProgramCount === 1 ? activePrograms[0]?.programParticipantNumber : null;

				if (activeProgramCount === 1) {
					setSelectedProgram(activePrograms[0]);
				}
        if (activeProgramCount > 1 || activeProgramCount === 0) {
					setSelectedProgram({ id: 'select', description: '' });
				}
			},
		}
	);

	const toggleSection = (section: keyof typeof displaySections) => {
		setDisplaySections((prev) => ({
			...defaultSections,
			[section]: !prev[section],
		}));
		setActiveTab(section);
		if (section === "legal") {
			!selectedProgram.id && setSelectedProgram({ id: "select", description: "" })
		}
	};


	const getPublicImageUrl = () => {
		return process.env.PUBLIC_URL + `/logoODR.png`;
	};

	return (
		<div className={`min-h-screen`}>
			<div className="min-h-[90vh] mx-auto px-2 sm:px-4 lg:px-3 ">
				{/* Main Content Container */}

				{/* Participant Data Section */}
				<div
					className={`min-h-[80vh] w-full border-b border-gray-200 px-6 py-4 my-4 bg-white`}
				>
					{true && (
						<div className="flex items-center gap-4 mb-4">
							<img
								src={getPublicImageUrl()}
								className="h-12 w-auto"
								alt="DHS Logo"
							/>
							<div className="flex items-center justify-end w-full mb-4">
								<div className="flex items-center">
									<DuplicateCount odrNumber={parseInt(participant)} />
									<ToolsDropdown
										onAuditLog={() => setDisplaySummary(!displaySummary)}
										onCandidate={() => setDisplayCandidate(!displayCandidate)}
										onMerge={() => {
											/* handle merge */
										}}
										onSeparate={() => {
											/* handle separate */
										}}
									/>

									<button
										onClick={() => setIsTimelineOpen(true)}
										className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
									>
										<Clock className="mr-2 h-4 w-4" />
										View Timeline
									</button>
									
								</div>
							</div>
						</div>
					)}

					<div className="grid border border-gray-200 rounded-md border-b gap-4 px-6 py-3 text-sm font-medium text-gray-600">
					<h2 className="text-gray-600 ">Client Data : {participant}</h2>

					<ParticipantDataSection
						odrnumber={parseInt(participant)}
						program={data?.getParticipantByODRNumber?.activeProgram}
						data={data}
						loading={loading}
						error={error}
						refetch={refetch}
					/>

					
					<h2 className="text-gray-600 border-t border-gray-200 pt-4">Program Data : {selectedProgram.programParticipantNumber}</h2>
					<ProgramDetailsGrid
						data={data}
						odrnumber={parseInt(participant)}
						color="bg-gray-50"
						selectedProgram={selectedProgram}
						setSelectedProgram={setSelectedProgram}
						refetch={refetch}
						selectedProgramForModal={selectedProgramForModal}
						setSelectedProgramForModal={setSelectedProgramForModal}
					/>
					</div>

					{/*<Scans odrnumber={parseInt(participant)} scans={data?.getParticipantByODRNumber?.scans||[]} loading={loading} error={error} />*/}

					<div className="">
						{true && (
							<div className="mt-4">
								<div className="bg-white border border-gray-200 shadow-md rounded-md">
									{/* Tab Navigation */}
									<div className="border-b border-gray-200 ">
										<nav className="flex -mb-px px-6" aria-label="Tabs">
											{Object.entries(tabConfig)?.map(
												([key, { icon: Icon, label }]) => {
													const isActive = activeTab === key;
													return (
														<button
															key={key}
															onClick={() =>
																toggleSection(
																	key as keyof typeof defaultSections
																)
															}
															className={`
                          group inline-flex items-center px-6 py-4 border-b-2 font-bold text-sm
                          ${isActive
																	? "border-blue-500 text-blue-600"
																	: "border-transparent text-gray-600 hover:text-gray-700 hover:border-gray-300"
																}
                        `}
														>
															<Icon
																className={`-ml-0.5 mr-2 h-4 w-4 ${isActive
																		? "text-blue-500"
																		: "text-gray-400 group-hover:text-gray-500"
																	}`}
															/>
															{label}
														</button>
													);
												}
											)}
										</nav>
									</div>

									{/* Tab Content */}

									<div className="px-6 py-4 ">
										{/*activeTab === "programs" && (
                      <ProgramDetailsGrid
                        data={data}
                        odrnumber={parseInt(participant)}
                        activeProgram={data?.getParticipantByODRNumber?.activeProgram?.programParticipantNumber}
                        color='bg-gray-50'
                        setSelectedProgram={setSelectedProgram}
                      />
                    )*/}
										{activeTab === "full" && (
											<ParticipantDataSectionFull
												odrnumber={parseInt(participant)}
											/>
										)}
										{activeTab === "enrollment" && (
											<Enrollment programNumber={parseInt(participant)} />
										)}
										{activeTab === "scans" && (
											<Scans
												odrnumber={parseInt(participant)}
												scans={data?.getParticipantByODRNumber?.scans || []}
												loading={loading}
												error={error}
											/>
											//<ScansTab odrnumber={parseInt(participant)} scans={data?.getParticipantByODRNumber?.scans||[]} loading={loading} error={error} />
										)}
										{activeTab === "legal" && !selectedProgram.id ? (
											<ProgramDetailsModal
												isOpen={true} // Always open if selectedProgram.id is missing
												onClose={() => setSelectedProgramForModal(null)}
												programNumber={selectedProgramForModal || ''}
												odrnumber={parseInt(participant)}
											/>
										) : (
											<>
												{activeTab === "legal" && selectedProgram.id && (
													<LegalStatus
														odrNumber={parseInt(participant)}
														programNumber={parseInt(selectedProgram.id)}
													/>
												)}
											</>
										)}
										{activeTab === "medical" && (
											<MedicalStatus
												odrnumber={parseInt(participant)}
												data={data}
												refetch={refetch}
											/>
										)}
										{activeTab === "disposition" && (
											<PlanAndLocation
												odrNumber={parseInt(participant)}
												programNumber={parseInt(selectedProgram.id)}
											/>
										)}
										{activeTab === "notes" && (
											<Notes odrnumber={parseInt(participant)} programNumber={selectedProgram.id} />
										)}
										{activeTab === "calaim" && (
											<CalAIM
												odrNumber={parseInt(participant)}
												programNumber={parseInt(selectedProgram.id)}
											/>
										)}
										{activeTab === "notifications" && (
											<Notifications
												programName={programNameFromID(selectedProgram.id)}
											/>
										)}
										{activeTab === "incidents" && (
											<Incidents programNumber={parseInt(participant)} />
										)}
									</div>
								</div>
							</div>
						)}{" "}
					</div>
				</div>
			</div>
			{displaySummary && (
				<Summary
					participant={parseInt(participant)}
					onClose={() => {
						setDisplaySummary(false);
					}}
				/>
			)}
			{displayCandidate && (
				<CandidateModal
					isOpen={displayCandidate}
					onClose={() => {
						setDisplayCandidate(false);
					}}
					odrNumber={parseInt(participant)}
				/>
			)}
			<TimelineModal
				isOpen={isTimelineOpen}
				onClose={() => setIsTimelineOpen(false)}
				odrnumber={parseInt(participant)}
			/>
			{/*activeProgramCount > 1 &&  activeProgramClose == false && <ActiveProgramModal onClose={()=>{setActiveProgramClose(true)}} odrnumber={parseInt(participant)} programs={data?.getParticipantByODRNumber?.programDetails} refetch={refetch}/>}*/}
			{selectedProgram.id == 'select' 
				

				&& (
					<SelectProgramModal
						onClose={() => {
						//	setActiveProgramClose(true);
						}}
						odrnumber={parseInt(participant)}
						programs={data?.getParticipantByODRNumber?.programDetails}
						onSelect={setSelectedProgram}
					/>
				)}
		</div>
	);
};

export default ParticipantsManager;
