import { useState, useEffect } from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';




import {toast} from 'react-toastify';
import { useUser } from '../../../store/useUser';

    
const boxed = {
    marginBottom: "1rem",
    border: "1px solid #ccc",
    borderRadius: "1px",
    padding: "0.5rem",
    paddingRight: "1rem",
    marginRight: "1rem"
  }
  

const style={

}

const programSquare =(idstring:string)=>{
    
    const box =()=>{
        const id=parseInt(idstring)
        
        if (id < 20000000) //19999999 and below
            return {c: 'mist', text: 'MIST',url: `/show_full.php?participant=${idstring}`};
        if (id < 30000000) //29999999 and below
            return {c: 'zodr', text: 'ODR',url: `/odrhousing/show_full.php?participant=${idstring}`};
        if (id < 40000000) //39999999 and below
            return {c: 'prg_wmn', text: 'MIST',url: `/prg_wmn/show_full.php?participant=${idstring}`};       
        if (id < 60000000) //59999999 and below
            return {c: 'fist', text: 'FIST',url: `/fist/show_full.php?participant=${idstring}`};       
        if (id < 70000000) //69999999 and below
            return {c: 'dsh', text: 'DSH',url: `/dsh/show_full.php?participant=${idstring}`};      
        if (id < 80000000) //79999999 and below
            return {class: 'lead', text: 'LEAD',url: `/lead/show_full.php?participant=${idstring}`};  
        // anyone else is ODR Range we dont do that here

    return {}
    }

    const {c, text, url} = box()
    return (
        <div style={{display: 'grid', gridTemplateColumns: '3rem 1fr 5rem', gap: '5px', }}>
            
            <div className={`programSquare ${c}`}>
                <div>{text}</div>
            </div>
            {idstring}            
        </div>
    )
}


const CustomDate = (props: any) => {
  const { value } = props;
  if (!value)
  return <div></div>;
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.\d{3}Z$/;
  const [, year, month, day, hour, minute, second] = value.match(regex) || [];


  if (!year) {
    // Handle invalid date format
    return <span>Invalid date format</span>;
  }

  const usaDateFormat = `${month}/${day}/${year}`;

  return <span>{usaDateFormat}</span>;
};

const MERGE_PARTICIPANTS = gql`
  mutation MergeParticipants($participantToKeep: String!, $participantsToMerge: [String]!, $updates: updateParticipantInput!) {
    mergeParticipantsbyODRNumber(participantToKeep: $participantToKeep, participantsToMerge: $participantsToMerge, updates: $updates)
    {
      message
      extra
    }
  }
`;

const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantsByODRNumber($odrnumbers: [Integer]) {
    getParticipantsByODRNumber(odrnumbers: $odrnumbers) {
      id      
      dob      
      cid      
      mrun
      cin
      ssn      
      cii
      disposition      {
      disposition
      }
      bookingNumber
      mainNumber
      gender
      fin
      rso
      wpc
      programs
      name {
        firstName
        lastName
      }
      nameHistory {
        firstName
        lastName
        real
        entrydate
      }      
    }
  }
`;

const UPDATE_PARTICIPANT_BY_ODR_NUMBER = gql`
  mutation UpdateParticipantByODRNumber($ODRNumber: Integer!, $updates: updateParticipantInput!) {
    updateParticipantByODRNumber(ODRNumber: $ODRNumber, updates: $updates) {     
      message     
  }}
`;






export const Demographics = (params: any) => {
    const { odrnumbers, onMerge  } = params;
    console.log(odrnumbers)

    const [state, setState] = useState({}) as any;
    const [mergeEnabled, setMergeEnabled] = useState(true);
    const { user, isUserLoading } = useUser();

    const [mergeParticipants] = useMutation(MERGE_PARTICIPANTS);
    const { loading, error, data } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
        variables: { odrnumbers: odrnumbers},
        onCompleted: (result) => {
            // Existing onCompleted logic
        },
    });

    const merge = async () => {
        // Existing merge function
    };

    if (loading || isUserLoading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    const participantData = data.getParticipantsByODRNumber;

    const sharedData = {
      cin: [],
      cid: [],
      dob: [],
      firstName: [],
      lastName: [],
      gender: [],
      mrun: [],
      main: [],
      booking: [],
      ssn: [],
      cii: [],
      disposition: [],
      fin: [],      
      wpc: [],      
      rso: [],
    } as any;

    // Populate sharedData
    participantData.forEach((p: any) => {
        const ifValidPush = (e: any, array: any[]) => {
            if (e !== undefined && e !== null && e !== '') {
                array.push(e);
            }
        };

        const ifValidPushBool = (e: any, array: any[]) => {
            if (e !== undefined && e !== null) {
                array.push(e ? 'Yes' : 'No');
            }
        };

        if (p.name && p.name.firstName) ifValidPush(p.name.firstName, sharedData.firstName);
        if (p.name && p.name.lastName)  ifValidPush(p.name.lastName, sharedData.lastName);
        ifValidPush(p.dob, sharedData.dob);
        ifValidPush(p.gender, sharedData.gender);
        ifValidPush(p.cid, sharedData.cid);
        ifValidPush(p.mrun, sharedData.mrun);
        ifValidPush(p.cin, sharedData.cin);
        ifValidPush(p.ssn, sharedData.ssn);
        ifValidPush(p.mainNumber, sharedData.main);
        ifValidPush(p.cii, sharedData.cii);
        ifValidPush(p.disposition, sharedData.disposition);
        ifValidPush(p.fin, sharedData.fin);
        ifValidPushBool(p.wpc, sharedData.wpc);
        ifValidPushBool(p.rso, sharedData.rso);
    });

    // Function to check if a field is matching across all participants
    const isMatching = (field: string) => {
        return sharedData[field] && 
               sharedData[field].length === participantData.length && 
               sharedData[field].every((v: any) => v === sharedData[field][0]);
    };

    // Function to render a field with conditional bold formatting
    const renderField = (label: string, value: any, field: string) => {
        const content = field === 'dob' ? <CustomDate value={value} /> : value;
        return (
            <div>
                <span>{label}:&nbsp;</span>
                {isMatching(field) ? <strong>{content}</strong> : <span>{content}</span>}
            </div>
        );
    };

    return (
        <div className='columns3'>
            {participantData && participantData.length > 0 && participantData.map((p: any, i: number) => {
                return (
                    <div style={boxed} key={p.id}>
                        <div style={{fontWeight: 'bold', marginBottom: '5px', display:'flex', justifyContent:'space-between'}}>
                            <div>{p.id}</div>
                            <a href={`/participants/${p.id}`} target="_blank" rel="noopener noreferrer">
                                <button>Profile</button>
                            </a>
                        </div>
                        <div>
                        {p.programs && p.programs.map((prog:any, index:any)=>{return <div key={index}>{programSquare(prog)}</div>})}
                        </div>
                        {renderField('First Name', p.name && p.name.firstName ? p.name.firstName : '', 'firstName')}
                        {renderField('Last Name', p.name && p.name.lastName ? p.name.lastName : '', 'lastName')}
                        {renderField('DOB', p.dob, 'dob')}
                        {renderField('Gender', p.gender, 'gender')}
                        {renderField('CID', p.cid, 'cid')}
                        {renderField('MRUN', p.mrun, 'mrun')}
                        {renderField('CIN', p.cin, 'cin')}
                        {renderField('SSN', p.ssn, 'ssn')}
                        {renderField('CII', p.cii, 'cii')}
                        {renderField('Disposition', p.disposition.disposition, 'disposition')}
                        {renderField('FIN', p.fin, 'fin')}
                        {renderField('WPC', p.wpc ? 'Yes' : 'No', 'wpc')}
                        {renderField('RSO', p.rso ? 'Yes' : 'No', 'rso')}
                    </div>
                )
            })}
            
        </div>
    );
}