import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { Badge } from '@/components/ui/badge';
import { Search, X, Plus, ChevronsUpDown, Check, ChevronDown, ChevronRight } from 'lucide-react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { ScrollArea } from '@/components/ui/scroll-area';
import { toast } from 'react-toastify';

interface User {
  userName: string;
  name: string;
  email: string;
}

interface Function {
  functionName: string;
  description: string;
  users: string[];
  groups: string[]; // This remains an array for backward compatibility
  privileges: string[];
  group: string; // Added single group property
}

interface SecurityObjects {
  users: User[];
  groups: string[];
  privileges: string[];
  functions: Function[];
  description: string; // Added description at the root level
}

interface PendingChanges {
  users: string[];
  groups: string[];
  privileges: string[];
}

const GET_SECURITY_OBJECTS = gql`
  query GetSecurityObjects {
    getSecurityObjects {
      users {
        userName
        name
        email
      }
      groups
      privileges      
      functions {
        functionName
        description
        users
        groups
        privileges
        group
      }
    }
  }
`;

export const SecurityManager = () => {
  const [securityObjects, setSecurityObjects] = useState<SecurityObjects>({
    users: [],
    groups: [],
    privileges: [],
    functions: [],
    description: '' // Initialize with empty description
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFunction, setSelectedFunction] = useState<Function | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [pendingChanges, setPendingChanges] = useState<PendingChanges>({
    users: [],
    groups: [],
    privileges: []
  });
  // Add filter states for each category
  const [showOnlySelected, setShowOnlySelected] = useState({
    users: false,
    groups: false,
    privileges: false
  });
  // Track expanded groups in the sidebar
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});
  
  const { data, loading, error } = useQuery(GET_SECURITY_OBJECTS);

  useEffect(() => {
    if (data?.getSecurityObjects) {
      setSecurityObjects(data.getSecurityObjects);
      
      // Initialize all groups as expanded
      const groupsFromFunctions = extractUniqueGroupsFromFunctions(data.getSecurityObjects.functions);
      const initialExpandedState: Record<string, boolean> = {};
      groupsFromFunctions.forEach(group => {
        initialExpandedState[group] = true;
      });
      setExpandedGroups(initialExpandedState);
      
      // Select the first function by default if available
      if (data.getSecurityObjects.functions.length > 0 && !selectedFunction) {
        setSelectedFunction(data.getSecurityObjects.functions[0]);
        setPendingChanges({
          users: [...data.getSecurityObjects.functions[0].users],
          groups: [...data.getSecurityObjects.functions[0].groups],
          privileges: [...data.getSecurityObjects.functions[0].privileges]
        });
      }
    }
  }, [data]);

  // Extract unique groups from functions - using the single group property
  const extractUniqueGroupsFromFunctions = (functions: Function[]): string[] => {
    const allGroups = functions.map(func => func.group).filter(Boolean);
    return Array.from(new Set(allGroups));
  };

  // Toggle expanded state for a group
  const toggleGroupExpanded = (group: string) => {
    setExpandedGroups(prev => ({
      ...prev,
      [group]: !prev[group]
    }));
  };

  // Function to organize functions by their single group property
  const organizeFunctionsByGroup = () => {
    const groups = extractUniqueGroupsFromFunctions(securityObjects.functions);
    const result: Record<string, Function[]> = {};
    
    // Initialize with empty arrays for each group (sorted alphabetically)
    groups.sort((a, b) => a.localeCompare(b)).forEach(group => {
      result[group] = [];
    });
    
    // Add functions to their respective groups
    securityObjects.functions.forEach(func => {
      if (func.group && result[func.group]) {
        result[func.group].push(func);
      }
    });
    
    // Handle functions without any group
    const functionsWithoutGroup = securityObjects.functions.filter(func => !func.group);
    if (functionsWithoutGroup.length > 0) {
      result['Ungrouped'] = functionsWithoutGroup;
    }
    
    return result;
  };

  const handleFunctionSelect = (func: Function) => {
    setSelectedFunction(func);
    setPendingChanges({
      users: [...func.users],
      groups: [...func.groups],
      privileges: [...func.privileges]
    });
  };

  const toggleUser = (userName: string) => {
    setPendingChanges(prev => {
      if (prev.users.includes(userName)) {
        return {
          ...prev,
          users: prev.users.filter(u => u !== userName)
        };
      } else {
        return {
          ...prev,
          users: [...prev.users, userName]
        };
      }
    });
  };

  const toggleGroup = (group: string) => {
    setPendingChanges(prev => {
      if (prev.groups.includes(group)) {
        return {
          ...prev,
          groups: prev.groups.filter(g => g !== group)
        };
      } else {
        return {
          ...prev,
          groups: [...prev.groups, group]
        };
      }
    });
  };

  const togglePrivilege = (privilege: string) => {
    setPendingChanges(prev => {
      if (prev.privileges.includes(privilege)) {
        return {
          ...prev,
          privileges: prev.privileges.filter(p => p !== privilege)
        };
      } else {
        return {
          ...prev,
          privileges: [...prev.privileges, privilege]
        };
      }
    });
  };

  const handleSave = () => {
    if (selectedFunction) {
      // Create a new function object with updated fields
      const updatedFunction = {
        ...selectedFunction,
        users: pendingChanges.users,
        groups: pendingChanges.groups,
        privileges: pendingChanges.privileges
      };
      
      // Update the functions array
      const updatedFunctions = securityObjects.functions.map(f => 
        f.functionName === selectedFunction.functionName ? updatedFunction : f
      );
      
      // Update the state with the new functions array
      setSecurityObjects(prev => ({
        ...prev,
        functions: updatedFunctions
      }));
      
      // Also update the selected function
      setSelectedFunction(updatedFunction);
      
      // Show a success toast
      toast.success(`Changes saved for ${selectedFunction.functionName}`);
    }
  };

  // Filter functions by search term
  const filterFunctionsBySearchTerm = (func: Function) => {
    if (!searchTerm) return true;
    const term = searchTerm.toLowerCase();
    return (
      func.functionName.toLowerCase().includes(term) ||
      (func.description && func.description.toLowerCase().includes(term))
    );
  };

  if (loading) return <div className="flex justify-center items-center h-64">Loading...</div>;
  if (error) return <div className="text-red-500">Error loading security objects</div>;

  const functionsByGroup = organizeFunctionsByGroup();

  return (
    <div className="p-4 w-full">    
      <Card className="shadow-md">
        <CardHeader>
          <div className="flex justify-between items-center">
            <div>
              <CardTitle>Function Management</CardTitle>
              {securityObjects.description && (
                <p className="text-xs text-gray-600 mt-1">{securityObjects.description}</p>
              )}
            </div>
            <div className="relative w-64">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-400" />
              <Input
                placeholder="Search functions..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-8"
              />
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div className="flex gap-4 h-[600px]">
            {/* Left sidebar - Function list organized by groups */}
            <div className="w-1/4 border rounded-md shadow-sm">
              <div className="p-2 bg-gray-50 border-b text-xs font-medium">
                Functions by Group
              </div>
              <ScrollArea className="h-[550px]">
                <div className="divide-y">
                  {/* Ensure groups are displayed in alphabetical order */}
                  {Object.entries(functionsByGroup)
                    .sort(([groupA], [groupB]) => {
                      // Always keep "Ungrouped" at the end
                      if (groupA === 'Ungrouped') return 1;
                      if (groupB === 'Ungrouped') return -1;
                      return groupA.localeCompare(groupB);
                    })
                    .map(([group, functions]) => {
                      // Filter functions by search term
                      const filteredFunctions = functions.filter(filterFunctionsBySearchTerm);
                      if (filteredFunctions.length === 0) return null;
                      
                      const isExpanded = expandedGroups[group] || false;
                      
                      return (
                        <div key={group} className="border-b last:border-b-0">
                          {/* Group header */}
                          <div 
                            className="p-2 bg-gray-50 flex items-center justify-between cursor-pointer hover:bg-gray-100"
                            onClick={() => toggleGroupExpanded(group)}
                          >
                            <span className="text-xs font-medium">{group}</span>
                            {isExpanded ? 
                              <ChevronDown className="h-4 w-4 text-gray-500" /> : 
                              <ChevronRight className="h-4 w-4 text-gray-500" />
                            }
                          </div>
                          
                          {/* Functions in this group */}
                          {isExpanded && (
                            <div className="divide-y">
                              {/* Sort functions alphabetically by name within each group */}
                              {filteredFunctions
                                .sort((a, b) => a.functionName.localeCompare(b.functionName))
                                .map((func) => (
                                  <div 
                                    key={func.functionName}
                                    className={`p-3 pl-6 cursor-pointer hover:bg-gray-50 transition-colors ${
                                      selectedFunction?.functionName === func.functionName ? 'bg-blue-50 border-l-4 border-blue-500' : ''
                                    }`}
                                    onClick={() => handleFunctionSelect(func)}
                                  >
                                    <div className="text-xs font-medium truncate">{func.functionName}</div>
                                    <div className="text-xs text-gray-500 truncate">{func.description}</div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  
                  {searchTerm && Object.values(functionsByGroup)
                    .flat()
                    .filter(filterFunctionsBySearchTerm).length === 0 && (
                    <div className="p-3 text-center text-gray-500 text-xs">
                      No functions match your search
                    </div>
                  )}
                  
                  {Object.values(functionsByGroup)
                    .flat()
                    .filter(filterFunctionsBySearchTerm).length === 0 && (
                    <div className="p-3 text-center text-gray-500 text-xs">
                      No functions match your search
                    </div>
                  )}
                </div>
              </ScrollArea>
            </div>
            
            {/* Right content - Function details */}
            <div className="w-3/4 border rounded-md shadow-sm">
              {selectedFunction ? (
                <div className="h-full flex flex-col">
                  <div className="p-3 bg-gray-50 border-b">
                    <h3 className="text-base font-semibold">{selectedFunction.functionName}</h3>
                    <p className="text-xs text-gray-600">{selectedFunction.description}</p>
                    {selectedFunction.group && (
                      <div className="mt-2">
                        <Badge variant="outline" className="text-xs bg-gray-100">
                          {selectedFunction.group}
                        </Badge>
                      </div>
                    )}
                  </div>
                  
                  <div className="p-2 space-y-3 flex-grow overflow-auto">
                    <div className="grid grid-cols-3 gap-3 h-full">
                      {/* Users Section - Modified for static list with highlighting */}
                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <div className="text-xs font-medium">Users</div>
                          <div 
                            className="flex items-center text-xs text-blue-600 cursor-pointer hover:underline"
                            onClick={() => setShowOnlySelected(prev => ({...prev, users: !prev.users}))}
                          >
                            {showOnlySelected.users ? "Show All Users" : "Show Selected Only"}
                          </div>
                        </div>
                        <div className="p-2 border rounded bg-white h-96 flex flex-col">
                          <div className="mb-1 text-xs font-medium text-gray-600">Click to select/deselect:</div>
                          <div className="flex-grow overflow-y-auto border rounded divide-y">
                            {/* Map through unique users to prevent repeating */}
                            {Array.from(new Set(securityObjects.users.map(user => user.userName)))
                              .filter(userName => {
                                // Apply filter if showOnlySelected is true
                                if (showOnlySelected.users) {
                                  return pendingChanges.users.includes(userName);
                                }
                                return true;
                              })
                              .map(userName => {
                                const user = securityObjects.users.find(u => u.userName === userName);
                                if (!user) return null;
                                
                                const isSelected = pendingChanges.users.includes(user.userName);
                                return (
                                  <div 
                                    key={user.userName} 
                                    className={`py-2 px-3 flex justify-between items-center cursor-pointer text-xs ${
                                      isSelected ? 'bg-blue-50 font-medium' : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => toggleUser(user.userName)}
                                  >
                                    <span>{user.name} ({user.userName})</span>
                                    {isSelected ? (
                                      <Check className="h-4 w-4 text-blue-500" />
                                    ) : null}
                                  </div>
                                );
                              })}
                            {Array.from(new Set(securityObjects.users.map(user => user.userName)))
                              .filter(userName => {
                                if (showOnlySelected.users) {
                                  return pendingChanges.users.includes(userName);
                                }
                                return true;
                              }).length === 0 && (
                              <div className="p-2 text-gray-500 text-center text-xs">
                                {showOnlySelected.users ? "No selected users" : "No users available"}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* Groups Section - Modified for static list with highlighting */}
                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <div className="text-xs font-medium">Groups</div>
                          <div 
                            className="flex items-center text-xs text-blue-600 cursor-pointer hover:underline"
                            onClick={() => setShowOnlySelected(prev => ({...prev, groups: !prev.groups}))}
                          >
                            {showOnlySelected.groups ? "Show All Groups" : "Show Selected Only"}
                          </div>
                        </div>
                        <div className="p-2 border rounded bg-white h-96 flex flex-col">
                          <div className="mb-1 text-xs font-medium text-gray-600">Click to select/deselect:</div>
                          <div className="flex-grow overflow-y-auto border rounded divide-y">
                            {/* Map through unique groups */}
                            {Array.from(new Set(securityObjects.groups))
                              .filter(group => {
                                // Apply filter if showOnlySelected is true
                                if (showOnlySelected.groups) {
                                  return pendingChanges.groups.includes(group);
                                }
                                return true;
                              })
                              .map(group => {
                                const isSelected = pendingChanges.groups.includes(group);
                                return (
                                  <div 
                                    key={group} 
                                    className={`py-2 px-3 flex justify-between items-center cursor-pointer text-xs ${
                                      isSelected ? 'bg-blue-50 font-medium' : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => toggleGroup(group)}
                                  >
                                    <span>{group}</span>
                                    {isSelected ? (
                                      <Check className="h-4 w-4 text-blue-500" />
                                    ) : null}
                                  </div>
                                );
                              })}
                            {Array.from(new Set(securityObjects.groups))
                              .filter(group => {
                                if (showOnlySelected.groups) {
                                  return pendingChanges.groups.includes(group);
                                }
                                return true;
                              }).length === 0 && (
                              <div className="p-2 text-gray-500 text-center text-xs">
                                {showOnlySelected.groups ? "No selected groups" : "No groups available"}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* Privileges Section - Modified for static list with highlighting */}
                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <div className="text-xs font-medium">Privileges</div>
                          <div 
                            className="flex items-center text-xs text-blue-600 cursor-pointer hover:underline"
                            onClick={() => setShowOnlySelected(prev => ({...prev, privileges: !prev.privileges}))}
                          >
                            {showOnlySelected.privileges ? "Show All Privileges" : "Show Selected Only"}
                          </div>
                        </div>
                        <div className="p-2 border rounded bg-white h-96 flex flex-col">
                          <div className="mb-1 text-xs font-medium text-gray-600">Click to select/deselect:</div>
                          <div className="flex-grow overflow-y-auto border rounded divide-y">
                            {/* Map through unique privileges */}
                            {Array.from(new Set(securityObjects.privileges))
                              .filter(privilege => {
                                // Apply filter if showOnlySelected is true
                                if (showOnlySelected.privileges) {
                                  return pendingChanges.privileges.includes(privilege);
                                }
                                return true;
                              })
                              .map(privilege => {
                                const isSelected = pendingChanges.privileges.includes(privilege);
                                return (
                                  <div 
                                    key={privilege} 
                                    className={`py-2 px-3 flex justify-between items-center cursor-pointer text-xs ${
                                      isSelected ? 'bg-blue-50 font-medium' : 'hover:bg-gray-50'
                                    }`}
                                    onClick={() => togglePrivilege(privilege)}
                                  >
                                    <span>{privilege}</span>
                                    {isSelected ? (
                                      <Check className="h-4 w-4 text-blue-500" />
                                    ) : null}
                                  </div>
                                );
                              })}
                            {Array.from(new Set(securityObjects.privileges))
                              .filter(privilege => {
                                if (showOnlySelected.privileges) {
                                  return pendingChanges.privileges.includes(privilege);
                                }
                                return true;
                              }).length === 0 && (
                              <div className="p-2 text-gray-500 text-center text-xs">
                                {showOnlySelected.privileges ? "No selected privileges" : "No privileges available"}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="p-3 border-t mt-auto bg-gray-50">
                    <Button 
                      onClick={handleSave} 
                      className="bg-blue-600 hover:bg-blue-700 text-white text-xs h-8"
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center h-full text-gray-500 text-xs">
                  Select a function to view details
                </div>
              )}
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Keep the dialog for backward compatibility but hide it */}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-lg">
          <DialogHeader>
            <DialogTitle>{selectedFunction?.functionName}</DialogTitle>
            <DialogDescription>{selectedFunction?.description}</DialogDescription>
          </DialogHeader>
          
          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleSave}>
              Save Changes
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SecurityManager;