import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { useCurrentHelpTopic } from '../../store/useHelpTopics';
import { gql, useMutation } from '@apollo/client';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { HelpCircle, AlertCircle, BookOpen } from 'lucide-react'

const CREATE_SUPPORT_TICKET = gql`
  mutation CreateSupportTicket($message: String!) {
    createSupportTicket(message: $message) {
      id
      message
    }
  }
`;

const HelpModal = ({onClose}: {onClose: () => void}) => {  
  const [message, setMessage] = useState('');
  const [createSupportTicket] = useMutation(CREATE_SUPPORT_TICKET);

  const handleSubmit = () => {
    createSupportTicket({variables: {message}});
    onClose();
    toast.success('Support ticket created. Someone will contact you shortly.');
  }

  return (    
      <div>
        <div style={{display: 'grid', gridTemplateColumns: '95% 1fr'}}>
          <div></div>
          <div>
            <button onClick={onClose}>Close</button>
          </div>
        </div>
        <h1>Contact Support</h1>        
        <div>
          <div><label>Message</label></div>
          <textarea value={message} onChange={(e) => setMessage(e.target.value)} />
          <div style={{paddingTop: '10px'}}><button onClick={handleSubmit}>Submit</button></div>
        </div>
      </div>
    
  );
};

export const GET_HELP_TOPIC = gql`
  query GetHelpTopic($name: String!) {
    getHelpTopic(name: $name) {
      id
      name
      content
    }
  }
`;
export const Help = () => {
  const currentHelpTopic = useCurrentHelpTopic();
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const { loading, error, data } = useQuery(GET_HELP_TOPIC, {
    variables: { name: currentHelpTopic() },
    skip: !currentHelpTopic(),
  });

  if (loading) return (
    <div className="min-h-[200px] flex items-center justify-center bg-gray-50 rounded-lg">
      <div className="text-center">
        <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-600 border-r-transparent align-[-0.125em]"></div>
        <div className="mt-4 text-sm font-medium text-gray-600">Loading help content...</div>
      </div>
    </div>
  );

  if (error) return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <AlertCircle className="h-5 w-5 text-red-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">Error Loading Help</h3>
          <div className="mt-2 text-sm text-red-700">{error.message}</div>
        </div>
      </div>
    </div>
  );

  if (!data || !data.getHelpTopic) return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <HelpCircle className="h-5 w-5 text-yellow-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">No Help Content</h3>
          <div className="mt-2 text-sm text-yellow-700">
            No help topic found for this section
          </div>
        </div>
      </div>
    </div>
  );

  let helptopic = currentHelpTopic() ;
  if (helptopic === "Default") {
    helptopic = "Diversion Database Help";
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Help Content Card */}
        <div className="bg-white shadow-sm rounded-lg">
        <div className="border-b border-gray-200 px-6 py-4">
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-2">
              <BookOpen className="h-5 w-5 text-gray-400" />
              <h1 className="text-xl font-semibold text-gray-900">
                Diversion Database Help
              </h1>
            </div>
            <div className="text-sm text-gray-500">
              Topic: {currentHelpTopic()}
            </div>
          </div>
        </div>
          
          <div className="px-6 py-4">
            <div className="prose max-w-none">
              <div 
                className="text-gray-600 space-y-4"
                dangerouslySetInnerHTML={{ 
                  __html: data.getHelpTopic.content 
                }} 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; 