import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { gql, useQuery } from '@apollo/client';

const GET_PARTICIPANT_SCAN = gql`
  query GetParticipantScan($odrnumber: Integer!, $scanID: Integer!) {
    getParticipantScan(odrnumber: $odrnumber, scanID: $scanID) {
      id
      filename
      filetype
      filelabel
      filedate
      content
      contentType
    }
  }
`;

export const ScanPreview = ({ 
  odrnumber, 
  scan, 
  close 
}: { 
  odrnumber: number;
  scan: number;
  close: () => void;
}) => {
  const { loading, error, data } = useQuery(GET_PARTICIPANT_SCAN, {
    variables: { odrnumber, scanID: scan },
    skip: !odrnumber || !scan,
  });

  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [showFallback, setShowFallback] = useState(false);

  const scanData = data?.getParticipantScan;

  useEffect(() => {
    if (scanData?.content) {
      const binaryData = atob(scanData.content);
      const extension = scanData.filename.split('.').pop()?.toLowerCase() || '';
      
      let contentType = scanData.contentType;
      if (extension === 'pdf') {
        contentType = 'application/pdf';
      } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
        contentType = `image/${extension}`;
      }

      const blob = new Blob(
        [new Uint8Array(binaryData.split('').map(char => char.charCodeAt(0)))], 
        { type: contentType }
      );
      const url = URL.createObjectURL(blob);
      setDownloadUrl(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [scanData, scan]);

  const renderFilePreview = () => {
    if (!scanData?.content || !downloadUrl) return null;

    const extension = scanData.filename.split('.').pop()?.toLowerCase() || '';

    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
      return (
        <img 
          src={downloadUrl} 
          alt="Scan preview" 
          className="max-w-full max-h-[80vh]"
        />
      );
    }

    if (extension === 'pdf') {
      return (
        <>
          {!showFallback && (
            <iframe
              src={downloadUrl}
              className="w-full h-[80vh]"
              onError={() => setShowFallback(true)}
            />
          )}
          {showFallback && (
            <div className="p-4">
              <p>Unable to display PDF. The file might be corrupted or in an unsupported format.</p>
            </div>
          )}
        </>
      );
    }

    return (
      <div className="my-4">Unable to Preview File</div>
    );
  };

  return (
    <ReactModal
      isOpen={true}
      onRequestClose={close}
      className="bg-white p-6 w-[95%] mx-auto mt-8 relative max-h-[90vh] "
      overlayClassName="fixed inset-0 bg-black/50 flex items-center justify-center"
    >
      <div className="flex justify-between items-center mb-4">
        <div>
          {downloadUrl && scanData && (
            <a
              href={downloadUrl}
              download={`${scanData.filelabel || scanData.filename.split('.')[0]}.${scanData.filename.split('.').pop()}`}
              className="text-blue-600 hover:text-blue-800"
            >
              Download File
            </a>
          )}
        </div>
        <button 
          onClick={close}
          className="text-gray-600 hover:text-gray-800"
        >
          Close
        </button>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {!loading && !error && renderFilePreview()}
    </ReactModal>
  );
};

export default ScanPreview;