import React from "react";
import Modal from "react-modal";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useUser } from "../../../store/useUser";

const ADD_NOTE = gql`
  mutation AddNote($odrnumber: Integer!, $program: String!, $input: ParticipantNoteInput) {
    createProgramNote(odrnumber: $odrnumber, program: $program, input: $input) {
      message
    }
  }
`;

export const CreateNote = ({ onClose, odrnumber, programNumber, refetch }: any) => {
  const { user } = useUser();
  
  const [popupState, setPopupState] = React.useState({
    notesBy: user.realname,
    notesDate: new Date().toISOString(),
    note: ""
  });

  const [addNote] = useMutation(ADD_NOTE);

  const handleAddNote = () => {
    addNote({
      variables: {
        odrnumber: odrnumber,
        program: programNumber,
        input: {
          note: popupState.note,
          noteBy: popupState.notesBy,
          noteDate: new Date(popupState.notesDate).toISOString()
        }
      }
    });
    refetch();
    onClose();
    toast.success("Note added successfully");
    setPopupState({
      ...popupState,
      note: "",
      notesBy: user.realname,
      notesDate: new Date().toISOString()
    });
  };

  const customModalStyles = {
    content: {
      width: '100%',
      maxWidth: '800px',
      margin: '40px auto',
      background: 'white',
      border: '1px solid #ccc',
      borderRadius: '4px',
      padding: '20px'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    }
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      style={customModalStyles}
    >
      <div className="w-full bg-white rounded-lg">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-xl font-semibold text-gray-900">Add Note</h3>
            <button onClick={onClose} className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
              Close
            </button>
          </div>

          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-8">
              <input
                value={popupState.notesBy}
                onChange={(e) => setPopupState({ ...popupState, notesBy: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
              <input
                type="date"
                value={popupState.notesDate}
                onChange={(e) => setPopupState({ ...popupState, notesDate: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>

            <textarea
              value={popupState.note}
              onChange={(e) => setPopupState({ ...popupState, note: e.target.value })}
              className="w-full h-48 px-3 py-2 border border-gray-300 rounded-md"
            />

            <div className="flex justify-end">
              <button
                onClick={handleAddNote}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
              >
                Add Note
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};