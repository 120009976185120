import React, { useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Demographics } from '../../reports/components/minidemographics';
import { useUser } from '../../../store/useUser';
import ReactModal from 'react-modal';

const MERGE_PARTICIPANTS = gql`
  mutation MergeParticipants($participantToKeep: String!, $participantsToMerge: [String]!) {
    mergeParticipantsbyODRNumber(participantToKeep: $participantToKeep, participantsToMerge: $participantsToMerge)
    {
      message
      extra
    }
  }
`;

const GET_DUPLICATE_CLIENTS_REPORT = gql`
  query DuplicateClientsReport {
    duplicateClientsReport {
      entries {                       
        participants     
        mrun   
        cin   
        ssn   
        cii   
        fin   
        cid   
      }
      totalCount
    }
  }
`;

const matchingFields = ['mrun', 'cin', 'ssn', 'cii', 'fin', 'cid'];

interface Entry {
  type: string;
  value: string;
  odrparticipants: string[];
  participants: string[];
  firstName: string;
  lastName: string;
}

interface DuplicateClientsReportData {
  entries: Entry[];
  totalCount: number;
}

interface DuplicateClientsModalProps {
  isOpen: boolean;
  onClose: () => void;
  report?: any;
}

export const DuplicateClientsModal = ({ isOpen, onClose, report }: DuplicateClientsModalProps) => {
  const [duplicateClientsReport, setDuplicateClientsReport] = useState<DuplicateClientsReportData>(report || { entries: [], totalCount: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(20);
  const [working, setWorking] = useState(false);

  const { user, isUserLoading } = useUser();

  const { loading, error, refetch } = useQuery<{ duplicateClientsReport: DuplicateClientsReportData }>(GET_DUPLICATE_CLIENTS_REPORT, {
    skip: report,
    onCompleted: (data) => {
      setDuplicateClientsReport(data.duplicateClientsReport);      
    }
  });

  const [mergeParticipants] = useMutation(MERGE_PARTICIPANTS);

  if (!isOpen) return null;

  if (loading || isUserLoading) return <div className="fixed inset-0 bg-black/50 flex items-center justify-center">Loading...</div>;
  if (error) return <div className="fixed inset-0 bg-black/50 flex items-center justify-center">Error: {error.message}</div>;

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = duplicateClientsReport.entries.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(duplicateClientsReport.entries.length / entriesPerPage);

  const changePage = (newPage: number) => {
    setCurrentPage(Math.max(1, Math.min(newPage, totalPages)));
  };

  const merge = async (entry: Entry, index: number) => {
    setWorking(true);
    if (!window.confirm('Are you sure you want to merge these participants?')) {
      setWorking(false);
      return;
    }
    
    try {
      const { data } = await mergeParticipants({ 
        variables: { 
          participantToKeep: entry.participants[0], 
          participantsToMerge: entry.participants.slice(1) 
        } 
      });

      if (data) {
        const updatedEntries = [...duplicateClientsReport.entries];    
        updatedEntries.splice(indexOfFirstEntry + index, 1);
        setDuplicateClientsReport({ ...duplicateClientsReport, entries: updatedEntries });
      }
    } catch (error) {
      console.error('Error merging participants:', error);
    } finally {
      setWorking(false);
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center"
    >
      <div className="bg-white w-11/12 h-5/6 overflow-auto p-4">
        <div className="flex justify-between items-center">
          <h2>Duplicate Clients Report</h2>
          <button onClick={onClose} className="px-4 py-2">Close</button>
        </div>
        
        <p>Total Count: {duplicateClientsReport.totalCount}</p>
        
        <div>
          {currentEntries.map((entry: any, index: number) => (
            <div key={index} className="mb-4 border border-gray-200 p-2">
              <div className="grid grid-cols-[15rem_1fr_20rem] gap-2">
                <div>
                  <div className="grid grid-cols-[10rem_1fr_1fr] gap-2">
                    <div className="font-bold mb-1">ODR Numbers</div>
                  </div>
                  <div>
                    {entry.participants.map((odrnumber: any) => (
                      <div key={odrnumber}>{odrnumber}</div>
                    ))}
                  </div>
                </div>

                <Demographics 
                  odrnumbers={entry.participants} 
                  refetch={refetch} 
                  onMerge={() => {}}                      
                />

                <div className="border border-gray-200 p-2">
                  <div className="font-bold mb-1">Matched Fields</div>
                  {matchingFields.map((field: any) => (
                    <>
                      {entry[field] && (
                        <div key={field + entry[field]} className="grid grid-cols-[5rem_1fr]">
                          <div>{field}</div>
                          <div>{entry[field]}</div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
              
              <div className="flex justify-between mt-2">
                <button 
                  onClick={() => merge(entry, index)}
                  disabled={working}
                  className="px-4 py-2"
                >
                  Merge
                </button>
              </div>
            </div>
          ))}
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={changePage}
        />
        
        {working && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
            <div>Working...</div>
          </div>
        )}
      </div>
    </ReactModal>
  );
};

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];
  const maxPageButtons = 5;

  let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

  if (endPage - startPage + 1 < maxPageButtons) {
    startPage = Math.max(1, endPage - maxPageButtons + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex justify-center items-center mt-4">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-4 py-2 border mx-1"
      >
        Previous
      </button>
      
      {pageNumbers.map(number => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={`px-4 py-2 border mx-1 ${
            currentPage === number ? 'bg-blue-500 text-white' : ''
          }`}
        >
          {number}
        </button>
      ))}
      
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-4 py-2 border mx-1"
      >
        Next
      </button>
    </div>
  );
};