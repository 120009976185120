import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { PlusCircle, X } from "lucide-react";
import { useMutation, gql } from "@apollo/client";

const ADD_CASE = gql`
  mutation AddCase(
    $odrnumber: Integer!
    $bookingNumber: Integer!
    $case: AddCaseInput!
	$participant: Integer!,
  ) {
    addCase(odrnumber: $odrnumber, bookingNumber: $bookingNumber, case: $case, participant: $participant) {
      message
    }
  }
`;

export const AddCaseModal = ({
  isOpen,
  onClose,
  odrNumber,
  bookingNumber,
  participant,
  refetch,
}: {
  isOpen: boolean;
  onClose: () => void;
  odrNumber: number;
  bookingNumber: string;
  participant: number;
  refetch: () => void;
}) => {
  const [formData, setFormData] = useState({
    caseNumber: "",
    status: "open",
    charges: [{ charge: "", charge_entrydate: new Date().toISOString() }],
  });

  const [addCase] = useMutation(ADD_CASE);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleStatusChange = (value: string) => {
    setFormData((prev) => ({ ...prev, status: value }));
  };

  const handleChargeChange = (index: number, value: string) => {
    setFormData((prev) => {
      const newCharges = [...prev.charges];
      newCharges[index] = {
        ...newCharges[index],
        charge: value,
        charge_entrydate: new Date().toISOString(),
      };
      return { ...prev, charges: newCharges };
    });
  };

  const addCharge = () => {
    setFormData((prev) => ({
      ...prev,
      charges: [
        ...prev.charges,
        { charge: "", charge_entrydate: new Date().toISOString() },
      ],
    }));
  };

  const removeCharge = (index: number) => {
    if (formData.charges.length > 1) {
      setFormData((prev) => ({
        ...prev,
        charges: prev.charges.filter((_, i) => i !== index),
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await addCase({
        variables: {
          odrnumber: odrNumber,
          bookingNumber: bookingNumber,
		  participant: participant,
          case: {
            caseNumber: formData.caseNumber,
            caseOpen: formData.status === "open",
            charges: formData.charges.filter(
              (charge) => charge.charge.trim() !== ""
            ),
          },
        },
      });
      refetch();
      onClose();
    } catch (error) {
      console.error("Error adding case:", error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle className="text-center text-2xl font-bold">
            Add New Case
          </DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="caseNumber">Case Number</Label>
              <Input
                id="caseNumber"
                name="caseNumber"
                value={formData.caseNumber}
                onChange={handleInputChange}
                required
                className="w-full"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="status">Case Status</Label>
              <Select
                value={formData.status}
                onValueChange={handleStatusChange}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="open">Open</SelectItem>
                  <SelectItem value="closed">Closed</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <Label>Charges</Label>
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={addCharge}
                className="flex items-center gap-2"
              >
                <PlusCircle className="h-4 w-4" />
                Add Charge
              </Button>
            </div>

            <div className="space-y-2">
              {formData.charges.map((charge, index) => (
                <div key={index} className="flex gap-2 items-start">
                  <Input
                    value={charge.charge}
                    onChange={(e) => handleChargeChange(index, e.target.value)}
                    placeholder="Enter charge description"
                    className="flex-1"
                  />
                  {formData.charges.length > 1 && (
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={() => removeCharge(index)}
                      className="text-destructive hover:text-destructive"
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-end space-x-2 pt-4">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Add Case</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
