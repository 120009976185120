import React, { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { useAuthenticationState } from "../../store/useAuthenticationState";
import { DiscountModal } from "./AutoLogoutComponents/discountModal";
import { Mail, Lock, Loader } from "lucide-react";

const LOGIN_PASSWORD = gql`
  mutation LoginPassword($username: String!, $password: String!) {
    loginPassword(username: $username, password: $password){
      state
      token
      extra
    }
  }
`;

const LOGIN_TWOFACTOR = gql`
  mutation LoginTwofactor($token: String!, $code: String!) {
    loginTwofactor(token: $token, code: $code)
  }
`;



export const Login = ({ onSuccess }: any) => {

  const { setAuthenticationState } = useAuthenticationState();
  const [state, setState] = useState({
    username: "",
    password: "",
    error: "",
    twofactor: "",
    state: "login",
    extra : '',
    loading: false,
    token: "",
  });

  useEffect(() => {
    setAuthenticationState(undefined);
  }, []);

  const handleAzureLogin = () => {
    setState({ ...state, loading: true });
    // Redirect to Azure AD login
    window.location.href = `https://login.microsoftonline.com/07597248-ea38-451b-8abe-a638eddbac81/oauth2/v2.0/authorize?
      client_id=06572a3f-f284-4a21-8b9e-fed62288abe1
      &response_type=code
      &redirect_uri=${encodeURIComponent("https://edge.lacountydiversion.org/loginAD")}
      &response_mode=query
      &scope=openid profile email
      &state=${Math.random().toString(36).substring(2, 15)}`;
  };

  const [loginPassword] = useMutation(LOGIN_PASSWORD);
  const [loginTwofactor] = useMutation(LOGIN_TWOFACTOR);

  const handleUsername = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    try {
      const loginPasswordResult = await loginPassword({
        variables: {
          username: state.username,
          password: state.password,
        },
      });
      if (loginPasswordResult) {
        setState({
          ...state,
          state: loginPasswordResult.data.loginPassword.state === 0 ? "passimage" : loginPasswordResult.data.loginPassword.state === 1 ? "twofactor" : loginPasswordResult.data.loginPassword.state === 2 ? "activeDirectory" : "login",          
          extra: loginPasswordResult.data.loginPassword.extra,
          token: loginPasswordResult.data.loginPassword.token,
          loading: false,
        });
      }
    } catch (error) {
      setState({ 
        ...state, 
        error: "Invalid credentials", 
        loading: false,
        state: "login" 
      });
    }
  };

  const handleTwofactor = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    try {
      const loginTwofactorResult = await loginTwofactor({
        variables: {
          token: state.token,
          code: state.twofactor,
        },
      });
      if (loginTwofactorResult) {
        setAuthenticationState(loginTwofactorResult.data.loginTwofactor);
        if (onSuccess) {
          onSuccess(loginTwofactorResult.data.loginTwofactor);
        }
      }
    } catch (error) {
      setState({ 
        ...state, 
        error: "Invalid code", 
        loading: false,
        state: "login"
      });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <DiscountModal>
        <div className="w-full max-w-md space-y-8 bg-white p-8 rounded-lg">
          {/* Logo */}
          <div className="flex justify-center">
            <img
              src={`${process.env.PUBLIC_URL}/logoODR.png`}
              alt="logo"
              className="h-20 w-auto"
            />
          </div>
          <div className="text-center text-2xl font-bold">
            <div>Welcome to the</div>
            <div>Diversion Database</div>
          </div>


          {/* Login Form */}
          {state.state === "login" && (
            <form onSubmit={handleUsername} className="space-y-6">
              <div className="space-y-4">
                {/* Username Field */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Username
                  </label>
                  <div className="flex items-center border border-gray-300 rounded-md ">
                    <Mail className="h-5 w-5 text-gray-400 mx-3" />
                    <input
                      type="text"
                      value={state.username}
                      onChange={(e) =>
                        setState({ ...state, username: e.target.value })
                      }
                      className="w-full py-2 border-0 focus:ring-0 text-base"
                      style={{ margin: 0, padding: "8px 0 8px 10px" }}
                    />
                  </div>
                </div>

                {/* Password Field */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <div className="flex items-center border border-gray-300 rounded-md ">
                    <Lock className="h-5 w-5 text-gray-400 mx-3" />
                    <input
                      type="password"
                      value={state.password}
                      onChange={(e) =>
                        setState({ ...state, password: e.target.value })
                      }
                      className="w-full py-2 border-0 focus:ring-0 text-base"
                      style={{ margin: 0, padding: "8px 0 8px 10px" }}
                    />
                  </div>
                </div>
                
              </div>

              {state.error && (
                <div className="text-red-600 text-sm text-center">
                  {state.error}
                </div>
              )}

              <button
                type="submit"
                disabled={state.loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {state.loading ? (
                  <Loader className="h-5 w-5 animate-spin" />
                ) : (
                  "Login"
                )}
              </button>
            </form>
          )}

          {/* Pass Image Selection */}
          {state.state === "passimage" && (
            <div className="space-y-6">
              <div className="text-center text-sm text-gray-600">
                Please select your security image
              </div>
              <div className="grid grid-cols-3 gap-6">
                {[...state.extra.split(',')].map((num) => (
                  <button
                    key={num}
                    onClick={async () => {
                      setState({ ...state, loading: true });
                      try {
                        const loginTwofactorResult = await loginTwofactor({
                          variables: {
                            token: state.token,
                            code: num,
                          },
                        });
                        if (loginTwofactorResult) {
                          setAuthenticationState(loginTwofactorResult.data.loginTwofactor);
                          if (onSuccess) {
                            onSuccess(loginTwofactorResult.data.loginTwofactor);
                          }
                        }
                      } catch (error) {
                        setState({ 
                          ...state, 
                          error: "Invalid image selected", 
                          loading: false,
                          state: "login" 
                        });
                      }
                    }}
                    className="p-3 border rounded-lg hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <img 
                      src={`/ui/build/passimages/${num}.png`}
                      alt={`Security image ${num}`}
                      className="w-full aspect-square object-cover rounded border border-gray-200 scale-150"
                    />
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Two Factor Form */}
          {state.state === "twofactor" && (
            <form onSubmit={handleTwofactor} className="mt-8 space-y-6">
              <div className="text-center text-sm text-gray-600">
                A code has been sent to your email address
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Two Factor Code
                </label>
                <input
                  type="text"
                  value={state.twofactor}
                  onChange={(e) =>
                    setState({ ...state, twofactor: e.target.value })
                  }
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              {state.error && (
                <div className="text-red-600 text-sm text-center">
                  {state.error}
                </div>
              )}

              <button
                type="submit"
                disabled={state.loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {state.loading ? (
                  <Loader className="h-5 w-5 animate-spin" />
                ) : (
                  "Verify"
                )}
              </button>
            </form>
          )}

          {state.state === "login" && (
            <div className="text-center text-sm text-gray-600">
              <button onClick={handleAzureLogin} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50">
                LA County Microsoft SSO Login
              </button>
            </div>
          )}
        </div>
      </DiscountModal>
    </div>
  );
};
