import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation, useSubscription } from '@apollo/client';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Card } from "@/components/ui/card"

const UPDATE_PCODE = gql`
  mutation generatePLevelReport {
    generatePLevelReport{
      message
      responseCode
    }
  }
`;

const SUBSCRIPTION_UPLOAD_PCODE_DATA_EXCEL = gql`
  subscription {
    subscriptionPCodeReportProgress
    {
        id
        runid
        action         
        total
        name
        dob
        race
        sex
        booking
        fin
        mrun
        champid
        arrest_count
        program
        ODRAssessmentDate
        release_date
        pcode_at_odrAssessment
        pcodeDate_at_odrAssessment
        pcodeSource_at_odrAssessment
        building_at_odrAssessment
        pcode_at_release
        pcodeDate_at_release
        pcodeSource_at_release
        building_at_release      
        tests
        message
    }
  }
`;

export const GeneratePLevelReport = () => {
  const [updatePCode] = useMutation(UPDATE_PCODE);
  const { data: subscriptionData } = useSubscription(SUBSCRIPTION_UPLOAD_PCODE_DATA_EXCEL);
    
  const [report, setReport] = React.useState({}) as any; // Change report to a hash
  const [currentStep, setCurrentStep] = useState(0)

  const [selectedItem, setSelectedItem] = useState(null) as any;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showClients, setShowClients] = useState(false);

  const openModal = (item: any) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setIsModalOpen(false);
  };

  const eventQueueRef = React.useRef([]) as any ;

  useEffect(() => {
    if (subscriptionData && subscriptionData.subscriptionPCodeReportProgress) {
      const data = subscriptionData.subscriptionPCodeReportProgress;
      eventQueueRef.current.push(data);
    }
  }, [subscriptionData]);

  useEffect(() => {
    const processEventQueue = () => {
      const queue = eventQueueRef.current;
      if (queue.length === 0) return;

      setReport((prevReport: Record<string, any>) => {
        const updatedReport = { ...prevReport };
        
        queue.forEach((data: { action: string; id: number; message?: string; tests?: any }) => {

          if (updatedReport[data.id] == null) {
            updatedReport[data.id] = {
              ...data,
              messages: data.message ? [data.message] : [],
            };
          }
          const messages = updatedReport[data.id]?.messages || []

          if (data.action === 'insert') {
            
            updatedReport[data.id]  = {
                ...data,                
              };
              setCurrentStep(data.id)
          } 
          
          if (data.action === 'update') {
            updatedReport[data.id] = {
              ...data,
              messages: [...messages, data.message],
              client : true
            };
          } 
          
          if (data.action === 'test') {
            if (updatedReport[data.id]) {
              updatedReport[data.id].tests = data.tests;
              updatedReport[data.id].message = data.message;
              updatedReport[data.id].messages = [...messages, data.message];
            }
          } else if (data.action === 'finish') {
            toast.success('Report Generation Finished');
          }
        });

        return updatedReport;
      });

      eventQueueRef.current = [];
    };

    const intervalId = setInterval(processEventQueue, 600); // Process queue every 100ms

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    updatePCode();
  }, []);
  useEffect(() => {
    updatePCode()    
  }, [])

 const handleClick = () => {
    updatePCode()    
 }

  const style = {
    display: 'grid',
    gridTemplateColumns: '2rem 3rem 10rem 5rem 7rem 6rem 5rem 4rem 5rem 6rem 6rem 4rem 9rem 8rem 7rem 3rem 9rem 8rem',
   
  }

  let otherindex = 0

  const downloadCSV = () => {
    const headers = [
      "QA",
      "id",
      "Row",
      "Name",
      "Booking",
      "FIN",
      "MRUN",
      "ChampID",
      "Arrest Count",
      "Program",
      "ODR Assessment Date",
      "Release Date",
      "P odr",
      "Date odr",
      "PCode Source",
      "Building",
      "P rel",
      "Date rel",
      "PCode Source",      
    ];
  
    const csvContent = [
      headers.join(","),
      ...Object.values(report).map((item: any) => [
        item.tests ? "PASS" : "FAIL",
        item.id,
        item.name,
        item.booking,
        item.fin,
        item.mrun,
        item.champid,
        item.arrest_count,
        item.program,
        item.ODRAssessmentDate,
        item.release_date,
        item.pcode_at_odrAssessment,
        item.pcodeDate_at_odrAssessment,
        item.pcodeSource_at_odrAssessment,
        item.building_at_odrAssessment,
        item.pcode_at_release,
        item.pcodeDate_at_release,
        item.pcodeSource_at_release,        
      ].join(",")),
    ].join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "pLevelReport.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const buttonText = (showClients) ? "Show All" : "Show Only Clients"
  return (
    <Card className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Refresh PCode Report</h1>
        <div className="flex gap-4">
          {/*<Button onClick={downloadCSV}>Download CSV</Button>*/}
          <Button onClick={() => setShowClients(!showClients)}>
            {buttonText}
          </Button>
        </div>
      </div>
      
      <div className="text-sm text-muted-foreground mb-4">
        Processing: {currentStep}
      </div>

      <Card className="p-4">
        <ScrollArea className="h-[600px] w-full">
          <div className="grid grid-cols-[2rem_3rem_10rem_5rem_7rem_6rem_5rem_4rem_5rem_6rem_6rem_4rem_9rem_8rem_7rem_3rem_9rem_8rem] gap-2 mb-4 font-medium">
            <div>qa</div>
            <div>id</div>        
            <div>Name</div>        
            <div>Booking</div>
            <div>FIN</div>
            <div>MRUN</div>
            <div>ChampID</div>
            <div>Arrest Count</div>
            <div>Program</div>
            <div>ODR Assessment Date</div>
            <div>Release Date</div>
            <div>P<br/>odr</div>
            <div>Date<br/>odr</div>
            <div>PCode Source</div>
            <div>Building</div>
            <div>P<br/>rel</div>
            <div>Date<br/>rel</div>
            <div>PCode Source</div>        
          </div>

          <div className="space-y-1">
            {Object.values(report).map((item: any, index: number) => {
              if (showClients && !item.client) return null;
              
              const color = item.tests === 'passed' ? 'green' : 
                           item.tests === 'skipped' ? 'yellow' : 'red';

              return (
                <div 
                  key={index}
                  onClick={() => openModal(item)}
                  className={`hover:bg-accent cursor-pointer rounded-md p-2 ${
                    index % 2 === 0 ? 'bg-muted/50' : ''
                  }`}
                >
                  <div className="grid grid-cols-[2rem_3rem_10rem_5rem_7rem_6rem_5rem_4rem_5rem_6rem_6rem_4rem_9rem_8rem_7rem_3rem_9rem_8rem] gap-2">
                    <div><img src={`/ui/build/icons/${color}check.png`} alt='check' width='10px' /></div>                
                    <div>{item.id}</div>                
                    <div>{item.name}</div>
                    <div>{item.booking}</div>
                    <div>{item.fin}</div>
                    <div>{item.mrun}</div>
                    <div>{item.champid}</div>
                    <div>{item.arrest_count}</div>
                    <div>{item.program}</div>
                    <div>{item.ODRAssessmentDate}</div>
                    <div>{item.release_date}</div>
                    <div>{item.pcode_at_odrAssessment}</div>
                    <div>{item.pcodeDate_at_odrAssessment}</div>
                    <div>{item.pcodeSource_at_odrAssessment}</div>
                    <div>{item.building_at_odrAssessment}</div>
                    <div>{item.pcode_at_release}</div>
                    <div>{item.pcodeDate_at_release}</div>
                    <div>{item.pcodeSource_at_release}</div>                
                  </div>
                  {item.message && (
                    <div className="mt-1 text-sm text-muted-foreground">
                      {item.message}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </ScrollArea>
      </Card>

      <Modal 
        isOpen={isModalOpen} 
        onRequestClose={closeModal}
        className="bg-background p-6 rounded-lg max-w-md mx-auto mt-20"
      >
        <h2 className="text-xl font-bold mb-4">Messages</h2>
        {selectedItem?.messages && (
          <ScrollArea className="h-[300px]">
            <ul className="space-y-2">
              {selectedItem.messages.map((message: string, index: number) => (
                <li key={index} className="text-sm">{message}</li>
              ))}
            </ul>
          </ScrollArea>
        )}
        <Button onClick={closeModal} className="mt-4">Close</Button>
      </Modal>
    </Card>
  );
};
