import React from "react";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Copy } from "lucide-react";
import { Button } from "@/components/ui/button";

const UPDATE_PARTICIPANT = gql`
  mutation UpdateParticipantByODRNumber($odrnumber: Integer!, $participant: Integer, $updates: UpdateParticipantInput!) {
    updateParticipantByODRNumber(odrnumber: $odrnumber, participant: $participant, updates: $updates) {
      message
    }
  }
`;



const CalAimDemographics = ({ calaimData : c, odrNumber, healthPlanProviders, refetch }: any) => {
  const [calaimData, setCalaimData] = React.useState(c);
  const [originalData] = React.useState(c);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    const updates: any = {};
    const fields = ['ssn', 'cin', 'cii', 'healthplanProvider', 'pcpname', 'fin'];
    
    fields.forEach(field => {
      if (calaimData[field] !== originalData[field]) {
        updates[field] = calaimData[field];
      }
    });

    if (Object.keys(updates).length > 0) {
      updateParticipantProgramData({
        variables: {
          odrnumber: odrNumber,
          updates
        },
      });
      refetch();
    }
  };

  const [updateParticipantProgramData] = useMutation(UPDATE_PARTICIPANT);

  return (
    <div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <h3>ECM CalAIM Demographics</h3>
        <div>ECM Care Manager: {calaimData.ecmmanager}</div>
      </div>

      <form onSubmit={handleSubmit}>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <div>
            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="ssn">SSN</label>
              <div>
                <input
                  type="text"
                  name="ssn"
                  value={calaimData.ssn}
                  id="calaim_ssn"
                  onChange={(e) => setCalaimData({ ...calaimData, ssn: e.target.value })}
                />
              </div>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="cin">MediCal-CIN</label>
              <div>
                <input
                  type="text"
                  name="cin"
                  value={calaimData.cin}
                  id="calaim_cin"
                  onChange={(e) => setCalaimData({ ...calaimData, cin: e.target.value })}
                />
              </div>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="cii">MRUN</label>
              <div className="flex">
                <input
                  type="text"
                  name="cii"
                  value={calaimData.mrun}
                  disabled
                  className="rounded-r-none border-r-0"
                />
                <button
                  type="button"
                  onClick={() => {
                    navigator.clipboard.writeText(calaimData.mrun);
                    toast.success("MRUN copied to clipboard");
                  }}
                  className="px-2 hover:bg-gray-100"
                >
                  <Copy className="h-4 w-4 text-gray-500" />
                </button>
              </div>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="cii">Other</label>
              <div>
                <input
                  type="text"
                  name="cii"
                  value={calaimData.cii}
                  id="calaim_cii"
                    onChange={(e) => setCalaimData({ ...calaimData, cii: e.target.value })}
                />
              </div>
            </div>
          </div>

          <div>
            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="healthplan">Health Plan Provider</label>
              <div>
                <select
                  name="healthplan"
                  value={calaimData.healthplan}
                  id="calaim_hcprovider"
                  onChange={(e) => setCalaimData({ ...calaimData, healthplan: e.target.value })}
                >
                  <option value="">--Select an option--</option>
                  {healthPlanProviders.map((provider: any) => (
                    <option key={provider.providerId} value={provider.providerId}>
                      {provider.providerName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="pcpname">PCP Name</label>
              <div>
                <input
                  type="text"
                  name="pcpname"
                  value={calaimData.pcpname}
                  id="calaim_pcpname"
                  onChange={(e) => setCalaimData({ ...calaimData, pcpname: e.target.value })}
                />
              </div>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="fin">FIN</label>
              <div className="flex">
                <input
                  type="text"
                  name="fin"
                  value={calaimData.fin}
                  id="calaim_fin"
                  onChange={(e) => setCalaimData({ ...calaimData, fin: e.target.value })}
                  className="rounded-r-none border-r-0"
                />
                <button
                  type="button"
                  onClick={() => {
                    navigator.clipboard.writeText(calaimData.fin);
                    toast.success("FIN copied to clipboard");
                  }}
                  className="px-2 hover:bg-gray-100"
                >
                  <Copy className="h-4 w-4 text-gray-500" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <Button type="submit" className="mr-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Update</Button>
      </form>
    </div>
  );
};

export default CalAimDemographics;
