import React from 'react';
import { useQuery } from '@apollo/client';
import { X } from 'lucide-react';
import { gql } from '@apollo/client';

// Define TypeScript interfaces
interface CandidateData {
  id: string;
  firstName: string;
  lastName: string;
  dob: string;
  bookingNumber: string;
  caseNumber: string;
  location: string;
  facility: string;
  floor: string;
  gender: string;
  maxDate: string;
  courtName: string;
  hearingDate: string;
  clinician: string;
  md: string;
  disposition: string;
  dispositionDate: string;
  dispositionType: string;
  ultimatePlacement: string;
  notes: string;
  notesMessages: string;
  arrestDate: string;
  referralDate: string;
  program: string;
  icmsProvider: string;
  serviceProvider: string;
  pLevel: string;
  team: string;
  updated: string;
  conditionalReleaseDate: string;
  conditionalRelease: boolean;
  conditionalReleaseOutcome: string;
  conditionalReleaseOutcomeDate: string;
  released: boolean;
  returnee: boolean;
  mediCal: boolean;
  healthPlan: string;
  empaneledDhsAtBaseline: boolean;
  empaneledByOdrDate: string;
  empaneledByClinic: string;
  pcpName: string;
  elmCareManager: string;
  enrolled: boolean;
  enrolledDate: string;
  caseAccepted: boolean;
  departmentHub: string;
  cancelled: boolean;
  cancelledDate: string;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  odrNumber: number;
}

const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
      candidatesData {
        id
        firstName 
        lastName
        dob
        bookingNumber
        caseNumber
        location
        facility
        floor
        gender
        maxDate
        courtName
        hearingDate
        clinician
        md
        disposition
        dispositionDate 
        dispositionType
        ultimatePlacement
        notes
        notesMessages
        arrestDate
        referralDate
        program
        icmsProvider
        serviceProvider
        pLevel
        team
        updated
        conditionalReleaseDate
        conditionalRelease
        conditionalReleaseOutcome
        conditionalReleaseOutcomeDate
        released
        returnee
        mediCal
        healthPlan
        empaneledDhsAtBaseline
        empaneledByOdrDate
        empaneledByClinic
        pcpName
        elmCareManager
        enrolled
        enrolledDate
        caseAccepted
        departmentHub
        cancelled 
        cancelledDate
      }
    }
  }
`;

const StatusBadge = ({ value }: { value: boolean }) => (
    <span className={`px-2 py-1 text-xs font-medium rounded-full ${
      value 
        ? 'bg-green-100 text-green-800' 
        : 'bg-gray-100 text-gray-800'
    }`}>
      {value ? 'Yes' : 'No'}
    </span>
  );
  
  const TableRow = ({ label, value, important = false }: { label: string; value: any; important?: boolean }) => {
    const renderValue = () => {
      if (typeof value === 'boolean') {
        return <StatusBadge value={value} />;
      }
      
      if (!value) return '-';
      
      // Check if the value contains HTML tags
      const containsHTML = typeof value === 'string' && /<[a-z][\s\S]*>/i.test(value);
      
      if (containsHTML) {
        // Sanitize and render HTML content
        const sanitizedHTML = value; // Temporarily removed DOMPurify until imported
        return (
          <div 
            className="prose prose-sm max-w-none"
            dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
          />
        );
      }
      
      return value;
    };
  
    return (
      <div className="grid grid-cols-2 py-2 border-b border-gray-200 last:border-b-0">
        <div className={`font-medium ${important ? 'text-blue-600' : 'text-gray-600'}`}>
          {label}
        </div>
        <div>{renderValue()}</div>
      </div>
    );
  };
  

export const CandidateModal: React.FC<ModalProps> = ({ isOpen, onClose, odrNumber }) => {
  const { loading, error, data } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
    variables: { odrnumber: odrNumber },
    skip: !odrNumber
  });

  if (!isOpen) return null;

  const formatDate = (dateString: string): string => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString();
  };

  const ModalOverlay = (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-40 backdrop-blur-sm"
      onClick={onClose}
    />
  );

  const ModalContent = (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg w-11/12 max-w-6xl max-h-[90vh] shadow-xl">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-200 sticky top-0 bg-white rounded-t-lg">
          <div>
            <h2 className="text-xl font-bold">Candidate Information</h2>
            <p className="text-sm text-gray-500">ODR #{odrNumber}</p>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {/* Content */}
        <div className="overflow-y-auto p-6" style={{ maxHeight: 'calc(90vh - 120px)' }}>
          {loading ? (
            <div className="flex justify-center items-center h-32">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
            </div>
          ) : error ? (
            <div className="text-red-500 text-center p-4 bg-red-50 rounded">
              <p className="font-semibold">Error loading candidate data</p>
              <p className="text-sm">{error.message}</p>
            </div>
          ) : !data?.getParticipantByODRNumber?.candidatesData?.length ? (
            <div className="text-gray-500 text-center p-8 bg-gray-50 rounded-lg">
              <p className="font-semibold">No candidate data found</p>            
            </div>
          ) : (
            data.getParticipantByODRNumber.candidatesData.map((candidate: CandidateData, index: number) => (
              <div 
                key={candidate.id || index} 
                className="mb-8 bg-gray-50 rounded-lg p-6"
              >
                <div className="flex items-center justify-between mb-6">
                  <h3 className="text-lg font-semibold text-blue-600">
                    {candidate.program.toUpperCase()} Candidate Details
                  </h3>
                  {candidate.cancelled && (
                    <span className="px-3 py-1 bg-red-100 text-red-800 rounded-full text-sm font-medium">
                      Cancelled {formatDate(candidate.cancelledDate)}
                    </span>
                  )}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  {/* Personal Information */}
                  <div className="space-y-4">
                    <h4 className="font-medium text-gray-700 mb-2">Personal Information</h4>
                    <div className="bg-white p-4 rounded-md shadow-sm">
                      <TableRow label="Name" value={`${candidate.firstName || ''} ${candidate.lastName || ''}`} important />
                      <TableRow label="DOB" value={formatDate(candidate.dob)} />
                      <TableRow label="Gender" value={candidate.gender} />
                      <TableRow label="Location" value={candidate.location || candidate.facility} />
                      <TableRow label="Floor" value={candidate.floor} />
                      <TableRow label="Department Hub" value={candidate.departmentHub} />
                    </div>
                  </div>

                  {/* Case Information */}
                  <div className="space-y-4">
                    <h4 className="font-medium text-gray-700 mb-2">Case Information</h4>
                    <div className="bg-white p-4 rounded-md shadow-sm">
                      <TableRow label="Booking Number" value={candidate.bookingNumber} important />
                      <TableRow label="Case Number" value={candidate.caseNumber} />
                      <TableRow label="Court" value={candidate.courtName} />
                      <TableRow label="Hearing Date" value={formatDate(candidate.hearingDate)} />
                      <TableRow label="Max Date" value={formatDate(candidate.maxDate)} />
                      <TableRow label="Arrest Date" value={formatDate(candidate.arrestDate)} />
                      <TableRow label="Referral Date" value={formatDate(candidate.referralDate)} />
                    </div>
                  </div>

                  {/* Program Status */}
                  <div className="space-y-4">
                    <h4 className="font-medium text-gray-700 mb-2">Program Status</h4>
                    <div className="bg-white p-4 rounded-md shadow-sm">
                      <TableRow label="ICMS Provider" value={candidate.icmsProvider} />
                      <TableRow label="Service Provider" value={candidate.serviceProvider} />
                      <TableRow label="Team" value={candidate.team} />
                      <TableRow label="P Level" value={candidate.pLevel} />
                      <TableRow label="Case Accepted" value={candidate.caseAccepted} />
                      <TableRow label="Enrolled" value={candidate.enrolled} />
                      <TableRow label="Enrolled Date" value={formatDate(candidate.enrolledDate)} />
                    </div>
                  </div>

                  {/* Medical Information */}
                  <div className="space-y-4">
                    <h4 className="font-medium text-gray-700 mb-2">Medical Information</h4>
                    <div className="bg-white p-4 rounded-md shadow-sm">
                      <TableRow label="MediCal" value={candidate.mediCal} />
                      <TableRow label="Health Plan" value={candidate.healthPlan} />
                      <TableRow label="Empaneled DHS at Baseline" value={candidate.empaneledDhsAtBaseline} />
                      <TableRow label="Empaneled By ODR Date" value={formatDate(candidate.empaneledByOdrDate)} />
                      <TableRow label="Empaneled By Clinic" value={candidate.empaneledByClinic} />
                      <TableRow label="PCP Name" value={candidate.pcpName} />
                      <TableRow label="Care Manager" value={candidate.elmCareManager} />
                      <TableRow label="Clinician" value={candidate.clinician} />
                      <TableRow label="MD" value={candidate.md} />
                    </div>
                  </div>

                  {/* Release Information */}
                  <div className="space-y-4">
                    <h4 className="font-medium text-gray-700 mb-2">Release Status</h4>
                    <div className="bg-white p-4 rounded-md shadow-sm">
                      <TableRow label="Released" value={candidate.released} />
                      <TableRow label="Returnee" value={candidate.returnee} />
                      <TableRow label="Conditional Release" value={candidate.conditionalRelease} />
                      <TableRow label="Conditional Release Date" value={formatDate(candidate.conditionalReleaseDate)} />
                      <TableRow label="Release Outcome" value={candidate.conditionalReleaseOutcome} />
                      <TableRow label="Outcome Date" value={formatDate(candidate.conditionalReleaseOutcomeDate)} />
                    </div>
                  </div>

                  {/* Disposition Information */}
                  <div className="space-y-4">
                    <h4 className="font-medium text-gray-700 mb-2">Disposition</h4>
                    <div className="bg-white p-4 rounded-md shadow-sm">
                      <TableRow label="Disposition" value={candidate.disposition} important />
                      <TableRow label="Disposition Date" value={formatDate(candidate.dispositionDate)} />
                      <TableRow label="Disposition Type" value={candidate.dispositionType} />
                      <TableRow label="Ultimate Placement" value={candidate.ultimatePlacement} />
                      <TableRow label="Last Updated" value={formatDate(candidate.updated)} />
                    </div>
                  </div>
                </div>

                {/* Notes Section */}
                {(candidate.notes || candidate.notesMessages) && (
                  <div className="mt-6">
                    <div className="h-px bg-gray-200 my-6" />
                    <h4 className="font-medium text-gray-700 mb-2">Notes</h4>
                    <div className="bg-white p-4 rounded-md shadow-sm whitespace-pre-wrap">
                      {candidate.notes || candidate.notesMessages}
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {ModalOverlay}
      {ModalContent}
    </>
  );
};

export default CandidateModal;