import React, { useState } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Edit, Heart, AlertCircle, Pill, History, Brain, File, Stethoscope, LucideIcon } from "lucide-react";
import { EditMedical } from "./modals/EditMedical";
import { gql, useQuery } from "@apollo/client";

const GET_ICD10_CODES = gql`
  query GetICD10Codes {
    getICD10Codes {
      icd10
      icd10Description
    }
  }
`;

interface ICD10SectionProps {
  icd10?: string;
}

interface ICD10Code {
  icd10: string;
  icd10Description: string;
}

interface MedicalData {
  bookingNumber?: string;
  mainNumber?: string;
  medicalProblems?: string;
  dsm5dx?: string;
  psychotropicMeds?: string;
  otherMeds?: string;
  currentMeds?: string;
  clinicalPresentation?: string;
  psychHistory?: string;
  substanceHistory?: string;
  icd10?: string;
  fin?: string;
}

interface MedicalStatusProps {
  odrnumber: number;
  data: {
    getParticipantByODRNumber: MedicalData;
  } | null;
  refetch: () => void;
}

interface MedicalSectionProps {
  title: string;
  content?: string;
  icon?: LucideIcon;
  showDangerousHTML?: boolean;
}

const translateContent = (content: string): string => {
  if (!content) return "";
  const str1 = content.replace(/<br\/>/g, "").replace(/<p>/g, "");
  const str2 = str1
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
  const str3 = str2
    .replace(/&lt;br\/&gt;/g, "")
    .replace(/\r\n|\r|\n/g, "<br>")
    .replace(/<br\/>/g, "<br>")
    .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
    .replace(/ {2,}/g, (match: string) => "&nbsp;".repeat(match.length));
  return str3;
};

const MedicalSection: React.FC<MedicalSectionProps> = ({ 
  title, 
  content, 
  icon: Icon, 
  showDangerousHTML = false 
}) => {
  const translatedContent = content ? translateContent(content) : "No information recorded";
  
  return (
    <Card className="w-full shadow-sm hover:shadow-md transition-all duration-200">
      <CardHeader className="py-2 px-3">
        <div className="flex items-center space-x-2">
          {Icon && (
            <div className="p-1.5 bg-blue-50 rounded-full">
              <Icon className="h-4 w-4 text-blue-500" />
            </div>
          )}
          <CardTitle className="text-sm font-medium text-gray-700">{title}</CardTitle>
        </div>
      </CardHeader>
      <CardContent className="px-3 py-2 text-xs text-gray-600">
        <div
          className="prose-sm max-w-none"
          dangerouslySetInnerHTML={{ __html: translatedContent }}
        />
      </CardContent>
    </Card>
  );
};

const ICD10Section: React.FC<ICD10SectionProps> = ({ icd10 }) => {
  const { data, loading, error } = useQuery(GET_ICD10_CODES);
  
  if (!icd10) return null;
  if (loading) return <div className="text-xs text-gray-500">Loading ICD10 codes...</div>;
  if (error) return <div className="text-xs text-red-500">Error loading ICD10 codes</div>;
  
  const icd10Codes = icd10.split(',').map(code => code.trim());
  const matchedCodes = icd10Codes.map(code => {
    const match = data.getICD10Codes.find(
      (item: ICD10Code) => item.icd10 === code
    );
    return match ? { code, description: match.icd10Description } : { code, description: 'Description not found' };
  });
  
  return (
    <Card className="w-full shadow-sm hover:shadow-md transition-all duration-200">
      <CardHeader className="py-2 px-3">
        <div className="flex items-center space-x-2">
          <div className="p-1.5 bg-blue-50 rounded-full">
            <AlertCircle className="h-4 w-4 text-blue-500" />
          </div>
          <CardTitle className="text-sm font-medium text-gray-700">
            ICD10 Diagnoses
          </CardTitle>
        </div>
      </CardHeader>
      <CardContent className="px-3 py-2">
        <div className="space-y-1.5">
          {matchedCodes.map(({ code, description }, index) => (
            <div key={index} className="p-1.5 bg-gray-50 rounded text-xs">
              <span className="font-medium text-gray-700">{code}</span>
              <span className="text-gray-500 mx-1">-</span>
              <span className="text-gray-600">{description}</span>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export const MedicalStatus: React.FC<MedicalStatusProps> = ({ odrnumber, data, refetch }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  if (!data) {
    return (
      <div className="flex items-center justify-center h-32">
        <p className="text-xs text-gray-500">Loading medical information...</p>
      </div>
    );
  }

  const medicalData = data.getParticipantByODRNumber;

  return (
    <div className="space-y-4 mx-auto">
      {/* Header Section */}
      <div className="flex items-center justify-between pb-1 mb-2">
        <div className="flex items-center gap-1.5">
          <Heart className="h-4 w-4 text-blue-500" />
          <h3 className="font-medium text-sm text-gray-700">Medical Information</h3>          
        </div>        
        <button 
          onClick={() => setIsEditModalOpen(true)}
          className="inline-flex items-center gap-1 px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors text-xs font-medium"
        >
          <Edit className="h-3 w-3" />
          Edit
        </button>
      </div>
      <div className="text-xs text-gray-500">CHS FIN # {medicalData.fin}</div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <ICD10Section icd10={medicalData.icd10} />
                
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <MedicalSection
          title="Medical Problems"
          content={medicalData.medicalProblems}
          icon={Stethoscope}
          showDangerousHTML={true}
        />

        <MedicalSection
          title="DSM Primary Diagnosis"
          content={medicalData.dsm5dx}
          icon={Brain}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <MedicalSection
          title="Psychotropic Medications"
          content={medicalData.psychotropicMeds}
          icon={Pill}
          showDangerousHTML={true}
        />

        <MedicalSection
          title="Other Medications"
          content={medicalData.otherMeds}
          icon={Pill}
        />
      </div>

      <MedicalSection
        title="Current Medications"
        content={medicalData.currentMeds}
        icon={Pill}
      />

      <MedicalSection
        title="Clinical Presentation and Competency"
        content={medicalData.clinicalPresentation}
        icon={Stethoscope}
        showDangerousHTML={true}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <MedicalSection
          title="Brief Psychiatric History"
          content={medicalData.psychHistory}
          icon={History}
          showDangerousHTML={true}
        />

        <MedicalSection
          title="Substance History"
          content={medicalData.substanceHistory}
          icon={History}
        />
      </div>

      {isEditModalOpen && (
        <EditMedical
          refetch={refetch}
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          programNumber={odrnumber}
          medicalData={medicalData}
        />
      )}
    </div>
  );
};

export default MedicalStatus;