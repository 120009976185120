import React from "react";
import ReactModal from "react-modal";
import { isoDateToMDY } from "../../../utils/common";
import { useQuery, gql } from "@apollo/client";
interface TimelineEventProps {
  date: string;
  label: string;
  details: string;
  summary?: string;
  type?: "disposition" | "arrest" | "release" | "note" | "booking" | "default";
  program?: string;
}

const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
      scans {
        id
        filename
        filetype
        filelabel
        filedate
      }
      nameHistory {
        lastName
        firstName
        program
      }
      dobHistory {
        value
        program
      }
      programs
      mrun
      cid
      champidHistory {
        value
        program
      }
      genderHistory {
        value
        program
      }
      mrunHistory {
        value
        program
      }
      isNumberHistory {
        value
        program
      }
      bookingHistory {
        value
        program
        entrydate
      }
      disposition {
        disposition
      }
      dispositionHistory {
        disposition
        dispositionDate
        program
      }
      pathwayHistory {
        value
        program
        entrydate
      }
      providerHistory {
        value
        program
        entrydate
      }
      releasedDate
      releasedDateHistory {
        value
        program
      }
      jcitHistory {
        value
        program
      }
      probationHistory {
        value
        program
      }
      notes {
        note
        program
        date
      }
      programDetails {
        programDescription
        programParticipantNumber
        entryDate
        exitDate
        entryDate
        exitDate
        disposition
        provider
        pathway
        releaseDate
        probation
      }
      
      fin
    }
  }
`;

const TimelineEvent: React.FC<TimelineEventProps> = ({
  date,
  label,
  details,
  summary,
  type = "default",
  program,
}) => {
  const getEventColor = (type: string) => {
    switch (type) {
      case "disposition":
        return "bg-blue-100 border-blue-200";
      case "arrest":
        return "bg-red-100 border-red-200";
      case "release":
        return "bg-green-100 border-green-200";
      case "note":
        return "bg-yellow-100 border-yellow-200";
      case "booking":
        return "bg-red-100 border-red-200";
      default:
        return "bg-gray-100 border-gray-200";
    }
  };



  const programName = program ? program.toUpperCase() : "";

  return (
    <div className="flex gap-4 items-start">
      <div className="w-32 flex-shrink-0 text-sm text-gray-500 pt-1">
        <span>{isoDateToMDY(date)}</span>
        <span className="text-sm font-medium text-blue-600 hover:text-blue-800 cursor-pointer ml-2">{programName}</span>
      </div>
      <div className={`flex-grow p-3 rounded-lg border ${getEventColor(type)}`}>
        <div className="text-sm font-medium text-gray-900">{label}</div>
        <div className="text-sm text-gray-600 mt-1">{details}</div>
        {summary && (
          <div className="mt-2 pt-2 border-t border-gray-200">
            <div className="text-xs text-gray-500 font-medium">Summary</div>
            <div className="text-sm text-gray-600">{summary}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export const TimelineModal = ({
  isOpen,
  onClose,
  odrnumber,
}: {
  isOpen: boolean;
  onClose: () => void;
  odrnumber: number;
}) => {

  const getProgramEvents = (data: any, selectedItems:any) => {
    if (!data?.getParticipantByODRNumber) return [];

    const events: TimelineEventProps[] = [];
    const program = data.getParticipantByODRNumber;

    
    // Add dispositions (only ones with dates and filter duplicates)
    const seenDispositions = new Set();
    program.dispositionHistory
      ?.filter((d: any) => d.dispositionDate && d.disposition && selectedItems.disposition)
      .forEach((d: any) => {
        const key = `${d.dispositionDate}-${d.disposition}`;
        if (!seenDispositions.has(key)) {
          seenDispositions.add(key);
          events.push({
            date: d.dispositionDate,
            label: "Disposition Change",
            details: d.disposition,
            type: "disposition",
            program: d.program,
          });
        }
      });

    // Add booking numbers
    program.bookingHistory?.forEach((b: any) => {
      events.push({
        date: b.entrydate,
        label: "New Booking",
        details: b.value,
        type: "booking",
        program: b.program,
      });
    });

    // Add arrest dates
    program.arrestDateHistory?.forEach((a: any) => {
      events.push({
        date: a.value,
        label: "Arrest Date",
        details: "Arrested",
        type: "arrest",
        program: a.program,
      });
    });

    // Add notes
    selectedItems.Notes && program.notes?.forEach((n: any) => {
      events.push({
        date: n.date,
        label: "Note Added",
        details: n.note,
        type: "note",
        program: n.program,
      });
    });

    // Add scans/documents
    selectedItems.Scans && program.scans?.forEach((scan: any) => {
      events.push({
        date: scan.filedate,
        label: `Document Added: ${scan.filetype}`,
        details: scan.filelabel,
        type: "note",
        program: scan.program,
      });
    });

    // Add program events
    program.programDetails
      ?.filter((pd: any) => pd.programparticipantnumber === odrnumber)
      .forEach((pd: any) => {
        if (pd.entryDate) {
          events.push({
            date: pd.entryDate,
            label: "Program Entry",
            details: `Entered ${pd.programDescription.toUpperCase()}`,
            type: "default",
            program: pd.program,
          });
        }
        if (pd.exitDate) {
          events.push({
            date: pd.exitDate,
            label: "Program Exit",
            details: `Exited ${pd.programDescription.toUpperCase()}`,
            type: "release",
            program: pd.program,
          });
        }
      });

    // pathway changes
    selectedItems.Pathway && program.pathwayHistory?.forEach((p: any) => {
      if (p.entrydate && p.value) {
        events.push({
          date: p.entrydate,
          label: "Pathway Change",
          details: p.value,
          type: "default",
          program: p.program,
        });
      }
    });

    program.probationHistory?.forEach((p: any) => {
      if (p.entrydate && p.value) {
        events.push({
          date: p.entrydate,
          label: "Probation Status Change",
          details: p.value,
          type: "default",
          program: p.program,
        });
      }
    });


    // provider changes
    selectedItems.Provider && program.providerHistory?.forEach((p: any) => {
      if (p.entrydate && p.value) {
        events.push({
          date: p.entrydate,
          label: "Provider Change",
          details: p.value,
          type: "default",
          program: p.program,
        });
      }
    });

    // release dates
    program.releaseDateHistory?.forEach((r: any) => {
      if (r.value) {
        events.push({
          date: r.value,
          label: "Released from Custody",
          details: "",
          type: "release",
          program: r.program,
        });
      }
    });

    // Sort all events by date, most recent first
    return events.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  };


  const { loading, error, data } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
    variables: { odrnumber: odrnumber },
    //skip: !participant,
  });

  const [selectedItems, setSelectedItems] = React.useState({
    //Disposition: true,
    //Booking: true,
    //Arrest: true,
    //Release: true,
    Notes: false,
    Provider: false,
    Pathway: false,
    //Probation: true,
    Scans: true,
    //Programs: true,
  });

  // Example events array
  const events: TimelineEventProps[] = getProgramEvents(data,selectedItems)/*[
    {
      date: "2024-01-15T00:00:00.000Z",
      label: "cat Disposition",
      details: "Initial assessment completed",
      summary: "Case file updated",
      type: "disposition",
    },
    {
      date: "2024-01-10T00:00:00.000Z",
      label: "Example Note",
      details: "Case file updated",
      summary: "Case file updated",
      type: "note",
    },
    {
      date: "2024-01-01T00:00:00.000Z",
      label: "Example Release",
      details: "Released from facility",
      summary: "Case file updated",
      type: "release",
    },
  ];*/

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          border: "none",
        },
        content: {
          position: "relative",
          background: "white",
          padding: "20px",
          maxHeight: "80vh",
          width: "100%",
          maxWidth: "800px",
          inset: 0,
          border: "none",
        },
      }}
      onRequestClose={onClose}
    >
     
      <div className="space-y-4">
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-lg font-semibold text-neutral-900">
            Participant Timeline
          </h2>
          <button
            onClick={onClose}
            className="text-neutral-500 hover:text-neutral-700"
          >
            Close
          </button>
        </div>

        {/* check boxes */}
        <div className="flex items-center space-x-4">
          {Object.keys(selectedItems).map((item) => (
            <span key={item}>
              
              <input className="mr-2"
                key={item}
              type="checkbox"
              checked={selectedItems[item as keyof typeof selectedItems]}
                onChange={() => setSelectedItems({...selectedItems, [item]: !selectedItems[item as keyof typeof selectedItems]})}
              />
              <span className="text-sm text-gray-500">{item}</span>            
            </span>
          ))}
        </div>

        <div className="space-y-6 p-4 max-h-[60vh] overflow-y-auto">
          {events.map((event, index) => (
            <TimelineEvent
              key={`${event.date}-${index}`}
              date={event.date}
              program={event.program}
              label={event.label}
              details={event.details}
              summary={event.summary}
              type={event.type}
            />
          ))}
        </div>
      </div>
    </ReactModal>
  );
};

export default TimelineModal;
