import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { programNameFromID, isoDateToMDY } from "../../utils/common";
import {
  User,
  Calendar,
  HashIcon,
  Fingerprint,
  Scan,
  Building,
  ChevronUp,
  ChevronDown,
  MoreHorizontal,
  Edit,
} from "lucide-react";
import { HistorySection } from "./components/HistorySection";
import { ParticipantDataSectionFull } from "./modals/ParticipantDataSectionFull";

import { ProgramDetailsModal } from "./modals/ProgramDetails";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { EditParticipant } from "./modals/EditParticipant";

export const ParticipantDataSection = ({
  odrnumber,
  program,
  refetch,
  data,
  loading,
  error,
}: {
  odrnumber: any;
  program: any;
  refetch: () => void;
  data: any;
  loading: boolean;
  error: any;
}) => {
  /* const { loading, error, data } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
    variables: { odrnumber },
    skip: !odrnumber,
  });*/
  const [selectedProgramForModal, setSelectedProgramForModal] = useState<
    string | null
  >(null);
  const [showMoreOpen, setShowMoreOpen] = useState(false);
  const [editParticipant, setEditParticipant] = useState(false);

  if (loading)
    return (
      <div className="min-h-[200px] flex items-center justify-center bg-gray-50 rounded-lg">
        <div className="text-center">
          <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-600 border-r-transparent align-[-0.125em]"></div>
          <div className="mt-4 text-sm font-bold text-gray-600">
            Loading participant data...
          </div>
        </div>
      </div>
    );

  if (error)
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-red-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              Error Loading Data
            </h3>
            <div className="mt-2 text-sm text-red-700">
              Unable to load participant data. Please try again.
            </div>
          </div>
        </div>
      </div>
    );

  const participantData = data.getParticipantByODRNumber;

  const historySections = [
    {
      title: "Name",
      icon: <User className="h-5 w-5 text-gray-400" />,
      data: participantData.nameHistory,
      maxCollapsed: 1,
      columns: 3,
      showDates: true,
      renderCollapsed: (item: any) =>
        `${participantData.name.lastName}, ${participantData.name.firstName}`,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[8rem,5rem,auto]  items-left">
          <span className="text-gray-900 text-nowrap items-left">
            {item.lastName} - {item.firstName}
          </span>
          <span className="text-gray-400 text-xs">
            {isoDateToMDY(item.entrydate)}
          </span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == "prg_wmn" ? "prgwmn" : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Gender",
      icon: <Calendar className="h-5 w-5 text-gray-400" />,
      data: participantData.genderHistory,
      maxCollapsed: 1,
      mostRecent: participantData.gender,
      showDates: true,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[8rem,6rem,auto]  items-left">
          <span className="text-gray-900 items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">
            {isoDateToMDY(item.entrydate)}
          </span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == "prg_wmn" ? "prgwmn" : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Date of Birth",
      icon: <Calendar className="h-5 w-5 text-gray-400" />,
      data: participantData.dobHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => isoDateToMDY(item.value),
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[8rem,6rem,auto]  items-left">
          <span className="text-gray-900 items-left">
            {isoDateToMDY(item.value)}
          </span>
          <span className="text-gray-400 text-xs">
            {isoDateToMDY(item.entrydate)}
          </span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == "prg_wmn" ? "prgwmn" : item.program}
          </span>
        </div>
      ),
    },
    /*{
      title: "Pathway History",
      icon: <HashIcon className="h-5 w-5 text-gray-400" />,
      data: participantData.pathwayHistory,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => `${item.program} - ${item.value}`,
      showDates: true,
      formatDate: isoDateToMDY,
    },*/
    /*{
      title: "Release Date History",
      icon: <Calendar className="h-5 w-5 text-gray-400" />,
      data: participantData.releaseDateHistory,
      renderCollapsed: (item: any) => isoDateToMDY(item.value),
      renderExpanded: (item: any) =>
        `${item.program} - ${isoDateToMDY(item.value)}`,
    },*/

    {
      title: "ChampID",
      icon: <HashIcon className="h-5 w-5 text-gray-400" />,
      data: participantData.champidHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => String(item.value), // Convert number to string
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[8rem,6rem,auto]  items-left">
          <span className="text-gray-900 items-left">{String(item.value)}</span>
          <span className="text-gray-400 text-xs">
            {isoDateToMDY(item.entrydate)}
          </span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == "prg_wmn" ? "prgwmn" : item.program}
          </span>
        </div>
      ), // Convert number to string
    },
    {
      title: "MRUN",
      icon: <Fingerprint className="h-5 w-5 text-gray-400" />,
      data: participantData.mrunHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => participantData.mrun,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[8rem,6rem,auto]  items-left">
          <span className="text-gray-900 items-left">{String(item.value)}</span>
          <span className="text-gray-400 text-xs">
            {isoDateToMDY(item.entrydate)}
          </span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == "prg_wmn" ? "prgwmn" : item.program}
          </span>
        </div>
      ),
    },

    {
      title: "Probation",
      icon: <Fingerprint className="h-5 w-5 text-gray-400" />,
      data: participantData.probationHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto]  items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">
            {isoDateToMDY(item.entrydate)}
          </span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == "prg_wmn" ? "prgwmn" : item.program}
          </span>
        </div>
      ),
    } 
  ];

  return (
    <div className="w-full">
  

      <div className="flex justify-between px-2 py-2">
          <div className="grid grid-cols-7 gap-20 text-sm font-medium text-gray-600">
            <div className="ml-2" >
              <div className="font-bold flex items-center gap-2">
                <User className="h-4 w-4 text-gray-400" />
                Name
              </div>
              <div className="text-sm">
                {participantData.name.lastName}, {participantData.name.firstName}
              </div>
            </div>
            <div className="ml-2" >
              <div className="font-bold flex items-center gap-2">
                <User className="h-4 w-4 text-gray-400" />
                Gender
              </div>
              <div className="text-sm">
                {participantData.gender}
              </div>
            </div>
            <div className="ml-2" >
              <div className="font-bold flex items-center gap-2">
                <Calendar className="h-4 w-4 text-gray-400" />
                Date of Birth
              </div>
              <div className="text-sm text-gray-600">
                {isoDateToMDY(participantData.dob)}
              </div>
            </div>
            <div className="ml-2" >
              <div className="font-bold flex items-center gap-2">
                <HashIcon className="h-4 w-4 text-gray-400" />
                Champ ID
              </div>
              <div className="text-sm text-gray-600">
                {participantData.champid}
              </div>
            </div>
            <div className="ml-2" >
              <div className="font-bold flex items-center gap-2">
                <Fingerprint className="h-4 w-4 text-gray-400" />
                MRUN
              </div>
              <div className="text-sm text-gray-600">
                {participantData.mrun}
              </div>
            </div>
            <div className="ml-2" >
              <div className="font-bold flex items-center gap-2">
                <Building className="h-4 w-4 text-gray-400" />
                Provider
              </div>
              <div className="text-sm text-gray-600">
                {participantData.probation}
              </div>
            </div>           
          </div>

          <div className="flex flex-col gap-2 ml-4">
          <button
            onClick={() => setEditParticipant(true)}
            className="inline-flex items-center px-4 border-transparent text-sm font-medium rounded-md bg-white hover:bg-blue-700 hover:text-white"
          >
            <Edit className="mr-2 h-4 w-4 text-gray-600" />
            <span className="text-blue-600">Edit Participant</span>
          </button>
          <button
            onClick={() => setShowMoreOpen(true)}
            className="inline-flex items-center px-4 border-transparent text-sm font-medium rounded-md bg-white hover:bg-blue-700 hover:text-white"
          >
            <MoreHorizontal className="mr-2 h-4 w-4 text-gray-600" />
            <span className="text-blue-600">Show More</span>
          </button>
        </div>
      </div>      

      {selectedProgramForModal && (
        <ProgramDetailsModal
          isOpen={!!selectedProgramForModal}
          onClose={() => setSelectedProgramForModal(null)}
          programNumber={selectedProgramForModal}
          odrnumber={odrnumber}
        />
      )}

      {editParticipant && (
        <EditParticipant
          isOpen={editParticipant}
          onClose={() => setEditParticipant(false)}
          program={program}
          odrnumber={parseInt(odrnumber)}
          data={data}
          isActive={true}
          refetch={refetch}
        />
      )}

      <Dialog open={showMoreOpen} onOpenChange={setShowMoreOpen}>
        <DialogContent className="min-w-[85vw]">
          <DialogHeader>
            <DialogTitle>Additional Participant Information</DialogTitle>
            <DialogDescription>
              View complete participant history and details
            </DialogDescription>
          </DialogHeader>

          <ParticipantDataSectionFull odrnumber={odrnumber} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
