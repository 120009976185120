import React, { useState, useCallback } from 'react';
import { Calendar, Clock } from 'lucide-react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

interface EditCellState {
  rowIndex: number;
  columnName: string;
}

interface DataType {
  id: string;
  name: string;
  age: number;
  active?: boolean;
  date?: string;
  time?: string;
  datetime?: string;
  email?: string;
  type?: string;
  url?: string;
  color?: string;
  rating?: number;
  [key: string]: any;
}

const config = {
  title: 'Table Control',
  description: 'Control the table',
};

const initialData: DataType[] = [
  { id: '1', name: 'John Doe', age: 30, active: true, injail: false },
  { id: '2', name: 'Jane Doe', age: 25, active: false},
];

const columnHelper = createColumnHelper<DataType>();

export const TableControl = () => {
  const [data, setData] = useState(initialData);
  const [editCell, setEditCell] = useState<EditCellState | null>(null);
  const [editValue, setEditValue] = useState<any>(null);

  const startEditing = useCallback((rowIndex: number, columnName: string, initialValue: any) => {
    setEditCell({ rowIndex, columnName });
    setEditValue(initialValue);
  }, []);

  const stopEditing = useCallback((save: boolean = true) => {
    if (save && editCell && editValue !== null) {
      const newData = [...data];
      newData[editCell.rowIndex] = {
        ...newData[editCell.rowIndex],
        [editCell.columnName]: editValue
      };
      setData(newData);
    }
    setEditCell(null);
    setEditValue(null);
  }, [data, editCell, editValue]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      stopEditing(true);
    } else if (e.key === 'Escape') {
      stopEditing(false);
    }
  }, [stopEditing]);

  const handleChange = useCallback((value: any) => {
    setEditValue(value);
  }, []);

  const renderEditableCell = (value: any, column: any, rowIndex: number) => {
    const currentValue = editValue ?? value;

    switch (column.type) {
      case 'boolean':
        return (
          <input
            type="checkbox"
            checked={currentValue || false}
            onChange={(e) => {
              handleChange(e.target.checked);
              stopEditing(true);
            }}
            className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
          />
        );

      case 'number':
        return (
          <input
            type="number"
            value={currentValue ?? ''}
            onChange={(e) => handleChange(e.target.value ? parseFloat(e.target.value) : null)}
            onBlur={() => stopEditing(true)}
            onKeyDown={handleKeyDown}
            className="w-full px-2 py-1 border-0 bg-blue-50 focus:ring-2 focus:ring-blue-500"
            autoFocus
          />
        );

      case 'date':
        return (
          <div className="relative">
            <input
              type="date"
              value={currentValue || ''}
              onChange={(e) => handleChange(e.target.value)}
              onBlur={() => stopEditing(true)}
              onKeyDown={handleKeyDown}
              className="w-full px-2 py-1 border-0 bg-blue-50 focus:ring-2 focus:ring-blue-500"
              autoFocus
            />
            <Calendar className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
          </div>
        );

      // ... other input types remain the same, just update value to currentValue
      default:
        return (
          <input
            type="text"
            value={currentValue || ''}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={() => stopEditing(true)}
            onKeyDown={handleKeyDown}
            className="w-full px-2 py-1 border-0 bg-blue-50 focus:ring-2 focus:ring-blue-500"
            autoFocus
          />
        );
    }
  };

  const columns = [
    columnHelper.accessor('id', {
      header: 'ID',
      cell: info => <div className="px-2 py-1">{info.getValue()}</div>,
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => {
        const rowIndex = info.row.index;
        const isEditing = editCell?.rowIndex === rowIndex && editCell?.columnName === 'name';
        
        if (isEditing) {
          return renderEditableCell(info.getValue(), { type: 'string' }, rowIndex);
        }

        return (
          <div
            className="px-2 py-1 cursor-pointer hover:bg-gray-50"
            onClick={() => startEditing(rowIndex, 'name', info.getValue())}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor('age', {
      header: 'Age',
      cell: info => {
        const rowIndex = info.row.index;
        const isEditing = editCell?.rowIndex === rowIndex && editCell?.columnName === 'age';
        
        if (isEditing) {
          return renderEditableCell(info.getValue(), { type: 'number' }, rowIndex);
        }

        return (
          <div
            className="px-2 py-1 cursor-pointer hover:bg-gray-50"
            onClick={() => startEditing(rowIndex, 'age', info.getValue())}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    // Add other columns similarly...
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full overflow-x-auto">
      <div className="mb-4">
        <h2 className="text-xl font-bold">{config.title}</h2>
        <p className="text-gray-600">{config.description}</p>
      </div>
      <table className="min-w-full border border-gray-200">
        <thead className="bg-gray-50">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-r border-gray-200"
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white">
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} className="hover:bg-gray-50">
              {row.getVisibleCells().map(cell => (
                <td
                  key={cell.id}
                  className="px-4 py-2 whitespace-nowrap border-b border-r border-gray-200"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};