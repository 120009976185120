import React, { useState, useMemo } from 'react';
import { SearchParticipants } from '../pages/search/searchParticipants';
import { useNavigate } from 'react-router-dom';
import { usePrintView } from '../store/usePrintView';
import { useHelpSidebarVisibility } from '../store/useHelpSidebarVisibility';
import { useUser } from '../store/useUser';
import {
  ChevronLeft,
  Search,
  Home,
  HelpCircle,
  Printer,
  Menu,
  ChevronDown,
  ChevronRight
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"

const menuItems = [
  {
    type: 'separator',
    label: 'Administration',
    group: ['superadmin', 'dev'],
    submenu: [
      {
        label: 'User Manager',
        path: '/admin/users'
      },
      {
        label: 'Security Manager',
        path: '/admin/security'
      },
      {
        label: 'Form Templates',
        path: '/admin/forms'
      },
      {
        label: 'Edit Help',
        path: '/admin/help'
      },
      {
        label: 'Duplicate Clients',
        path: '/reports/duplicateclients'
      }
    ]
  },
  {
    type: 'separator',
    label: 'Development',
    group: ['dev', 'superadmin'],
    submenu: [
      {
        label: 'Data Schema',
        path: '/admin/dev/databaseSchema'
      },
      {
        label: 'Data Dictionary',
        path: '/admin/dataDictionary'
      },
      {
        label: 'Status',
        path: '/status'
      },
      {
        label: 'Import PCode',
        path: '/admin/dev/importPCode'
      },
      {
        label: 'Import CSV',
        path: '/admin/dev/importCSV'
      },
      {
        label: 'Help Topics',
        path: '/admin/dev/help/topics'
      }
    ]
  },
 
  {
    type: 'separator',
    label: 'Clients'
  },
  {
    type: 'item',
    label: 'Search',
    path: '/search'
  },
  {
    type: 'item',
    label: 'New Client',
    path: '/new-client',
    group: ['admin']
  },
  
  {
    type: 'item',
    label: 'Reports',
    path: '/reports',        
  }
];

export const Header = ({ settings }: { settings?: { withoutSearch?: boolean; withPrint?: boolean } }) => {
  const { user, isUserLoading } = useUser();
  const { isPrintView, setPrintView } = usePrintView();
  const { toggleHelpSidebar } = useHelpSidebarVisibility();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isReportsOpen, setIsReportsOpen] = useState(false);

  const handleSearch = () => {
    navigate('/search');
  };

  const closeDropdowns = () => {
    setIsDropdownOpen(false);
    setIsReportsOpen(false);
  };

  // Filter menu items based on user's group
  const filteredMenuItems = useMemo(() => {
    if (!user || !user.groups) return [];

    const result = [];
    let shouldIncludeItems = true;

    for (const item of menuItems) {
      if (item.type === 'separator') {
        if (item.group) {
          // Check if any of the user's groups match any of the required groups
          shouldIncludeItems = item.group.some((userGroup: string) => 
            user.groups.includes(userGroup)
          );
          if (shouldIncludeItems) {
            result.push(item);
          }
        } else {
          shouldIncludeItems = true;
          result.push(item);
        }
      } else if (shouldIncludeItems) {
        result.push(item);
      }
    }

    return result;
  }, [user]);

  const renderMenuItem = (item: any) => {
    if (item.type === 'separator') {
      return item.submenu ? (
        <DropdownMenu key={item.label}>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="w-full justify-between">
              {item.label}
              <ChevronRight className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent side="right" className="w-56  mt-11">
            {item.submenu.map((subItem: any) => (
              <DropdownMenuItem
                key={subItem.label}
                onClick={() => {
                  navigate(subItem.path);
                  closeDropdowns();
                }}
              >
                {subItem.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <DropdownMenuLabel key={item.label} className="text-xs font-bold text-black uppercase tracking-wider">
          {item.label}
        </DropdownMenuLabel>
      );
    }

    return (
      <DropdownMenuItem
        key={item.label}
        onClick={() => {
          navigate(item.path);
          closeDropdowns();
        }}
      >
        {item.label}
      </DropdownMenuItem>
    );
  };

  if (isPrintView) return null;

  return (
    <header className="border-b border-gray-200 pb-3 mb-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="gap-2">
                <Menu className="h-4 w-4" />
                Menu
                <ChevronDown className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              {filteredMenuItems.map((item) => renderMenuItem(item))}
            </DropdownMenuContent>
          </DropdownMenu>

          <div className="font-medium">
            {isUserLoading ? (
              <span className="text-gray-500">Loading...</span>
            ) : user ? (
              user.realname
            ) : (
              <span className="text-gray-500">Not logged in</span>
            )}
          </div>
        </div>

        <div className="flex items-center gap-2">
          <Button variant="outline" onClick={() => navigate('/menu.php')} className="gap-2">
            <Home className="h-4 w-4" />
            Main Menu
          </Button>
          {!settings?.withoutSearch && (
            <Button variant="outline" onClick={handleSearch} className="gap-2">
              <Search className="h-4 w-4" />
              Search
            </Button>
          )}
          <Button variant="outline" onClick={toggleHelpSidebar} className="gap-2">
            <HelpCircle className="h-4 w-4" />
            Help
          </Button>
          <Button variant="outline" onClick={() => window.history.back()} className="gap-2">
            <ChevronLeft className="h-4 w-4" />
            Back
          </Button>
          {settings?.withPrint && (
            <Button variant="outline" onClick={() => window.print()} className="gap-2">
              <Printer className="h-4 w-4" />
              Print
            </Button>
          )}
        </div>
      </div>
    </header>
  );
};