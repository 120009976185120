import React, { useState } from 'react';

import { AlertCircle, Check, Copy, FileJson, Plus, Trash2 } from 'lucide-react';
import { Alert, AlertDescription } from '../../../components/ui/alert';

interface FieldMapping {
  from: string;
  to: string;
}

interface ConfigEntry {
  key: string;
  type: string;
  odrtable: string | null;
  legacytable: string | null;
  canonical: boolean;
  fields: FieldMapping[];
  isArray: boolean;
  hasHistory: boolean;
  isArrayType: boolean;
  writePolicy?: string;
  removeDuplicates?: string[];
}

// Extracted unique types from the configuration
const TYPES = [
  'ParticipantEnrollment',
  'ParticipantIncident',
  'GenericJSONDocument',
  'ParticipantHearing',
  'ParticipantScan',
  'ParticipantNote',
  'ParticipantCandidateData',
  'ProgramDetails',
  'ParticipantOutReach',
  'Date',
  'DateWithID',
  'Integer',
  'String',
  'ParticipantAddress',
  'ParticipantCase',
  'ParticipantConsents',
  'ParticipantDisposition',
  'ParticipantIstStatus',
  'ParticipantName',
  'ParticipantPCode'
];

// Extracted unique ODR tables
const ODR_TABLES = [
  'allparticipants_documents',
  'allparticipants_scans',
  'allparticipants_candidates',
  'allparticipants_programdetails',
  'allparticipants_outreach',
  'allparticipants_careplan',
  'allparticipants_initialassessment',
  'lacarereferral',
  'lacarescreening',
  'programcompletion',
  'allparticipants_ab109',
  'allparticipants_acuity',
  'allparticipants_ptaddress',
  'allparticipants_champid',
  'allparticipants_cin',
  'allparticipants_cii',
  'allparticipants_consents',
  'allparticipants_dsm',
  'allparticipants_ecm_determination',
  'allparticipants_fin',
  'allparticipants_icd10',
  'allparticipants_mainnumber',
  'allparticipants_mrun',
  'allparticipants_names',
  'allparticipants_pcode',
  'allparticipants_programcompletion',
  'allparticipants_ssn',
  'allparticipants_wpc'
];

// Extracted unique legacy tables
const LEGACY_TABLES = [
  'enrollment',
  'inrep_candidates',
  '$hearings',
  'notes',
  'allparticipants_adherence',
  'allparticipants_arrestdate',
  '$bookings',
  '$cases',
  'champ_id',
  'allparticipants_clinicalpresentation',
  'commitmentdate',
  'programcompletion',
  'currentmeds',
  'discharge_date',
  'category',
  'releasedate',
  'dob',
  'ist',
  'jcit',
  'maxdate',
  'medicalproblems',
  'mrun',
  'names',
  'othermeds',
  'pathway',
  'provider',
  'psychhistory',
  'allparticipants_psychotropicmeds',
  'released',
  'sexoffender',
  'participant_social'
];

// Add this constant at the top with other constants
const WRITE_POLICIES = ['all', 'currentProgram', 'none'];

export  function ConfigEditor() {
  const [entries, setEntries] = useState<ConfigEntry[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [outputValue, setOutputValue] = useState('');
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);

  const parseConfig = (input: string) => {
    try {
      const objectStart = input.indexOf('{');
      const objectEnd = input.lastIndexOf('}');
      
      if (objectStart === -1 || objectEnd === -1) {
        throw new Error('Invalid format');
      }

      const lines = input.split('\n');
      const parsedEntries: ConfigEntry[] = [];
      let currentEntry: ConfigEntry | null = null;

      lines.forEach(line => {
        const trimmed = line.trim();
        
        if (trimmed.startsWith("'") || trimmed.startsWith('"')) {
          if (currentEntry) {
            parsedEntries.push(currentEntry);
          }
          
          const key = trimmed.match(/['"]([^'"]+)['"]/)?.[1] || '';
          currentEntry = {
            key,
            type: '',
            odrtable: null,
            legacytable: null,
            canonical: false,
            fields: [],
            isArray: false,
            hasHistory: false,
            isArrayType: false
          };

          // Extract type
          const typeMatch = line.match(/type:\s*['"]\[?([^\]'"]+)\]?['"]/);
          if (typeMatch) {
            currentEntry.type = typeMatch[1];
            currentEntry.isArrayType = line.includes('[' + typeMatch[1] + ']');
          }

          // Extract table references
          const odrtableMatch = line.match(/odrtable:\s*['"]?([^'",}]+)['"]?/);
          currentEntry.odrtable = odrtableMatch ? odrtableMatch[1] : null;

          const legacytableMatch = line.match(/legacytable:\s*['"]?([^'",}]+)['"]?/);
          currentEntry.legacytable = legacytableMatch ? legacytableMatch[1] : null;

          // Extract boolean flags
          currentEntry.canonical = line.includes('canonical: true');
          currentEntry.isArray = line.includes('isArray: true');
          currentEntry.hasHistory = line.includes('hasHistory: true');

          // Extract fields array
          const fieldsMatch = line.match(/fields:\s*\[(.*?)\]/);
          if (fieldsMatch) {
            const fieldsStr = fieldsMatch[1];
            const fieldPairs = fieldsStr.match(/\[[^\]]+\]/g) || [];
            currentEntry.fields = fieldPairs.map(pair => {
              const [from, to] = pair.replace(/[\[\]']/g, '').split(',').map(s => s.trim());
              return { from, to };
            });
          }
        }
      });

      if (currentEntry) {
        parsedEntries.push(currentEntry);
      }

      setEntries(parsedEntries);
      setError('');
    } catch (err) {
      setError('Failed to parse configuration: ' + (err instanceof Error ? err.message : 'Unknown error'));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setInputValue(value);
    parseConfig(value);
  };

  const updateEntry = (index: number, field: keyof ConfigEntry, value: any) => {
    const newEntries = [...entries];
    newEntries[index] = { ...newEntries[index], [field]: value };
    setEntries(newEntries);
  };

  const addField = (entryIndex: number) => {
    const newEntries = [...entries];
    newEntries[entryIndex].fields.push({ from: 'newField', to: 'new_field' });
    setEntries(newEntries);
  };

  const removeField = (entryIndex: number, fieldIndex: number) => {
    const newEntries = [...entries];
    newEntries[entryIndex].fields.splice(fieldIndex, 1);
    setEntries(newEntries);
  };

  const updateField = (entryIndex: number, fieldIndex: number, key: keyof FieldMapping, value: string) => {
    const newEntries = [...entries];
    newEntries[entryIndex].fields[fieldIndex][key] = value;
    setEntries(newEntries);
  };

  const generateConfig = () => {
    let output = 'const CombinedTablesFromDataBasic = {\n';
    
    entries.forEach((entry, index) => {
      output += `    '${entry.key}': { `;
      output += `type: '${entry.isArrayType ? '[' + entry.type + ']' : entry.type}', `;
      output += `odrtable: ${entry.odrtable ? `'${entry.odrtable}'` : 'null'}, `;
      output += `legacytable: ${entry.legacytable ? `'${entry.legacytable}'` : 'null'}, `;
      output += `canonical: ${entry.canonical}, `;
      output += `fields: [${entry.fields.map(f => `['${f.from}', '${f.to}']`).join(', ')}], `;
      output += `isArray: ${entry.isArray}, `;
      output += `hasHistory: ${entry.hasHistory}`;
      if (entry.writePolicy) output += `, writePolicy: '${entry.writePolicy}'`;
      if (entry.removeDuplicates) output += `, removeDuplicates: ${JSON.stringify(entry.removeDuplicates)}`;
      output += ` }${index < entries.length - 1 ? ',' : ''}\n`;
    });

    output += '}';
    setOutputValue(output);
  };

  const copyToClipboard = async () => {
    if (outputValue) {
      try {
        await navigator.clipboard.writeText(outputValue);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        setError('Failed to copy to clipboard');
      }
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-6">
      <div>
        <textarea
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Paste your configuration here..."
          className="w-full h-48 p-4 border rounded-lg font-mono text-sm"
        />
      </div>

      {error && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {/* Scrollable list container */}
      {entries.length > 0 && (
        <div className="border rounded-lg max-h-[600px] overflow-y-auto">
          <div className="space-y-4 p-4">
            {entries.map((entry, entryIndex) => (
              <div key={entryIndex} className="border rounded-lg p-4 space-y-4 bg-white">
                <div className="flex gap-4 items-center">
                  <div className="flex-1">
                    <input
                      value={entry.key}
                      onChange={(e) => updateEntry(entryIndex, 'key', e.target.value)}
                      className="w-full p-2 border rounded"
                      placeholder="Key"
                    />
                  </div>
                  <div className="flex-1">
                    <select
                      value={entry.type}
                      onChange={(e) => updateEntry(entryIndex, 'type', e.target.value)}
                      className="w-full p-2 border rounded"
                    >
                      {TYPES.map(type => (
                        <option key={type} value={type}>{type}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex-1">
                    <select
                      value={entry.writePolicy || 'all'}
                      onChange={(e) => updateEntry(entryIndex, 'writePolicy', e.target.value)}
                      className="w-full p-2 border rounded"
                    >
                      {WRITE_POLICIES.map(policy => (
                        <option key={policy} value={policy}>{policy}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="flex gap-4">
                  <div className="flex-1">
                    <select
                      value={entry.odrtable || ''}
                      onChange={(e) => updateEntry(entryIndex, 'odrtable', e.target.value || null)}
                      className="w-full p-2 border rounded"
                    >
                      <option value="">ODR Table</option>
                      {ODR_TABLES.map(table => (
                        <option key={table} value={table}>{table}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex-1">
                    <select
                      value={entry.legacytable || ''}
                      onChange={(e) => updateEntry(entryIndex, 'legacytable', e.target.value || null)}
                      className="w-full p-2 border rounded"
                    >
                      <option value="">Legacy Table</option>
                      {LEGACY_TABLES.map(table => (
                        <option key={table} value={table}>{table}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="flex gap-6 py-2">
                  <label className="flex items-center gap-2 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={entry.canonical}
                      onChange={(e) => updateEntry(entryIndex, 'canonical', e.target.checked)}
                      className="rounded"
                    />
                    <span className="text-sm">Canonical</span>
                  </label>
                  <label className="flex items-center gap-2 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={entry.isArray}
                      onChange={(e) => updateEntry(entryIndex, 'isArray', e.target.checked)}
                      className="rounded"
                    />
                    <span className="text-sm">Is Array</span>
                  </label>
                  <label className="flex items-center gap-2 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={entry.hasHistory}
                      onChange={(e) => updateEntry(entryIndex, 'hasHistory', e.target.checked)}
                      className="rounded"
                    />
                    <span className="text-sm">Has History</span>
                  </label>
                  <label className="flex items-center gap-2 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={entry.isArrayType}
                      onChange={(e) => updateEntry(entryIndex, 'isArrayType', e.target.checked)}
                      className="rounded"
                    />
                    <span className="text-sm">Array Type</span>
                  </label>
                </div>

                <div className="space-y-2 border-t pt-4">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="font-medium">Field Mappings</h3>
                    <button
                      onClick={() => addField(entryIndex)}
                      className="flex items-center gap-2 px-3 py-1 text-sm bg-blue-50 text-blue-600 hover:bg-blue-100 rounded-full"
                    >
                      <Plus className="h-4 w-4" /> Add Field
                    </button>
                  </div>
                  <div className="space-y-2">
                    {entry.fields.map((field, fieldIndex) => (
                      <div key={fieldIndex} className="flex gap-2 items-center bg-gray-50 p-2 rounded">
                        <input
                          value={field.from}
                          onChange={(e) => updateField(entryIndex, fieldIndex, 'from', e.target.value)}
                          className="p-2 border rounded flex-1 bg-white"
                          placeholder="From field"
                        />
                        <span className="text-gray-400">→</span>
                        <input
                          value={field.to}
                          onChange={(e) => updateField(entryIndex, fieldIndex, 'to', e.target.value)}
                          className="p-2 border rounded flex-1 bg-white"
                          placeholder="To field"
                        />
                        <button
                          onClick={() => removeField(entryIndex, fieldIndex)}
                          className="p-2 text-red-600 hover:bg-red-50 rounded"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                <button
                  onClick={() => {
                    const newEntries = [...entries];
                    newEntries.splice(entryIndex, 1);
                    setEntries(newEntries);
                  }}
                  className="mt-4 px-3 py-2 text-red-600 hover:bg-red-50 rounded flex items-center gap-2"
                >
                  <Trash2 className="h-4 w-4" /> Delete Entry
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Action buttons outside scroll container */}
      <div className="flex gap-4 mt-4">
        <button
          onClick={() => {
            setEntries([...entries, {
              key: 'newKey',
              type: TYPES[0],
              odrtable: null,
              legacytable: null,
              canonical: false,
              fields: [],
              isArray: false,
              hasHistory: false,
              isArrayType: false,
              writePolicy: 'all'
            }]);
          }}
          className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition"
        >
          Add New Entry
        </button>

        <button
          onClick={generateConfig}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
        >
          Generate Config
        </button>
      </div>

      {outputValue && (
        <div className="relative">
          <textarea
            value={outputValue}
            readOnly
            className="w-full h-48 p-4 border rounded-lg font-mono text-sm bg-gray-50"
          />
          <button
            onClick={copyToClipboard}
            className="absolute top-2 right-2 p-2 bg-white border rounded-md hover:bg-gray-100 transition"
            title="Copy to clipboard"
          >
            {copied ? <Check className="h-4 w-4 text-green-500" /> : <Copy className="h-4 w-4" />}
          </button>
        </div>
      )}
    </div>
  );
}