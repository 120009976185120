import React, { useState, useCallback } from "react";
import ReactModal from "react-modal";
import { useMutation, gql } from "@apollo/client";
import { useDropzone } from "react-dropzone";
import { ScanPreview } from "./PreviewScan";

const UPDATE_PARTICIPANT = gql`
  mutation UpdateParticipant(
    $programNumber: Integer!
    $input: participantProgramDataInput!
  ) {
    updateParticipantProgramDataByProgramNumber(
      programNumber: $programNumber
      input: $input
    ) {
      responseCode
      message
    }
  }
`;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'none',
    background: 'transparent'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  }
};

export const EditScans = ({
  isOpen,
  onClose,
  programNumber,
  odrNumber,
  scans,
}: {
  isOpen: boolean;
  onClose: () => void;
  programNumber: number;
  odrNumber: number;
  scans: any[];
}) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadType, setUploadType] = useState("");
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [uploadLabel, setUploadLabel] = useState("");
  const [selectedScan, setSelectedScan] = useState<any>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);

  const [updateParticipant] = useMutation(UPDATE_PARTICIPANT);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setUploadFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpload = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!uploadFile) return;

    try {
      const result = await updateParticipant({
        variables: {
          programNumber,
          input: {
            scans: [
              {
                filetype: uploadType,
                filelabel: uploadLabel,
                filedata: await fileToBase64(uploadFile),
                filename: uploadFile.name,
              },
            ],
          },
        },
      });

      if (result.data.updateParticipantProgramDataByProgramNumber.responseCode === "SUCCESS") {
        console.log("File uploaded successfully");
        setIsUploadModalOpen(false);
        setUploadFile(null);
        setUploadLabel("");
      } else {
        console.error("Upload failed:", result.data.updateParticipantProgramDataByProgramNumber.message);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const result = await updateParticipant({
        variables: {
          programNumber,
          input: {
            scans: [{ id, deleted: true }],
          },
        },
      });

      if (result.data.updateParticipantProgramDataByProgramNumber.responseCode === "SUCCESS") {
        console.log("File deleted successfully");
        setShowDeleteConfirm(null);
      } else {
        console.error("Deletion failed:", result.data.updateParticipantProgramDataByProgramNumber.message);
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const renderScanSection = (type: string, label: string) => (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold text-gray-900">{label}</h3>
      {scans
        .filter((scan) => scan.filetype === type)
        .map((scan) => (
          <div key={scan.id} className="flex items-center justify-between bg-white rounded-lg border border-gray-200 p-4">
            <button
              className="text-blue-600 hover:text-blue-800 font-medium"
              onClick={() => setSelectedScan(scan.id)}
            >
              {type === "otherFile" ? `Other file: ${scan.filelabel}` : label}
            </button>
            <div className="text-sm text-gray-500">
              Uploaded {new Date(scan.filedate).toLocaleString()}
            </div>
            <button
              onClick={() => setShowDeleteConfirm(scan.id)}
              className="px-3 py-1.5 text-sm font-medium text-red-600 hover:text-red-700 border border-red-600 hover:border-red-700 rounded-md transition-colors"
            >
              Delete
            </button>
          </div>
        ))}
      <button
        onClick={() => {
          setUploadType(type);
          setIsUploadModalOpen(true);
        }}
        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Upload a new {label}
      </button>
    </div>
  );

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={modalStyles}
        contentLabel="Edit Scans Modal"
      >
        <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg">
          <div className="p-6 space-y-6">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold text-gray-900">Upload or delete scans for this person</h2>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                Close
              </button>
            </div>

            {selectedScan && (
              <ScanPreview
                odrnumber={odrNumber}
                scan={selectedScan}
                close={() => setSelectedScan(null)}
              />
            )}

            <div className="space-y-8">
              {renderScanSection("courtFile", "Court File")}
              {renderScanSection("rapSheet", "Rapsheet")}
              {renderScanSection("otherFile", "Other File")}
            </div>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={isUploadModalOpen}
        onRequestClose={() => setIsUploadModalOpen(false)}
        style={modalStyles}
        contentLabel="Upload File Modal"
      >
        <div className="w-full max-w-md bg-white rounded-lg shadow-lg">
          <div className="p-6 space-y-6">
            <h2 className="text-xl font-semibold text-gray-900">Upload File</h2>
            <form onSubmit={handleUpload} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Type of file:
                </label>
                <select
                  value={uploadType}
                  onChange={(e) => setUploadType(e.target.value)}
                  className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select file type</option>
                  <option value="courtFile">Court file for case</option>
                  <option value="rapSheet">Rapsheet</option>
                  <option value="otherFile">Other File</option>
                </select>
              </div>

              {uploadType === "courtFile" && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Insert code:
                  </label>
                  <input
                    type="text"
                    value={uploadLabel}
                    onChange={(e) => setUploadLabel(e.target.value)}
                    className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              )}

              {uploadType === "otherFile" && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Insert label:
                  </label>
                  <input
                    type="text"
                    value={uploadLabel}
                    onChange={(e) => setUploadLabel(e.target.value)}
                    className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              )}

              <div
                {...getRootProps()}
                className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer hover:border-gray-400 transition-colors"
              >
                <input {...getInputProps()} />
                <p className="text-sm text-gray-600">
                  {isDragActive
                    ? "Drop the file here ..."
                    : "Drag 'n' drop a file here, or click to select a file"}
                </p>
              </div>

              {uploadFile && (
                <p className="text-sm text-gray-600">
                  Selected file: {uploadFile.name}
                </p>
              )}

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setIsUploadModalOpen(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Upload
                </button>
              </div>
            </form>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={!!showDeleteConfirm}
        onRequestClose={() => setShowDeleteConfirm(null)}
        style={modalStyles}
        contentLabel="Confirm Delete Modal"
      >
        <div className="w-full max-w-md bg-white rounded-lg shadow-lg">
          <div className="p-6 space-y-6">
            <h2 className="text-xl font-semibold text-gray-900">Confirm Delete</h2>
            <p className="text-gray-600">
              Are you sure you want to delete this file? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteConfirm(null)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={() => showDeleteConfirm && handleDelete(showDeleteConfirm)}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default EditScans;