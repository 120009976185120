import React, { useState } from 'react';

interface PasswordSectionProps {
  newPassword: string;
  onChange: (password: string) => void;
  onSubmit: () => Promise<void>;
}

const generatePassword = (): string => {
  const length = 12;
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const symbols = '!@#$%^&*()_+-=[]{}|;:,.<>?';

  // Ensure at least one of each type
  const password = [
    uppercase[Math.floor(Math.random() * uppercase.length)],
    lowercase[Math.floor(Math.random() * lowercase.length)],
    numbers[Math.floor(Math.random() * numbers.length)],
    symbols[Math.floor(Math.random() * symbols.length)]
  ];

  // Fill the rest with random characters
  const allChars = uppercase + lowercase + numbers + symbols;
  for (let i = password.length; i < length; i++) {
    password.push(allChars[Math.floor(Math.random() * allChars.length)]);
  }

  // Shuffle the password array
  for (let i = password.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [password[i], password[j]] = [password[j], password[i]];
  }

  return password.join('');
};

export const PasswordSection: React.FC<PasswordSectionProps> = ({
  newPassword,
  onChange,
  onSubmit
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleGeneratePassword = () => {
    const password = generatePassword();
    onChange(password);
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">Reset Password</label>
      <div className="mt-1 space-y-2">
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            value={newPassword}
            onChange={(e) => onChange(e.target.value)}
            placeholder="New password"
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          
          {/* Show/Hide Password Button */}
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-20 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 px-2"
          >
            {showPassword ? "Hide" : "Show"}
          </button>
          
          {/* Generate Password Button */}
          <button
            type="button"
            onClick={handleGeneratePassword}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-700 px-2"
          >
            Generate
          </button>
        </div>

        {/* Reset Password Button */}
        <button
          onClick={onSubmit}
          disabled={!newPassword}
          className="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          Reset Password
        </button>
      </div>
    </div>
  );
};

export default PasswordSection;