import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  OnChangeFn,
} from '@tanstack/react-table';
import { ChevronUp, ChevronDown, UserIcon, Shield, Clock, AlertTriangle } from 'lucide-react';
import { User } from '../types';

interface UserTableProps {
  users: User[];
  onUserSelect: (user: User) => void;
  totalCount: number;
  pageSize: number;
  pageIndex: number;
  onPaginationChange: (pageIndex: number, pageSize: number) => void;
  onSortingChange: OnChangeFn<SortingState>;
  sorting: SortingState;
  loading: boolean;
}

const columnHelper = createColumnHelper<User>();

export const UserTable: React.FC<UserTableProps> = ({
  users,
  onUserSelect,
  totalCount,
  pageSize,
  pageIndex,
  onPaginationChange,
  onSortingChange,
  sorting,
  loading,
}) => {
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('username', {
        id: 'username', // Explicit ID to match filter field
        header: 'Username',
        cell: info => (
          <div className="flex items-center">
            <UserIcon className="h-5 w-5 text-gray-400 mr-2" />
            <div className="text-sm font-medium text-gray-900">
              {info.getValue()}
            </div>
          </div>
        ),
      }),
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Full Name',
        cell: info => (
          <span className="text-sm text-gray-500">
            {info.getValue() || '-'}
          </span>
        ),
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: 'Email Address',
        cell: info => (
          <span className="text-sm text-gray-500">
            {info.getValue()}
          </span>
        ),
      }),
   
	  columnHelper.accessor('disabled', {
        id: 'disabled',
        header: 'Status',
        cell: info => (
          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
            info.getValue() ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
          }`}>
            {info.getValue() ? 'Disabled' : 'Active'}
          </span>
        ),
      }),
      columnHelper.accessor('locked', {
        id: 'locked',
        header: 'Account Lock',
        cell: info => (
          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
            info.getValue() === 1 ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
          }`}>
            {info.getValue() === 1 ? 'Locked' : 'Unlocked'}
          </span>
        ),
      }),
	  columnHelper.accessor('lastlogin', {
        id: 'lastlogin',
        header: () => (
          <div className="flex items-center">
            <Clock className="h-4 w-4 mr-1" />
            <span>Last Login</span>
          </div>
        ),
        cell: info => {
          const value = info.getValue();
          if (!value) return <span className="text-sm text-gray-500">Never</span>;
          
          // Convert to DD/MM/YYYY, HH:mm:ss format
          const date = new Date(value);
          const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
          
          return (
            <span className="text-sm text-gray-500">
              {formattedDate}
            </span>
          );
        },
        sortingFn: (rowA, rowB) => {
          const aValue = rowA.original.lastlogin;
          const bValue = rowB.original.lastlogin;
          
          // Handle null/undefined values
          if (!aValue && !bValue) return 0;
          if (!aValue) return 1; // Null values should be sorted last
          if (!bValue) return -1;
          
          // Convert both dates to timestamps for comparison
          const aDate = new Date(aValue).getTime();
          const bDate = new Date(bValue).getTime();
          
          return aDate - bDate;
        },
        enableSorting: true
      }),
      columnHelper.accessor(row => row.twofactorEnabled, {
        id: 'twofactorEnabled',
        header: '2FA',
        cell: info => (
          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
            info.getValue() ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
          }`}>
            {info.getValue() ? 'Enabled' : 'Disabled'}
          </span>
        ),
      }),
    
      columnHelper.accessor('warnCount', {
        id: 'warnCount',
        header: () => (
          <div className="flex items-center">
            <AlertTriangle className="h-4 w-4 mr-1" />
            <span>Warnings</span>
          </div>
        ),
        cell: info => (
          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
            info.getValue() > 0 ? 'bg-yellow-100 text-yellow-800' : 'bg-gray-100 text-gray-800'
          }`}>
            {info.getValue()}
          </span>
        ),
      }),
     
    ],
    []
  );

  const table = useReactTable({
    data: users,
    columns,
    state: {
      sorting,
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    onSortingChange: onSortingChange,
    manualSorting: true,
    manualPagination: true,
    pageCount: Math.ceil(totalCount / pageSize),
    getCoreRowModel: getCoreRowModel(),
  });

  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <div className="space-y-4">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex items-center space-x-1">
                      <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>
                      {header.column.getIsSorted() && (
                        header.column.getIsSorted() === 'asc' 
                          ? <ChevronUp className="w-4 h-4" />
                          : <ChevronDown className="w-4 h-4" />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {loading ? (
              <tr>
                <td colSpan={columns.length} className="px-6 py-4 text-center">
                  <div className="flex justify-center items-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                  </div>
                </td>
              </tr>
            ) : users.length === 0 ? (
              <tr>
                <td colSpan={columns.length} className="px-6 py-4 text-center text-gray-500">
                  No users found
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map(row => (
                <tr
                  key={row.id}
                  onClick={() => onUserSelect(row.original)}
                  className="hover:bg-gray-50 cursor-pointer"
                >
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className="px-6 py-4 whitespace-nowrap">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="flex items-center justify-between px-4">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => onPaginationChange(0, pageSize)}
            disabled={pageIndex === 0 || loading}
            className="px-3 py-1 border rounded-md disabled:opacity-50"
          >
            {'<<'}
          </button>
          <button
            onClick={() => onPaginationChange(pageIndex - 1, pageSize)}
            disabled={pageIndex === 0 || loading}
            className="px-3 py-1 border rounded-md disabled:opacity-50"
          >
            {'<'}
          </button>
          <button
            onClick={() => onPaginationChange(pageIndex + 1, pageSize)}
            disabled={pageIndex >= totalPages - 1 || loading}
            className="px-3 py-1 border rounded-md disabled:opacity-50"
          >
            {'>'}
          </button>
          <button
            onClick={() => onPaginationChange(totalPages - 1, pageSize)}
            disabled={pageIndex >= totalPages - 1 || loading}
            className="px-3 py-1 border rounded-md disabled:opacity-50"
          >
            {'>>'}
          </button>
        </div>

        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-700">
            Page{' '}
            <strong>
              {pageIndex + 1} of {totalPages}
            </strong>
            {' '}({totalCount} total users)
          </span>
          <select
            value={pageSize}
            onChange={e => {
              onPaginationChange(0, Number(e.target.value));
            }}
            disabled={loading}
            className="px-2 py-1 border rounded-md disabled:opacity-50"
          >
            {[10, 20, 30, 40, 50].map(size => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default UserTable;