import React, { useState } from "react";
import { EditScans } from "./modals/EditScans";
import { ScanPreview } from "./modals/PreviewScan";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { isoDateToMDY } from "../../utils/common";

const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
      scans {
        id
        filename
        filetype
        filelabel
        filedate
      }
    }
  }
`;

export const Scans = ({
  odrnumber,
  scans,
  loading,
  error,
}: {
  odrnumber: number;
  scans: any[];
  loading: boolean;
  error: any;
}) => {
  const [editScans, setEditScans] = useState(false);
  const [selectedScan, setSelectedScan] = useState<any>(null);

  if (loading) return <div className="text-gray-500">Loading scans...</div>;
  if (error) return <div className="text-red-500">Error loading scans :(</div>;

  const SelectBox = ({ label, type }: { label: string; type: string }) => (
    <div className="space-y-2">
      <div className="text-sm font-medium text-gray-700">{label}</div>
      <select 
        onChange={(e) => setSelectedScan(e.target.value)}
        className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      >
        <option value="">Select a scan</option>
        {scans.map(
          (scan: any) =>
            scan.filetype.indexOf(type) > -1 && (
              <option key={scan.id} value={scan.id}>
                {type === "courtFile" && `Court File ${scan.filelabel}`}
                {type === "rapSheet" && `Rapsheet ${isoDateToMDY(scan.filedate)}`}
                {type === "other" && `Other ${scan.filelabel}`}
              </option>
            )
        )}
      </select>
    </div>
  );

  return (
    <div className="w-full bg-white rounded-lg   p-6">
      <div className="space-y-6">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-xl font-semibold text-gray-900">Scans</h3>
          <button 
            onClick={() => setEditScans(true)}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Edit Scans
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <SelectBox label="Court File" type="courtFile" />
          <SelectBox label="Rap Sheet" type="rapSheet" />
          <SelectBox label="Other" type="other" />
        </div>
      </div>

      <EditScans
        isOpen={editScans}
        onClose={() => setEditScans(false)}
        scans={scans}
        odrNumber={odrnumber}
        programNumber={1}
      />

      {selectedScan && (
        <ScanPreview
          odrnumber={odrnumber}
          scan={selectedScan}
          close={() => setSelectedScan(null)}
        />
      )}
    </div>
  );
};

export default Scans;