import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import ReactModal from 'react-modal';
import { useUser } from '../../../store/useUser';
import { GET_PARTICIPANT_BY_ODR_NUMBER } from './summaryTypes';

interface SummaryProps {
  participant?: string | number;
  onClose: () => void;
}

interface EntryProps {
  children: React.ReactNode;
  entryBy: string;
}

interface LabelProps {
  children: React.ReactNode;
}

const Label = ({ children }: LabelProps) => (
  <span className="font-bold mr-2">{children}:</span>
);

const Entry = ({ children, entryBy }: EntryProps) => (
  <div className="grid grid-cols-[1fr,auto] gap-4 items-start">
    <div>{children}</div>
    <div>Entry By: {entryBy}</div>
  </div>
);

const formatDate = (date: string) => {
  return new Date(parseInt(date)).toLocaleDateString();
};

const convertToHash = (data: any) => {
  const result: Record<string, any[]> = {};
  Object.entries(data).forEach(([key, value]) => {
    if (key === '__typename') return;
    if (Array.isArray(value)) {
      value.forEach((item) => {
        const dateKey = formatDate(item.entrydate);
        result[dateKey] = result[dateKey] || [];
        result[dateKey].push({ ...item, __typename: key });
      });
    }
  });
  return result;
};

const EventContent = ({ type, data }: { type: string; data: any }) => {
  switch (type) {
    case 'releasedPPAdmin':
      return (
        <Entry entryBy={data.entryby}>
          <Label>Released</Label>
          <span>{data.released ? "Yes" : "No"}</span>
        </Entry>
      );

    case 'ab109PPAdmin':
      return (
        <Entry entryBy={data.entryby}>
          <Label>AB109</Label>
          <span>{data.status}</span>
        </Entry>
      );

    case 'clinicalpresentationPPAdmin':
      return (
        <div className="space-y-2">
          <div className="font-bold">Clinical Presentation:</div>
          <div dangerouslySetInnerHTML={{ __html: data.clinicalpresentation }} />
          <div className="mt-2">Entry By: {data.entryby}</div>
        </div>
      );

    case 'namesPPAdmin':
      return (
        <Entry entryBy={data.entryby}>
          <>
            <span className="mr-8">
              <Label>First Name</Label>
              {data.firstname}
            </span>
            <span>
              <Label>Last Name</Label>
              {data.lastname}
            </span>
          </>
        </Entry>
      );

    case 'psychHistoryPPAdmin':
    case 'psychotropicmedsPPAdmin':
    case 'medicalProblemsPPAdmin':
    case 'medicalDemographicsNotesAPAdmin':
      const labels = {
        psychHistoryPPAdmin: 'Psych History',
        psychotropicmedsPPAdmin: 'Psychotropic Meds',
        medicalProblemsPPAdmin: 'Medical Problems',
        medicalDemographicsNotesAPAdmin: 'Medical Demographics Notes'
      };
      const content = data[Object.keys(data).find(key => 
        ['psychhistory', 'psychotropicmeds', 'medicalproblems', 'note'].includes(key)
      ) || ''];
      
      return (
        <div className="space-y-2">
          <div className="font-bold">{labels[type]}:</div>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <div className="mt-2">Entry By: {data.entryby}</div>
        </div>
      );

    case 'ecmContactsAPAdmin':
      return (
        <div className="space-y-2">
          <div>
            <Label>Phone Number</Label>
            {data.phone_number}
          </div>
          <div>
            <Label>Address</Label>
            {data.address}
          </div>
          <div>Entry By: {data.entryby}</div>
        </div>
      );

    default:
      // Handle all other simple key-value pairs
      const key = Object.keys(data).find(k => 
        !['uniqueid', 'entryby', 'entrydate', '__typename', 'program'].includes(k)
      );
      
      if (!key) return null;

      const label = key.charAt(0).toUpperCase() + key.slice(1)
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase());

      const value = typeof data[key] === 'boolean' 
        ? (data[key] ? 'Yes' : 'No')
        : key.toLowerCase().includes('date') 
          ? formatDate(data[key])
          : data[key];

      return (
        <Entry entryBy={data.entryby}>
          <Label>{label}</Label>
          <span>{value}</span>
        </Entry>
      );
  }
};

export const Summary = ({ participant: participantProp, onClose }: SummaryProps) => {
  const { user, isUserLoading } = useUser();
  const { participant: participantParam } = useParams();
  const participant = participantProp || participantParam;

  const { loading, error, data } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
    variables: { odrnumber: participant }
  });

  if (loading || isUserLoading) {
    return (
      <ReactModal isOpen={true} className="bg-white p-8 max-w-4xl mx-auto mt-20 rounded-lg">
        <div className="text-center">Loading...</div>
      </ReactModal>
    );
  }

  if (error) {
    return (
      <ReactModal isOpen={true} className="bg-white p-8 max-w-4xl mx-auto mt-20 rounded-lg">
        <div className="text-red-500">Error loading data</div>
      </ReactModal>
    );
  }

  const report = convertToHash(data?.getParticipantByODRNumberAdmin || {});

  return (
    <ReactModal 
      isOpen={true} 
      onRequestClose={onClose}
      className="bg-white p-6 max-w-6xl mx-auto mt-10 rounded-lg"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="space-y-4">
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-2xl font-bold">Audit Log</h2>
          <button 
            onClick={onClose}
            className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded transition-colors"
          >
            Close
          </button>
        </div>

        <div className="max-h-[calc(100vh-12rem)] overflow-y-auto">
          {Object.entries(report)
            .sort(([a], [b]) => new Date(a).getTime() - new Date(b).getTime())
            .map(([date, entries], index) => (
              <div
                key={date}
                className={`grid grid-cols-[25rem,1fr] border border-gray-200 
                  ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
              >
                <div className="p-4 font-medium">{date}</div>
                <div className="border-l border-gray-200">
                  {entries.map((entry, i) => (
                    <div 
                      key={`${entry.__typename}-${i}`}
                      className="px-8 py-3 border-b border-gray-200 last:border-b-0"
                    >
                      <EventContent 
                        type={entry.__typename}
                        data={entry}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </ReactModal>
  );
};

