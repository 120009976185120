import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { PlusCircle, X } from "lucide-react";
import { useMutation, gql } from "@apollo/client";

const EDIT_CASE = gql`
  mutation EditCase(
    $participant: Integer!
    $bookingNumber: String!
    $caseNumber: String!
    $case: EditCaseInput!
  ) {
    editCase(
      participant: $participant
      bookingNumber: $bookingNumber
      caseNumber: $caseNumber
      case: $case
    ) {
      message
    }
  }
`;

export const EditCaseModal = ({
  isOpen,
  onClose,
  caseData,
  participant,
  bookingNumber,
  refetch,
}: {
  isOpen: boolean;
  onClose: () => void;
  caseData: any;
  participant: number;
  bookingNumber: string;
  refetch: () => void;
}) => {
  const [formData, setFormData] = useState({
    status: "open",
    charges: [{ charge: "", charge_entrydate: new Date().toISOString() }],
  });

  const [editCase] = useMutation(EDIT_CASE);

  useEffect(() => {
    if (caseData) {
      setFormData({
        status: caseData.caseOpen ? "open" : "closed",
        charges: caseData.charges?.length
          ? caseData.charges
          : [{ charge: "", charge_entrydate: new Date().toISOString() }],
      });
    }
  }, [caseData]);

  const handleStatusChange = (value: string) => {
    setFormData((prev) => ({ ...prev, status: value }));
  };

  const handleChargeChange = (index: number, value: string) => {
    setFormData((prev) => {
      const newCharges = [...prev.charges];
      newCharges[index] = {
        ...newCharges[index],
        charge: value,
        charge_entrydate: new Date().toISOString(),
      };
      return { ...prev, charges: newCharges };
    });
  };

  const addCharge = () => {
    setFormData((prev) => ({
      ...prev,
      charges: [
        ...prev.charges,
        { charge: "", charge_entrydate: new Date().toISOString() },
      ],
    }));
  };

  const removeCharge = (index: number) => {
    if (formData.charges.length > 1) {
      setFormData((prev) => ({
        ...prev,
        charges: prev.charges.filter((_, i) => i !== index),
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const cleanedCharges = formData.charges
        .filter(charge => charge.charge.trim() !== "")
        .map(({ charge, charge_entrydate }) => ({
          charge,
          charge_entrydate
        }));

      await editCase({
        variables: {
          participant,
          bookingNumber,
          caseNumber: caseData.caseNumber,
          case: {
            caseOpen: formData.status === "open",
            charges: cleanedCharges,
          },
        },
      });
      refetch();
      onClose();
    } catch (error) {
      console.error("Error editing case:", error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle className="text-center text-2xl font-bold">
            Edit Case
          </DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label>Case Number</Label>
              <div className="text-gray-600">{caseData?.caseNumber}</div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="status">Case Status</Label>
              <Select value={formData.status} onValueChange={handleStatusChange}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="open">Open</SelectItem>
                  <SelectItem value="closed">Closed</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <Label>Charges</Label>
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={addCharge}
                className="flex items-center gap-2"
              >
                <PlusCircle className="h-4 w-4" />
                Add Charge
              </Button>
            </div>

            <div className="space-y-2">
              {formData.charges.map((charge, index) => (
                <div key={index} className="flex gap-2 items-start">
                  <Input
                    value={charge.charge}
                    onChange={(e) => handleChargeChange(index, e.target.value)}
                    placeholder="Enter charge description"
                    className="flex-1"
                  />
                  {formData.charges.length > 1 && (
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={() => removeCharge(index)}
                      className="text-destructive hover:text-destructive"
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-end space-x-2 pt-4">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Update Case</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};