import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useMutation, gql } from "@apollo/client";

const DELETE_CASE = gql`
  mutation DeleteCase($participant: Integer!, $caseNumber: String!) {
    deleteCase(participant: $participant, caseNumber: $caseNumber) {
      message
    }
  }
`;

export const DeleteCaseModal = ({
  isOpen,
  onClose,
  caseData,
  participant,
  refetch,
}: {
  isOpen: boolean;
  onClose: () => void;
  caseData: any;
  participant: number;
  refetch: () => void;
}) => {
  const [deleteCase] = useMutation(DELETE_CASE);

  const handleDelete = async () => {
    try {
      await deleteCase({
        variables: {
          participant,
          caseNumber: caseData?.caseNumber,
        },
      });
      refetch();
      onClose();
    } catch (error) {
      console.error("Error deleting case:", error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle className="text-center text-2xl font-bold">
            Delete Case
          </DialogTitle>
        </DialogHeader>
        <div className="py-4 text-center">
          <p>Are you sure you want to delete this case?</p>
          <p className="text-sm text-muted-foreground mt-1">
            This action cannot be undone.
          </p>
        </div>
        <div className="flex justify-end space-x-2">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="destructive" onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};