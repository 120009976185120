import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../store/useUser";
import { useQuery, gql } from "@apollo/client";

const GET_NOTIFICATIONS = gql`
  query GetNotifications($programID: Integer!) {
    getEmailNotifications(programID: $programID) {
      id
      name
      emailID
      group
      subTitle
      position
    }
  }
`;

const NotificationLink = ({ to, children }: { to: string; children: React.ReactNode }) => (
  <Link 
    to={to} 
    className="flex items-center text-sm text-blue-600 hover:text-blue-800 transition-colors duration-200 py-2 px-3 rounded-md hover:bg-blue-50"
  >
    {children}
  </Link>
);

const SectionTitle = ({ children }: { children: React.ReactNode }) => (
  <h2 className="text-lg font-semibold text-gray-900 mb-4">
    {children}
  </h2>
);

const SubSectionTitle = ({ children }: { children: React.ReactNode }) => (
  <h3 className="text-sm font-medium text-gray-700 mb-2">
    {children}
  </h3>
);


const programs= {
  MIST : 1,
  FIST : 2,
  ODR : 3,
  PRG: 4,
  LEAD: 5,
  DSH : 6,
} as any

export const Notifications = ({ programName }: { programName: string }) => {
  const { user } = useUser();
  const canEditNotifications = user.access?.includes("EditNotifications");
  const isAdmin = user.groups?.includes("admin");

  const { data, loading, error } = useQuery(GET_NOTIFICATIONS, {
    variables: { programID: programs[programName] },
    skip: !programs[programName]
  });

  if (!programName) return <></>;
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Sort notifications by position first
  const sortedNotifications = [...(data?.getEmailNotifications || [])].sort(
    (a, b) => (a.position || 0) - (b.position || 0)
  );

  // Group sorted notifications
  const groupedNotifications = sortedNotifications.reduce((acc: any, notification: any) => {
    const group = notification.group || 'Other';
    const subTitle = notification.subTitle || 'General';
    
    if (!acc[group]) {
      acc[group] = {};
    }
    if (!acc[group][subTitle]) {
      acc[group][subTitle] = [];
    }
    acc[group][subTitle].push(notification);
    return acc;
  }, {});

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="border-b border-gray-200 mb-8" />
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Email Management */}
        {canEditNotifications && (
          <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
            <SectionTitle>Email Management</SectionTitle>
            <div className="space-y-1">
              <NotificationLink to="/edit_sendmail.php">
                Edit Email Details
              </NotificationLink>
              
              {isAdmin && (
                <NotificationLink to="/notifications/email/create_new_template_email.php?program=mist">
                  Create New Template
                </NotificationLink>
              )}
            </div>
          </div>
        )}

        {/* Dynamic Sections */}
        {Object.entries(groupedNotifications || {}).map(([group, subGroups]: [string, any]) => (
          <div key={group} className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
            <SectionTitle>
              For use by<br />
              {group}:
            </SectionTitle>

            {Object.entries(subGroups).map(([subTitle, notifications]: [string, any]) => (
              <div key={subTitle} className="mb-8 last:mb-0">
                {subTitle !== 'General' && (
                  <SubSectionTitle>{subTitle}:</SubSectionTitle>
                )}
                <div className="space-y-1">
                  {notifications.map((notification: any) => (
                    <NotificationLink 
                      key={notification.id} 
                      to={`/sendmail.php?for=${notification.emailID}`}
                    >
                      {subTitle.toLowerCase() === 'for icms clients' 
                        ? `Email provider (assign client) - ${notification.name}`
                        : notification.name
                      }
                    </NotificationLink>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notifications;