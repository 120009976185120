import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { toast } from "react-toastify";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { format } from "date-fns";
import { Checkbox } from "@/components/ui/checkbox";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useTrackedState } from '@/utils/useTrackedState';
import { Copy } from "lucide-react";
import { useProgramColor } from '@/store/useProgramColor'; 

interface Program {
  programDescription: string;
  entryDate: string;
  exitDate: string;
  provider: string;
  disposition: string;
}

interface NameHistory {
  entrydate: string;
  firstName: string;
  lastName: string;
  program: string;
}

interface DispositionHistory {
  dispositionDate: string;
  disposition: string;
  program: string;
}

interface Scan {
  filedate: string;
  filetype: string;
  filelabel: string;
  filename: string;
}

interface Participant {
  activeProgram?: {
    programDescription: string;
  };
  programs?: string[];
  mrun?: string;
  champid?: string;
  disposition?: string;
  providerHistory?: Array<{ value: string }>;
  genderHistory?: Array<{ value: string }>;
  releaseDate?: string;
  pathwayHistory?: Array<{ value: string }>;
  nameHistory?: NameHistory[];
  dispositionHistory?: DispositionHistory[];
  programDetails?: Program[];
  scans?: Scan[];
  name?: any;
  probation?: boolean;
  cii?: string;
  ssn?: string;
  isNumber?: string;
  booking?: string;
  maxDate?: string;
  completionDate?: string;
  dischargeDate?: string;
  commitmentDate?: string;
  jcit?: string;
  diversionDate?: string;
  notes?: string;
  incidents?: string;
  dob?: string;
  adherence?: string;
  champId?: string;
  fin?: string;
  arrestDate?: string;
  cases?: string;
  active?: boolean;
}

interface EditParticipantProps {
  isOpen: boolean;
  refetch: () => void;
  onClose: () => void;
  program: any;
  odrnumber: number;
  data?: {
    getParticipantByODRNumber: Participant;
  };
  programOnly?: boolean;
  active: boolean;
}

interface FormState {
  mrun: string;  
  champid: string;
  disposition: string;
  provider: string;
  gender: string;
  releaseDate: string;
  pathway: string;
  program: string;
  names: NameHistory[];
  isOpen: boolean;
  race: string;
  dob: string;
  isReal: boolean;
  fivePercent: boolean;
  isNumbers: string[];
  ab109: string;
  dispositionDate: string;
  programTransfers: string[];
  name: any;
  probation: boolean;
  adherence: string;
  champId: string;
  fin: string;
  arrestDate: string;
  cases: string;
  medicalProblems: string;
  psychotropicMeds: string;
  otherMeds: string;
  clinicalPresentation: string;
  currentMeds: string;
  substanceHistory: string;
  istStatus: string;
  ist: string;
  fso: string;
  booking: string;
  bookingNumber: string;
  maxDate: string;
  completionDate: string;
  isNumber: string;
  dischargeDate: string;
  commitmentDate: string;  
  jcit: string;
  diversionDate: string;
  notes: string;
  incidents: string;
  firstName: string;
  lastName: string;
  cii: string;
  ssn: string;
  mrunNumber: string;
  mrunStatus: 'open' | 'closed';
}

const UPDATE_PARTICIPANT = gql`
  mutation UpdateParticipantByODRNumber($odrnumber: Integer!, $participant: Integer, $updates: UpdateParticipantInput!) {
    updateParticipantByODRNumber(odrnumber: $odrnumber, participant: $participant, updates: $updates) {
      message
    }
  }
`;


const PROGRAM_DISPOSITIONS = {
  mist: [
    'CBR Placement',
    'CBR to Diversion Placement',
    'Diversion Placement',
    'Maxed Out',
    'Diversion Completed',
    'Unrestorable',
    'Restored',
    'Lost to Follow-Up',
    'Conserved',
    'Cancelled',
    'DMH',
    'Other'
  ],
  fist: [
    'Conditionally released',
    'In placement',
    'AWOL',
    'Re-incarcerated',
    'Conserved',
    'Restored',
    'Revoked',
    'Maxed out',
    'Other'
  ],
  odr: [
    'Conditionally released unassigned',
    'Conditionally released assigned',
    'Released to housing/services',
    'In community pending assignment',
    'Re-incarcerated ICMS retained',
    'Deactivated',
    'Deceased',
    'Conserved',
    'Cancelled',
    'Other'
  ],
  dsh: [
    'Conditionally released',
    'In placement',
    'AWOL',
    'Re-incarcerated',
    'Conserved',
    'Restored',
    'Revoked',
    'Other'
  ],
  prg_wmn: [
    'Conditionally released unassigned',
    'Conditionally released assigned',
    'Released to housing/services',
    'In community pending assignment',
    'Re-incarcerated ICMS retained',
    'Deactivated',
    'Deceased',
    'Conserved',
    'Cancelled',
    'Other'
  ],
  lead: [
    'Active',
    'Deactivated-no contact',
    'Deactivated-moved out of LA County',
    'Deactivated-voluntary withdrawal/refusal',
    'Deactivated-sentence > 16 mo, 1 day',
    'Deactivated-drug court/other diversion',
    'Deactivated-transferred to another program',
    'Deactivated-danger to staff/participants',
    'Deactivated-transferred to ICE custody',
    'Deceased',
    'Other'
  ]
};

const PROGRAM_PATHWAYS = {
  mist: [], // hidden for MIST
  fist: ['CBR', 'Diversion (1001.36)'],
  prg_wmn: {
    'PRE-TRIAL123': ['ICMS', 'ICMS +', 'FSP', 'None'],
    'PRE-TRIAL003': ['ICMS', 'ICMS +', 'FSP', 'None'],
    'PRE-TRIAL044': ['ICMS', 'ICMS +', 'FSP', 'None'],
    'COURT LINKAGE': ['ICMS', 'ICMS +', 'FSP', 'None'],
    'JAIL LINKAGE': ['ICMS', 'ICMS +', 'FSP', 'None']
  },
  lead: ['SOCIAL CONTACT', 'PRE-BOOKING', 'COMMUNITY REFERRAL']
};

const PROGRAM_PROVIDERS = {
  mist: ['CCM', 'HR360', 'OVMC', 'SERENITY', 'ALCOTT', 'AP RECOVERY', 'BUILDING LIVES', 'NONE'],
  fist: [
    'ALCOTT',
    'CCM',
    'EXODUS',
    'GATEWAYS',
    'IMD',
    'LAS ENCINAS',
    'OVMC',
    'PROJECT 180/SSG',
    'VSN',
    'NONE'
  ],
  dsh: [
    'EXODUS',
    'CCM',
    'OVMC',
    'HHH',
    'VSN',
    'P180',
    'NONE'
  ],
  odr: [
    'ALCOTT',
    'ALCOTT FISHER IH+',
    'AMITY FOUNDATION',
    'BUILDING LIVES IH+',
    'CCM ICMS',
    'CCM IH+',
    'EXODUS',
    'HHCLA',
    'HOUSING WORKS',
    'LIFESTEPS',
    'MHALA',
    'PROJECT 180/SSG',
    'ST. JOSEPHS',
    'STEP UP ON SECOND',
    'TELECARE',
    'TPC',
    'VOA',
    'VSN ALLIANCE IH+',
    'VSN PROSPERITY IH+'
  ],
  prg_wmn: [
    'ALCOTT',
    'AMITY FOUNDATION',
    'HHCLA',
    'TPC',
    'PROJECT 180/SSG',
    'VOA'
  ],
  lead: [
    'CHPLA',
    'HOPICS',
    'ALMA ELA',
    'ALMA SLA',
    'PATH',
    'TPC',
    'Velnonart'
  ]
};

export const EditProgram: React.FC<EditParticipantProps> = ({ isOpen, onClose, program, odrnumber, data, programOnly, active , refetch } : EditParticipantProps ) => {
  const programNameDescription = data?.getParticipantByODRNumber?.activeProgram?.programDescription
  const participant = data?.getParticipantByODRNumber;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { programColor } = useProgramColor();

  
  
  const [activeProgram, setActiveProgram] = useState<string>(
    participant?.activeProgram?.programDescription || ""
  );

  const [formState, handleInputChange, getModifiedValues] = useTrackedState<FormState>({
    firstName: participant?.name?.firstName || "",
    lastName: participant?.name?.lastName || "",
    program: participant?.activeProgram?.programDescription || "",
    names: participant?.nameHistory || [],
    isOpen: false,
    isReal: false,
    champid: participant?.champid || "",
    gender: participant?.genderHistory?.[0]?.value || "",
    disposition: participant?.disposition || "",
    releaseDate: participant?.releaseDate || "",
    pathway: participant?.pathwayHistory?.[0]?.value || "",
    provider: participant?.providerHistory?.[0]?.value || "",
    mrun: participant?.mrun || "",
    adherence: participant?.adherence || "",
    champId: participant?.champId || "",
    fin: participant?.fin || "",
    arrestDate: participant?.arrestDate || "", 
    name: "",
    probation: false,
    cases: participant?.cases || "",
    medicalProblems: "",
    psychotropicMeds: "",
    otherMeds: "",
    clinicalPresentation: "",
    currentMeds: "",
    substanceHistory: "",
    istStatus: "",
    ist: "",
    fso: "",
    booking: participant?.booking || "",
    bookingNumber: "",
    maxDate: "",
    dob: participant?.dob || "",
    completionDate: "",    
    dischargeDate: "",
    commitmentDate: "",
    jcit: "",
    diversionDate: "",
    notes: "",    
    incidents: "",
    fivePercent: false,
    isNumbers: [],
    ab109: "",
    dispositionDate: "",
    programTransfers: [],    
    isNumber: participant?.isNumber || "",
    race: "",
    cii: participant?.cii || "",
    ssn: participant?.ssn || "",
    mrunNumber: participant?.mrun ? participant.mrun.split('-')[0] : '',
    mrunStatus: participant?.mrun?.includes('open') ? 'open' : 'closed',
  });

  const [updateParticipant] = useMutation(UPDATE_PARTICIPANT, {
    onCompleted: () => {
      toast.success("Participant updated sucdcessfully");
      onClose();
    },
    onError: (error) => {
      toast.error(`Error updating participant: ${error.message}`);
    },
  });



  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const modifiedValues = getModifiedValues();
      if (Object.keys(modifiedValues).length === 0) {
        toast.info("No changes to save");
        return;
      }

      // Check if any name-related fields were modified
      const nameFieldsModified = ['firstName', 'lastName', 'isReal'].some(
        field => field in modifiedValues
      );

      // Create the final updates object
      const updates = { ...modifiedValues };

      if (modifiedValues.mrun) {
        updates.mrun = formState.mrunNumber + '-' + formState.mrunStatus
        delete updates.mrunNumber;
        delete updates.mrunStatus;
      }
      
      // If any name field was modified, group them together
      if (nameFieldsModified) {
        // Remove individual name fields
        delete updates.firstName;
        delete updates.lastName;
        delete updates.isReal;                
        // Add grouped name object with correct field names
        updates.name = {
          firstName: formState.firstName,
          lastName: formState.lastName,
          isName: Boolean(formState.isReal),
          isAlias: !formState.isReal
        };
      }

      await updateParticipant({
        variables: {
          odrnumber: odrnumber,
          participant: program.programParticipantNumber,
          updates: updates,
        },
      });
      refetch();
    } catch (error) {
      console.error("Submit error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatDateString = (dateString: string) => {
    try {
      return format(new Date(dateString), "MM/dd/yyyy");
    } catch {
      return dateString;
    }
  };

  const programs = ['MIST', 'FIST', 'ODR Housing', 'Pregnant Woman', 'LEAD'];
  const providers = ['CCM', 'HR360', 'OVMC', 'SERENITY', 'ALCOTT', 'AP RECOVERY', 'BUILDING LIVES', 'NONE'];
  const dispositions = [
    'CBR Placement',
    'CBR to Diversion Placement',
    'Diversion Placement',
    'Maxed Out',
    'Diversion Completed',
    'Unrestorable',
    'Restored',
    'Lost to Follow-Up',
    'Conserved',
    'Cancelled',
    'DMH',
    'Other'
  ];
  const raceOptions = ['American Indian', 'Asian', 'Black', 'Hispanic', 'Middle Eastern', 'Pacific Islander', 'White', 'Doesn\'t Know', 'Refused', 'Unknown'];

  const getAvailableDispositions = () => {
    const program = programNameDescription || '';
    if (program =='mist') return PROGRAM_DISPOSITIONS.mist;
    if (program =='FIST') return PROGRAM_DISPOSITIONS.fist;
    if (program =='ODR') return PROGRAM_DISPOSITIONS.odr;
    if (program =='dsh') return PROGRAM_DISPOSITIONS.dsh;
    if (program =='prg_wmn') return PROGRAM_DISPOSITIONS.prg_wmn;
    if (program =='lead') return PROGRAM_DISPOSITIONS.lead;
    return [];
  };

  const getProgramType = (programDescription: string | undefined): 'mist' | 'fist' | 'odr' | 'lead' | 'prg_wmn' => {
    if (!programDescription) return 'mist';
    if (programDescription.toLowerCase().includes('mist')) return 'mist';
    if (programDescription.toLowerCase().includes('fist')) return 'fist';
    if (programDescription.toLowerCase().includes('odr')) return 'odr';
    if (programDescription.toLowerCase().includes('lead')) return 'lead';
    if (programDescription.toLowerCase().includes('prg_wmn')) return 'prg_wmn';
    return 'mist'; // default
  };

  const handleMrunChange = (type: 'number' | 'status', value: string) => {
    if (type === 'number') {
      // Only allow digits and limit to 9 characters
      const sanitizedValue = value.replace(/\D/g, '').slice(0, 9);
      handleInputChange('mrunNumber', sanitizedValue);
    } else {
      handleInputChange('mrunStatus', value as 'open' | 'closed');
    }
    
    // Combine both values to update the actual mrun field
    const number = type === 'number' ? value : formState.mrunNumber;
    const status = type === 'status' ? value : formState.mrunStatus;
    if (number) {
      handleInputChange('mrun', `${number}-${status}`);
    }
  };

  const [primaryPathway, setPrimaryPathway] = useState(
    Object.keys(PROGRAM_PATHWAYS.prg_wmn || {}).find(key => 
      formState.pathway?.startsWith(key)
    ) || ''
  );

  const renderPathwaySelects = () => {
    const programType = getProgramType(programNameDescription);
    
    if (programType === 'prg_wmn') {
      return (
        <div className="flex gap-4">
          <Select
            value={primaryPathway}
            onValueChange={(value) => {
              setPrimaryPathway(value);
              handleInputChange('pathway', value);
            }}
          >
            <SelectTrigger className="h-10 w-[250px]">
              <SelectValue placeholder="Select primary pathway" />
            </SelectTrigger>
            <SelectContent>
              {Object.keys(PROGRAM_PATHWAYS.prg_wmn).map((path) => (
                <SelectItem key={path} value={path}>
                  {path}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          {primaryPathway && (
            <Select
              value={formState.pathway?.split(' - ')[1] || ''}
              onValueChange={(value) => {
                handleInputChange('pathway', `${primaryPathway} - ${value}`);
              }}
            >
              <SelectTrigger className="h-10 w-[250px]">
                <SelectValue placeholder="Select secondary pathway" />
              </SelectTrigger>
              <SelectContent>
                {PROGRAM_PATHWAYS.prg_wmn[primaryPathway as keyof typeof PROGRAM_PATHWAYS.prg_wmn].map((subPath) => (
                  <SelectItem key={subPath} value={subPath}>
                    {subPath}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        </div>
      );
    }

    // Default single pathway select for other programs
    return (
      <Select
        value={formState.pathway}
        onValueChange={(value) => handleInputChange('pathway', value)}
      >
        <SelectTrigger className="h-10 w-[250px]">
          <SelectValue placeholder="Select pathway" />
        </SelectTrigger>
        <SelectContent>
          {Object.values(PROGRAM_PATHWAYS[programType as keyof typeof PROGRAM_PATHWAYS] || []).map((path: string) => (
            <SelectItem key={path} value={path}>
              {path}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  };

  const handleToggleActive = async () => {
    try {
      await updateParticipant({
        variables: {
          odrnumber,
          participant: program.programParticipantNumber,
          updates: {
            active: !active
          }
        }
      });
      refetch();
    } catch (error) {
      console.error("Toggle active error:", error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className={`min-w-[80vw] max-w-[90vw] max-h-[90vh] overflow-y-auto ${active ? 'bg-white' : 'bg-gray-100'}`}>
        <DialogHeader>
          <DialogTitle>Edit Program Details {active ? <span className="text-blue-500 ml-2">Active</span> : <span className="text-red-500 ml-2">Inactive</span>}</DialogTitle>
        </DialogHeader>

        
          <Card className={`bg-white`}>
            <CardHeader className="flex flex-row items-center justify-between">
              <CardTitle>{`${program.programDescription?.toUpperCase()} - ${program.programParticipantNumber}`}</CardTitle>
              <Button 
                onClick={handleToggleActive}
                variant="outline"
                className={`${active ? 'hover:bg-red-100' : 'hover:bg-green-100'}`}
              >
                {active ? 'Deactivate' : 'Activate'}
              </Button>
            </CardHeader>
            <CardContent>
            <form onSubmit={handleSubmit} className="">
              <div>
      
                  
                  <div className={``}>
              
                 
               
                <div className={`space-y-2.5`}>
                  <Label className="text-sm font-medium">{}</Label>
                  <div className="flex gap-8">
                  
                  
                  </div>
                  <div className="grid grid-cols-3 gap-6">
                    <div className="space-y-2.5">
                      <Label htmlFor="ab109Funded" className="text-sm font-medium">
                        AB109 Funded
                      </Label>
                      <Select
                        value={formState.ab109}
                        onValueChange={(value) => handleInputChange('ab109', value)}
                      >
                        <SelectTrigger className="h-10 w-[250px]">
                          <SelectValue placeholder="Select AB109 status" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="1">Yes</SelectItem>
                          <SelectItem value="0">No</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="space-y-2.5">
                      <Label htmlFor="commitmentDate" className="text-sm font-medium">
                        Commitment Date
                      </Label>
                      <Input
                        id="commitmentDate"
                        type="date"
                        value={formState.commitmentDate}
                        onChange={(e) => handleInputChange('commitmentDate', e.target.value)}
                        className="h-10 w-[160px]"
                      />
                    </div>

                    <div className="space-y-2.5">
                      <Label htmlFor="completionDate" className="text-sm font-medium">
                        Completion Date
                      </Label>
                      <Input
                        id="completionDate"
                        type="date"
                        value={formState.completionDate}
                        onChange={(e) => handleInputChange('completionDate', e.target.value)}
                        className="h-10 w-[160px]"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="space-y-2.5">
                      <Label htmlFor="dischargeDate" className="text-sm font-medium">
                        Discharge Date
                      </Label>
                      <Input
                        id="dischargeDate"
                        type="date"
                        value={formState.dischargeDate}
                        onChange={(e) => handleInputChange('dischargeDate', e.target.value)}
                        className="h-10 w-[160px]"
                      />
                    </div>

                    <div className="space-y-2.5">
                      <Label htmlFor="maxDate" className="text-sm font-medium">
                        Max Date
                      </Label>
                      <Input
                        id="maxDate"
                        type="date"
                        value={formState.maxDate}
                        onChange={(e) => handleInputChange('maxDate', e.target.value)}
                        className="h-10 w-[160px]"
                      />
                    </div>

                    <div className="space-y-2.5">
                      <Label htmlFor="jcit" className="text-sm font-medium">
                        JCIT
                      </Label>
                      <Input
                        id="jcit"
                        value={formState.jcit}
                        onChange={(e) => handleInputChange('jcit', e.target.value)}
                        className="h-10 w-[250px]"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="space-y-2.5">
                      <Label htmlFor="disposition" className="text-sm font-medium">
                        Disposition
                      </Label>
                      <Select
                        value={formState.disposition}
                        onValueChange={(value) => handleInputChange('disposition', value)}
                      >
                        <SelectTrigger className="h-10 w-[250px]">
                          <SelectValue placeholder="Select disposition" />
                        </SelectTrigger>
                        <SelectContent>
                          {getAvailableDispositions().map((disp) => (
                            <SelectItem key={disp} value={disp}>
                              {disp}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="space-y-2.5">
                      <Label htmlFor="pathway" className="text-sm font-medium">
                        Pathway
                      </Label>
                      {renderPathwaySelects()}
                    </div>

                    <div className="space-y-2.5">
                      <Label htmlFor="provider" className="text-sm font-medium">
                        Provider
                      </Label>
                      <Select
                        value={formState.provider}
                        onValueChange={(value) => handleInputChange('provider', value)}
                      >
                        <SelectTrigger className="h-10 w-[250px]">
                          <SelectValue placeholder="Select provider" />
                        </SelectTrigger>
                        <SelectContent>
                          {PROGRAM_PROVIDERS[getProgramType(programNameDescription) as keyof typeof PROGRAM_PROVIDERS]?.map((prov: string) => (
                            <SelectItem key={prov} value={prov}>
                              {prov}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </div>

                <div className="space-y-2.5">
                  <Label className="text-sm font-medium">Status</Label>
                  <div className="flex gap-8">
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        id="isOpen"
                        checked={formState.isOpen}
                        onCheckedChange={(checked: boolean) => 
                          handleInputChange('isOpen', checked === true ? "true" : "false")
                        }
                      />
                      <Label htmlFor="isOpen" className="text-sm">
                        Open
                      </Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        id="fivePercent"
                        checked={formState.fivePercent}
                        onCheckedChange={(checked: boolean) => 
                          handleInputChange('fivePercent', checked === true ? "true" : "false")
                        }
                      />
                      <Label htmlFor="fivePercent" className="text-sm">
                        5%
                      </Label>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div className="flex justify-end gap-4 mt-6">
            <Button 
              type="submit" 
              disabled={isSubmitting}
              className="w-[100px]"
              variant="outline"
            >
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
            <Button 
              type="button" 
              variant="outline"
              onClick={onClose}
              className="w-[100px]"
            >
              Cancel
            </Button>
          </div>
              </form>
              
            </CardContent>
          </Card>

         
        
      </DialogContent>
    </Dialog>
  );
};

export default EditProgram;
