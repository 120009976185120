import React, { useState, useEffect, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useAuthenticationState } from '../../../store/useAuthenticationState';
import { Lock } from 'lucide-react';

const LOGIN_PASSWORD = gql`
    mutation ValidatePassword($username: String!, $password: String!) {
        validatePassword(username: $username, password: $password)
    }
`;



export const LockScreen = ({onSuccess} :any) => {
    const { authenticationState, setAuthenticationState } = useAuthenticationState();

    const [state, setState] = useState({
        username: '',
        password: '',
        error: '',        
        loading: false,
        
    })

    const [loginPassword, { data: loginPasswordData, error: loginPasswordError }] = useMutation(LOGIN_PASSWORD);


    const handleUsername = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        const loginPasswordResult = await loginPassword({ variables: { username: state.username, password: state.password } });
        if (loginPasswordResult?.data?.validatePassword) {
            onSuccess();
        }
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
          <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg">
            <img 
              src={`${process.env.PUBLIC_URL}/logoODR.png`} 
              alt="logo" 
              className="w-48 mx-auto"
            />
            
            <div className="text-center space-y-4">
              <div className="flex items-center justify-center space-x-2">
                <Lock className="w-5 h-5 text-gray-600" />
                <h1 className="text-2xl font-bold text-gray-900">Unlock Session</h1>
              </div>
              
              <div className="space-y-2 text-gray-600">
                <p>For security reasons, your session has been locked.</p>
                <p>Please enter your username and password to unlock.</p>
              </div>
              
              <form onSubmit={handleUsername} className="mt-8 space-y-6">
                <div className="space-y-6">
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700 text-left">
                      Username
                    </label>
                    <input
                      type="text"
                      value={state.username}
                      onChange={(e) => setState({ ...state, username: e.target.value })}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700 text-left">
                      Password
                    </label>
                    <input
                      type="password"
                      value={state.password}
                      onChange={(e) => setState({ ...state, password: e.target.value })}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
                
                <button
                  type="submit"
                  className="w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                >
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      );
}