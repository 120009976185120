 
import React, { useState } from 'react';
import { Shield, ChevronDown, ChevronUp, Search, X } from 'lucide-react';

const PERMISSION_MAP = {
  fistaccess: "FIST",
  fisteditparticipant: "FIST - Edit participant",
  odrhousingaccess: "ODR Housing",
  prgwmnaccess: "Pregnant Women",
  diversionaccess: "DSH Diversion",
  dshaccess: "Acute Psych Beds",
  ovistaaccess: "Olive Vista",
  covidaccess: "COVID-19 Jail Releases",
  leadaccess: "LEAD",
  laiaccess: "LAI Jail",
  laicomaccess: "LAI Community",
  hsngdshbrdaccess: "Housing Dashboard",
  inrepaccess: "Incident Reports",
  covidtestaccess: "COVID-19 Testing",
  odrquarantineaccess: "Quarantine Sites",
  influenzavaccineaccess: "Influenza Vaccine Tracking",
  influenzavacappointmentaccess: "Influenza Vaccine Appointment",
  influenzavacmanagementaccess: "Influenza Vaccine Management",
  covidvacaccess: "COVID-19 Vaccine Tracking",
  vacappointmentaccess: "COVID-19 Vaccine Appointment",
  vacmanagementaccess: "COVID-19 Vaccine Management",
  monkeypoxavaccineaccess: "Monkeypox Vaccine Tracking",
  monkeypoxvacappointmentaccess: "Monkeypox Vaccine Appointment",
  monkeypoxvacmanagementaccess: "Monkeypox Vaccine Management",
  medcalsuperaccess: "Medcall Power User",
  medcalstandardaccess: "Medcall Standard User",
  courtcalendaraccess: "Court Calendar",
  dbdumpaccess: "Database Dump",
  findduplicatesaccess: "Find Duplicates",
  updateprovideremailsaccess: "Provider Emails Manager",
  updatefacilitiesaccess: "Facilities Manager",
  ecmdashboardaccess: "ECM Dashboard",
  ecmdashboardsuperaccess: "ECM Dashboard Super",
  ecmoutreachaccess: "ECM Outreach",
  legacyecmdashboardaccess: "Legacy ECM Dashboard",
  miftoolsaccess: "MiF Tools",
  providermedicaldemographicsaccess: "Provider Medi-Cal Demographics",
  efaxaccess: "eFax",
  plevelaccess: "P-Level"
};

const PERMISSION_CATEGORIES = {
  "Core Access": ["fistaccess", "fisteditparticipant"],
  "Housing": ["odrhousingaccess", "hsngdshbrdaccess", "ovistaaccess"],
  "Health Services": ["dshaccess", "medcalsuperaccess", "medcalstandardaccess"],
  "COVID-19": ["covidaccess", "covidtestaccess", "covidvacaccess", "vacappointmentaccess", "vacmanagementaccess"],
  "Vaccines": [
    "influenzavaccineaccess", 
    "influenzavacappointmentaccess", 
    "influenzavacmanagementaccess",
    "monkeypoxavaccineaccess",
    "monkeypoxvacappointmentaccess",
    "monkeypoxvacmanagementaccess"
  ],
  "Programs": ["prgwmnaccess", "fistaccess", "mistaccess", "odrhousingaccess", "dshaccess", "leadaccess"],
  "Reports & Management": [
    "laiaccess", "laicomaccess",
    "inrepaccess",
    "courtcalendaraccess",
    "dbdumpaccess",
    "findduplicatesaccess",
    "updateprovideremailsaccess",
    "updatefacilitiesaccess"
  ],
  "ECM": ["ecmdashboardaccess", "ecmdashboardsuperaccess", "ecmoutreachaccess", "legacyecmdashboardaccess"],
  "Other": ["miftoolsaccess", "providermedicaldemographicsaccess", "efaxaccess", "plevelaccess"]
};

interface UserPermission {
  permission: string;
  enabled: boolean;
}

interface PermissionManagerProps {
  selectedPermissions: UserPermission[];
  onChange: (permissions: UserPermission[]) => void;
  disabled?: boolean;
}

export const PermissionManager: React.FC<PermissionManagerProps> = ({
  selectedPermissions,
  onChange,
  disabled = false
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedCategories, setExpandedCategories] = useState<string[]>(Object.keys(PERMISSION_CATEGORIES));

  const toggleCategory = (category: string) => {
    setExpandedCategories(prev => 
      prev.includes(category) 
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const togglePermission = (permission: string) => {
    if (disabled) return;
    const permissionObj = { permission, enabled: true };
    const newPermissions = selectedPermissions.some(p => p.permission === permission)
      ? selectedPermissions.filter(p => p.permission !== permission)
      : [...selectedPermissions, permissionObj];
    onChange(newPermissions);
  };

  const toggleCategoryPermissions = (category: string, isChecked: boolean) => {
    if (disabled) return;
    const categoryPermissions = PERMISSION_CATEGORIES[category as keyof typeof PERMISSION_CATEGORIES];
    const newPermissions = isChecked
      ? Array.from(new Set([...selectedPermissions, ...categoryPermissions.map(p => ({ permission: p, enabled: true }))]))
      : selectedPermissions.filter(p => !categoryPermissions.includes(p.permission));
    onChange(newPermissions);
  };

  const filteredCategories = Object.entries(PERMISSION_CATEGORIES).filter(([category, permissions]) => {
    if (!searchTerm) return true;
    const searchLower = searchTerm.toLowerCase();
    return category.toLowerCase().includes(searchLower) ||
      permissions.some(p => 
        PERMISSION_MAP[p as keyof typeof PERMISSION_MAP].toLowerCase().includes(searchLower) ||
        p.toLowerCase().includes(searchLower)
      );
  });
  const isCategoryFullySelected = (permissions: string[]) => 
    permissions.every(p => selectedPermissions.some(sp => sp.permission === p));

  const isCategoryPartiallySelected = (permissions: string[]) =>
    permissions.some(p => selectedPermissions.some(sp => sp.permission === p)) &&
    !isCategoryFullySelected(permissions);

  return (
    <div className="space-y-4">
      <div className="sticky top-0 bg-white pb-4 z-10">
        <div className="flex items-center space-x-2 mb-4">
          <Shield className="h-5 w-5 text-gray-400" />
          <h3 className="text-lg font-medium">Permissions</h3>
          <span className="text-sm text-gray-500">
            ({selectedPermissions.length} selected)
          </span>
        </div>
        <div className="relative">
          <Search className="absolute right-3  top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search permissions..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-10 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={disabled}
          />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm("")}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
            >
              <X className="h-5 w-5" />
            </button>
          )}
        </div>
      </div>

      <div className="space-y-2 max-h-[500px] overflow-y-auto pr-2">
        {filteredCategories.map(([category, permissions]) => (
          <div key={category} className="border rounded-md">
            <div 
              className="flex items-center p-3 bg-gray-50 cursor-pointer hover:bg-gray-100"
              onClick={() => toggleCategory(category)}
            >
              <input
                type="checkbox"
                checked={isCategoryFullySelected(permissions)}
                ref={input => {
                  if (input) {
                    input.indeterminate = isCategoryPartiallySelected(permissions);
                  }
                }}
                onChange={(e) => toggleCategoryPermissions(category, e.target.checked)}
                onClick={(e) => e.stopPropagation()}
                className="mr-3 h-4 w-4 text-blue-500 rounded"
              />
              <span className="text-sm font-medium">{category}</span>
              {expandedCategories.includes(category) ? (
                <ChevronUp className="h-4 w-4 text-gray-500 ml-auto" />
              ) : (
                <ChevronDown className="h-4 w-4 text-gray-500 ml-auto" />
              )}
            </div>
            {expandedCategories.includes(category) && (
              <div className="pl-6">
                {permissions.map(permission => (
                  <div key={permission} className="flex items-center py-1">
                    <input
                      type="checkbox"
                      checked={selectedPermissions.some(p => p.permission === permission)}
                      onChange={(e) => togglePermission(permission)}
                      className="mr-3 h-4 w-4 text-blue-500 rounded"
                    />
                    <span className="text-sm">{PERMISSION_MAP[permission as keyof typeof PERMISSION_MAP]}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PermissionManager;
