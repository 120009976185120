import { Login as LoginComponent } from './LoginComponent'
import { createPhpSession } from './createSession'


export const Login = () => {


   const onSuccess = async (authenticationState: string) => {
       if (!authenticationState) {
           window.location.href = '/app/components/auth/login/index.php';
           return;
       }

       await createPhpSession(authenticationState);       
   };

   return (
    <div className="flex justify-center items-center h-screen">
       <LoginComponent onSuccess={onSuccess}/>       
    </div>
   );
}