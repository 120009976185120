import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useHelpTopics } from '../../../store/useHelpTopics';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent } from '@/components/ui/card';
import { ChevronRight, Plus, Save } from 'lucide-react';

// GraphQL Queries and Mutations
const GET_HELP_TOPICS = gql`
  query GetHelpTopics {
    listHelpTopics {
      id
      name
    }
  }
`;

const GET_HELP_TOPIC = gql`
  query GetHelpTopic($id: Integer!) {
    getHelpTopic(id: $id) {
      id
      name
      content
    }
  }
`;

const CREATE_HELP_TOPIC = gql`
  mutation CreateHelpTopic($name: String!, $content: String!) {
    createHelpTopic(name: $name, content: $content) {
      id
      name
      content
    }
  }
`;

const UPDATE_HELP_TOPIC = gql`
  mutation UpdateHelpTopic($id: Integer!, $updates: UpdateHelpTopicInput!) {
    updateHelpTopic(id: $id, updates: $updates) {      
      message
    }
  }
`;

const HTMLInput = ({ name, value, onChange }: any) => {
  const [html, setHtml] = React.useState(value || '');
  const quillRef = React.useRef(null);

  const handleChange = React.useCallback((content: any) => {
    setHtml(content);
    onChange({ target: { name, value: content } });
  }, [name, onChange]);

  useEffect(() => {
    if (value !== html) {
      setHtml(value || '');
    }
  }, [value, html]);

  return (
    <div className="mt-4 h-[calc(100vh-240px)]">
      <ReactQuill
        ref={quillRef}
        value={html}
        onChange={handleChange}
        className="bg-white h-full text-sm"
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link'],
          ]
        }}
      />
    </div>
  );
};

export const HelpTopicsManager = () => {
  const [selectedTopic, setSelectedTopic] = useState<number | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [newTopicName, setNewTopicName] = useState('');
  const [newTopicContent, setNewTopicContent] = useState('');
  
  // Simplified approach - only track actual user changes
  const [hasUserMadeChanges, setHasUserMadeChanges] = useState(false);

  const { pushHelpTopic, popHelpTopic } = useHelpTopics();
  
  useEffect(() => {
    pushHelpTopic('HelpTopics');
    return () => {
      popHelpTopic();
    };
  }, [pushHelpTopic, popHelpTopic]);

  const { loading: topicsLoading, error: topicsError, data: topicsData, refetch: refetchTopics } = useQuery(GET_HELP_TOPICS);
  const { loading: topicLoading, error: topicError, data: topicData } = useQuery(GET_HELP_TOPIC, {
    variables: { id: selectedTopic },
    skip: !selectedTopic,
  });

  const [createHelpTopic] = useMutation(CREATE_HELP_TOPIC);
  const [updateHelpTopic] = useMutation(UPDATE_HELP_TOPIC);

  useEffect(() => {
    if (topicData?.getHelpTopic) {
      const topicName = topicData.getHelpTopic.name || '';
      const topicContent = topicData.getHelpTopic.content || '';
      
      setNewTopicName(topicName);
      setNewTopicContent(topicContent);
      
      // Reset change indicator when loading a topic
      setHasUserMadeChanges(false);
    }
  }, [topicData]);

  const handleCreateTopic = async () => {
    try {
      const result = await createHelpTopic({
        variables: { name: newTopicName, content: newTopicContent },
      });
      
      setIsCreating(false);
      refetchTopics();
      
      // Select the newly created topic
      if (result.data?.createHelpTopic?.id) {
        setSelectedTopic(result.data.createHelpTopic.id);
      }
      
      setHasUserMadeChanges(false);
    } catch (error) {
      console.error('Error creating topic:', error);
    }
  };

  const handleUpdateTopic = async () => {
    if (!selectedTopic) return;
    
    try {
      await updateHelpTopic({
        variables: {
          id: selectedTopic,
          updates: {
            name: newTopicName,
            content: newTopicContent,
          },
        },
      });
      
      setHasUserMadeChanges(false);
      refetchTopics();
    } catch (error) {
      console.error('Error updating topic:', error);
    }
  };

  const handleSelectTopic = (id: number) => {
    if (hasUserMadeChanges) {
      if (window.confirm('You have unsaved changes. Do you want to discard them?')) {
        setSelectedTopic(id);
        setIsCreating(false);
        setHasUserMadeChanges(false);
      }
      return;
    }
    
    setSelectedTopic(id);
    setIsCreating(false);
  };

  const startCreateTopic = () => {
    if (hasUserMadeChanges) {
      if (window.confirm('You have unsaved changes. Do you want to discard them?')) {
        resetEditorState();
      }
      return;
    }
    
    resetEditorState();
  };
  
  const resetEditorState = () => {
    setIsCreating(true);
    setSelectedTopic(null);
    setNewTopicName('');
    setNewTopicContent('');
    setHasUserMadeChanges(false);
  };

  // Only register explicit changes by user interaction
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTopicName(e.target.value);
    // Only mark as changed if this was triggered by user input
    if (e.type === 'change') {
      setHasUserMadeChanges(true);
    }
  };

  const handleContentChange = (e: any) => {
    setNewTopicContent(e.target.value);
    // For ReactQuill, we need a different approach because change events work differently
    // Only set hasUserMadeChanges if there was an actual user interaction
    if (document.activeElement?.tagName === 'TEXTAREA' || 
        document.activeElement?.className.includes('ql-editor')) {
      setHasUserMadeChanges(true);
    }
  };

  if (topicsLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
      </div>
    );
  }

  if (topicsError) {
    return (
      <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded text-sm">
        Error loading topics: {topicsError.message}
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-gray-50 text-sm">
      {/* Left Panel - Topic List */}
      <div className="w-1/4 border-r border-gray-200 bg-white overflow-y-auto">
        <div className="p-4 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-bold text-gray-800">Help Topics</h2>
            <Button onClick={startCreateTopic} size="sm" variant="ghost" className="p-1 h-8">
              <Plus className="w-4 h-4" />
            </Button>
          </div>
        </div>
        
        <div className="divide-y divide-gray-100">
          {topicsData.listHelpTopics.map((topic: any) => (
            <div
              key={topic.id}
              onClick={() => handleSelectTopic(topic.id)}
              className={`p-3 cursor-pointer hover:bg-gray-50 flex items-center justify-between ${
                selectedTopic === topic.id ? 'bg-blue-50 border-l-2 border-blue-500' : ''
              }`}
            >
              <span className="text-sm text-gray-700 truncate">{topic.name}</span>
              <ChevronRight className="w-4 h-4 text-gray-400" />
            </div>
          ))}
        </div>

        {topicsData.listHelpTopics.length === 0 && !isCreating && (
          <div className="text-center py-8 text-gray-500 text-sm">
            No help topics found.
            <div className="mt-2">
              <Button onClick={startCreateTopic} size="sm" variant="outline">
                Create your first topic
              </Button>
            </div>
          </div>
        )}
      </div>

      {/* Right Panel - Topic Content */}
      <div className="w-3/4 bg-white p-4 overflow-y-auto">
        {isCreating ? (
          <div className="h-full flex flex-col">
            <div className="flex justify-between items-center mb-4 pb-2 border-b">
              <h2 className="text-lg font-medium">Create New Topic</h2>
              <Button 
                onClick={handleCreateTopic} 
                size="sm"
                disabled={!newTopicName.trim()}
                className="flex items-center gap-1"
              >
                <Save className="w-4 h-4" />
                Create Topic
              </Button>
            </div>
            
            <div className="space-y-4 flex-1">
              <Input
                type="text"
                placeholder="Topic Name"
                value={newTopicName}
                onChange={handleInputChange}
                className="w-full text-sm"
              />
              
              <HTMLInput
                name="content"
                value={newTopicContent}
                onChange={handleContentChange}
              />
            </div>
          </div>
        ) : selectedTopic ? (
          topicLoading ? (
            <div className="flex justify-center items-center h-full">
              <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-blue-500"></div>
            </div>
          ) : (
            <div className="h-full flex flex-col">
              <div className="flex justify-between items-center mb-4 pb-2 border-b">
                <h2 className="text-lg font-medium">Edit Topic</h2>
                <Button 
                  onClick={handleUpdateTopic} 
                  size="sm"
                  disabled={!hasUserMadeChanges}
                  className="flex items-center gap-1"
                >
                  <Save className="w-4 h-4" />
                  Save Changes
                </Button>
              </div>
              
              <div className="space-y-4 flex-1">
                <Input
                  type="text"
                  placeholder="Topic Name"
                  value={newTopicName}
                  onChange={handleInputChange}
                  className="w-full text-sm"
                />
                
                <HTMLInput
                  name="content"
                  value={newTopicContent}
                  onChange={handleContentChange}
                />
              </div>
            </div>
          )
        ) : (
          <div className="flex flex-col items-center justify-center h-full text-gray-500">
            <p>Select a topic from the list or create a new one</p>
            <Button onClick={startCreateTopic} className="mt-4" size="sm">
              <Plus className="w-4 h-4 mr-2" />
              Create New Topic
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default HelpTopicsManager;