import ReactDOM from 'react-dom';
const styles = {
    overlay: {
      position: 'fixed' as 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      zIndex: 9999,
      backgroundColor: 'rgba(128, 128, 128)', // Optional: adds a semi-transparent black background
    },
    popup : {
        position: 'fixed' as 'fixed',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(128, 128, 128)', // Optional: adds a semi-transparent black background
     
        //backgroundColor: 'white',
     
    }
  };




  export const DiscountModal = ({ children }:any) => {
    return ReactDOM.createPortal(
      <div style={styles.overlay}>
        <div style={styles.popup}>
          {children}
        </div>
      </div>,
      document.body
    );
  };