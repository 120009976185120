import React from "react";
import { Link } from "react-router-dom";
import { programNameFromID, isoDateToMDY } from "../../../utils/common";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { buttonVariants } from "@/components/ui/button"
import { cn } from "@/lib/utils"

export const CalAimForms = ({calaimData, odrNumber}: any) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>ECM CalAIM Forms</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4 text-xs">
        {/* Form Links Grid */}
        <div className="grid gap-3">
          <div className="grid grid-cols-[10rem,1fr] items-center">
            <Link 
              to={`/documents/${odrNumber}`}
              className={cn(buttonVariants({ variant: "link" }), "text-xs justify-start p-0 text-blue-600 hover:text-white hover:bg-blue-800 px-2 rounded-md")}
            >
              Initial Assessment
            </Link>
            <span className="text-muted-foreground">
              {isoDateToMDY(calaimData.lastInitialAssessmentDate?.date) || "None"}
            </span>
          </div>

          <div className="grid grid-cols-[10rem,1fr] items-center">
            <Link 
              to={`/documents/${odrNumber}`}
              className={cn(buttonVariants({ variant: "link" }), "text-xs justify-start p-0 text-blue-600 hover:text-white hover:bg-blue-800 px-2 rounded-md")}
            >
              Care Plan
            </Link>
            <span className="text-muted-foreground">
              {isoDateToMDY(calaimData.lastLACAREScreeningDate?.date) || "None"}
            </span>
          </div>

          <div className="grid grid-cols-[10rem,1fr] items-center">
            <Link 
              to={`/documents/${odrNumber}`}
              className={cn(buttonVariants({ variant: "link" }), "text-xs justify-start p-0 text-blue-600 hover:text-white hover:bg-blue-800 px-2 rounded-md")}
            >
              Program Completion
            </Link>
            <span className="text-muted-foreground">
              {isoDateToMDY(calaimData.lastLACAREReferralDate?.date) || "None"}
            </span>
          </div>

          <Link 
            to={`/documents/${odrNumber}`}
            className={cn(buttonVariants({ variant: "link" }), "text-xs justify-start p-0 text-blue-600 hover:text-white hover:bg-blue-800 px-2 rounded-md")}
          >
            LA Care Forms
          </Link>

          <Link 
            to={`/CalAIM/outreach/${odrNumber}`}
            className={cn(buttonVariants({ variant: "link" }), "text-xs justify-start p-0 text-blue-600 hover:text-white hover:bg-blue-800 px-2 rounded-md")}
          >
            Client Outreach
          </Link>
        </div>
      </CardContent>
    </Card>
  )
}

