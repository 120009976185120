import { toast } from 'react-toastify';

export const createPhpSession = async (token: string) => {
    const response = await fetch('/app/components/auth/login/index.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `loginJWT=${token}`
    });

   if (!response.ok) {
       toast.error('Failed to create session');
       return;
   }

   const data = await response.json();
   
   if (data.success?.path) {
    window.location.href = '/menu.php';
       return;
   }

   toast.error(data.error?.message || 'Session creation failed');
};

