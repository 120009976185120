import React from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useUser } from '../../../store/useUser';
import { FormEditor } from '../lib/formEditor';

const LIST_FORM_TEMPLATES = gql`
  query listFormTemplates {
    listFormTemplates {
      id
      name
      body
    }
  }
`;

const UPDATE_FORM_TEMPLATE = gql`
  mutation updateFormTemplate($id: Integer!, $updates: FormTemplateInput!) {
    updateFormTemplate(id: $id, updates: $updates) {
      id
      name
      body
    }
  }
`;

const CREATE_FORM_TEMPLATE = gql`
  mutation createFormTemplate($updates: FormTemplateInput!) {
    createFormTemplate(updates: $updates) {
      id
      name
      body
    }
  }
`;

export const FormTemplates = () => {
  const [selectedTemplate, setSelectedTemplate] = React.useState(-1);
  const { loading, error, data, refetch } = useQuery(LIST_FORM_TEMPLATES);
  const [updateFormTemplateMutation] = useMutation(UPDATE_FORM_TEMPLATE);
  const [createFormTemplateMutation] = useMutation(CREATE_FORM_TEMPLATE);
  const { user } = useUser();
  
  const Templates = data ? data.listFormTemplates : [];

  const onSave = (formTemplate: any) => {
    if (selectedTemplate > -1) {
      updateFormTemplateMutation({
        variables: {
          id: Templates[selectedTemplate].id,
          updates: { body: JSON.stringify(formTemplate) }
        }
      });
    }
    refetch();
  };

  const onClose = () => {
    setSelectedTemplate(-1);
    refetch();
  };

  const newForm = async () => {
    await createFormTemplateMutation({
      variables: {
        updates: { name: 'New Form', body: JSON.stringify([]) }
      }
    });
    refetch();
  };

  if (loading) return (
    <div className="min-h-[200px] flex items-center justify-center bg-gray-50">
      <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-600 border-r-transparent align-[-0.125em]" />
    </div>
  );

  if (error) return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">Error Loading Data</h3>
          <div className="mt-2 text-sm text-red-700">{error.message}</div>
        </div>
      </div>
    </div>
  );

  if (selectedTemplate > -1 && Templates[selectedTemplate]) {
    const body = JSON.parse(Templates[selectedTemplate].body);
    const name = Templates[selectedTemplate].name;
    
    return (
      <FormEditor
        template={Templates[selectedTemplate]}
        onSave={onSave}
        name={name}
        formTemplate={body}
        onClose={onClose}
      />
    );
  }

  return (
    <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white shadow-sm rounded-lg">
        <div className="border-b border-gray-200 px-6 py-4">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold text-gray-900">Form Templates</h2>
            <button
              onClick={newForm}
              className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              New Template
            </button>
          </div>
        </div>
        
        <div className="px-6 py-4">
          <div className="space-y-2">
            {Templates.map((template: any, index: any) => (
              <div
                key={index}
                onClick={() => setSelectedTemplate(index)}
                className="flex items-center justify-between px-4 py-2 border rounded-md hover:bg-gray-50 cursor-pointer"
              >
                <span className="text-sm text-gray-600">{template.name}</span>
                <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            ))}
          </div>
          
          {Templates.length === 0 && (
            <div className="text-center py-12">
              <p className="text-sm text-gray-500">No templates found. Create your first template to get started.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormTemplates;