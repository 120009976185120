import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuthenticationState } from "../../store/useAuthenticationState";
import { gql, useMutation } from "@apollo/client";
import { DiscountModal } from "./AutoLogoutComponents/discountModal";
import { Loader } from "lucide-react";
import { createPhpSession } from "./createSession";
import { toast } from "react-toastify";
const LOGIN_AZURE_AD = gql`
  mutation LoginAzureAD($code: String!) {
    loginAzureAD(code: $code)
  }
`;

export const AzureADLogin = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loginAzureAD] = useMutation(LOGIN_AZURE_AD);
  const [isLoading, setIsLoading] = React.useState(false);
  const { setAuthenticationState } = useAuthenticationState();


  const doLogin = async (code:string) => {
    const {data} = await loginAzureAD({ variables: { code } })
    const token = data.loginAzureAD
    setAuthenticationState(token);
    createPhpSession(token);
  }

  React.useEffect(() => {
    setAuthenticationState(undefined);
  }, []);

  React.useEffect(() => {
    const code = searchParams.get('code');    
    const error = searchParams.get('error');
    if (code) {          
        doLogin(code)
        return 
    } 
    
    if (error) {
      toast.error(error);
      setTimeout(() => {
        navigate("/login.php");
      }, 3000);
      return 
    }

  }, [searchParams, loginAzureAD, navigate]);


  const handleAzureLogin = () => {
    setIsLoading(true);
    // Redirect to Azure AD login
    window.location.href = `https://login.microsoftonline.com/07597248-ea38-451b-8abe-a638eddbac81/oauth2/v2.0/authorize?
      client_id=06572a3f-f284-4a21-8b9e-fed62288abe1
      &response_type=code
      &redirect_uri=${encodeURIComponent("https://edge.lacountydiversion.org/loginAD")}
      &response_mode=query
      &scope=openid profile email
      &state=${Math.random().toString(36).substring(2, 15)}`;
  };

  return <></>
  
  return (
    <div className="min-h-screen flex items-center justify-center">
      <DiscountModal>
        <div className="w-full max-w-md space-y-8 bg-white p-8 rounded-lg shadow-md">
          {/* Logo */}
          <div className="flex justify-center">
            <img
              src={`${process.env.PUBLIC_URL}/logoODR.png`}
              alt="logo"
              className="h-20 w-auto"
            />
          </div>

          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">
              LA County Sign In
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Sign in with your LA County account
            </p>
          </div>

          <button
            onClick={handleAzureLogin}
            disabled={isLoading}
            className="w-full flex justify-center items-center gap-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? (
              <Loader className="h-5 w-5 animate-spin" />
            ) : (
              <>
                <img 
                  src={`${process.env.PUBLIC_URL}/ms-symbol.svg`} 
                  alt="Microsoft"
                  className="w-5 h-5"
                />
                Sign in with Microsoft
              </>
            )}
          </button>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  Need database login instead?
                </span>
              </div>
            </div>
            <div className="mt-6 text-center">
              <a
                href="/login"
                className="text-sm font-medium text-blue-600 hover:text-blue-500"
              >
                Switch to Database Login
              </a>
            </div>
          </div>
        </div>
      </DiscountModal>
    </div>
  );
};

export default AzureADLogin; 
