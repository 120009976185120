import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useMutation, useQuery, gql } from "@apollo/client";
import { format } from "date-fns";

const EDIT_CALAIM_NOTE = gql`
  mutation EditCalaimNote($noteId: Integer!, $note: AddCalaimNoteInput!) {
    editCalaimNote(noteId: $noteId, note: $note) {
      message
    }
  }
`;

// Query to fetch Place of Service options from database
const GET_POS_OPTIONS = gql`
  query GetCalaimPosOptions {
    calaimPosOptions {
      pos_code
      pos_name
    }
  }
`;

// Query to fetch Procedure Type options from database
const GET_PROCEDURE_TYPES = gql`
  query GetCalaimProcedureTypes {
    calaimProcedureTypes {
      value
      label
    }
  }
`;

export const EditCalAIM = ({
  isOpen,
  onClose,
  calaimData,
  user,
}: {
  isOpen: boolean;
  onClose: () => void;
  calaimData: any;
  user: any;
}) => {
  // Fetch Place of Service options
  const { data: posData, loading: posLoading } = useQuery(GET_POS_OPTIONS);
  
  // Fetch Procedure Type options
  const { data: procedureTypeData, loading: procedureTypeLoading } = useQuery(GET_PROCEDURE_TYPES);

  const [formData, setFormData] = useState({
    calaim_pos: "",
    proceduremod1: "",
    proceduremod2: "",
    proceduremod3: "",
    proceduretype: "",
    procedurelength: "",
    note_entry_date: "",
    procedurenote: "",
    procedureentery: "",
  });

  const [errors, setErrors] = useState<{[key: string]: string}>({});

  const [editCalaimNote, { loading: updateLoading }] = useMutation(EDIT_CALAIM_NOTE);

  useEffect(() => {
    if (calaimData) {
      setFormData({
        calaim_pos: calaimData.pos_code || "",
        proceduremod1: calaimData.proceduremod1 || "",
        proceduremod2: calaimData.proceduremod2 || "",
        proceduremod3: calaimData.proceduremod3 || "",
        proceduretype: calaimData.proceduretype || "",
        procedurelength: calaimData.procedurelength || "",
        note_entry_date: calaimData.note_entry_date || format(new Date(), "yyyy-MM-dd"),
        procedurenote: calaimData.procedurenote || "",
        procedureentery: calaimData.procedureentery || user?.fullname || "",
      });
    }
  }, [calaimData, user]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (name: string, value: string) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    const newErrors: {[key: string]: string} = {};

    if (formData.calaim_pos === "" || formData.calaim_pos === "0") {
      newErrors.calaim_pos = "Place of Service must be selected";
    }

    if (!formData.proceduremod1) {
      newErrors.proceduremod1 = "In Person/Telehealth must be selected";
    }

    if (!formData.proceduremod2) {
      newErrors.proceduremod2 = "Clinical/NonClinical must be selected";
    }

    if (!formData.proceduremod3) {
      newErrors.proceduremod3 = "Outreach/Care must be selected";
    }

    if (!formData.procedureentery) {
      newErrors.procedureentery = "Entered by must be filled";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      await editCalaimNote({
        variables: {
          noteId: parseInt(calaimData.id, 10),
          note: {
            pos_code: formData.calaim_pos,
            proceduremod1: formData.proceduremod1,
            proceduremod2: formData.proceduremod2, 
            proceduremod3: formData.proceduremod3,
            proceduretype: formData.proceduretype,
            procedurelength: formData.procedurelength,
            note_entry_date: formData.note_entry_date,
            procedurenote: formData.procedurenote,
            procedureentery: formData.procedureentery
          },
        },
      });

      onClose();
    } catch (error) {
      console.error("Error updating CalAIM note:", error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle className="text-center text-2xl font-bold">
            Edit CalAIM Note
          </DialogTitle>
        </DialogHeader>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="calaim_pos">Place of Service</Label>
              <Select 
                onValueChange={(value) => handleSelectChange("calaim_pos", value)}
                value={formData.calaim_pos}
                disabled={posLoading}
              >
                <SelectTrigger className={errors.calaim_pos ? "border-red-500" : ""}>
                  <SelectValue placeholder="Select place of service" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="0">-- Select an option --</SelectItem>
                  {posLoading ? (
                    <SelectItem value="loading">Loading...</SelectItem>
                  ) : (
                    posData?.calaimPosOptions?.map((option: { pos_code: string; pos_name: string }) => (
                      <SelectItem key={option.pos_code} value={option.pos_code}>
                        {option.pos_name}
                      </SelectItem>
                    ))
                  )}
                </SelectContent>
              </Select>
              {errors.calaim_pos && <p className="text-sm text-red-500">{errors.calaim_pos}</p>}
            </div>
            
            <div className="space-y-2">
              <Label htmlFor="note_entry_date">Note Date</Label>
              <Input
                type="date"
                id="note_entry_date"
                name="note_entry_date"
                value={formData.note_entry_date}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div className="space-y-2">
              <Label>In Person/Telehealth</Label>
              <RadioGroup 
                value={formData.proceduremod1} 
                onValueChange={(value) => handleSelectChange("proceduremod1", value)}
                className={errors.proceduremod1 ? "border border-red-500 rounded-md p-2" : ""}
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="1" id="inperson" />
                  <Label htmlFor="inperson">In Person</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="0" id="telehealth" />
                  <Label htmlFor="telehealth">Telehealth</Label>
                </div>
              </RadioGroup>
              {errors.proceduremod1 && <p className="text-sm text-red-500">{errors.proceduremod1}</p>}
            </div>

            <div className="space-y-2">
              <Label>Clinical/NonClinical</Label>
              <RadioGroup 
                value={formData.proceduremod2} 
                onValueChange={(value) => handleSelectChange("proceduremod2", value)}
                className={errors.proceduremod2 ? "border border-red-500 rounded-md p-2" : ""}
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="1" id="clinical" />
                  <Label htmlFor="clinical">Clinical</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="0" id="nonclinical" />
                  <Label htmlFor="nonclinical">NonClinical</Label>
                </div>
              </RadioGroup>
              {errors.proceduremod2 && <p className="text-sm text-red-500">{errors.proceduremod2}</p>}
            </div>

            <div className="space-y-2">
              <Label>Outreach/Care</Label>
              <RadioGroup 
                value={formData.proceduremod3} 
                onValueChange={(value) => handleSelectChange("proceduremod3", value)}
                className={errors.proceduremod3 ? "border border-red-500 rounded-md p-2" : ""}
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="0" id="outreach" />
                  <Label htmlFor="outreach">Outreach</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="1" id="care" />
                  <Label htmlFor="care">Care</Label>
                </div>
              </RadioGroup>
              {errors.proceduremod3 && <p className="text-sm text-red-500">{errors.proceduremod3}</p>}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label>Procedure Type</Label>
              <Select
                onValueChange={(value) => handleSelectChange("proceduretype", value)}
                value={formData.proceduretype}
                disabled={procedureTypeLoading}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select procedure type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="none">-- Select an option --</SelectItem>
                  {procedureTypeLoading ? (
                    <SelectItem value="loading">Loading...</SelectItem>
                  ) : (
                    procedureTypeData?.calaimProcedureTypes?.map((option: { value: string; label: string }) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))
                  )}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label>Procedure Length</Label>
              <Select
                onValueChange={(value) => handleSelectChange("procedurelength", value)}
                value={formData.procedurelength}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select procedure length" />
                </SelectTrigger>
                <SelectContent>
                  {PROCEDURE_LENGTH_OPTIONS.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="procedurenote">Procedure Note</Label>
            <Textarea
              id="procedurenote"
              name="procedurenote"
              value={formData.procedurenote}
              onChange={handleInputChange}
              placeholder="Enter your note here"
              className="min-h-[100px]"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="procedureentery">Entered By</Label>
            <Input
              id="procedureentery"
              name="procedureentery"
              value={formData.procedureentery}
              onChange={handleInputChange}
              className={errors.procedureentery ? "border-red-500" : ""}
              required
            />
            {errors.procedureentery && <p className="text-sm text-red-500">{errors.procedureentery}</p>}
          </div>

          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={onClose} type="button">
              Cancel
            </Button>
            <Button type="submit" disabled={updateLoading}>
              {updateLoading ? "Saving..." : "Update Note"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

// These match the procedure lengths from the PHP file
const PROCEDURE_LENGTH_OPTIONS = [
  { value: "15", label: "15 mins" },
  { value: "30", label: "30 mins" },
  { value: "45", label: "45 mins" },
  { value: "60", label: "60 mins" },
  { value: "75", label: "1hour 15mins" },
  { value: "90", label: "1hour 30mins" },
  { value: "105", label: "1hour 45mins" },
  { value: "120", label: "2hours" },
  { value: "150", label: "2hours 30mins" },
  { value: "180", label: "3hours" },
  { value: "210", label: "3hours 30mins" },
  { value: "240", label: "4hours" }
];
