
const SERVER = "http://localhost:9000"
const SERVERWS = "ws:///localhost:9000"

//********************************************************************************************
//DO NOT CHANGE ANYTHING BELOW THIS LINE ON YOUR DEV BOX UNLESS YOU ARE 100% SURE WHAT YOU ARE DOING.
//********************************************************************************************
export const server = {
  http: `https://api.lacountydiversion.org/ql`,
  ws: `wss://api.lacountydiversion.org/ws`,
}
//********************************************************************************************

export const TIMEOUT_PERIODS = {
  MODALTIMEOUT: 30,  // 5 minutes (300 seconds) This is the time the modal will stay open before the user is logged out
  SESSION: 14* 60 * 1000,      // 14 minutes (840 seconds) This is the time the user will be logged out if they are inactive
  ACTIVITYTIME: 10 * 60 * 1000  // 10 minutes (600 seconds) If the user has been active in the past X minutes, auto refresh the token 
};

 