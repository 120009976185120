import React, { useState, useEffect, useCallback } from "react";
import { TIMEOUT_PERIODS } from "../../../settings";
import { Lock } from "lucide-react";

const formatTime = (seconds: number) => {
  if (seconds > 60) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} min ${remainingSeconds} sec`;
  }
  return `${seconds} sec`;
};

export const WarningControl = ({
  onReset,
  lock,
}: {
  onReset: () => void;
  lock: () => void;
}) => {
  const [redirectTimer, setRedirectTimer] = useState(
    TIMEOUT_PERIODS.MODALTIMEOUT
  );

  const startCountdown = useCallback(() => {
    const interval = setInterval(() => {
      setRedirectTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          lock();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return interval;
  }, [lock]);

  useEffect(() => {
    const interval = startCountdown();
    return () => clearInterval(interval);
  }, [startCountdown]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg">
        <img
          src={`${process.env.PUBLIC_URL}/logoODR.png`}
          alt="logo"
          className="w-48 mx-auto"
        />
        
        <div className="text-center space-y-4">
          <div className="flex items-center justify-center space-x-2">
            <Lock className="w-5 h-5 text-gray-600" />
            <h2 className="text-xl font-semibold text-gray-900">Session Locking</h2>
          </div>
          
          <p className="text-gray-600">
            Your screen will be locked in {formatTime(redirectTimer)}
          </p>
          
          <button
            onClick={onReset}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Unlock
          </button>
        </div>
      </div>
    </div>
  );
};
