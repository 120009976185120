import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { programNameFromID, isoDateToMDY } from "../../../utils/common";
import {
  User,
  Calendar,
  HashIcon,
  Fingerprint,
  Phone,
  Scan,
  Building,
  ChevronUp,
  ChevronDown,
  MoreHorizontal,
  FileText,
  Stethoscope,
  Shield,
  CreditCard,
} from "lucide-react";
import { HistorySection } from "../components/HistorySection";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { CandidateModal } from "../CandidatesData";

const sectionCategories = {
  identification: [
    "Name",
    "Gender",
    "Date of Birth",
    "ECM Address",
    "ECM Phone Number",
    "Address",
    "SSN",
    "Race"
  ],
  legalIdentifiers: [
    "ChampID",
    "MRUN",
    "CIN",
    "CII",
    "IS Number",
    "CHS Fin Number",
    "Main Number",
    "WPC Number",
    "RSO Status",
   // "JCI",
    "Booking Number",
  ],
 /* programInfo: [
    "Disposition",
    "Pathway",
    "Provider",
    "Probation",
    "AB109 Status",
  ],*/
  dates: [
    "Arrest Date",
    "Commitment Date",
    "Max Date",
    "Release Date",
  ],
  /*medical: [
    "DSM-5 Diagnosis",
    "Medical Problems",
    "Current Medications",
    "Other Medications",
    "Psychotropic Medications",
    "Psychiatric History",
    "Clinical Presentation",
    "Treatment Adherence",
    "Acuity",
    "ECM Determination",
    "IST Status",
    "Medical Demographics Notes",
  ],*/
};

const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
    race
    ssn
      ssnHistory {
        value
        program
        entrydate
      }
      pcodeHistory {
        date
        pcode
      }
      candidatesData {
        id
        firstName
        lastName
      }
      address {
        address
        city
        state
        zip       
      }
      addressHistory {
        address
        city
        state
        zip       
      }
      scans {
        id
        filename
        filetype
        filelabel
        filedate
      }
      nameHistory {
        lastName
        firstName
        program
        entrydate
      }
      ecmContacts {
        phoneNumber
        address
      }
      ecmContactsHistory {
        phoneNumber
        address
      }
      name {
        firstName
        lastName
      }
      dob
      dobHistory {
        value
        program
        entrydate
      }
      programs
      mrun      
      champid
      champidHistory {
        value
        program
        entrydate
      }
      gender
      genderHistory {
        value
        program
        entrydate
      }
      mrunHistory {
        value
        program
        entrydate
      }
      isNumber
      isNumberHistory {
        value
        program
        entrydate
      }
      disposition{
        disposition
        dispositionDate
        program
      }

      dispositionHistory {
        disposition
        dispositionDate
        program
      }     
      pathwayHistory {
        value
        program
        entrydate
      }
      providerHistory {
        value
        program
        entrydate
      }
      releasedDate
      releasedDateHistory {
        value
        program
      }
      jcitHistory {
        value
        program
        entrydate
      }
      probation
      probationHistory {
        value
        program
        entrydate
      }
      programDetails {
        programDescription
        programParticipantNumber
        entryDate
        exitDate                
        disposition
        provider
        pathway
        releaseDate
        probation
      } 
      fin
      finHistory {
        value
        program
        entrydate
      }
      
      rso
      rsoHistory {
        value
        program
        entrydate
      }
      cin
      cinHistory {
        value
        program
        entrydate
      }
      cii
      ciiHistory {
        value
        program
        entrydate
      }
      ab109
      ab109History {
        value
        program
        entrydate
      }
      acuity
      acuityHistory {
        value
        program
        entrydate
      }
      adherence
      adherenceHistory {
        value
        program
        entrydate
      }
      arrestDateHistory {
        value
        program
        entrydate
      }
      clinicalPresentation
      clinicalPresentationHistory {
        value
        program
        entrydate
      }
      currentMeds
      currentMedsHistory {
        value
        program
        entrydate
      }
      medicalDemographicsNotes
      medicalDemographicsNotesHistory {
        value
        program
        entrydate
      }
      dsm5dx
      dsm5dxHistory {
        value
        program
        entrydate
      }
      ecmDetermination
      ecmDeterminationHistory {
        value
        program
        entrydate
      }
      ist
      istHistory {
        value
        program
        entrydate
      }
      medicalProblemsHistory {
        value
        program
        entrydate
      }
      psychotropicMedsHistory {
        value
        program
        entrydate
      }
      otherMedsHistory {
        value
        program
        entrydate
      }
      psychHistoryHistory {
        value
        program
        entrydate
      }
      commitmentDateHistory {
        value
        program
        entrydate
      }
      maxDateHistory {
        value
        program
        entrydate
      }
      wpcHistory {
        value
        program
        entrydate
      }
      mainNumberHistory {
        value
        program
        entrydate
      }
    }
  }
`;

export const ParticipantDataSectionFull = ({
  odrnumber,      
}: {
  odrnumber: any;  
  
}) => {
  /* const { loading, error, data } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
    variables: { odrnumber },
    skip: !odrnumber,
  });*/

  const [displayCandidate, setDisplayCandidate] = useState(false);
  const { loading, error, data } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
    variables: { odrnumber },
    skip: !odrnumber,
  });
 
  if (loading)
    return (
      <div className="min-h-[200px] flex items-center justify-center bg-gray-50 rounded-lg">
        <div className="text-center">
          <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-600 border-r-transparent align-[-0.125em]"></div>
          <div className="mt-4 text-sm font-medium text-gray-600">
            Loading participant data...
          </div>
        </div>
      </div>
    );

  if (error)
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-red-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              Error Loading Data
            </h3>
            <div className="mt-2 text-sm text-red-700">
              Unable to load participant data. Please try again.
            </div>
          </div>
        </div>
      </div>
    );

  const participantData = data.getParticipantByODRNumber;

  const historySections = [
    // Basic Identification
    {
      title: "Name",
      icon: <User className="h-5 w-5 text-gray-400" />,
      data: participantData.nameHistory,
      maxCollapsed: 1,
      columns: 3,
      showDates: true,
      renderCollapsed: (item: any) => `${participantData.name.lastName}, ${participantData.name.firstName}`,
      renderExpanded: (item: any) =>
        <div className="grid grid-cols-[8rem,5rem,auto]  items-left">
          <span className="text-gray-900 text-nowrap items-left">{item.lastName} - {item.firstName}</span>          
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>          
          <span className="text-gray-400 text-xs text-nowrap">{item.program == 'prg_wmn' ? 'prgwmn' : item.program}</span>                    
        </div>,
    },
    {
      title: "Gender",
      icon: <Calendar className="h-5 w-5 text-gray-400" />,
      data: participantData.genderHistory,
      maxCollapsed: 1,
      mostRecent: participantData.gender,
      showDates: true,
      renderCollapsed: (item: any) => participantData.gender,
      renderExpanded: (item: any) => 
        <div className="grid grid-cols-[8rem,6rem,auto]  items-left">
          <span className="text-gray-900 items-left">{item.value}</span>          
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">{item.program == 'prg_wmn' ? 'prgwmn' : item.program}</span>          
          
        </div>,
    },
    {
      title: "Date of Birth",
      icon: <Calendar className="h-5 w-5 text-gray-400" />,
      data: participantData.dobHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => isoDateToMDY(participantData.dob),
      renderExpanded: (item: any) =>
        <div className="grid grid-cols-[8rem,6rem,auto]  items-left">
          <span className="text-gray-900 items-left">{isoDateToMDY(item.value)}</span>          
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">{item.program == 'prg_wmn' ? 'prgwmn' : item.program}</span>          
          
        </div>,
    },

    // Legal/Criminal Justice Info
    {
      title: "Arrest Date",
      icon: <Calendar className="h-5 w-5 text-gray-400" />,
      data: participantData.arrestDateHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => isoDateToMDY(item.value),
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[8rem,6rem,auto] items-left">
          <span className="text-gray-900 items-left">{isoDateToMDY(item.value)}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Booking Number",
      icon: <HashIcon className="h-5 w-5 text-gray-400" />,
      data: participantData.bookingHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[8rem,6rem,auto] items-left">
          <span className="text-gray-900 items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Race",
      icon: <HashIcon className="h-5 w-5 text-gray-400" />,
      data: [participantData.race],
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[8rem,6rem,auto] items-left">
          <span className="text-gray-900 items-left">{item}</span>      
        </div>
      ),
    },

    // ChampID, MRUN
    {
      title: "ChampID",
      icon: <HashIcon className="h-5 w-5 text-gray-400" />,
      data: participantData.champidHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => String(item.value), // Convert number to string
      renderExpanded: (item: any) => <div className="grid grid-cols-[8rem,6rem,auto]  items-left">
        <span className="text-gray-900 items-left">{String(item.value)}</span>          
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">{item.program == 'prg_wmn' ? 'prgwmn' : item.program}</span>          
          
        </div>, // Convert number to string
    },
    {
      title: "MRUN",
      icon: <Fingerprint className="h-5 w-5 text-gray-400" />,
      data: participantData.mrunHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => data.mrun,
      renderExpanded: (item: any) => <div className="grid grid-cols-[8rem,6rem,auto]  items-left">
        <span className="text-gray-900 items-left">{String(item.value)}</span>          
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">{item.program == 'prg_wmn' ? 'prgwmn' : item.program}</span>          
          
        </div>,
    },

    // Disposition, Pathway
    {
      title: "Disposition",
      icon: <HashIcon className="h-5 w-5 text-gray-400" />,
      data: participantData.dispositionHistory,
      
      renderCollapsed: (item: any) => participantData.disposition?.disposition,
      renderExpanded: (item: any) => <div className="grid grid-cols-[10rem,4.5rem,auto]  items-left">        
          <span className="text-gray-900 text-sm items-left">{item.disposition}</span>                    
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.dispositionDate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">{item.program == 'prg_wmn' ? 'prgwmn' : item.program}</span>          
        </div>,      
      maxCollapsed: 1,
      formatDate: isoDateToMDY,
    },

    {
      title: "Pathway",
      icon: <Fingerprint className="h-5 w-5 text-gray-400" />,
      data: participantData.pathwayHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => <div className="grid grid-cols-[10rem,4.5rem,auto]  items-left">        
          <span className="text-gray-900 text-sm items-left">{item.value}</span>                    
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">{item.program == 'prg_wmn' ? 'prgwmn' : item.program}</span>          
        </div>,
    },
    
    {
      title: "Provider",
      icon: <Fingerprint className="h-5 w-5 text-gray-400" />,
      data: participantData.providerHistory,
      columns: 2,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => <div className="grid grid-cols-[10rem,4.5rem,auto]  items-left">        
          <span className="text-gray-900 text-sm items-left">{item.value}</span>                    
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">{item.program == 'prg_wmn' ? 'prgwmn' : item.program}</span>          
        </div>,      
      maxCollapsed: 1,
      formatDate: isoDateToMDY,
    },

    {
      title: "IS Number",
      icon: <Fingerprint className="h-5 w-5 text-gray-400" />,
      data: participantData.isNumberHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => <div className="grid grid-cols-[10rem,4.5rem,auto]  items-left">        
          <span className="text-gray-900 text-sm items-left">{item.value}</span>                    
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">{item.program == 'prg_wmn' ? 'prgwmn' : item.program}</span>          
        </div>,
    },
    {
      title: "CHS Fin Number",
      icon: <Fingerprint className="h-5 w-5 text-gray-400" />,
      data: participantData.finHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => participantData.fin,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "SSN",
      icon: <CreditCard className="h-5 w-5 text-gray-400" />,
      data: participantData.ssnHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => participantData.ssn,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "CIN",
      icon: <HashIcon className="h-5 w-5 text-gray-400" />,
      data: participantData.cinHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => participantData.cin,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "CII",
      icon: <HashIcon className="h-5 w-5 text-gray-400" />,
      data: participantData.ciiHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => participantData.cii,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "RSO Status",
      icon: <Shield className="h-5 w-5 text-gray-400" />,
      data: participantData.rsoHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => participantData.rso || "Not Registered",
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "JCI",
      icon: <Fingerprint className="h-5 w-5 text-gray-400" />,
      data: participantData.jcitHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => <div className="grid grid-cols-[10rem,4.5rem,auto]  items-left">        
          <span className="text-gray-900 text-sm items-left">{item.value}</span>                    
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">{item.program == 'prg_wmn' ? 'prgwmn' : item.program}</span>          
        </div>,
    },
    { title: 'Probation',
      icon: <Fingerprint className="h-5 w-5 text-gray-400" />,
      data: participantData.probationHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => <div className="grid grid-cols-[10rem,4.5rem,auto]  items-left">        
          <span className="text-gray-900 text-sm items-left">{item.value}</span>                    
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">{item.program == 'prg_wmn' ? 'prgwmn' : item.program}</span>          
        </div>,
    },
    {
      title: "Address",
      icon: <Building className="h-5 w-5 text-gray-400" />,
      data: participantData.addressHistory,
      maxCollapsed: 1,
      renderCollapsed: () => {
        const current = participantData.address;
        return current ? 
          `${current.address}, ${current.city}, ${current.state} ${current.zip}` :
          "No current address";
      },
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[1fr] gap-1">
          <span className="text-gray-900">{item.address}</span>
          <span className="text-gray-600">
            {item.city}, {item.state} {item.zip}
          </span>
        </div>
      ),
    },
    {
      title: "ECM Address",
      icon: <Building className="h-5 w-5 text-gray-400" />,
      data: participantData.ecmContactsHistory,
      maxCollapsed: 1,
      renderCollapsed: () => {
        const current = participantData.ecmContacts.address;
        return current ? 
          `${current}` :
          "No current address";
      },
      renderExpanded: (item: any) => (
          <div className="grid grid-cols-[1fr] gap-1">
          <span className="text-gray-900">{item.address}</span>
        </div>
      ),
    },
    {
      title: "ECM Phone Number",
      icon: <Phone className="h-5 w-5 text-gray-400" />,
      data: participantData.ecmContactsHistory,
      maxCollapsed: 1,
      renderCollapsed: () => {
        const current = participantData.ecmContacts.phoneNumber;
        return current ? 
          `${current} ` :
          "No current address";
      },
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[1fr] gap-1">
          <span className="text-gray-900">{item.phoneNumber}</span>
        </div>
      ),
    },

    {
      title: "DSM-5 Diagnosis",
      icon: <Stethoscope className="h-5 w-5 text-gray-400" />,
      data: participantData.dsm5dxHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value || "No diagnosis recorded",
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "AB109 Status",
      icon: <Shield className="h-5 w-5 text-gray-400" />,
      data: participantData.ab109History,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Acuity",
      icon: <Stethoscope className="h-5 w-5 text-gray-400" />,
      data: participantData.acuityHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Treatment Adherence",
      icon: <Stethoscope className="h-5 w-5 text-gray-400" />,
      data: participantData.adherenceHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Clinical Presentation",
      icon: <Stethoscope className="h-5 w-5 text-gray-400" />,
      data: participantData.clinicalPresentationHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Current Medications",
      icon: <Stethoscope className="h-5 w-5 text-gray-400" />,
      data: participantData.currentMedsHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "ECM Determination",
      icon: <FileText className="h-5 w-5 text-gray-400" />,
      data: participantData.ecmDeterminationHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "IST Status",
      icon: <Shield className="h-5 w-5 text-gray-400" />,
      data: participantData.istHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Medical Problems",
      icon: <Stethoscope className="h-5 w-5 text-gray-400" />,
      data: participantData.medicalProblemsHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Psychotropic Medications",
      icon: <Stethoscope className="h-5 w-5 text-gray-400" />,
      data: participantData.psychotropicMedsHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Other Medications",
      icon: <Stethoscope className="h-5 w-5 text-gray-400" />,
      data: participantData.otherMedsHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Psychiatric History",
      icon: <Stethoscope className="h-5 w-5 text-gray-400" />,
      data: participantData.psychHistoryHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Commitment Date",
      icon: <Calendar className="h-5 w-5 text-gray-400" />,
      data: participantData.commitmentDateHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => isoDateToMDY(item.value),
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{isoDateToMDY(item.value)}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Max Date",
      icon: <Calendar className="h-5 w-5 text-gray-400" />,
      data: participantData.maxDateHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => isoDateToMDY(item.value),
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{isoDateToMDY(item.value)}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "WPC Number",
      icon: <HashIcon className="h-5 w-5 text-gray-400" />,
      data: participantData.wpcHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Main Number",
      icon: <HashIcon className="h-5 w-5 text-gray-400" />,
      data: participantData.mainNumberHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => item.value,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{item.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(item.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    },
    {
      title: "Medical Demographics Notes",
      icon: <HashIcon className="h-5 w-5 text-gray-400" />,
      data: participantData.medicalDemographicsNotesHistory,
      maxCollapsed: 1,
      renderCollapsed: (item: any) => participantData.medicalDemographicsNotes,
      renderExpanded: (item: any) => (
        <div className="grid grid-cols-[10rem,4.5rem,auto] items-left">
          <span className="text-gray-900 text-sm items-left">{participantData.medicalDemographicsNotes.value}</span>
          <span className="text-gray-400 text-xs">{isoDateToMDY(participantData.medicalDemographicsNotes.entrydate)}</span>
          <span className="text-gray-400 text-xs text-nowrap">
            {item.program == 'prg_wmn' ? 'prgwmn' : item.program}
          </span>
        </div>
      ),
    }
  ];

  // Group the sections by category
 

  return (
    <div className="flex flex-col h-[85vh]">
      <div className="flex justify-between items-center mb-4">
        <p className="text-sm text-gray-600">ODR Number: {odrnumber}</p>
        <Link to={`/documents/${odrnumber}`}>
          <Button 
            variant="outline"
            size="sm"
            className="flex items-center gap-1 text-xs"
          >
            <FileText className="h-3 w-3" />
            Documents
          </Button>
        </Link>
      </div>

      <div className="flex-1 overflow-y-auto rounded-lg border border-gray-200 shadow">
        <div className="px-4 py-5 sm:p-8">
          {Object.entries(sectionCategories).map(([category, titles], index) => (
            <div key={category}>
              <div className="mb-8">
                {index > 0 && (
                  <div className="mb-8 border-t border-gray-200" />
                )}
                
                <h2 className="text-lg font-semibold text-gray-900 mb-4 capitalize">
                  {category.replace(/([A-Z])/g, ' $1').trim()}
                </h2>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-7">
                  {historySections
                    .filter(section => titles.includes(section.title))
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((section) => (
                      <HistorySection key={section.title} {...section} />
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {displayCandidate && <CandidateModal isOpen={displayCandidate} onClose={()=>{setDisplayCandidate(false)}} odrNumber={odrnumber} />}
    </div>
  );
};
