import React, { useState } from 'react';
import Modal from 'react-modal';
import { AlertTriangle } from 'lucide-react';

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  username: string;
}

export const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  username
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleConfirm = async () => {
    try {
      setIsDeleting(true);
      await onConfirm();
    //   toast.success('User deleted successfully');
    } catch (error) {
      console.error('Failed to delete user:', error);
    //   toast.error('Failed to delete user');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-[400px]"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="space-y-4">
        {/* Header */}
        <div className="flex items-center space-x-2 text-red-600">
          <AlertTriangle className="h-6 w-6" />
          <h3 className="text-lg font-medium">Confirm Delete</h3>
        </div>

        {/* Warning Message */}
        <div className="space-y-2">
          <p className="text-gray-600">
            Are you sure you want to delete user <span className="font-semibold">{username}</span>?
          </p>
          <p className="text-sm text-red-500">
            This action cannot be undone. All user data will be permanently deleted.
          </p>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-2 pt-4">
          <button
            onClick={onClose}
            disabled={isDeleting}
            className="px-4 py-2 border rounded-md hover:bg-gray-50 disabled:opacity-50"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            disabled={isDeleting}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 disabled:opacity-50 flex items-center space-x-2"
          >
            {isDeleting ? (
              <>
                <span className="inline-block animate-spin rounded-full h-4 w-4 border-b-2 border-white"></span>
                <span>Deleting...</span>
              </>
            ) : (
              <span>Delete User</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;