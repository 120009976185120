import React, { useState } from 'react';
import { Search, X, SlidersHorizontal } from 'lucide-react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
	DropdownMenuCheckboxItem,
} from "@/components/ui/dropdown-menu";

interface FilterState {
	showDisabled: boolean;
	showLocked: boolean;
	selectedGroups: string[];
	hasTwoFactor: boolean | null;
	sortBy: string;
	sortDirection: 'asc' | 'desc';
}

interface SearchAndFiltersProps {
	onSearch: (value: string) => void;
	filterState: FilterState;
	onFilterChange: (updates: Partial<FilterState>) => void;
	availableGroups: string[];
	totalCount: number;
	filteredCount: number;
	loading: boolean;
}

export const SearchAndFilters: React.FC<SearchAndFiltersProps> = ({
	onSearch,
	filterState,
	onFilterChange,
	availableGroups,
	totalCount,
	filteredCount,
	loading
}) => {
	const [searchInput, setSearchInput] = useState('');

	const handleSearch = (e: React.FormEvent) => {
		e.preventDefault();
		onSearch(searchInput);
	};

	return (
		<div className="space-y-4">
			{/* Search and Counter Row */}
			<div className="flex justify-between items-center gap-4">
				<form onSubmit={handleSearch} className="flex-1 max-w-md flex gap-2">
					<div className="relative">
						{searchInput.length > 0 ? <></> : <div className="absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none">
							<Search className="h-5 w-5 text-gray-400" />
						</div>}
						<input
							type="text"
							value={searchInput}
							onChange={(e) => setSearchInput(e.target.value)}
							placeholder="Search users (min 3 characters)..."
							className={searchInput.length > 0 ? "pl-10 pr-3 py-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" : "pl-10 pr-3 py-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-left"} // Added text-right and reduced pr padding
						/>
						{searchInput && (
							<button
								type="button"
								onClick={() => {
									setSearchInput('');
									onSearch('');
								}}
								className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
							>
								<X className="h-5 w-5" />
							</button>
						)}
					</div>
					<button
						type="submit"
						disabled={loading || searchInput.length < 3}
						className={`px-4 py-2 rounded-md text-white ${loading || searchInput.length < 3
								? 'bg-gray-400 cursor-not-allowed'
								: 'bg-blue-500 hover:bg-blue-600'
							}`}
					>
						{loading ? 'Searching...' : 'Search'}
					</button>
				</form>

				<div className="text-sm text-gray-500">
					Showing {filteredCount} of {totalCount} users
				</div>
			</div>

			{/* Filters Row */}
			<div className="flex items-center space-x-4">
				{/* Status Filter */}
				<DropdownMenu>
					<DropdownMenuTrigger className="flex items-center px-3 py-2 text-sm border rounded-md hover:bg-gray-50">
						<SlidersHorizontal className="h-4 w-4 mr-2" />
						Filters
					</DropdownMenuTrigger>
					<DropdownMenuContent className="w-56">
						<DropdownMenuLabel>User Status</DropdownMenuLabel>
						<DropdownMenuSeparator />
						<DropdownMenuCheckboxItem
							checked={filterState.showDisabled}
							onCheckedChange={(checked) => onFilterChange({ showDisabled: checked })}
						>
							Show Disabled Users
						</DropdownMenuCheckboxItem>
						<DropdownMenuCheckboxItem
							checked={filterState.showLocked}
							onCheckedChange={(checked) => onFilterChange({ showLocked: checked })}
						>
							Show Locked Users
						</DropdownMenuCheckboxItem>

						<DropdownMenuSeparator />
						<DropdownMenuLabel>Two-Factor Auth</DropdownMenuLabel>
						<DropdownMenuCheckboxItem
							checked={filterState.hasTwoFactor === true}
							onCheckedChange={(checked) => onFilterChange({ hasTwoFactor: checked ? true : null })}
						>
							2FA Enabled
						</DropdownMenuCheckboxItem>
						<DropdownMenuCheckboxItem
							checked={filterState.hasTwoFactor === false}
							onCheckedChange={(checked) => onFilterChange({ hasTwoFactor: checked ? false : null })}
						>
							2FA Disabled
						</DropdownMenuCheckboxItem>
					</DropdownMenuContent>
				</DropdownMenu>

				{/* Groups Filter */}
				<Select
					value={filterState.selectedGroups.length === 0 ? "all" : filterState.selectedGroups.join(",")}
					onValueChange={(value) => {
						if (value === "all") {
							onFilterChange({ selectedGroups: [] });
						} else {
							onFilterChange({ selectedGroups: value.split(",") });
						}
					}}
				>
					<SelectTrigger className="w-[180px] capitalize">
						<SelectValue placeholder="Select Groups" />
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="all">All Roles</SelectItem>
						{availableGroups.map((group) => (
							<SelectItem key={group} value={group} className='capitalize'>
								{group}
							</SelectItem>
						))}
					</SelectContent>
				</Select>

				{/* Sort Options */}
				<Select
					value={`${filterState.sortBy}-${filterState.sortDirection}`}
					onValueChange={(value) => {
						const [field, direction] = value.split('-');
						onFilterChange({
							sortBy: field,
							sortDirection: direction as 'asc' | 'desc'
						});
					}}
				>
					<SelectTrigger className="w-[180px]">
						<SelectValue placeholder="Sort by" />
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="username-asc">Username (A-Z)</SelectItem>
						<SelectItem value="username-desc">Username (Z-A)</SelectItem>
						<SelectItem value="lastlogin-desc">Last Login (Recent)</SelectItem>
						<SelectItem value="lastlogin-asc">Last Login (Oldest)</SelectItem>
						<SelectItem value="warnCount-desc">Warning Count (High)</SelectItem>
						<SelectItem value="warnCount-asc">Warning Count (Low)</SelectItem>
					</SelectContent>
				</Select>

				{/* Reset Filters Button */}
				{(filterState.showDisabled ||
					filterState.showLocked ||
					filterState.selectedGroups.length > 0 ||
					filterState.hasTwoFactor !== null) && (
						<button
							onClick={() => {
								onFilterChange({
									showDisabled: false,
									showLocked: false,
									selectedGroups: [],
									hasTwoFactor: null,
									sortBy: 'username',
									sortDirection: 'asc'
								});
								setSearchInput('');
								onSearch('');
							}}
							className="px-3 py-2 text-sm text-blue-600 hover:text-blue-800"
						>
							Reset Filters
						</button>
					)}
			</div>
		</div>
	);
};

export default SearchAndFilters;