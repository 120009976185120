import { gql } from '@apollo/client';

export const LIST_USERS = gql`
  query ListUsers($filters: FilterOptions!) {
    listUsers(filters: $filters) {
      entries {
        id
        username
        name
        email
        disabled
        locked
        lastlogin
        lastpasswordchange
        groups
        permissions {
          permission
          enabled
        }
        role
        lastWarnDate
        warnCount
        twofactorEnabled
        passwordfailures
        firstlogin
      }
      totalCount
	  availableGroups
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query GetUserDetails($usernames: [String]!) {
    getUserInformation(usernames: $usernames) {
      entries {
        username
        name
        email
        disabled
        locked
        lastlogin
        lastpasswordchange
        groups
        permissions
        role
        lastWarnDate
        warnCount
        twofactorEnabled
        twofactorCode
        passwordfailures
        firstlogin
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($username: String!, $password: String!) {
    resetPassword(username: $username, password: $password) {      
      message
    }
  }
`;

export const ENABLE_ACCOUNT = gql`
  mutation EnableAccount($username: String!) {
    enableAccount(username: $username) {      
      message
    }
  }
`;

export const MODIFY_USER = gql`
  mutation ModifyUser(
    $username: String!, 
    $disabled: Boolean,
    $email: String,
    $realname: String,
    $password: String,    
    $permissions: [UserPermissionInput],
    $role: String
	$twoFactorMethod: String
  ) {
    modifyUser(
      username: $username,
      disabled: $disabled,
      email: $email,
      realname: $realname,
      password: $password,      
      permissions: $permissions,
      role: $role
	  twoFactorMethod: $twoFactorMethod
    ) {      
      message
    }
  }
`; 

export const UPDATE_PERMISSIONS = gql`
  mutation UpdatePermissions($username: String!, $permissions: [String!]!) {
    updatePermissions(username: $username, permissions: $permissions) {
      status
      message
    }
  }
`;

export const DELETE_USER = gql`
mutation DeleteUser($username: String!) {
  deleteUser(username: $username) {
    status
    message
  }
}
`;