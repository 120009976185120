import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useUser } from '../../store/useUser';
import { useHelpTopics } from '../../store/useHelpTopics';
import { Search, User, AlertCircle } from 'lucide-react';

const SEARCH_PARTICIPANTS = gql`
  query searchParticipants($search: String!) {
    searchParticipants(search: $search) {
      id
      nameHistory {
        firstName
        lastName
      }
      programs
      gender
      mrun
      fin
      champid
    }
  }
`;

const PROGRAM_CONFIG = {
  MIST: { max: 20000000, classes: 'bg-blue-100 text-blue-800' },
  ODR: { max: 30000000, classes: 'bg-green-100 text-green-800' },
  PRWM: { max: 40000000, classes: 'bg-purple-100 text-purple-800' },
  FIST: { max: 60000000, classes: 'bg-orange-100 text-orange-800' },
  DSH: { max: 70000000, classes: 'bg-red-100 text-red-800' },
  LEAD: { max: 80000000, classes: 'bg-yellow-100 text-yellow-800' },
};


export const settingSearch = {
  withoutSearch: true,
}

const ProgramBadge = ({ id }: { id: string }) => {
  const numericId = parseInt(id);
  
  for (const [program, { max, classes }] of Object.entries(PROGRAM_CONFIG)) {
    if (numericId < max) {
      return (
        <div className="flex items-center gap-2">
          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${classes}`}>
            {program}
          </span>
          <a 
            href={`/${program.toLowerCase()}/show_full.php?participant=${id}`}
            className="text-blue-600 hover:text-blue-800 text-sm"
          >
            {id}
          </a>
        </div>
      );
    }
  }
  return null;
};

const LoadingSpinner = () => (
  <div className="min-h-[400px] flex items-center justify-center">
    <div className="text-center">
      <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-600 border-r-transparent align-[-0.125em]" />
      <div className="mt-4 text-sm font-medium text-gray-600">Loading results...</div>
    </div>
  </div>
);

export const SearchParticipants = ({ Close = () => {} }) => {
  const navigate = useNavigate();
  const { user, isUserLoading } = useUser();
  const { pushHelpTopic, popHelpTopic } = useHelpTopics();
  
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  
  const cookieSearchBoxState = JSON.parse(localStorage.getItem('searchBoxState') || '{}');
  const defaultCheckboxStates = {
    genderChecked: true,
    mrunChecked: true,
    finChecked: true,
    cinChecked: true,
    icd10Checked: false,
    careplanChecked: false,
    initialAssessmentChecked: false,
    programCompletionChecked: false,
    lastLACAREReferralDateChecked: false,
    lastLACAREScreeningDateChecked: false,
  };

  const [checkboxStates, setCheckboxStates] = useState({
    ...defaultCheckboxStates,
    ...cookieSearchBoxState,
  });

  const [searchParticipants, { loading, data, error }] = useLazyQuery(SEARCH_PARTICIPANTS);

  useEffect(() => {
    pushHelpTopic('Search');
    return () => popHelpTopic();
  }, [pushHelpTopic, popHelpTopic]);

  useEffect(() => {
    if (data?.searchParticipants) {
      setSearchResults(data.searchParticipants);
    }
  }, [data]);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchParticipants({ variables: { search: searchInput }});
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white shadow-sm rounded-lg">
          {/* Header */}
          <div className="border-b border-gray-200 px-6 py-4">
            <div className="flex justify-between items-center">
              <h1 className="text-xl font-semibold text-gray-900">Participant Search</h1>
              <div className="text-sm text-gray-500">
                Total Results: {searchResults.length}
              </div>
            </div>
          </div>

          {/* Search Form */}
          <div className="px-6 py-4 border-b border-gray-200 bg-gray-50">
            <form onSubmit={handleSearch} className="space-y-4">
              <div className="max-w-3xl">
                <div className="relative rounded-md shadow-sm">
                  <input
                    type="text"
                    placeholder="Search by name, CID, MRUN, or FIN"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm"
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center">
                    {loading ? (
                      <div className="mr-3">
                        <div className="animate-spin rounded-full h-5 w-5 border-2 border-gray-400 border-t-transparent" />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-r-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        <Search className="h-5 w-5" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/* Results Section */}
          <div className="px-6 py-4">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr className="bg-gray-50">
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Participant
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Gender
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Programs
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        MRUN
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        FIN
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        ChampID
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {searchResults.map((result: any) => (
                      <tr key={result.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <User className="h-8 w-8 text-gray-400" />
                            </div>
                            <div className="ml-4">
                              <Link 
                                to={`/participants/${result.id}`}
                                onClick={Close}
                                className="text-sm font-medium text-gray-900 hover:text-blue-600"
                              >
                                {result.nameHistory?.map((name: any, index: number) => (
                                  <div key={index}>
                                    {name.firstName} {name.lastName}
                                  </div>
                                ))}
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {result.gender}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex flex-wrap gap-2">
                            {result.programs?.map((p: any) => (
                              <ProgramBadge key={p} id={p} />
                            ))}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {result.mrun}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {result.fin}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {result.champid}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        {/* Error Display */}
        {error && (
          <div className="mt-6">
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Error</h3>
                  <div className="mt-2 text-sm text-red-700">{error.message}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};