import React from "react";
import ReactModal from "react-modal";
import { programNameFromID, isoDateToMDY } from "../../../utils/common";
import { useQuery, gql } from "@apollo/client";

const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
      scans {
        id
        filename
        filetype
        filelabel
        filedate
      }
      nameHistory {
        lastName
        firstName
        program
      }
      dobHistory {
        value
        program
      }
      programs
      mrun
      cid
      champidHistory {
        value
        program
      }
      genderHistory {
        value
        program
      }
      mrunHistory {
        value
        program
      }
      isNumberHistory {
        value
        program
      }
      disposition
      dispositionHistory {
        disposition
        dispositionDate
        program
      }
      pathwayHistory {
        value
        program
        entrydate
      }
      providerHistory {
        value
        program
        entrydate
      }
      releaseDateHistory {
        value
        program
      }
      jcitHistory {
        value
        program
      }
      probationHistory {
        value
        program
      }
      programDetails {
        programDescription
        programParticipantNumber
        entryDate
        exitDate
        entryDate
        exitDate
        disposition
        provider
        pathway
        releaseDate
        probation
      }
      activeProgram {
        programDescription
        programParticipantNumber
      }
      fin
    }
  }
`;

const formatProbation = (probation: string) => {
  const probationStatus = {
    no: "Not on Probation",
    yes: "Probation",
    terminated: "Probation Terminated",
  } as any;

  return probationStatus[probation] || "N/A";
};

interface TimelineEventProps {
  date: string;
  label: string;
  details: string;
  type?: "disposition" | "arrest" | "release" | "note" | "default";
}

const TimelineEvent: React.FC<TimelineEventProps> = ({
  date,
  label,
  details,
  type = "default",
}) => {
  type EventType = "disposition" | "arrest" | "release" | "note" | "default";

  const getEventColor = (type: EventType) => {
    switch (type) {
      case "disposition":
        return "bg-blue-100 border-blue-200";
      case "arrest":
        return "bg-red-100 border-red-200";
      case "release":
        return "bg-green-100 border-green-200";
      case "note":
        return "bg-yellow-100 border-yellow-200";
      default:
        return "bg-gray-100 border-gray-200";
    }
  };

  return (
    <div className="flex gap-4 items-start">
      <div className="w-32 flex-shrink-0 text-sm text-gray-500 pt-1">
        {isoDateToMDY(date)}
      </div>
      <div className={`flex-grow p-3 rounded-lg border ${getEventColor(type)}`}>
        <div className="text-sm font-medium text-gray-900">{label}</div>
        <div className="text-sm text-gray-600 mt-1">{details}</div>
      </div>
    </div>
  );
};

export const ProgramDetailsModal = ({
  isOpen,
  onClose,
  programNumber,
  odrnumber,
}: {
  isOpen: boolean;
  onClose: () => void;
  programNumber: string;
  odrnumber: any;
}) => {
  const { loading, error, data } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
    variables: { odrnumber },
  });

  const getProgramEvents = (data: any) => {
    if (!data?.getParticipantByODRNumber) return [];

    const events: TimelineEventProps[] = [];
    const program = data.getParticipantByODRNumber;

    // Add dispositions (only ones with dates and filter duplicates)
    const seenDispositions = new Set();
    program.dispositionHistory
      ?.filter((d: any) => d.dispositionDate && d.disposition)
      .forEach((d: any) => {
        const key = `${d.dispositionDate}-${d.disposition}`;
        if (!seenDispositions.has(key)) {
          seenDispositions.add(key);
          events.push({
            date: d.dispositionDate,
            label: "Disposition Change",
            details: d.disposition,
            type: "disposition",
          });
        }
      });

    // Add scans/documents
    program.scans?.forEach((scan: any) => {
      events.push({
        date: scan.filedate,
        label: `Document Added: ${scan.filetype}`,
        details: scan.filelabel,
        type: "note",
      });
    });

    // Add program events
    program.programDetails
      ?.filter((pd: any) => pd.programParticipantNumber === programNumber)
      .forEach((pd: any) => {
        if (pd.entryDate) {
          events.push({
            date: pd.entryDate,
            label: "Program Entry",
            details: `Entered ${pd.programDescription.toUpperCase()}`,
            type: "default",
          });
        }
        if (pd.exitDate) {
          events.push({
            date: pd.exitDate,
            label: "Program Exit",
            details: `Exited ${pd.programDescription.toUpperCase()}`,
            type: "release",
          });
        }
      });

    // pathway changes
    program.pathwayHistory?.forEach((p: any) => {
      if (p.entrydate && p.value) {
        events.push({
          date: p.entrydate,
          label: "Pathway Change",
          details: p.value,
          type: "default",
        });
      }
    });

    // provider changes
    program.providerHistory?.forEach((p: any) => {
      if (p.entrydate && p.value) {
        events.push({
          date: p.entrydate,
          label: "Provider Change",
          details: p.value,
          type: "default",
        });
      }
    });

    // release dates
    program.releaseDateHistory?.forEach((r: any) => {
      if (r.value) {
        events.push({
          date: r.value,
          label: "Release Date",
          details: "Released from custody",
          type: "release",
        });
      }
    });

    // Sort all events by date, most recent first
    return events.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          border: "none",
        },
        content: {
          position: "relative",
          background: "white",
          padding: "20px",
          maxHeight: "80vh",
          width: "100%",
          maxWidth: "800px",
          inset: 0,
          border: "none",
        },
      }}
      onRequestClose={onClose}
    >
      <div className="space-y-4">
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-lg font-semibold text-neutral-900">
            {programNameFromID(programNumber)} Program Timeline
          </h2>
          <button
            onClick={onClose}
            className="text-neutral-500 hover:text-neutral-700"
          >
            Close
          </button>
        </div>

        {loading ? (
          <div className="flex justify-center p-12">
            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-indigo-600 border-r-transparent align-[-0.125em]" />
          </div>
        ) : error ? (
          <div className="text-red-600 p-4">Error loading program data</div>
        ) : (
          <div className="space-y-6 p-4 max-h-[60vh] overflow-y-auto">
            {getProgramEvents(data).map((event, index) => (
              <TimelineEvent
                key={`${event.date}-${index}`}
                date={event.date}
                label={event.label}
                details={event.details}
                type={event.type}
              />
            ))}
          </div>
        )}
      </div>
    </ReactModal>
  );
};

export default ProgramDetailsModal;
