import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { Alert, AlertDescription } from '@/components/ui/alert';
import { ChevronDown, ChevronRight, FileDown, Download, Printer, Copy, Search } from "lucide-react";
import * as XLSX from 'xlsx';
import { toast } from "react-toastify"

// Define the Result type
interface Result {
  message: string;
  responseCode: number;
  responseID: number;
  extra: string;
}

// Add new interfaces
interface TableData {
  group?: string;
  subgroup?: string;
  description?: string;
  fields: Record<string, FieldInfo>;
  documentation?: string;
}

interface FieldInfo {
  type: string;
  description?: string;
  default?: string | null;
  nullable?: string;
  key?: string;
  extra?: string;
  comment?: string;
  isPrimaryKey?: boolean;
  isForeignKey?: boolean;
  references?: string;
}

// GraphQL query
const GET_DATABASE_DICTIONARY = gql`
  query GetDatabaseDictionary {
    databaseDictionary {
      message
      responseCode
      responseID
      extra
    }
  }
`;

export const DatabaseDictionaryPage = () => {
  const [expandedTables, setExpandedTables] = useState<Set<string>>(new Set());
  const [fullDictionary, setFullDictionary] = useState<Record<string, TableData>>({});
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activeTable, setActiveTable] = useState<string | null>(null);

  // GraphQL query execution
  const { loading, error, data } = useQuery<{ databaseDictionary: Result }>(GET_DATABASE_DICTIONARY, {
    onError: (error) => {
      console.error("Error fetching database dictionary:", error);
      // If you're using react-toastify
      // toast.error("Failed to fetch database dictionary");
    },
    onCompleted: (data) => {
      try {
        const dictionary = JSON.parse(data.databaseDictionary.extra);
        setFullDictionary(dictionary);
        
        // Set first table as active if available
        const tableNames = Object.keys(dictionary);
        if (tableNames.length > 0) {
          setActiveTable(tableNames[0]);
        }
      } catch (err) {
        console.error('Error parsing dictionary data:', err);
        setFullDictionary({});
      }
    }
  });

  // Toggle table expansion
  const toggleTableExpansion = (tableName: string) => {
    setExpandedTables(prev => {
      const next = new Set(prev);
      prev.has(tableName) ? next.delete(tableName) : next.add(tableName);
      return next;
    });
  };

  // Export to Excel
  const exportToExcel = () => {
    try {
      // Format data for Excel
      const worksheetData: any[][] = [
        ['Table', 'Field', 'Type', 'Description', 'Primary Key', 'Foreign Key', 'Extra']
      ];
      
      Object.entries(fullDictionary).forEach(([tableName, tableData]: [string, any]) => {
        worksheetData.push([
          tableName,
          tableName,
          'TABLE',
          tableData.description || '',          
        ]);
        if (tableData.fields) {
          Object.entries(tableData.fields).forEach(([fieldName, fieldInfo]: [string, any]) => {
            worksheetData.push([
              tableName,
              fieldName,
              fieldInfo.type || '',
              fieldInfo.description || '',
              fieldInfo.key === 'PRI' ? 'Yes' : 'No',
              fieldInfo.isForeignKey ? 'Yes' : 'No',
              fieldInfo.extra || '',              
            ]);
          });
        }
      });
      
      // Create workbook and worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(worksheetData);
      
      // Set column widths
      const columnWidths = [
        { wch: 20 }, // Table
        { wch: 20 }, // Field
        { wch: 15 }, // Type
        { wch: 60 }, // Description
        { wch: 10 }, // Primary Key
        { wch: 10 }, // Foreign Key
        { wch: 15 }, // Extra
        
      ];
      
      ws['!cols'] = columnWidths;
      
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Database Dictionary');
      
      // Create a second worksheet for table documentation
      const docsData: any[][] = [
        ['Table', 'Description', 'Documentation']
      ];
      
      Object.entries(fullDictionary).forEach(([tableName, tableData]: [string, any]) => {
        docsData.push([
          tableName,
          tableData.description || '',
          tableData.documentation || ''
        ]);
      });
      
      const docsWs = XLSX.utils.aoa_to_sheet(docsData);
      
      // Set column widths for documentation sheet
      const docsColumnWidths = [
        { wch: 20 }, // Table
        { wch: 40 }, // Description
        { wch: 60 }  // Documentation
      ];
      
      docsWs['!cols'] = docsColumnWidths;
      
      // Add the documentation worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, docsWs, 'Table Documentation');
      
      // Generate Excel file
      XLSX.writeFile(wb, 'database_dictionary.xlsx');
      
      toast.success("Excel file generated successfully");
    } catch (error) {
      console.error("Error generating Excel file:", error);
      toast.error("Failed to generate Excel file");
    }
  };


  // Export to PDF functionality
  const exportToPDF = () => {
    // Create a new window for the PDF
    const printWindow = window.open('', '_blank');
    if (!printWindow) {
      // toast.error("Pop-up blocked. Please allow pop-ups for this site.");
      console.error("Pop-up blocked. Please allow pop-ups for this site.");
      return;
    }

    // Build the HTML content for printing
    let htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Database Schema</title>
        <style>
          body { font-family: Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; }
          h1 { text-align: center; }
          .table-section { margin-bottom: 30px; page-break-inside: avoid; }
          table { width: 100%; border-collapse: collapse; margin-top: 10px; }
          th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
          th { background-color: #f2f2f2; }
          .table-name { font-size: 18px; font-weight: bold; margin-bottom: 5px; }
          .table-description { margin-bottom: 10px; color: #666; }
        </style>
      </head>
      <body>
        <h1>Database Schema Documentation</h1>
    `;

    // Add all tables to the document
    Object.entries(fullDictionary).forEach(([tableName, tableInfo]) => {
      htmlContent += `
        <div class="table-section">
          <div class="table-name">${tableName}</div>
          <div class="table-description">${tableInfo.description || 'No description'}</div>
          <table>
            <thead>
              <tr>
                <th>Field</th>
                <th>Type</th>
                <th>Description</th>
                <th>Default</th>
                <th>Attributes</th>
              </tr>
            </thead>
            <tbody>
      `;

      // Add fields for each table
      Object.entries(tableInfo.fields || {}).forEach(([fieldName, fieldInfo]) => {
        const attributes = [];
        if (fieldInfo.key === 'PRI' || fieldInfo.isPrimaryKey) attributes.push('Primary Key');
        if (fieldInfo.isForeignKey) attributes.push('Foreign Key');
        if (fieldInfo.nullable === 'NO') attributes.push('Required');
        if (fieldInfo.extra) attributes.push(fieldInfo.extra);
        if (fieldInfo.references) attributes.push(`References: ${fieldInfo.references}`);

        htmlContent += `
          <tr>
            <td>${fieldName}</td>
            <td>${fieldInfo.type}</td>
            <td>${fieldInfo.description || fieldInfo.comment || 'No description'}</td>
            <td>${fieldInfo.default === null ? 'NULL' : (fieldInfo.default === undefined ? '-' : fieldInfo.default)}</td>
            <td>${attributes.join(', ') || '-'}</td>
          </tr>
        `;
      });

      htmlContent += `
            </tbody>
          </table>
        </div>
      `;
    });

    htmlContent += `
      </body>
      </html>
    `;

    // Write the HTML content to the print window
    printWindow.document.write(htmlContent);
    printWindow.document.close();
    
    // Print the window after it's fully loaded
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  // Filter tables based on search term
  const filteredTables = Object.entries(fullDictionary).filter(([tableName, tableInfo]) => 
    tableName.toLowerCase().includes(searchTerm.toLowerCase()) || 
    (tableInfo.description && tableInfo.description.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const copyJsonToClipboard = () => {
    const jsonString = JSON.stringify(fullDictionary, null, 2);
    navigator.clipboard.writeText(jsonString);
    toast.success("JSON copied to clipboard");
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-t-blue-500 border-gray-200 rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading schema data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <Alert className="max-w-xl mx-auto mt-8">
        <AlertDescription>
          Error loading schema data. Please try again later.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen py-6">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="mb-8 text-center">
          <h1 className="text-3xl font-bold mb-2">Database Schema Viewer</h1>
          <p className="text-gray-600">View and explore your database structure</p>
        </div>

        <div className="mb-6 flex justify-between items-center">
          <div className="relative w-64">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-4 w-4 text-gray-400" />
            </div>
            <Input
              placeholder="Search tables..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10"
            />
          </div>
          <div className="flex space-x-2">
            <Button 
              variant="outline" 
              onClick={exportToExcel}
              className="flex items-center gap-2"
            >
              <Download className="h-4 w-4" />
              Export Excel
            </Button>
            <Button 
              variant="outline" 
              onClick={exportToPDF}
              className="flex items-center gap-2"
            >
              <Printer className="h-4 w-4" />
              Export PDF
            </Button>
            <Button 
              variant="outline" 
              onClick={copyJsonToClipboard}
              className="flex items-center gap-2"
            >
              <Copy className="h-4 w-4" />
              Copy JSON
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {/* Table List */}
          <div className="md:col-span-1">
            <Card>
              <CardHeader className="pb-3">
                <CardTitle className="text-lg">Tables</CardTitle>
                <p className="text-sm text-gray-500">
                  {filteredTables.length} of {Object.keys(fullDictionary).length} tables
                </p>
              </CardHeader>
              <CardContent className="p-0">
                <ScrollArea className="h-[500px]">
                  <div className="px-1">
                    {filteredTables.map(([tableName, tableInfo]) => (
                      <div 
                        key={tableName}
                        className={`border-b border-gray-100 last:border-0 ${activeTable === tableName ? 'bg-gray-50' : ''}`}
                      >
                        <button
                          onClick={() => setActiveTable(tableName)}
                          className="w-full text-left px-4 py-3 hover:bg-gray-50 transition-colors"
                        >
                          <div className="font-medium text-gray-900">{tableName}</div>
                          <div className="text-sm text-gray-500 truncate">
                            {tableInfo.description || 'No description'}
                          </div>
                        </button>
                      </div>
                    ))}
                  </div>
                </ScrollArea>
              </CardContent>
            </Card>
          </div>

          {/* Table Details */}
          <div className="md:col-span-3">
            {activeTable && fullDictionary[activeTable] ? (
              <Card>
                <CardHeader className="pb-3">
                  <div className="flex justify-between items-center">
                    <div>
                      <CardTitle className="text-lg">{activeTable}</CardTitle>
                      <p className="text-sm text-gray-500 mt-1">
                        {fullDictionary[activeTable].description || 'No description'}
                      </p>
                    </div>
                    <Badge variant="secondary">
                      {Object.keys(fullDictionary[activeTable].fields || {}).length} fields
                    </Badge>
                  </div>
                </CardHeader>
                <CardContent>
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead>
                        <tr className="border-b text-left">
                          <th className="p-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Field</th>
                          <th className="p-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                          <th className="p-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                          <th className="p-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Default</th>
                          <th className="p-3 text-xs font-medium text-gray-500 uppercase tracking-wider">Attributes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(fullDictionary[activeTable].fields || {}).map(([fieldName, fieldInfo]) => {
                          // Create badges for field attributes
                          const badges = [];
                          if (fieldInfo.key === 'PRI' || fieldInfo.isPrimaryKey) {
                            badges.push(<Badge key="pri" className="bg-blue-600 mr-1">Primary Key</Badge>);
                          }
                          if (fieldInfo.isForeignKey) {
                            badges.push(<Badge key="fk" className="bg-purple-600 mr-1">Foreign Key</Badge>);
                          }
                          if (fieldInfo.nullable === 'NO') {
                            badges.push(<Badge key="req" className="bg-yellow-600 mr-1">Required</Badge>);
                          }
                          if (fieldInfo.extra) {
                            badges.push(<Badge key="extra" className="bg-green-600 mr-1">{fieldInfo.extra}</Badge>);
                          }
                          
                          return (
                            <tr key={fieldName} className="border-b hover:bg-gray-50">
                              <td className="p-3 text-sm font-medium text-gray-900">{fieldName}</td>
                              <td className="p-3 text-sm text-gray-500">
                                <code className="bg-gray-100 px-2 py-1 rounded text-sm">{fieldInfo.type}</code>
                              </td>
                              <td className="p-3 text-sm text-gray-500">
                                {fieldInfo.description || fieldInfo.comment || 'No description'}
                              </td>
                              <td className="p-3 text-sm text-gray-500">
                                {fieldInfo.default === null ? (
                                  <span className="text-gray-400">NULL</span>
                                ) : fieldInfo.default === undefined ? (
                                  <span className="text-gray-400">-</span>
                                ) : (
                                  <code className="bg-gray-100 px-2 py-1 rounded text-sm">{fieldInfo.default}</code>
                                )}
                              </td>
                              <td className="p-3 text-sm text-gray-500 flex flex-wrap gap-1">
                                {badges}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <Card>
                <CardContent className="p-6 text-center">
                  <p className="text-gray-500">Select a table to view its details</p>
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

