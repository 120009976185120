import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useUser } from "../../store/useUser";
import { useSetHelpTopic } from "../../store/useHelpTopics";
import CalAimDemographics from "./components/demographics";
import { CalAimForms } from "./components/calAIMForms";
import { EditCalAIM } from "./modals/EditCalAIM";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { AddNoteCalAIM } from "./modals/AddNoteCalAIM";
import { Button } from "@/components/ui/button";
import { 
  AlertCircle, 
  FileText, 
  Plus, 
  Edit, 
  Trash, 
  ClipboardList, 
  Calendar, 
  Activity, 
  Tag, 
  Clock, 
  FileSignature, 
  User, 
  AlertTriangle, 
  CheckCircle, 
  Building
} from "lucide-react";
import { 
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

const GET_CALAIM_DATA = gql`
  query GetCalAIMData($odrNumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrNumber) {
      ssn
      cin
      mrun
      fin
      cii
      lastInitialAssessmentDate {
        id
        date
      }
      lastLACAREScreeningDate {
        id
        date
      }
      lastLACAREReferralDate {
        id
        date
      }
      wpc      
    }
  }
`;

const GET_CALAIM_NOTES = gql`
  query GetCalaimNotes($odrnumber: String!) {
    calaimNotes(odrnumber: $odrnumber) {
      id
      pos_code
      pos_name
      procedurecode
      proceduremodifier
      proceduremod1
      proceduremod2
      proceduremod3
      proceduretype
      procedurelength
      note_entry_date
      procedurenote
      procedureentery
      entrydate
      entryby
    }
  }
`;

const UPDATE_PARTICIPANT = gql`
  mutation UpdateParticipantByODRNumber($odrnumber: Integer!, $participant: Integer, $updates: UpdateParticipantInput!) {
    updateParticipantByODRNumber(odrnumber: $odrnumber, participant: $participant, updates: $updates) {
      message
    }
  }
`;

const GET_HEALTHPLAN_PROVIDERS = gql`
  query GetHealthPlanProviders {
    getHealthPlanProviders {
      providerId
      providerName
    }
  }
`;

const DELETE_CALAIM_NOTE = gql`
  mutation DeleteCalaimNote($noteId: Integer!) {
    deleteCalaimNote(noteId: $noteId) {
      message
    }
  }
`;

const LoadingState = () => (
  <div className="space-y-3">
    <Skeleton className="h-5 w-[180px]" />
    <div className="grid grid-cols-3 gap-3">
      <Skeleton className="h-[80px]" />
      <Skeleton className="h-[80px]" />
      <Skeleton className="h-[80px]" />
    </div>
  </div>
);

const ErrorState = () => (
  <Card className="bg-destructive/10">
    <CardContent className="p-3 flex items-center">
      <AlertTriangle className="h-3.5 w-3.5 mr-2 text-destructive" />
      <span className="text-xs text-destructive">Unable to load CalAIM data. Please try again.</span>
    </CardContent>
  </Card>
);

const FormField = ({ label, children, className = "" }: { label: string, children: React.ReactNode, className?: string }) => (
  <div className={className}>
    <label className="block text-xs font-medium text-neutral-700 mb-1">
      {label}
    </label>
    {children}
  </div>
);

const RadioGroup = ({ label, options, name, value, onChange }: { label: string, options: any[], name: string, value: string, onChange: (e: any) => void }) => (
  <div className="space-y-1">
    <label className="block text-xs font-medium text-neutral-700">{label}</label>
    <div className="space-y-1">
      {options.map((option) => (
        <div key={option.value} className="flex items-center">
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={onChange}
            className="h-3 w-3 text-blue-600 focus:ring-blue-500 border-neutral-300"
          />
          <label className="ml-2 text-xs text-neutral-700">{option.label}</label>
        </div>
      ))}
    </div>
  </div>
);

export const CalAIM = ({ odrNumber, programNumber }: { odrNumber: number; programNumber: number; }) => {
  // State declarations remain unchanged
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [selectedCalAIM, setSelectedCalAIM] = React.useState(null);
  const [formData, setFormData] = React.useState({
    calaim_pos: "",
    proceduremod1: "",
    proceduremod2: "",
    proceduremod3: "",
    proceduretype: "",
    procedurelength: "",
    note_entry_date: "",
    procedurenote: "",
    procedureentery: "",
  });
  const [newNoteModalOpen, setNewNoteModalOpen] = React.useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [noteToDelete, setNoteToDelete] = React.useState<number | null>(null);
  const { data: healthPlanProvidersDataResult } = useQuery(GET_HEALTHPLAN_PROVIDERS);
  const { user } = useUser();
  const isAdmin = user.groups?.includes("admin");

  const { loading, error, data, refetch } = useQuery(GET_CALAIM_DATA, {
    variables: { odrNumber },
    skip: !odrNumber,
  });

  const { loading: loadingNotes, error: notesError, data: notesData, refetch: refetchNotes } = useQuery(GET_CALAIM_NOTES, {
    variables: { odrnumber: odrNumber.toString() },
    skip: !odrNumber,
  });

  const [updateParticipant] = useMutation(UPDATE_PARTICIPANT);
  const [deleteCalaimNote, { loading: deleteLoading }] = useMutation(DELETE_CALAIM_NOTE);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditClick = (calaimNote: any) => {
    setSelectedCalAIM(calaimNote);
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    setSelectedCalAIM(null);
  };

  const handleDeleteClick = (noteId: number) => {
    setNoteToDelete(noteId);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (noteToDelete === null) return;
    
    try {
      await deleteCalaimNote({
        variables: {
          noteId: noteToDelete
        }
      });
      refetchNotes();
      setIsDeleteDialogOpen(false);
      setNoteToDelete(null);
    } catch (error) {
      console.error("Error deleting CalAIM note:", error);
    }
  };

  useSetHelpTopic("ParticipantsCalAIM");

  const handleUpdateCalAIM = async (formData: any) => {
    try {
      const result = await updateParticipant({
        variables: {
          odrnumber: odrNumber,
          updates: {
            billingEvents: [
              {
                posCode: formData.calaim_pos,
                procedureModifier1: formData.proceduremod1,
                procedureModifier2: formData.proceduremod2,
                outreach: formData.proceduremod3,
                procedureType: formData.proceduretype,
                serviceUnits: parseInt(formData.procedurelength),
                serviceStart: formData.note_entry_date,
                timespentNote: formData.procedurenote,
                entryBy: formData.entryBy,
              },
            ],
          },
        },
      });
      const response = result.data.updateParticipantByODRNumber;
      if (response.responseCode === "SUCCESS") {
        console.log("Update successful:", response.message);
        handleCloseModal();
      } else {
        console.error("Update failed:", response.message);
      }
    } catch (error) {
      console.error("Error updating CalAIM:", error);
    }
  }

  if (loading) return <LoadingState />;
  if (error || !data) return <ErrorState />;

  const calaimData = data.getParticipantByODRNumber;
  // Ensure calaimNotes is always an array by using Array.isArray check
  const calaimNotes = Array.isArray(notesData?.calaimNotes) ? notesData.calaimNotes : [];
  const acuitys = { 1: "Low", 2: "Moderate", 4: "High" } as any;

  return (
    <div className="space-y-5">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-1.5">
          <ClipboardList className="h-4 w-4 text-gray-600" />
          <h1 className="text-base font-medium text-gray-800">CalAIM Status</h1>
        </div>
      </div>

      <div className="grid grid-cols-[minmax(0,_18rem)_1fr] gap-5">
        <div className="space-y-3">
          <Card className="w-full shadow-sm">
            <CardHeader className="pb-1 pt-2 px-3">
              <div className="flex items-center gap-1.5">
                <Activity className="h-3.5 w-3.5 text-gray-500" />
                <h4 className="text-xs font-medium text-gray-700">ECM Acuity</h4>
              </div>
            </CardHeader>
            <CardContent className="pt-0 px-3 pb-2">
              <span className="text-xs text-gray-600">
                {acuitys[calaimData.acuity] || "Unknown"}
              </span>
            </CardContent>
          </Card>
          <CalAimForms calaimData={calaimData} odrNumber={odrNumber} />
        </div>
        {calaimData && (
          <div className="text-xs origin-top-left">
            <CalAimDemographics 
              calaimData={calaimData} 
              odrNumber={odrNumber} 
              healthPlanProviders={healthPlanProvidersDataResult?.getHealthPlanProviders || []} 
              refetch={refetch}
            />
          </div>
        )}
      </div>

      {isAdmin && (
        <div className="mt-3">
          <Card className="shadow-sm">
            <CardHeader className="flex flex-row items-center justify-between py-2 px-3">
              <div className="flex items-center gap-1.5">
                <FileText className="h-3.5 w-3.5 text-gray-500" />
                <CardTitle className="text-xs font-medium text-gray-700">ECM CalAIM Notes</CardTitle>
              </div>
              {isAdmin && (
                <Button
                  variant="outline"
                  size="sm"
                  className="bg-blue-600 hover:bg-blue-700 text-white border-0 h-7 px-2.5 text-xs"
                  onClick={() => setNewNoteModalOpen(true)}
                >
                  <Plus className="h-3 w-3 mr-1" />
                  Add Note
                </Button>
              )}
            </CardHeader>
            <CardContent className="p-0">
              <div className="overflow-x-auto border-t border-gray-200">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-2 py-1.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          <Building className="h-3 w-3" />
                          <span>Place of Service</span>
                        </div>
                      </th>
                      <th className="px-2 py-1.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          <Calendar className="h-3 w-3" />
                          <span>Date</span>
                        </div>
                      </th>
                      <th className="px-2 py-1.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          <Tag className="h-3 w-3" />
                          <span>Procedure Code</span>
                        </div>
                      </th>
                      <th className="px-2 py-1.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          <Tag className="h-3 w-3" />
                          <span>Procedure Modifier</span>
                        </div>
                      </th>
                      <th className="px-2 py-1.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          <Tag className="h-3 w-3" />
                          <span>Procedure Type</span>
                        </div>
                      </th>
                      <th className="px-2 py-1.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          <Clock className="h-3 w-3" />
                          <span>Length</span>
                        </div>
                      </th>
                      <th className="px-2 py-1.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          <FileSignature className="h-3 w-3" />
                          <span>Notes</span>
                        </div>
                      </th>
                      <th className="px-2 py-1.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          <User className="h-3 w-3" />
                          <span>Entered By</span>
                        </div>
                      </th>
                      <th className="px-2 py-1.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {calaimNotes.length > 0 && calaimNotes.map((note: any) => (
                      <tr key={note.id} className="hover:bg-gray-50">
                        <td className="px-2 py-1.5 whitespace-nowrap text-xs text-gray-700">
                          {note.pos_name || note.pos_code}
                        </td>
                        <td className="px-2 py-1.5 whitespace-nowrap text-xs text-gray-700">
                          {note.note_entry_date}
                        </td>
                        <td className="px-2 py-1.5 whitespace-nowrap text-xs text-gray-700">
                          {note.procedurecode || "—"}
                        </td>
                        <td className="px-2 py-1.5 whitespace-nowrap text-xs text-gray-700">
                          {note.proceduremodifier || 
                            `${note.proceduremod1 === "1" ? "In Person" : "Telehealth"}, 
                             ${note.proceduremod2 === "1" ? "Clinical" : "NonClinical"}, 
                             ${note.proceduremod3 === "1" ? "Care" : "Outreach"}`}
                        </td>
                        <td className="px-2 py-1.5 whitespace-nowrap text-xs text-gray-700">
                          {note.proceduretype || "—"}
                        </td>
                        <td className="px-2 py-1.5 whitespace-nowrap text-xs text-gray-700">
                          {note.procedurelength || "—"}
                        </td>
                        <td className="px-2 py-1.5 text-xs text-gray-700 truncate max-w-[150px]" title={note.procedurenote}>
                          {note.procedurenote || "—"}
                        </td>
                        <td className="px-2 py-1.5 whitespace-nowrap text-xs text-gray-700">
                          {note.procedureentery || "—"}
                        </td>
                        <td className="px-2 py-1.5 whitespace-nowrap text-xs">
                          <div className="flex space-x-1">
                            <Button
                              variant="ghost"
                              size="sm"
                              className="h-6 px-1.5 text-xs text-blue-600 hover:text-blue-800 hover:bg-blue-50"
                              onClick={() => handleEditClick(note)}
                            >
                              <Edit className="h-3 w-3 mr-1" />
                              Edit
                            </Button>
                            <Button
                              variant="ghost"
                              size="sm"
                              className="h-6 px-1.5 text-xs text-red-600 hover:text-red-800 hover:bg-red-50"
                              onClick={() => handleDeleteClick(note.id)}
                            >
                              <Trash className="h-3 w-3 mr-1" />
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {calaimNotes.length === 0 && loadingNotes && (
                      <tr>
                        <td colSpan={9} className="px-2 py-1.5 text-center text-xs text-gray-500">
                          <div className="flex items-center justify-center gap-1.5">
                            <Clock className="h-3 w-3 animate-spin" />
                            <span>Loading notes...</span>
                          </div>
                        </td>
                      </tr>
                    )}
                    {calaimNotes.length === 0 && !loadingNotes && (
                      <tr>
                        <td colSpan={9} className="px-2 py-1.5 text-center text-xs text-gray-500">
                          <div className="flex items-center justify-center gap-1.5">
                            <AlertCircle className="h-3 w-3" />
                            <span>No CalAIM notes found.</span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>
        </div>
      )}

      <AddNoteCalAIM
        isOpen={newNoteModalOpen}
        onClose={() => {
          setNewNoteModalOpen(false);
          refetchNotes();
        }}
        user={user}
        calaimData={calaimData}
        odrNumber={odrNumber.toString()}
        participant={programNumber.toString()}
        refetch={refetchNotes}
      />
            
      <EditCalAIM
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setSelectedCalAIM(null);
          refetchNotes();
        }}
        calaimData={selectedCalAIM}
        user={user}
      />

      <AlertDialog
        open={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
      >
        <AlertDialogContent className="bg-white p-3 rounded-md shadow-md max-w-md">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-sm font-medium flex items-center gap-1.5">
              <AlertTriangle className="h-4 w-4 text-red-500" />
              Delete CalAIM Note
            </AlertDialogTitle>
            <AlertDialogDescription className="text-xs text-gray-600">
              Are you sure you want to delete this CalAIM note? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="mt-3">
            <AlertDialogCancel className="h-7 text-xs font-medium" onClick={() => setIsDeleteDialogOpen(false)}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction 
              className="h-7 text-xs font-medium bg-red-600 hover:bg-red-700"
              onClick={handleDeleteConfirm} 
              disabled={deleteLoading}
            >
              {deleteLoading ? (
                <span className="flex items-center gap-1">
                  <Clock className="h-3 w-3 animate-spin" />
                  Deleting...
                </span>
              ) : (
                <span className="flex items-center gap-1">
                  <Trash className="h-3 w-3" />
                  Delete
                </span>
              )}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default CalAIM;