import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { EditDisposition } from "./modals/EditDisposition";
import { Edit, MapPin, Building, Home, Phone, ClipboardList, MessageSquare, AlertTriangle, Calendar, FileText, PlusSquare, DollarSign, Search, Tag, ShieldAlert, LucideIcon } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

const GET_DISPOSITION_DATA = gql`
  query GetPlanData($odrNumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrNumber) {
      location{
      custodyOrCommunity
      pharmacyNotes
      location
      locationDetail
      contact
      dischargePlan
      highPriorityStatus
      ihAssignmentDate
      ihRequestDate
      ihRequestStatus
      notes
      pshFundingSource
      pshSearchNotes
      pshSearchStatus
      pshSearchStatusDate
      pshType
      comments
      restrictions}
    }
  }
`;

interface PlanSectionProps {
  title: string;
  content?: string;
  icon?: LucideIcon;
}

const PlanSection: React.FC<PlanSectionProps> = ({ title, content, icon: Icon }) => {
  const displayContent = content || "—";
  
  return (
    <Card className="w-full shadow-sm hover:shadow-md transition-all duration-200">
      <CardHeader className="py-2 px-3">
        <div className="flex items-center space-x-2">
          {Icon && (
            <div className="p-1.5 bg-blue-50 rounded-full">
              <Icon className="h-4 w-4 text-blue-500" />
            </div>
          )}
          <CardTitle className="text-sm font-medium text-gray-700">{title}</CardTitle>
        </div>
      </CardHeader>
      <CardContent className="px-3 py-2 text-xs text-gray-600">
        {content ? (
          <div className="whitespace-pre-wrap break-words leading-relaxed">
            {content}
          </div>
        ) : (
          <div className="text-gray-400 italic">—</div>
        )}
      </CardContent>
    </Card>
  );
};

export const PlanAndLocation = ({
  odrNumber,
  programNumber,
}: {
  odrNumber: number;
  programNumber: number;
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { loading, error, data } = useQuery(GET_DISPOSITION_DATA, {
    variables: { odrNumber },
    skip: !odrNumber,
  });

  if (loading) return <div className="flex items-center justify-center h-32"><p className="text-xs text-gray-500">Loading plan data...</p></div>;
  if (error) return <div className="flex items-center justify-center h-32"><p className="text-xs text-red-500">Error loading plan data</p></div>;
  if (!data) return null;

  const planData = data.getParticipantByODRNumber.location; 

  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  if (!planData) return <div className="flex items-center justify-center h-32"><p className="text-xs text-gray-500">No plan data found</p></div>;

  return (
    <div className="space-y-4 mx-auto">
      {/* Header Section */}
      <div className="flex items-center justify-between pb-1 mb-2">
        <div className="flex items-center gap-1.5">
          <MapPin className="h-4 w-4 text-blue-500" />
          <h3 className="font-medium text-sm text-gray-700">Plan and Location</h3>
        </div>
        <button 
          onClick={handleEditClick}
          className="inline-flex items-center gap-1 px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors text-xs font-medium"
        >
          <Edit className="h-3 w-3" />
          Edit
        </button>
      </div>
      
      <div className="mb-3">
        <div className="text-xs font-medium px-3 py-1.5 bg-blue-50 text-blue-700 rounded inline-block">
          {planData.custodyorcommunity == 1 ? "In Custody" : "Community"}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <PlanSection title="Location" content={planData.location} icon={Building} />
        <PlanSection title="Location Detail" content={planData.locationdetail} icon={Home} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <PlanSection title="Contact" content={planData.contact} icon={Phone} />
        <PlanSection title="Discharge Plan" content={planData.dischargeplan} icon={ClipboardList} />
      </div>

      <PlanSection title="Comments" content={planData.comments} icon={MessageSquare} />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <PlanSection title="High Priority Status" content={planData.high_priority_status} icon={AlertTriangle} />
        <PlanSection 
          title="IH Assignment Date" 
          content={planData.ih_assignment_date 
            ? new Date(planData.ih_assignment_date).toLocaleDateString() 
            : undefined}
          icon={Calendar}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <PlanSection 
          title="IH Request Date" 
          content={planData.ih_request_date 
            ? new Date(planData.ih_request_date).toLocaleDateString() 
            : undefined}
          icon={Calendar}
        />
        <PlanSection title="IH Request Status" content={planData.ih_request_status} icon={FileText} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <PlanSection title="Notes" content={planData.notes} icon={FileText} />
        <PlanSection title="Pharmacy Notes" content={planData.pharmacynotes} icon={PlusSquare} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <PlanSection title="PSH Funding Source" content={planData.psh_funding_source} icon={DollarSign} />
        <PlanSection title="PSH Search Notes" content={planData.psh_search_notes} icon={Search} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <PlanSection title="PSH Search Status" content={planData.psh_search_status} icon={FileText} />
        <PlanSection 
          title="PSH Search Status Date" 
          content={planData.psh_search_status_date 
            ? new Date(planData.psh_search_status_date).toLocaleDateString() 
            : undefined}
          icon={Calendar}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <PlanSection title="PSH Type" content={planData.psh_type} icon={Tag} />
        <PlanSection title="Restrictions" content={planData.restrictions} icon={ShieldAlert} />
      </div>
      
      <EditDisposition
        isOpen={isEditModalOpen}
        onClose={handleCloseModal}
        programNumber={programNumber}
        planData={planData}
      />
    </div>
  );
};

export default PlanAndLocation;