import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

const GET_LOGIN_STATS = gql`
  query GetLoginStats {
    getLoginStats {
      items {
        email
        action
      }
      count
    }
  }
`;

export const LoginStats = () => {
  const { data, loading } = useQuery(GET_LOGIN_STATS);

  if (loading) {
    return <div className="flex justify-center p-8">Loading...</div>;
  }

  const stats = data?.getLoginStats.items || [];
  
  const govEmails = stats.filter((item: any)     => item.email.includes('.gov'));
  const loginAD = govEmails.filter((item: any) => item.action === 'loginAD').length;
  const loginLegacy = govEmails.filter((item: any) => item.action === 'loginLegacy').length;
  const total = loginAD + loginLegacy;

  return (
    <Card className="w-full max-w-3xl mx-auto mt-8">
      <CardHeader>
        <CardTitle>Login Statistics (.gov users)</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span>AD Login ({loginAD})</span>
              <span>{Math.round((loginAD / total) * 100)}%</span>
            </div>
            <div className="w-full bg-secondary h-4 rounded-full">
              <div 
                className="bg-primary h-4 rounded-full transition-all"
                style={{ width: `${(loginAD / total) * 100}%` }}
              />
            </div>
          </div>

          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span>Legacy Login ({loginLegacy})</span>
              <span>{Math.round((loginLegacy / total) * 100)}%</span>
            </div>
            <div className="w-full bg-secondary h-4 rounded-full">
              <div 
                className="bg-primary h-4 rounded-full transition-all"
                style={{ width: `${(loginLegacy / total) * 100}%` }}
              />
            </div>
          </div>

          <div className="text-sm text-muted-foreground text-center pt-4">
            Total Logins: {total}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
