import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useMutation, gql } from "@apollo/client";

const ADD_BOOKING = gql`
  mutation AddBooking($odrnumber: Integer!, $participant: Integer!, $booking: AddBookingInput!) {
    addBooking(odrnumber: $odrnumber, participant: $participant, booking: $booking) {
      message
    }
  }
`;

export const AddBookingModal = ({
  isOpen,
  onClose,
  odrNumber,
  refetch,
  selectedProgram
}: {
  isOpen: boolean;
  onClose: () => void;
  odrNumber: number;
  refetch: () => void;
  selectedProgram: string;
}) => {
  const [formData, setFormData] = useState({
    bookingNumber: "",
    arrestDate: "",
    releaseDate: "",
  });

  const [addBooking] = useMutation(ADD_BOOKING);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await addBooking({
        variables: {
          odrnumber: odrNumber,
		  participant: selectedProgram,
          booking: {
            bookingNumber: formData.bookingNumber,
            arrestDate: formData.arrestDate,
            releaseDate: formData.releaseDate,
          },
        },
      });
      refetch();
      onClose();
    } catch (error) {
      console.error("Error adding booking:", error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle className="text-center text-2xl font-bold">
            Add New Booking
          </DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="bookingNumber">Booking Number</Label>
              <Input
                id="bookingNumber"
                name="bookingNumber"
                value={formData.bookingNumber}
                onChange={handleInputChange}
                maxLength={11}
                required
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="arrestDate">Arrest Date</Label>
              <Input
                type="date"
                id="arrestDate"
                name="arrestDate"
                value={formData.arrestDate}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="releaseDate">Release Date (projected)</Label>
              <Input
                type="date"
                id="releaseDate"
                name="releaseDate"
                value={formData.releaseDate}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Add Booking</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
