import React, { useState, useMemo, useEffect } from 'react';
import { SearchAndFilters } from './SearchAndFilters';
import { UserTable } from './UserTable';
import { EditModal } from './EditModal';
import { User as UserType } from '../types';
import { SortingState } from '@tanstack/react-table';

interface SortConfig {
  field: keyof UserType | null;
  direction: 'asc' | 'desc';
}


interface UserGridDisplayProps {
	users: UserType[];
	onUpdateUser: (username: string, updates: any) => Promise<void>;
	onResetPassword: (username: string, newPassword: string) => Promise<void>;
	onToggleDisable: (username: string, disabled: boolean) => Promise<void>;
	pageSize: number;
	pageIndex: number;
	onPaginationChange: (pageIndex: number, pageSize: number) => void;
	onSortingChange: (sorting: SortingState | ((old: SortingState) => SortingState)) => void;
	sorting: SortingState;
	totalCount: number;
	loading: boolean;
  }

export const UserGridDisplay: React.FC<UserGridDisplayProps> = ({
	users,
	onUpdateUser,
	onResetPassword,
	onToggleDisable,
	pageSize,
	pageIndex,
	onPaginationChange,
	onSortingChange,
	sorting,
	totalCount,
	
	loading
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [localUsers, setLocalUsers] = useState<UserType[]>(users);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ field: 'username', direction: 'asc' });
  const [showDisabled, setShowDisabled] = useState(false);
  const [showLocked, setShowLocked] = useState(false);

  useEffect(() => {
    setLocalUsers(users);
  }, [users]);

  const filteredUsers = useMemo(() => {
    if (!localUsers?.length) return [];

    let filtered = [...localUsers];

    // Apply disabled/locked filters
    if (showDisabled) {
      filtered = filtered.filter(user => user.disabled);
    }
    if (showLocked) {
      filtered = filtered.filter(user => user.locked);
    }

    // Apply search if there is a search term
    if (searchTerm?.trim()) {
      const searchTerms = searchTerm.toLowerCase().trim().split(/\s+/);

      filtered = filtered.filter((user: UserType) => {
        if (!user) return false;

        const searchFields = {
          username: (user.username || '').toLowerCase(),
          email: (user.email || '').toLowerCase(),
          name: (user.name || '').toLowerCase(),
        };

        return searchTerms.every(term => {
          return Object.values(searchFields).some(field => field.includes(term));
        });
      });
    }

    return filtered;
  }, [localUsers, searchTerm, showDisabled, showLocked]);

  const handleSort = (field: keyof UserType) => {
    setSortConfig(current => ({
      field,
      direction: current.field === field && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleUserSelect = (user: UserType) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    setSelectedUser(null);
  };

  return (
    <div>
      {/* <SearchAndFilters
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        showDisabled={showDisabled}
        setShowDisabled={setShowDisabled}
        showLocked={showLocked}
        setShowLocked={setShowLocked}
      /> */}

<UserTable
        users={users}
        onUserSelect={handleUserSelect}
        totalCount={totalCount}
        pageSize={pageSize}
        pageIndex={pageIndex}
        onPaginationChange={onPaginationChange}
        onSortingChange={onSortingChange}
        sorting={sorting}
        loading={loading}
      />

      <EditModal
        user={selectedUser}
        isOpen={isEditModalOpen}
        onClose={handleCloseModal}
        onUpdateUser={onUpdateUser}
        onResetPassword={onResetPassword}
        onToggleDisable={onToggleDisable}
      />
    </div>
  );
};

export default UserGridDisplay;