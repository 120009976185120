import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { SortingState } from '@tanstack/react-table';
import UserGridDisplay from './components/UserGridDisplay';
import { SearchAndFilters } from './components/SearchAndFilters';
import { LIST_USERS, RESET_PASSWORD, MODIFY_USER } from './queries';

const USERS_PER_PAGE = 20; // Changed to match table default

type SortDirection = 'asc' | 'desc';

interface FilterState {
  showDisabled: boolean;
  showLocked: boolean;
  selectedGroups: string[];
  hasTwoFactor: boolean | null;
  sortBy: string;
  sortDirection: SortDirection;
}


interface FilterVariables extends FilterState {
	search: string;
	limit: number;
	offset: number;
  }
  
export function UserManagement() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(USERS_PER_PAGE);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterState, setFilterState] = useState<FilterState>({
    showDisabled: false,
    showLocked: false,
    selectedGroups: [],
    hasTwoFactor: null,
    sortBy: 'username',
    sortDirection: 'asc'
  });

  const { loading, error, data, refetch } = useQuery(LIST_USERS, {
    variables: {
      filters: {
        search: searchTerm,
        limit: pageSize,
        offset: pageIndex * pageSize,
        ...filterState
      }
    },
    fetchPolicy: 'network-only'
  });

  const [resetPasswordMutation] = useMutation(RESET_PASSWORD);
  const [modifyUserMutation] = useMutation(MODIFY_USER);

  const handlePaginationChange = async (newPageIndex: number, newPageSize: number) => {
    setPageIndex(newPageIndex);
    setPageSize(newPageSize);
    try {
      const variables: FilterVariables = {
        search: searchTerm,
        limit: newPageSize,
        offset: newPageIndex * newPageSize,
        ...filterState
      };

      await refetch({ filters: variables });
    } catch (error) {
      toast.error('Failed to change page. Please try again.');
    }
  };

  const handleSearch = async (searchValue: string) => {
    setSearchTerm(searchValue);
    setPageIndex(0);
    try {
      const variables: FilterVariables = {
        search: searchValue,
        limit: pageSize,
        offset: 0,
        ...filterState
      };

      await refetch({ filters: variables });
    } catch (error) {
      toast.error('Search failed. Please try again.');
    }
  };

  const handleFilterChange = async (updates: Partial<FilterState>) => {
    const newFilterState: FilterState = { ...filterState, ...updates };
    setFilterState(newFilterState);
    setPageIndex(0);
    
    try {
      const variables: FilterVariables = {
        search: searchTerm,
        limit: pageSize,
        offset: 0,
        ...newFilterState
      };

      await refetch({ filters: variables });
    } catch (error) {
      toast.error('Failed to apply filters. Please try again.');
    }
  };

  const handleSortingChange = async (updaterOrValue: SortingState | ((old: SortingState) => SortingState)) => {
    const newSorting = typeof updaterOrValue === 'function' ? updaterOrValue(sorting) : updaterOrValue;
    setSorting(newSorting);
    
    if (newSorting.length > 0) {
      const sortField = newSorting[0].id;
      const sortDir = newSorting[0].desc ? 'desc' : 'asc';

      // Map table column sorting to filter dropdown values
      let sortValue = '';
      if (sortField === 'lastlogin') {
        sortValue = sortDir === 'desc' ? 'Last Login (Recent)' : 'Last Login (Oldest)';
      } else if (sortField === 'username') {
        sortValue = sortDir === 'asc' ? 'Username (A-Z)' : 'Username (Z-A)';
      } else if (sortField === 'warnCount') {
        sortValue = sortDir === 'desc' ? 'Warning Count (High)' : 'Warning Count (Low)';
      }

      const newFilterState: FilterState = {
        ...filterState,
        sortBy: sortField,
        sortDirection: sortDir
      };
      setFilterState(newFilterState);

      try {
        const variables: FilterVariables = {
          search: searchTerm,
          limit: pageSize,
          offset: pageIndex * pageSize,
          ...newFilterState
        };

        await refetch({ filters: variables });
      } catch (error) {
        toast.error('Failed to sort data. Please try again.');
      }
    }
  };


  const handleUpdateUser = async (username: string, updates: any) => {
    try {
      const variables: any = {
        username,
        disabled: updates.disabled ?? null,
      };

      if (updates.permissions) {
        variables.permissions = updates.permissions.map((p: {permission: string, enabled: boolean}) => ({
          permission: p.permission,
          enabled: p.enabled,
        }));
      }

      await modifyUserMutation({ variables });
      toast.success('User updated successfully');
      refetch();
    } catch (err) {
      toast.error('Failed to update user');
    }
  };

  const handleResetPassword = async (username: string, newPassword: string) => {
    try {
      await resetPasswordMutation({
        variables: { username, password: newPassword }
      });
      toast.success('Password reset successfully');
    } catch (err) {
      toast.error('Failed to reset password');
    }
  };

  const handleToggleDisable = async (username: string, disabled: boolean) => {
    try {
      await modifyUserMutation({
        variables: { username, disabled }
      });
      toast.success(`User ${disabled ? 'disabled' : 'enabled'} successfully`);
      refetch();
    } catch (err) {
      toast.error('Failed to update user status');
    }
  };

  if (error) return <div className="p-4 text-red-600">{error.message}</div>;

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <SearchAndFilters
          onSearch={handleSearch}
          filterState={filterState}
          onFilterChange={(updates) => setFilterState(prev => ({ ...prev, ...updates }))}
          availableGroups={data?.listUsers?.availableGroups || []}
          totalCount={data?.listUsers?.totalCount || 0}
          filteredCount={data?.listUsers?.entries?.length || 0}
          loading={loading}
        />

        <div className="mt-6">
          <UserGridDisplay
            users={data?.listUsers?.entries || []}
            onUpdateUser={handleUpdateUser}
            onResetPassword={handleResetPassword}
            onToggleDisable={handleToggleDisable}
            pageSize={pageSize}
            pageIndex={pageIndex}
            onPaginationChange={handlePaginationChange}
            onSortingChange={handleSortingChange}
            sorting={sorting}
            totalCount={data?.listUsers?.totalCount || 0}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}


export default UserManagement;