import * as React from 'react'
import { gql, useQuery } from '@apollo/client'
import { format } from 'date-fns'
import { CSVImportPCode } from './importPCodeReport'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"


import { Button } from "@/components/ui/button"

import { Badge } from "@/components/ui/badge"
import { Skeleton } from "@/components/ui/skeleton"
import { ScrollArea } from "@/components/ui/scroll-area"
import { DownloadIcon, RefreshCwIcon, UploadIcon } from "lucide-react"
import * as XLSX from 'xlsx'
//import { saveAs } from 'file-saver'
import Modal from 'react-modal'
import { GeneratePLevelReport } from './generatePCodeReport'

const GET_PLEVEL_REPORT = gql`
  query GetPLevelReport {
    reportPLevelReport {
      count
      items {
        id
        name
        champID
        dob
        sex
        race
        booking
        fin
        mrun
        program
        arrestCount
        ODRAssessmentDate
        pcodeOdrAssessment
        releaseDate
        pcodeRelease      
        pcodeSourceOdrAssessment
        buildingOdrAssessment
        pcodeSourceRelease
        buildingRelease
        seenCount
        error
        errormessage
      }
    }
  }
`

Modal.setAppElement('#root')

export const PCodeReport = () => {
  const [rawData, setStats] = React.useState(    {
    ODRi: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},
    ODRr: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},

    FISTi: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},
    FISTr: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},
    
    MISTi: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},
    MISTr: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},
    
    PRG_WMNi: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},
    PRG_WMNr: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0}
}) as any

  const [startDate, setStartDate] = React.useState(new Date(new Date().setMonth(new Date().getMonth() - 1)))
  const [endDate, setEndDate] = React.useState(new Date())
  const [isUploadOpen, setIsUploadOpen] = React.useState(false)
  const [isRefreshOpen, setIsRefreshOpen] = React.useState(false)
  
  const { data, loading, refetch } = useQuery(GET_PLEVEL_REPORT, {
    variables: {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd')
    },onCompleted: (data:any)=>{
        const stats = {
          ODRi: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},
          ODRr: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},

          FISTi: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},
          FISTr: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},
          
          MISTi: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},
          MISTr: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},
          
          PRG_WMNi: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},
          PRG_WMNr: { P0: 0, P1: 0, P2:0, P3:0, P4:0, "NO P LEVEL":0},
      } as any
      const {items} = data.reportPLevelReport
      console.log(items)

      items.forEach((item:any)=>{
        stats[`${item.program}r`][item.pcodeRelease]++
        stats[`${item.program}i`][item.pcodeOdrAssessment]++
      })
      setStats(stats)
      console.log(stats)
    }
  })

  const handleRefresh = () => {
    refetch({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd')
    })
  }

  const formatDate = (dateString: string) => {
    if (!dateString) return '-'
    try {
      return format(new Date(dateString), 'MMM d, yyyy')
    } catch (e) {
      return dateString
    }
  }

  const handleDownloadExcel = () => {
    if (!data?.reportPLevelReport?.items?.length) return

    const exportData = data.reportPLevelReport.items.map((item:any) => ({
      'Name': item.name || '',
      'CHAMP ID': item.champID || '',
      'DOB': formatDate(item.dob),
      'Sex': item.sex || '',
      'Race': item.race || '',
      'Booking': formatDate(item.booking),
      'Program': item.program || '',
      'Assessment Date': formatDate(item.ODRAssessmentDate),
      'P-Code (Assessment)': item.pcodeOdrAssessment || '',
      'P-Code Source (Assessment)': item.pcodeSourceOdrAssessment || '',
      'Building (Assessment)': item.buildingOdrAssessment || '',
      'Release Date': formatDate(item.releaseDate),
      'P-Code (Release)': item.pcodeRelease || '',
      'P-Code Source (Release)': item.pcodeSourceRelease || '',
      'Building (Release)': item.buildingRelease || '',
      'Seen Count': item.seenCount || '',
      'Error': item.error || '',
      'Error Message': item.errormessage || '',
    }))

    const ws = XLSX.utils.json_to_sheet(exportData)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'PCode Report')

    // Generate Excel file and download
    const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = `pcode-report-${format(new Date(), 'yyyy-MM-dd')}.xlsx`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '1850px',
      height: '95%',
      width: '95%',
      padding: '20px',
      borderRadius: '8px',
      backgroundColor: 'white',
      border: '1px solid rgb(229 231 235)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 50,
    },
  }


    // State for active view and table visibility
    const [activeView, setActiveView] = React.useState('assessment-vs-release');
    
  
    // Transform data for charts
    const transformDataForStackedChart = () => {
      const priorityLevels = ['P0', 'P1', 'P2', 'P3', 'P4', 'NO P LEVEL'];
      return priorityLevels.map(level => {
        const result = { name: level }as any
        Object.keys(rawData).forEach(category => {
          result[category] = rawData[category][level];
        });
        return result;
      });
    };
  
    const transformDataForCategoryChart = () => {
      return Object.keys(rawData).map(category => {
        const result = { name: category } as any;
        const categoryData = rawData[category];
        Object.keys(categoryData).forEach(level => {
          result[level] = categoryData[level];
        });
        // Calculate total for this category
        result.total = Object.values(categoryData).reduce((sum:any, val:any) => sum + val, 0);
        return result;
      });
    };
  
    // Create data specifically for Assessment vs Release view with systems on Y-axis
    const createAssessmentVsReleaseData = () => {
      const systems = ['ODR', 'FIST', 'MIST', 'PRG_WMN'];
      const result: any[] = [];
      
      systems.forEach(system => {
        // Map system names back to original data keys
        const assessmentKey = system === 'PRG_WMN' ? 'PRG_WMNi' : `${system}i`;
        const releaseKey = system === 'PRG_WMN' ? 'PRG_WMNr' : `${system}r`;
        
        // Calculate totals for each priority level
        const assessmentP0 = rawData[assessmentKey].P0;
        const assessmentP1 = rawData[assessmentKey].P1;
        const assessmentP2 = rawData[assessmentKey].P2;
        const assessmentP3 = rawData[assessmentKey].P3;
        const assessmentP4 = rawData[assessmentKey].P4;
        
        const releaseP0 = rawData[releaseKey].P0;
        const releaseP1 = rawData[releaseKey].P1;
        const releaseP2 = rawData[releaseKey].P2;
        const releaseP3 = rawData[releaseKey].P3;
        const releaseP4 = rawData[releaseKey].P4;
        
        result.push({
          name: system,
          Assessment_P0: assessmentP0,
          Assessment_P1: assessmentP1,
          Assessment_P2: assessmentP2,
          Assessment_P3: assessmentP3,
          Assessment_P4: assessmentP4,
          Release_P0: releaseP0,
          Release_P1: releaseP1,
          Release_P2: releaseP2,
          Release_P3: releaseP3,
          Release_P4: releaseP4,
          Assessment_Total: assessmentP0 + assessmentP1 + assessmentP2 + assessmentP3 + assessmentP4,
          Release_Total: releaseP0 + releaseP1 + releaseP2 + releaseP3 + releaseP4
        });
      });
      
      return result;
    };
  
    // Calculate statistics
    const calculateStats = () => {
      const categoryTotals = {} as any
      const priorityTotals = { P0: 0, P1: 0, P2: 0, P3: 0, P4: 0, 'NO P LEVEL': 0 } as any;
      let grandTotal = 0;
  
      Object.keys(rawData).forEach(category => {
        const categoryData = rawData[category];
        const total = Object.values(categoryData).reduce((sum:any, val:any) => sum + val, 0) as any
        categoryTotals[category] = total as any;
        grandTotal += total;
  
        Object.entries(categoryData).forEach(([priority, count]) => {
          priorityTotals[priority] += count as any;
        });
      });
  
      return {
        categoryTotals,
        priorityTotals,
        grandTotal,
        percentagesByCategory: Object.entries(categoryTotals).map(([category, total] :any) => ({
          category,
          percentage: ((total / grandTotal) * 100).toFixed(1)
        })),
        percentagesByPriority: Object.entries(priorityTotals).map(([priority, total]:any) => ({
          priority,
          percentage: ((total / grandTotal) * 100).toFixed(1)
        }))
      };
    };
  
    // Create system comparison data
    const createSystemComparisonData = () => {
      const systems = ['ODR', 'FIST', 'MIST', 'PRG_WMN'];
      
      return systems.map(system => {
        // Map system names back to original data keys
        const assessmentKey = system === 'PRG_WMN' ? 'PRG_WMNi' : `${system}i`;
        const releaseKey = system === 'PRG_WMN' ? 'PRG_WMNr' : `${system}r`;
        
        // Calculate totals
        const assessmentTotal = Object.values(rawData[assessmentKey]).reduce((sum:any, val:any) => sum + val, 0) as any;
        const releaseTotal = Object.values(rawData[releaseKey]).reduce((sum:any, val:any) => sum + val, 0) as any;
        
        return {
          name: system,
          'At Assessment': assessmentTotal,
          'At Release': releaseTotal
        };
      });
    };
  
    // Create data showing priority levels for each system, comparing assessment vs release
    const createPriorityComparisonBySystem = () => {
      const systems = ['ODR', 'FIST', 'MIST', 'PRG_WMN'];
      const result: any[] = [];
      
      systems.forEach(system => {
        // Map system names back to original data keys
        const assessmentKey = system === 'PRG_WMN' ? 'PRG_WMNi' : `${system}i`;
        const releaseKey = system === 'PRG_WMN' ? 'PRG_WMNr' : `${system}r`;
        
        const assessmentData = {
          name: `${system} - Assessment`,
          system: system,
          phase: 'Assessment',
          P0: rawData[assessmentKey].P0,
          P1: rawData[assessmentKey].P1,
          P2: rawData[assessmentKey].P2,
          P3: rawData[assessmentKey].P3,
          P4: rawData[assessmentKey].P4
        };
        
        const releaseData = {
          name: `${system} - Release`,
          system: system,
          phase: 'Release',
          P0: rawData[releaseKey].P0,
          P1: rawData[releaseKey].P1,
          P2: rawData[releaseKey].P2,
          P3: rawData[releaseKey].P3,
          P4: rawData[releaseKey].P4
        };
        
        result.push(assessmentData);
        result.push(releaseData);
      });
      
      return result ;
    };
  
    const systemComparisonData = createSystemComparisonData();
    const priorityBySystemData = createPriorityComparisonBySystem();
    const assessmentVsReleaseData = createAssessmentVsReleaseData();
  
    const colors = {
      P0: '#FF5252', // Red
      P1: '#FF9800', // Orange
      P2: '#FFC107', // Amber
      P3: '#4CAF50', // Green
      P4: '#2196F3', // Blue
      Assessment: '#7986CB', // Light purple
      Release: '#4DB6AC' // Teal
    };
  

  return (
    <>
      <Card className="w-full shadow-md">
        <CardHeader className="pb-2">
          <div className="flex items-center justify-between">
            <div>
              <CardTitle className="text-lg font-bold">DOJ PCode Report</CardTitle>
              <CardDescription className="text-sm">
                Viewing {data?.reportPLevelReport?.count || 0} client records
              </CardDescription>
            </div>
            <div className="flex items-center space-x-2">
              <Button 
                variant="outline"
                onClick={handleDownloadExcel}
                disabled={loading || !data?.reportPLevelReport?.items?.length}
              >
                <DownloadIcon className="h-4 w-4 mr-2" />
                Download Excel
              </Button>
              <Button 
                variant="outline"
                onClick={() => setIsRefreshOpen(true)}
                disabled={loading}
              >
                <RefreshCwIcon className="h-4 w-4 mr-2" />
                Refresh
              </Button>
              <Button 
                variant="default"
                onClick={() => setIsUploadOpen(true)}
              >
                <UploadIcon className="h-4 w-4 mr-2" />
                Upload CHS
              </Button>
            </div>          
          </div>
        </CardHeader>
        <div className="mb-4">
          <div className="flex space-x-2 border-b">
            <button 
              className={`px-4 py-2 font-medium ${activeView === 'assessment-vs-release' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'}`}
              onClick={() => {setActiveView('assessment-vs-release'); }}
            >
              Assessment vs Release
            </button>
            <button 
              className={`px-4 py-2 font-medium ${activeView === 'priority-by-system' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'}`}
              onClick={() => {setActiveView('priority-by-system'); }}
            >
              Priority Levels by System
            </button>
    
            <button 
              className={`px-4 py-2 font-medium ${activeView === 'chartview' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'}`}
              onClick={() => {setActiveView('chartview');}}
            >
              Table View
            </button>
            <button 
              className={`px-4 py-2 font-medium ${activeView === 'tableview' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'}`}
              onClick={() => {setActiveView('tableview');}}
            >
              Program Comparison
            </button>
            <button 
              className={`px-4 py-2 font-medium ${activeView === 'dataview' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'}`}
              onClick={() => {setActiveView('dataview'); }}
            >
              Data Overview
            </button>
          </div>
        </div>
        {activeView === 'assessment-vs-release' && (
          <div className="h-96">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart 
                data={assessmentVsReleaseData}
                layout="vertical"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis 
                  dataKey="name" 
                  type="category" 
                  width={100}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="Assessment_Total" name="Assessment" fill={colors.Assessment} />
                <Bar dataKey="Release_Total" name="Release" fill={colors.Release} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
        
        {activeView === 'priority-by-system' && (
          <div className="h-96">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart 
                data={priorityBySystemData}
                layout="vertical"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis 
                  dataKey="name" 
                  type="category" 
                  width={120}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="P0" stackId="a" fill={colors.P0} name="P0" />
                <Bar dataKey="P1" stackId="a" fill={colors.P1} name="P1" />
                <Bar dataKey="P2" stackId="a" fill={colors.P2} name="P2" />
                <Bar dataKey="P3" stackId="a" fill={colors.P3} name="P3" />
                <Bar dataKey="P4" stackId="a" fill={colors.P4} name="P4" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
        
        {activeView === 'system-comparison' && (
          <div className="h-96">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart 
                data={systemComparisonData}
                layout="vertical"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis 
                  dataKey="name" 
                  type="category"
                  width={100}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="At Assessment" fill={colors.Assessment} />
                <Bar dataKey="At Release" fill={colors.Release} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
        
        {/* Table View */}
        {activeView === 'chartview' && (
          <div className="w-full overflow-x-auto">
            <div className="my-4 font-bold text-lg text-center">ODR P-Level Release Data, October 1, 2024 - December 31, 2024</div>
            <table className="min-w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 p-2"></th>
                  <th className="border border-gray-300 p-2">ODR</th>
                  <th className="border border-gray-300 p-2">MIST</th>
                  <th  className="border border-gray-300 p-2">FIST</th>                  
                  <th  className="border border-gray-300 p-2">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-gray-100 font-semibold">
                  <td className="border border-gray-300 p-2">Assessment</td>
                </tr>
                <tr className="bg-gray-50">
                  <td className="border border-gray-300 p-2 font-semibold">P-Level</td>
                  <td className="border border-gray-300 p-2"></td>
                  <td className="border border-gray-300 p-2"></td>
                  <td className="border border-gray-300 p-2"></td>
                  <td className="border border-gray-300 p-2"></td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">P0</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRi.P0}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.MISTi.P0}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.FISTi.P0}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRi.P0 + rawData.MISTi.P0 + rawData.FISTi.P0}</td>
                </tr>
                <tr className="bg-gray-50">
                  <td className="border border-gray-300 p-2">P1</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRi.P1}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.MISTi.P1}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.FISTi.P1}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRi.P1 + rawData.MISTi.P1 + rawData.FISTi.P1}</td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">P2</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRi.P2}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.MISTi.P2}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.FISTi.P2}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRi.P2 + rawData.MISTi.P2 + rawData.FISTi.P2}</td>
                </tr>
                <tr className="bg-gray-50">
                  <td className="border border-gray-300 p-2">P3</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRi.P3}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.MISTi.P3}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.FISTi.P3}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRi.P3 + rawData.MISTi.P3 + rawData.FISTi.P3}</td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">P4</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRi.P4}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.MISTi.P4}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.FISTi.P4}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRi.P4 + rawData.MISTi.P4 + rawData.FISTi.P4}</td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">NO P LEVEL</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRi['NO P LEVEL']}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.MISTi['NO P LEVEL']}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.FISTi['NO P LEVEL']}</td>
                  <td className="border border-gray-300 p-2 text-center">
                    {rawData.ODRi['NO P LEVEL'] + rawData.MISTi['NO P LEVEL'] + rawData.FISTi['NO P LEVEL']}
                  </td>
                </tr>
                <tr className="bg-gray-100 font-semibold">
                  <td className="border border-gray-300 p-2">Total</td>
                  <td className="border border-gray-300 p-2 text-center">
                    {rawData.ODRi.P0 + rawData.ODRi.P1 + rawData.ODRi.P2 + rawData.ODRi.P3 + rawData.ODRi.P4 + rawData.ODRi['NO P LEVEL']}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {rawData.MISTi.P0 + rawData.MISTi.P1 + rawData.MISTi.P2 + rawData.MISTi.P3 + rawData.MISTi.P4 + rawData.MISTi['NO P LEVEL']}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {rawData.FISTi.P0 + rawData.FISTi.P1 + rawData.FISTi.P2 + rawData.FISTi.P3 + rawData.FISTi.P4 + rawData.FISTi['NO P LEVEL']}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {rawData.ODRi.P0 + rawData.ODRi.P1 + rawData.ODRi.P2 + rawData.ODRi.P3 + rawData.ODRi.P4 + rawData.ODRi['NO P LEVEL'] + 
                     rawData.MISTi.P0 + rawData.MISTi.P1 + rawData.MISTi.P2 + rawData.MISTi.P3 + rawData.MISTi.P4 + rawData.MISTi['NO P LEVEL'] + 
                     rawData.FISTi.P0 + rawData.FISTi.P1 + rawData.FISTi.P2 + rawData.FISTi.P3 + rawData.FISTi.P4 + rawData.FISTi['NO P LEVEL']}
                  </td>
                </tr>
                
                {/* Release Section */}
                <tr className="bg-gray-100 font-semibold">
                  <td  className="border border-gray-300 p-2">Release</td>
                </tr>
                <tr className="bg-gray-50">
                  <td className="border border-gray-300 p-2 font-semibold">P-Level</td>
                  <td className="border border-gray-300 p-2"></td>
                  <td className="border border-gray-300 p-2"></td>
                  <td className="border border-gray-300 p-2"></td>
                  <td className="border border-gray-300 p-2"></td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">P0</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRr.P0}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.MISTr.P0}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.FISTr.P0}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRr.P0 + rawData.MISTr.P0 + rawData.FISTr.P0}</td>
                </tr>
                <tr className="bg-gray-50">
                  <td className="border border-gray-300 p-2">P1</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRr.P1}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.MISTr.P1}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.FISTr.P1}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRr.P1 + rawData.MISTr.P1 + rawData.FISTr.P1}</td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">P2</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRr.P2}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.MISTr.P2}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.FISTr.P2}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRr.P2 + rawData.MISTr.P2 + rawData.FISTr.P2}</td>
                </tr>
                <tr className="bg-gray-50">
                  <td className="border border-gray-300 p-2">P3</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRr.P3}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.MISTr.P3}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.FISTr.P3}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRr.P3 + rawData.MISTr.P3 + rawData.FISTr.P3}</td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">P4</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRr.P4}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.MISTr.P4}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.FISTr.P4}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRr.P4 + rawData.MISTr.P4 + rawData.FISTr.P4}</td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">NO P LEVEL</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.ODRr['NO P LEVEL']}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.MISTr['NO P LEVEL']}</td>
                  <td className="border border-gray-300 p-2 text-center">{rawData.FISTr['NO P LEVEL']}</td>
                  <td className="border border-gray-300 p-2 text-center">
                    {rawData.ODRr['NO P LEVEL'] + rawData.MISTr['NO P LEVEL'] + rawData.FISTr['NO P LEVEL']}
                  </td>
                </tr>
                <tr className="bg-gray-100 font-semibold">
                  <td className="border border-gray-300 p-2">Total</td>
                  <td className="border border-gray-300 p-2 text-center">
                    {rawData.ODRr.P0 + rawData.ODRr.P1 + rawData.ODRr.P2 + rawData.ODRr.P3 + rawData.ODRr.P4 + rawData.ODRr['NO P LEVEL']}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {rawData.MISTr.P0 + rawData.MISTr.P1 + rawData.MISTr.P2 + rawData.MISTr.P3 + rawData.MISTr.P4 + rawData.MISTr['NO P LEVEL']}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {rawData.FISTr.P0 + rawData.FISTr.P1 + rawData.FISTr.P2 + rawData.FISTr.P3 + rawData.FISTr.P4 + rawData.FISTr['NO P LEVEL']}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {rawData.ODRr.P0 + rawData.ODRr.P1 + rawData.ODRr.P2 + rawData.ODRr.P3 + rawData.ODRr.P4 + rawData.ODRr['NO P LEVEL'] + 
                     rawData.MISTr.P0 + rawData.MISTr.P1 + rawData.MISTr.P2 + rawData.MISTr.P3 + rawData.MISTr.P4 + rawData.MISTr['NO P LEVEL'] + 
                     rawData.FISTr.P0 + rawData.FISTr.P1 + rawData.FISTr.P2 + rawData.FISTr.P3 + rawData.FISTr.P4 + rawData.FISTr['NO P LEVEL']}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        
        {activeView === 'tableview' && (
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-3">Data Overview</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">System</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phase</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">P0</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">P1</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">P2</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">P3</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">P4</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {priorityBySystemData.map((row, index) => {
                    const total = row.P0 + row.P1 + row.P2 + row.P3 + row.P4;
                    return (
                      <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{row.system}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.phase}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.P0}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.P1}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.P2}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.P3}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.P4}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{total}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {activeView === 'dataview' && <CardContent className="p-3">
          <ScrollArea className="h-[calc(100vh-240px)]">
            <div className="min-w-[2000px]">
              <Table>
                <TableHeader>
                  <TableRow className="bg-muted/50">
                    <TableHead className="py-2 text-sm font-medium">Name</TableHead>
                    <TableHead className="py-2 text-sm font-medium">CHAMP ID</TableHead>
                    <TableHead className="py-2 text-sm font-medium">DOB</TableHead>
                    <TableHead className="py-2 text-sm font-medium">Sex</TableHead>
                    <TableHead className="py-2 text-sm font-medium">Race</TableHead>
                    <TableHead className="py-2 text-sm font-medium">Booking</TableHead>
                    <TableHead className="py-2 text-sm font-medium">Program</TableHead>
                    <TableHead className="py-2 text-sm font-medium">Assessment Date</TableHead>
                    <TableHead className="py-2 text-sm font-medium">P-Code (Assessment)</TableHead>
                    <TableHead className="py-2 text-sm font-medium">P-Code Source (Assessment)</TableHead>
                    <TableHead className="py-2 text-sm font-medium">Building (Assessment)</TableHead>
                    <TableHead className="py-2 text-sm font-medium">Release Date</TableHead>
                    <TableHead className="py-2 text-sm font-medium">P-Code (Release)</TableHead>
                    <TableHead className="py-2 text-sm font-medium">P-Code Source (Release)</TableHead>
                    <TableHead className="py-2 text-sm font-medium">Building (Release)</TableHead>
                    <TableHead className="py-2 text-sm font-medium">Seen Count</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {loading ? (
                    Array(6).fill(0).map((_, index) => (
                      <TableRow key={`skeleton-${index}`}>
                        {Array(15).fill(0).map((_, cellIndex) => (
                          <TableCell key={`cell-${index}-${cellIndex}`}>
                            <Skeleton className="h-4 w-full" />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : data?.reportPLevelReport?.items?.length ? (
                    data.reportPLevelReport.items.map((item:any) => (
                      <TableRow key={item.id} className="hover:bg-muted/50">
                        <TableCell className="py-1.5 text-sm font-medium">{item.name || '-'}</TableCell>
                        <TableCell className="py-1.5 text-sm">{item.champID || '-'}</TableCell>
                        <TableCell className="py-1.5 text-sm">{formatDate(item.dob)}</TableCell>
                        <TableCell className="py-1.5 text-sm">{item.sex || '-'}</TableCell>
                        <TableCell className="py-1.5 text-sm">{item.race || '-'}</TableCell>
                        <TableCell className="py-1.5 text-sm">{formatDate(item.booking)}</TableCell>
                        <TableCell className="py-1.5 text-sm">
                          {item.program ? (
                            <Badge variant="outline" className="bg-primary/10">
                              {item.program}
                            </Badge>
                          ) : '-'}
                        </TableCell>
                        <TableCell className="py-1.5 text-sm">{formatDate(item.ODRAssessmentDate)}</TableCell>
                        <TableCell className="py-1.5 text-sm">{item.pcodeOdrAssessment || '-'}</TableCell>
                        <TableCell className="py-1.5 text-sm">{item.pcodeSourceOdrAssessment || '-'}</TableCell>
                        <TableCell className="py-1.5 text-sm">{item.buildingOdrAssessment || '-'}</TableCell>
                        <TableCell className="py-1.5 text-sm">{formatDate(item.releaseDate)}</TableCell>
                        <TableCell className="py-1.5 text-sm">{item.pcodeRelease || '-'}</TableCell>
                        <TableCell className="py-1.5 text-sm">{item.pcodeSourceRelease || '-'}</TableCell>
                        <TableCell className="py-1.5 text-sm">{item.buildingRelease || '-'}</TableCell>
                        <TableCell className="py-1.5 text-sm">{item.seenCount || '-'}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={15} className="h-24 text-center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </ScrollArea>
        </CardContent>}
      </Card>

      <Modal
        isOpen={isRefreshOpen}
        onRequestClose={() => setIsRefreshOpen(false)}
        style={customStyles}
        contentLabel="Generate PLevel Report"
      >
        <div className="mb-4">
          <h2 className="text-lg font-semibold">Generate PLevel Report</h2>
        </div>
        <GeneratePLevelReport />
      </Modal>

      <Modal
        isOpen={isUploadOpen}
        onRequestClose={() => setIsUploadOpen(false)}
        style={customStyles}
        contentLabel="Upload CHS Data"
      >
        <div className="mb-4">
          <h2 className="text-lg font-semibold">Upload CHS Data</h2>
        </div>
        <CSVImportPCode />
      </Modal>
    </>
  )
}