import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import { useEffect, useState } from "react";
import ProgramTable from "../components/ProgramItemDetails";

interface Program {
  active: boolean;
  programParticipantNumber: string;
  entryDate: string;
  programDescription: string;
  exitDate: string;
  disposition?: string;
  provider?: string;
  pathway?: string;
  releaseDate?: string;
}

interface SelectProgramModalProps {
  onClose: () => void;
  odrnumber: number;
  programs: Program[];
  onSelect: (program: {id: string} & Program) => void;
}

const UPDATE_PARTICIPANT = gql`
  mutation UpdateParticipantByODRNumber($odrnumber: Integer!, $participant: Integer, $updates: UpdateParticipantInput!) {
    updateParticipantByODRNumber(odrnumber: $odrnumber, participant: $participant, updates: $updates) {
      message
    }
  }
`;

export function SelectProgramModal({ 
  onClose, 
  odrnumber, 
  programs, 
  onSelect 
}: SelectProgramModalProps) {
  const [displayPrograms, setDisplayPrograms] = useState<Program[]>([]);
  const [showAllPrograms, setShowAllPrograms] = useState(programs.length === 1);
  const [updateParticipant] = useMutation(UPDATE_PARTICIPANT);

  useEffect(() => {
    const activeProgramCount = programs.filter((program) => program.active).length;
    if (activeProgramCount === 0) {
      setShowAllPrograms(true);
    }
    const programsToShow = (showAllPrograms || activeProgramCount === 1) 
      ? programs 
      : programs.filter((program) => program.active);
    setDisplayPrograms(programsToShow);
  }, [odrnumber, showAllPrograms, programs]);

  const onSelectProgram = async (program: Program) => {
    try {
      // Maintain the exact same structure as before
      onSelect({
        id: program.programParticipantNumber,
        ...program
      });
    } catch (error) {
      console.error('Error updating program:', error);
    }
  };

  return (
    <Dialog open={true} onOpenChange={() => {}}>
      <DialogContent className="max-w-[80vw] min-w-[60vw] [&>button]:hidden">
        <DialogHeader>
          <DialogTitle>Program Selection</DialogTitle>
        </DialogHeader>
        
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <p className="text-muted-foreground">
				{displayPrograms.length > 1 && <div>This client has multiple programs, </div>}
              
              <div>Please select the appropriate program</div>
            </p>
            <div className="flex items-center gap-2">
              <input 
                type="checkbox" 
                id="showAll" 
                checked={showAllPrograms}
                onChange={(e) => setShowAllPrograms(e.target.checked)}
                className="h-4 w-4"
              />
              <label htmlFor="showAll" className="text-sm">Show all programs</label>
            </div>
          </div>

          <ProgramTable
            programs={displayPrograms}
            onSelectProgram={onSelectProgram}
            setEditParticipant={() => {}}
            setSelectedProgramForModal={() => {}}
            showEdit={false}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}