import React, { useState, ReactNode } from "react";
import { ChevronUp, ChevronDown } from "lucide-react";

interface HistoryItem {
  program?: string;
  value?: string | number;
  firstName?: string;
  lastName?: string;
  dispositionDate?: string;
  entrydate?: string;
  disposition?: string;
}

interface HistorySectionProps {
  title: string;
  icon: React.ReactNode;
  data: HistoryItem[];
  mostRecent?: string;
  renderCollapsed: (item: HistoryItem) => string;
  renderExpanded: (item: HistoryItem) => ReactNode;
  formatDate?: (date: string) => string;
  className?: string;
  showDates?: boolean;
  maxCollapsed?: number;
  customRender?: (data: any[]) => React.ReactNode;
}

const formatDateSafely = (
  date: string | undefined,
  formatter?: (date: string) => string
): string => {
  if (!date || !formatter) return "";
  try {
    return formatter(date);
  } catch (e) {
    return "";
  }
};

const renderExpandedWithUpperProgram = (
  item: HistoryItem,
  renderExpanded: (item: HistoryItem) => ReactNode
): ReactNode => {
  const content = renderExpanded(item);

  if (typeof content === "string") {
    if (!content.includes("-")) return content;
    const [program, ...rest] = content.split("-");
    return `${program.trim().toUpperCase()} -${rest.join("-")}`;
  }

  return content;
};

export const HistorySection = ({
  title,
  icon,
  data,
  mostRecent,
  renderCollapsed,
  renderExpanded,
  formatDate,
  className = "",
  showDates = false,
  maxCollapsed = 5,
  customRender,
}: HistorySectionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (customRender) {
    return (
      <div className={className}>
        <div className="flex items-center space-x-2 mb-2">
          {icon}
          <h3 className="text-sm font-bold text-gray-900">{title}</h3>
        </div>
        {customRender(data)}
      </div>
    );
  }

  if (mostRecent) {
    return (
      <div className={className}>
        <div className="flex items-center space-x-2 mb-2">
          {icon}
          <h3 className="text-sm font-bold text-gray-900">{title}</h3>
        </div>
        <div className="text-sm text-gray-600">{mostRecent}</div>
      </div>
    );
  }

  const filteredData = data?.filter(Boolean) || [];

  const getUniqueKey = (item: HistoryItem) => {
    const baseKey = [
      item.firstName,
      item.lastName,
      item.program,
      item.value,
      item.disposition,
    ]
      .filter(Boolean)
      .join("-");

    const dateKey = showDates
      ? [
          formatDateSafely(item.dispositionDate, formatDate),
          formatDateSafely(item.entrydate, formatDate),
        ]
          .filter(Boolean)
          .join("-")
      : "";

    return `${baseKey}${dateKey ? `-${dateKey}` : ""}`;
  };

  const displayedData = isExpanded
    ? filteredData
    : filteredData.slice(0, maxCollapsed);

  return (
    <div className={`relative ${className}`}>
      <div className="flex items-center space-x-2 mb-2">
        {icon}
        <h3 className="text-sm font-bold text-gray-900">{title}</h3>
      </div>

      <div className="text-sm text-gray-600">
        {filteredData.length > 0 ? (
          <>
            <div
              className={`
              ${
                isExpanded
                  ? "absolute left-0 w-[20rem] bg-white shadow-lg rounded-lg border border-gray-200 flex flex-col z-10"
                  : "space-y-1 bg-white rounded-lg pb-0 overflow-hidden max-h-[10rem]"
              }
            `}
            >
              <div
                className={`
                ${
                  isExpanded
                    ? "overflow-y-auto max-h-[40vh] p-2 max-h-[40vh]"
                    : "pt-2 px-2"
                }
              `}
              >
                {displayedData.map((item) => (
                  <div
                    key={getUniqueKey(item)}
                    className="py-1.5 px-2 hover:bg-gray-50 rounded"
                  >
                    {isExpanded ? renderExpanded(item) : renderCollapsed(item)}
                  </div>
                ))}
              </div>

              {filteredData.length > maxCollapsed && (
                <button
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="sticky bottom-0 w-full flex items-center justify-left text-sm font-bold text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors bg-white"
                >
                  {isExpanded ? (
                    <>
                      <ChevronUp className="h-4 w-4 mr-1.5" />
                      <span>Show less</span>
                    </>
                  ) : (
                    <>
                      <ChevronDown className="h-4 w-4 mr-1.5" />
                      <span>Show ({filteredData.length}) more</span>
                    </>
                  )}
                </button>
              )}
            </div>
          </>
        ) : (
          <div className="text-sm text-gray-500 italic">No data available</div>
        )}
      </div>
    </div>
  );
};
