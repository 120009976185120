import React from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

type TwoFactorMethod = string;

interface TwoFactorSelectProps {
  value: TwoFactorMethod;
  onChange: (value: TwoFactorMethod) => void;
  disabled?: boolean;
}

export const TwoFactorSelect: React.FC<TwoFactorSelectProps> = ({
  value,
  onChange,
  disabled = false
}) => {
  return (
    <div className="space-y-1">
      <label className="block text-sm font-medium text-gray-700">
        Two-Factor Authentication Method
      </label>
      <Select
        value={value}
        onValueChange={onChange}
        disabled={disabled}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select 2FA method" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="image">Image Based</SelectItem>
          <SelectItem value="email">Email</SelectItem>
          <SelectItem value="ad">Active Directory</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};