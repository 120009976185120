import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { PermissionManager } from './permissionManager';
import { PasswordSection } from './PasswordSection';
import { EditableField } from './EditableField';
import { TwoFactorSelect } from './TwoFactorSelect';
import { TwoFactorType } from '../types';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { User as UserType, UserPermission } from '../types';

interface EditModalProps {
    user: UserType | null;
    isOpen: boolean;
    onClose: () => void;
    onUpdateUser: (username: string, updates: any) => Promise<void>;
    onDeleteUser?: (username: string) => Promise<void>;
    onResetPassword: (username: string, newPassword: string) => Promise<void>;
    onToggleDisable: (username: string, disabled: boolean) => Promise<void>;
}

interface FormErrors {
    username?: string;
    email?: string;
    name?: string;
    twofactorEmail?: string;
}

export const EditModal: React.FC<EditModalProps> = ({
    user,
    isOpen,
    onClose,
    onUpdateUser,
    onDeleteUser,
    onResetPassword,
    onToggleDisable
}) => {
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [selectedPerms, setSelectedPerms] = useState<UserPermission[]>([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        name: '',
        twoFactorMethod: 'none' as string,
        disabled: false
    });
    const [errors, setErrors] = useState<FormErrors>({});
    const [originalData, setOriginalData] = useState({
        username: '',
        email: '',
        name: '',
        twoFactorMethod: 'none' as string,
        disabled: false
    });

    useEffect(() => {
        if (user) {
            const userData = {
                username: user.username,
                email: user.email,
                name: user.name || '',
                twoFactorMethod: user.twoFactorMethod || 'none',
                disabled: user.disabled || false
            };
            setFormData(userData);
            setOriginalData(userData);

            if (user.permissions) {
                setSelectedPerms(user.permissions.map(p => ({
                    permission: p.permission,
                    enabled: p.enabled
                })));
            } else {
                setSelectedPerms([]);
            }
            setIsEditing(false);
            setErrors({});
        }
    }, [user]);

    const validateForm = () => {
        const newErrors: FormErrors = {};

        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
            newErrors.email = 'Invalid email address';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleApplyChanges = async () => {
        if (!user || !validateForm()) return;

        try {
            setIsSaving(true);

            const updates: any = {};
            if (formData.email !== originalData.email) updates.email = formData.email;
            if (formData.name !== originalData.name) updates.realname = formData.name;
            if (formData.twoFactorMethod !== originalData.twoFactorMethod) {
                updates.twoFactorMethod = formData.twoFactorMethod;
            }
            if (formData.disabled !== originalData.disabled) {
                await onToggleDisable(user.username, formData.disabled);
            }

            await onUpdateUser(user.username, updates);
            setIsEditing(false);
            setOriginalData(formData);
            toast.success('User information updated successfully');
        } catch (error) {
            console.error('Failed to update user:', error);
            toast.error('Failed to update user information');
        } finally {
            setIsSaving(false);
        }
    };

    const handleResetPassword = async () => {
        if (!user || !newPassword) return;

        try {
            await onResetPassword(user.username, newPassword);
            setNewPassword('');
            toast.success('Password reset successfully');
        } catch (error: any) {
            console.error('Failed to reset password:', error);
            toast.error(error.message || 'Failed to reset password');
        }
    };

    const handlePermissionsChange = async (newPermissions: UserPermission[]) => {
        if (!user) return;

        try {
            await onUpdateUser(user.username, { permissions: newPermissions });
            setSelectedPerms(newPermissions);
            toast.success('Permissions updated successfully');
        } catch (error) {
            console.error('Failed to update permissions:', error);
            toast.error('Failed to update permissions');
        }
    };

    const handleClose = () => {
        setFormData(originalData);
        setErrors({});
        setIsEditing(false);
        setNewPassword('');
        onClose();
    };

    if (!user) return null;

    const hasUnsavedChanges =
        formData.email !== originalData.email ||
        formData.name !== originalData.name ||
        formData.disabled !== originalData.disabled;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleClose}
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-xl p-6 w-[1000px] max-h-[90vh] overflow-y-auto"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
            <div className="space-y-6">
                <div className="flex justify-between items-start">
                    <h2 className="text-2xl font-semibold">Edit User: {user.username}</h2>
                    <button
                        onClick={handleClose}
                        className="text-gray-400 hover:text-gray-600"
                    >
                        ✕
                    </button>
                </div>

                <div className="grid grid-cols-2 gap-6">
                    <div className="space-y-6">
                        <div className="bg-gray-50 p-4 rounded-lg">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-medium">User Information</h3>
                                <button
                                    onClick={isEditing ? handleApplyChanges : () => setIsEditing(true)}
                                    disabled={isEditing && !hasUnsavedChanges}
                                    className={`px-4 py-1 rounded-md text-sm ${isEditing
                                        ? hasUnsavedChanges
                                            ? 'bg-green-500 hover:bg-green-600 text-white'
                                            : 'bg-gray-300 cursor-not-allowed text-white'
                                        : 'bg-blue-500 hover:bg-blue-600 text-white'
                                        }`}
                                >
                                    {isSaving ? 'Saving...' : isEditing ? 'Save Changes' : 'Edit Info'}
                                </button>
                            </div>

                            <div className="space-y-4">
                                <EditableField
                                    label="Username"
                                    value={formData.username}
                                    onChange={(value) => setFormData(prev => ({ ...prev, username: value }))}
                                    disabled={true}
                                    required
                                />

                                <EditableField
                                    label="Email"
                                    value={formData.email}
                                    onChange={(value) => setFormData(prev => ({ ...prev, email: value }))}
                                    disabled={!isEditing}
                                    type="email"
                                    required
                                    error={errors.email}
                                />

                                <EditableField
                                    label="Real Name"
                                    value={formData.name}
                                    onChange={(value) => setFormData(prev => ({ ...prev, name: value }))}
                                    disabled={!isEditing}
                                />

                                <TwoFactorSelect
                                    value={formData.twoFactorMethod}
                                    onChange={(value) => setFormData(prev => ({ ...prev, twoFactorMethod: value }))}
                                    disabled={!isEditing}
                                />

                                <div className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        id="userDisabled"
                                        checked={formData.disabled}
                                        onChange={(e) => setFormData(prev => ({ ...prev, disabled: e.target.checked }))}
                                        disabled={!isEditing}
                                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                    />
                                    <label htmlFor="userDisabled" className="text-sm font-medium text-gray-700">
                                        Disable User Account
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="bg-gray-50 p-4 rounded-lg">
                            <h3 className="text-lg font-medium mb-4">Account Actions</h3>
                            <div className="space-y-4">
                                <PasswordSection
                                    newPassword={newPassword}
                                    onChange={setNewPassword}
                                    onSubmit={handleResetPassword}
                                />

                                {onDeleteUser && (
                                    <button
                                        onClick={() => setIsConfirmingDelete(true)}
                                        className="w-full px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                                    >
                                        Delete User
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="bg-gray-50 p-4 rounded-lg">
                        <PermissionManager
                            selectedPermissions={selectedPerms}
                            onChange={handlePermissionsChange}
                        />
                    </div>
                </div>
            </div>

            {onDeleteUser && (
                <DeleteConfirmationModal
                    isOpen={isConfirmingDelete}
                    onClose={() => setIsConfirmingDelete(false)}
                    onConfirm={async () => {
                        await onDeleteUser(user.username);
                        setIsConfirmingDelete(false);
                        handleClose();
                    }}
                    username={user.username}
                />
            )}
        </Modal>
    );
};

export default EditModal;