import React from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { isoDateToMDY } from "../../utils/common";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { CreateNote } from "./modals/CreateNote";

const GET_NOTES_DATA = gql`
  query GetNotesData($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
      notes {
        note
        date
        author
        program
      }
    }
  }
`;

export const Notes = ({ odrnumber, programNumber }: { odrnumber: number, programNumber: string }) => {
  const { loading, error, data, refetch } = useQuery(GET_NOTES_DATA, {
    variables: { odrnumber },
    skip: !odrnumber,
  });
  
  const [showAddNote, setShowAddNote] = React.useState(false);

  if (loading) return <p className="text-gray-600 p-4">Loading notes data...</p>;
  if (error) return <p className="text-red-500 p-4">Error loading notes data :(</p>;
  if (!data) return null;

  const notesData = data.getParticipantByODRNumber.notes;

  return (
    <div className="w-full">
      {/* Header */}
      <div className="flex justify-between items-center mb-4 p-2 bg-gray-50">
        <h3 className="text-lg font-medium">Notes</h3>
        <button 
          onClick={() => setShowAddNote(true)}
          className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm"
        >
          Add Note
        </button>
      </div>

      {/* Notes List */}
      <div className="space-y-1">
        {notesData.map((note: any) => (
          <div 
            key={note.date} 
            className="grid grid-cols-[8rem_6rem_1fr_12rem] gap-4 p-2 border-b border-gray-200 hover:bg-gray-50 items-start"
          >
            <div className="text-sm text-gray-600">{isoDateToMDY(note.date)}</div>
            <div className="text-sm font-medium text-blue-600">{note.program.toUpperCase()}</div>
            <div className="text-sm whitespace-pre-wrap">{note.note}</div>
            <div className="text-sm text-gray-600">By: {note.author}</div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {showAddNote && (
        <CreateNote 
          onClose={() => setShowAddNote(false)} 
          odrnumber={odrnumber} 
          programNumber={programNumber}
          refetch={refetch}
        />
      )}
    </div>
  );
};