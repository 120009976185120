import React from 'react';
import { useQuery, gql, useMutation, useApolloClient } from '@apollo/client';
import { useParams,Link } from 'react-router-dom';
import { FormInput } from './lib/formRender';
import { FormView } from './lib/formView';
import  ReactModal from 'react-modal';
import {toast} from 'react-toastify';

import { FormCarePlan } from './careplan';
import { FormInitialAssessment } from './initialassessment';

import { useUser } from '../../store/useUser';

import { SearchParticipants } from './search';
import { useSetHelpTopic } from '../../store/useHelpTopics';

const DELETE_PARTICIPANT_DOCUMENT = gql`
  mutation DeleteParticipantDocument($id: Integer!) {
    deleteParticipantDocument(id: $id) {
      id      
    }
  }
`;


const GET_PARTICIPANT_DOCUMENT = gql`
  query GetParticipantDocument($documentID: Integer!) {
    getParticipantDocument(documentID: $documentID) {
      id
      odrparticipant
      contents
      template
      tags            
      type
      metadata
    }
  }
`;

const UPDATE_PARTICIPANT_DOCUMENT = gql`
  mutation UpdateParticipantDocument($id: Integer!, $updates: GenericJSONDocumentInput!) {
    updateParticipantDocument(id: $id, updates: $updates) {
      id
      contents
      published
      tags            
      metadata
      type
      date
      odrparticipant
      template
    }
  }
`;

const CREATE_PARTICIPANT_DOCUMENT = gql`
  mutation CreateParticipantDocument($updates: GenericJSONDocumentInput!) {
    createParticipantDocument( updates: $updates) {
      id
      contents
      published
      tags            
      metadata
      type
      date
      odrparticipant
      template
    }
  }
`;

const LIST_FORM_TEMPLATES = gql`
  query listFormTemplates {
    listFormTemplates {
      id
      name
      body
    }
  }
`;


const TRANSFER_PARTICIPANT_DOCUMENTS = gql`
  mutation TransferParticipantDocuments($odrnumber: Integer!, $documentID: [String]) {
    transferParticipantDocuments(odrnumber: $odrnumber, documentID: $documentID)
  }
`;

const GET_PARTICIPANT_BY_ODRNUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
      id 
      name {
        firstName
        lastName
      }
  
      documents {
        id        
        published
        tags                
        type        
        metadata
        date
      }
    }
  }
`;

const d = (date:any)=>{
  if (!date) return ('')
  const [year, month, day] = date.substring(0, 10).split('-');
  return `${month}-${day}-${year}`;
}

const alias = {
  LACAREREF : 'LACare Referral',
  LACARE_Initial_Assessment : 'Imported Initial Assessment',
  LACARE_Care_Plan : 'Imported Care Plan',
  LACAREProgram_Completion : 'Imported Program Completion',
} as any

export const ParticipantDocuments = (params:any) => {
  const { participant: reactParticipant } = params
  const { odrparticipant: urlParticipant } = useParams() as any;
  const odrparticipant = reactParticipant ||urlParticipant;

  useSetHelpTopic('Participant Documents');

  
  const [createDocument] = useMutation(CREATE_PARTICIPANT_DOCUMENT);
  const [deleteDocument] = useMutation(DELETE_PARTICIPANT_DOCUMENT);
  const [updateDocument] = useMutation(UPDATE_PARTICIPANT_DOCUMENT);

  const [transferDocuments] = useMutation(TRANSFER_PARTICIPANT_DOCUMENTS);

  const { user, isUserLoading } = useUser();
  const [participant, setParticipant] = React.useState ({ name: { firstName: '', lastName: '' }, documents: [] });


  const [isAltView, setIsAltView] = React.useState(false) as any;
  //const participant = data.getParticipantByODRNumber ||{ name: { firstName: '', lastName: '' }, documents: [] };


  const { loading: docListLoading, error: docListError, data: docList } = useQuery(LIST_FORM_TEMPLATES);
  const { loading, error, refetch } = useQuery(GET_PARTICIPANT_BY_ODRNUMBER, {
    variables: { odrnumber: parseInt(odrparticipant) },
    onCompleted: (data) => {
      const participantData = data.getParticipantByODRNumber ||{ name: { firstName: '', lastName: '' }, documents: [] };
        if (!participantData.name ) {
          participantData.name = { firstName: '', lastName: '' }
        }
        if (!participantData.documents) {
          participantData.documents = []
          }
        setParticipant(participantData)      
      }
  }); 

  const client = useApolloClient();
  const importPreviousCarePlan = async () => {

    const mostRecentCarePlan = findMostRecentCarePlan();
    if (!mostRecentCarePlan) {
      toast.error('No previous Care Plan found');
      return;
    }
  
    try {
      const { data } = await client.query({
        query: GET_PARTICIPANT_DOCUMENT,
        variables: { documentID: mostRecentCarePlan.id },
      });
  
      if (data && data.getParticipantDocument) {
        setNewDocumentState(JSON.parse(data.getParticipantDocument.contents));
        toast.success('Previous Care Plan imported');
      }
    } catch (error) {
      console.error('Error importing previous Care Plan', error);
      toast.error('Error importing previous Care Plan');
    }
  };

  const [currentSelectedType, setCurrentSelectedType] = React.useState('') as any;
  const [newDocumentState, setNewDocumentState] = React.useState() as any;
  const [newDocumentModal, setNewDocumentModal] = React.useState() as any;

  const [transferDocumentModal, setTransferDocumentModal] = React.useState() as any;
  const [currentDocumentID, setCurrentDocumentID] = React.useState(null) as any;
  const [currentDocument, setCurrentDocument] = React.useState({template:[]}) as any;
  const [printView, setPrintView] = React.useState(false) as any;  
  const [editMode, setEditMode] = React.useState(false) as any;
  const [adminView, setAdminView] = React.useState(false) as any;

  const [selectDocuments, setSelectDocuments] = React.useState([]) as any; 
  
  const CarePlanHeader = () => {
    return (
      <div className="grid grid-cols-3 gap-2 pt-4">
        <button 
          onClick={importPreviousCarePlan}
          className="px-3 py-1 bg-blue-500 text-white text-sm rounded hover:bg-blue-600"
        >
          Import Previous Care Plan
        </button>
        <div/>
        <button
          onClick={()=>{setIsAltView(!isAltView)}}
          className="px-3 py-1 bg-gray-200 text-gray-700 text-sm rounded hover:bg-gray-300"
        >
          {isAltView ? 'Full Care Plan' : 'Mini Care Plan View'}
        </button>
      </div>
    )
  }

  const startTransfer = async (participant:any) => {
    //create a popup to confirm transfer
    const confirmed = window.confirm(`Are you sure you want to transfer these document to ODR: ${participant.id} CID: ${participant.cid} ?`);
    if (!confirmed) {
      return; // User cancelled the deletion
    }

    const documentID = selectDocuments.map((i:any)=>`${i}`);
    console.log('transfer', participant, documentID)
    const { data } = await transferDocuments({
      variables: {
        odrnumber: participant.id,
        documentID: documentID
      }
    });


    setTransferDocumentModal(false);
    refetch();
  }

  //console.log('user', user)


  const [ legacyDocument, setLegacyDocument] = React.useState({
    id: null,
    type : '',  
  
  }) as any;

  React.useEffect (() => {    
    if (printView==true){
        window.print()
        setPrintView(false);        
    }
}, [printView]);


  useQuery(GET_PARTICIPANT_DOCUMENT, {
    variables: { documentID: currentDocumentID ? currentDocumentID : null },
    skip: currentDocumentID==null || currentDocumentID==undefined,
      onCompleted: (data) => {
        setCurrentDocument({ 
          id: data.getParticipantDocument.id,
          template: JSON.parse(data.getParticipantDocument.template),
          altTemplate: null
        });

        setNewDocumentState(JSON.parse(data.getParticipantDocument.contents));
        
    },
  });

  if (loading || docListLoading || isUserLoading) return (
    <div className="flex h-screen items-center justify-center">
      <div className="h-8 w-8 animate-spin rounded-full border-2 border-blue-500 border-t-transparent"></div>
    </div>
  );
  
  if (error || docListError) {    
    return (
      <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded">
        Error loading Data
      </div>
    );
  }

  
  
  const findMostRecentCarePlan = () => {
    const carePlans = participant.documents.filter((doc: any) => doc.type === 'Care Plan') as any;
    if (carePlans.length === 0) return null;
    return carePlans.reduce((mostRecent: any, current: any) => {
      return new Date(current.date) > new Date(mostRecent.date) ? current : mostRecent;
    });
  };
  

  const documentsByType = participant.documents.reduce((acc:any, document:any) => {
    const type = alias[document.type] || document.type;

    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(document);
    return acc;
  }, {});

  
const templateTypesFull = docList.listFormTemplates.map((template:any) => ({ ...template })); // Create a shallow copy of each template object

templateTypesFull.forEach((template: any) => {
  if (template.name === 'Care Plan') {
    // Find the corresponding altTemplate
    const altTemplate = templateTypesFull.find((t: any) => t.name === '_Care Plan');

    // Check if altTemplate exists and set the altTemplate property accordingly
    if (altTemplate) {
      // Create a new object with the same properties as template and add the altTemplate property
      template = { ...template, altTemplate: altTemplate.body };
    }
  }
});

// Now templateTypesFull contains objects with the altTemplate property

  
  const templatesByType = templateTypesFull.reduce((acc:any, template:any) => { 
    if (!acc[template.name]) {
      acc[template.name] = [];
    }
    acc[template.name]=template;
    return acc;
  },{})

  templateTypesFull.forEach((template: any) => {
    if (template.name === 'Care Plan') {
      // Find the corresponding altTemplate
      const altTemplate = templateTypesFull.find((t: any) => t.name === '_Care Plan');
  
      // Check if altTemplate exists and set the altTemplate property accordingly
      if (altTemplate) {
        template.altTemplate = altTemplate.body;
      }
    }
  });

  const templateTypes = templateTypesFull.filter((template: any) => !template.name.startsWith('_'));


  const createNewDocument = async () => {
    if (!currentSelectedType) {
      return toast.error('Please select a document type');
    }
    setNewDocumentState({})
    setNewDocumentModal(true);
  }


  const handleDeleteDocument = async () => {
    const confirmed = window.confirm('Are you sure you want to delete this document?');
    
    if (!confirmed) {
      return; // User cancelled the deletion
    }
  
    try {
      const { data } = await deleteDocument({
        variables: {
          id: currentDocument.id,          
        }
      });
      toast.success('Document Updated');
      setCurrentDocumentID(null)
      setEditMode(false) 
      refetch();
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleEditDocument = async () => {
    console.log('handleEditDocument', currentDocument)
    try {
      const { data } = await updateDocument({
        variables: {
          id: currentDocument.id,
          updates: {            
            contents: JSON.stringify(newDocumentState),
            published: 0,            
            metadata: '{}',
            type: currentSelectedType,            
            odrparticipant: parseInt(odrparticipant),
            template: JSON.stringify(currentDocument.template)
          }
        }
      });
      toast.success('Document Updated');
      setCurrentDocumentID(null)
      setEditMode(false) 
      refetch();
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleCreateDocument = async () => {
    try {
      const { data } = await createDocument({
        variables: {
          updates: {
            contents: JSON.stringify(newDocumentState),
            published: 0,            
            metadata: '{}',
            type: currentSelectedType,            
            odrparticipant: parseInt(odrparticipant),
            template: templatesByType[currentSelectedType].body
          }
        }
      });
      toast.success('Document Created');
      refetch();
    } catch (error) {
      // Handle error
    }
  };

  const viewDocument = async (document:any) => {    
    setCurrentDocumentID(document.id);
  };

  if (printView){          
    const printStyle={
      position: 'fixed',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      backgroundColor: 'white', // Semi-transparent black background
      zIndex: '9999', // Set a high z-index to ensure it's on top of other elements
    } as any


      if (legacyDocument.type=='inital') return <div style={printStyle}><FormInitialAssessment id={legacyDocument.id} /></div>
      if (legacyDocument.type=='careplan') return <div style={printStyle}><FormCarePlan id={legacyDocument.id} /></div>
      
      return <FormView print ={true} formTemplate={currentDocument.template} formState={newDocumentState}/>
    
  }

  // Custom modal styles
  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '90%',
      maxHeight: '90%',
      borderRadius: '0.5rem',
      padding: '1rem'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  };

  return (
    <div className="grid grid-cols-3 gap-4 p-4">
      <div className="rounded-lg border bg-card p-4 shadow-sm">
        <h2 className="text-base font-semibold mb-3">{`${participant.name.firstName} ${participant.name.lastName}`}</h2>
        
        <div className="space-y-2 mb-4">
          <h3 className="text-sm font-medium">Create new document</h3>
          <div className="flex gap-2">
            <select 
              value={currentSelectedType} 
              onChange={(e) => setCurrentSelectedType(e.target.value)}
              className="flex-1 h-8 text-sm rounded-md border border-input bg-background px-2"
            >
              <option value=''>Select type</option>
              {templateTypes.map((template: any) => (
                <option key={template.id} value={template.name}>{template.name}</option>
              ))}
            </select>
            <button 
              onClick={createNewDocument}
              className="h-8 px-3 text-sm bg-primary text-primary-foreground rounded-md hover:bg-primary/90"
            >
              New
            </button>
          </div>
        </div>

        <h3 className="text-sm font-medium mt-4 mb-2">Documents</h3>
        {Object.entries(documentsByType).map(([type, documents]: [string, any]) => (
          <div key={type} className="mb-3">
            <div className="text-sm font-medium text-muted-foreground mb-1">{type}</div>
            <div className="space-y-1">
              {documents.map((doc: any, index: number) => (
                <div key={`${doc.id}-${index}`} 
                  className="flex items-center justify-between text-sm py-0.5"
                >
                  {adminView && (
                    <input 
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300"
                      onChange={(e) => {
                        const newDocs = e.target.checked 
                          ? [...selectDocuments, doc.id]
                          : selectDocuments.filter((i: any) => i !== doc.id);
                        setSelectDocuments(newDocs);
                      }}
                    />
                  )}
                  <span className="text-sm text-muted-foreground">{d(doc.date)}</span>
                  <button 
                    onClick={() => viewDocument(doc)}
                    className="px-2 py-1 text-xs bg-secondary text-secondary-foreground rounded-md hover:bg-secondary/80"
                  >
                    View
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="col-span-1">
        { newDocumentModal && 
          <ReactModal 
            isOpen={true} 
            onRequestClose={()=>{setNewDocumentModal(false)}}
            style={customModalStyles}
          >
            <div className="grid grid-cols-[95%_1fr] mb-4">
              <div><button 
                onClick={()=>{setNewDocumentModal(false); handleCreateDocument()}}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >Save</button></div>
              <div><button 
                onClick={()=>{setNewDocumentModal(false)}}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              >Close</button></div>
            </div>
            {templatesByType[currentSelectedType]?.name == "Care Plan" && <CarePlanHeader/>}
            <FormInput formTemplate={JSON.parse((isAltView) && templatesByType[currentSelectedType]?.altTemplate || templatesByType[currentSelectedType]?.body)} formState={newDocumentState} setFormState ={setNewDocumentState} onSubmit ={handleCreateDocument}  onClose={()=>{}}/>
            <button 
              onClick={()=>{setNewDocumentModal(false); handleCreateDocument()}}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mt-4"
            >Save</button>
          </ReactModal>
        }

        { currentDocumentID && !editMode &&   //Read Only View
          <ReactModal 
            isOpen={true} 
            onRequestClose={()=>{setCurrentDocument(null)}}
            style={customModalStyles}
          >
            <div className="grid grid-cols-[95%_1fr] mb-4">
              <div>                
                <button 
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 mr-2"
                  onClick={()=>{setPrintView(true);}}
                >Print</button>
                <button 
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={()=>{setEditMode(true)}}
                >Edit</button>            
            </div>
            <div><button 
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              onClick={()=>{setCurrentDocumentID(null); setEditMode(false)}}
            >Close</button></div>
            </div>
            <FormView formTemplate={currentDocument.template} formState={newDocumentState} setFormState ={setNewDocumentState} onSubmit ={handleCreateDocument}  onClose={()=>{}}/>
          </ReactModal>
        }


      { currentDocumentID && editMode &&
          <ReactModal 
            isOpen={true} 
            onRequestClose={()=>{setCurrentDocument(null)}}
            style={customModalStyles}
          >
            <div className="grid grid-cols-[93%_1fr] mb-4">
              <div>
                <button 
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
                  onClick={handleEditDocument}
                >Save</button>
                
                <button
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                  onClick={()=>{setEditMode(false)}}
                >View</button>                
              </div>
              <div className="grid grid-cols-2 gap-1">
                 <button 
                   className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 mr-1"
                   onClick={handleDeleteDocument}
                 >Delete</button>
                 <button
                   className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                   onClick={()=>{setCurrentDocumentID(null); setEditMode(false); setCurrentDocumentID(null)}}
                 >Close</button>
                </div>
            </div>
            <FormInput formTemplate={currentDocument.template} formState={newDocumentState} setFormState={setNewDocumentState} onSubmit={handleEditDocument} onClose={()=>{}}/>
          </ReactModal>
        }


      { legacyDocument.type!='' &&
          <ReactModal 
            isOpen={true} 
            onRequestClose={()=>{setCurrentDocument(null)}}
            style={customModalStyles}
          >
            <div className="grid grid-cols-[95%_1fr] mb-4">
              <div>
                <button 
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 mr-2"
                  onClick={()=>{setPrintView(true);}}
                >Print</button>                
              </div>
              <div>
                <button
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                  onClick={()=>{setLegacyDocument({type:'', id:null}); setEditMode(false)}}
                >Close</button>
              </div>            
              {legacyDocument.type=='inital' && <FormInitialAssessment id={legacyDocument.id} />}
              {legacyDocument.type=='careplan' && <FormCarePlan id={legacyDocument.id} />}            
            </div>
          </ReactModal>
        }

      { transferDocumentModal &&
           <ReactModal 
             isOpen={true} 
             onRequestClose={()=>{setTransferDocumentModal(false)}}
             style={customModalStyles}
           >
             <div className="grid grid-cols-[95%_1fr] mb-4">
                <div></div>
             <div>
               <button
                 className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                 onClick={()=>{setTransferDocumentModal(false)}}
               >Close</button>
             </div>            
             <SearchParticipants onSelect={startTransfer}/>
             </div>
           </ReactModal>
        }
      </div>
      
      <div className="bg-white p-6 rounded shadow-sm border border-gray-200">
        <div className="flex justify-between mb-4">
          {(user.username == 'BENLOE') && 
            <Link to={`/admin/forms`}>
              <button className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300">
                Edit Form Templates
              </button>
            </Link>
          }
          {(user.username == 'BENLOE' || user.username == 'E680442') && 
            <button 
              onClick={()=>{setAdminView(!adminView)}}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
            >
              {adminView ? 'Exit Admin' : 'Admin'}
            </button>
          }
        </div>
        
        <h3 className="text-lg font-medium mb-4">Participant Information</h3>
        <div className="mb-2">ODR Number: {odrparticipant}</div>
        <div className="mb-2">First Name: {participant.name.firstName}</div>
        <div className="mb-2">Last Name: {participant.name.lastName}</div>
        <div></div>
        {(user.username == 'BENLOE' || user.username == 'E680442') && adminView && 
          <div className="mt-4"> 
            <button 
              onClick={()=>{setTransferDocumentModal(true)}}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Transfer
            </button>
          </div>
        }
      </div>
    </div>
  );
};