import React, { useState, useEffect } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import Select from "react-select";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Alert, AlertDescription } from "@/components/ui/alert";

const UPDATE_PARTICIPANT = gql`
  mutation UpdateParticipantByODRNumber($odrnumber: Integer!, $participant: Integer, $updates: UpdateParticipantInput!) {
    updateParticipantByODRNumber(odrnumber: $odrnumber, participant: $participant, updates: $updates) {
      message
    }
  }
`;

const GET_ICD10_CODES = gql`
  query GetICD10Codes {
    getICD10Codes {
      icd10
      icd10Description
    }
  }
`;

interface ICD10Option {
  value: string;
  label: string;
}

interface MedicalData {
  icd10?: string;
  psychotropicMeds?: string;
  otherMeds?: string;
  currentMeds?: string;
  clinicalPresentation?: string;
  psychHistory?: string;
  medicalProblems?: string;
  substanceHistory?: string;
  dsm5dx?: string;
}

interface FormData {
  icd10Codes: ICD10Option[];
  psychotropicMeds: string;
  otherMeds: string;
  clinicalPresentation: string;
  psychHistory: string;
  medicalProblems: string;
  substanceHistory: string;
  dsm5dx: string;
  currentMeds: string;
}

interface EditMedicalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  programNumber: number;
  medicalData: MedicalData;
}

export const EditMedical: React.FC<EditMedicalProps> = ({
  isOpen,
  onClose,
  refetch,
  programNumber,
  medicalData,
}) => {
  const [formData, setFormData] = useState<FormData>({
    icd10Codes: [],
    psychotropicMeds: "",
    otherMeds: "",
    clinicalPresentation: "",
    psychHistory: "",
    medicalProblems: "",
    substanceHistory: "",
    dsm5dx: "",
    currentMeds: "",
  });

  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: icd10Data, loading: icd10Loading, error: icd10Error } = useQuery(GET_ICD10_CODES);
  const [updateParticipant] = useMutation(UPDATE_PARTICIPANT);

  const icd10Options: ICD10Option[] = React.useMemo(() => {
    if (!icd10Data?.getICD10Codes) return [];
    return icd10Data.getICD10Codes.map((code: { icd10: string; icd10Description: string }) => ({
      value: code.icd10,
      label: `${code.icd10} - ${code.icd10Description}`,
    }));
  }, [icd10Data]);

  useEffect(() => {
    if (medicalData) {
      setFormData({
        icd10Codes: medicalData.icd10
          ? medicalData.icd10.split(',').map(
              (code) =>
                icd10Options.find((option) => option.value === code.trim()) || {
                  value: code.trim(),
                  label: code.trim(),
                }
            )
          : [],
        psychotropicMeds: medicalData.psychotropicMeds || "",
        otherMeds: medicalData.otherMeds || "",
        clinicalPresentation: medicalData.clinicalPresentation || "",
        psychHistory: medicalData.psychHistory || "",
        medicalProblems: medicalData.medicalProblems || "",
        substanceHistory: medicalData.substanceHistory || "",
        dsm5dx: medicalData.dsm5dx || "",
        currentMeds: medicalData.currentMeds || "",
      });
    }
  }, [medicalData, icd10Options]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setError(null);
  };

  const handleIcd10Change = (selectedOptions: readonly ICD10Option[] | null) => {
    setFormData((prev) => ({ 
      ...prev, 
      icd10Codes: selectedOptions ? Array.from(selectedOptions) : [] 
    }));
    setError(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);

    try {
      await updateParticipant({
        variables: {
          odrnumber: programNumber,
          updates: {
            psychotropicMeds: formData.psychotropicMeds,
            otherMeds: formData.otherMeds,
            clinicalPresentation: formData.clinicalPresentation,
            psychHistory: formData.psychHistory,
            medicalProblems: formData.medicalProblems,
            substanceHistory: formData.substanceHistory,
            dsm5dx: formData.dsm5dx,
            currentMeds: formData.currentMeds,
            icd10: formData.icd10Codes.map(code => code.value).join(','),
          },
        },
      });
      await refetch();
      onClose();
    } catch (error) {
      setError(error instanceof Error ? error.message : "An error occurred while updating medical data");
    } finally {
      setIsSubmitting(false);
    }
  };

  const formFields = [
    { label: "DSM5 Diagnosis", name: "dsm5dx" },
    { label: "Psychotropic Medication", name: "psychotropicMeds" },
    { label: "Current Medication", name: "currentMeds" },
    { label: "Other Medication", name: "otherMeds" },
    { label: "Clinical Presentation", name: "clinicalPresentation" },
    { label: "Psychiatric History", name: "psychHistory" },
    { label: "Medical Problems", name: "medicalProblems" },
    { label: "Substance History", name: "substanceHistory" },
  ];

  if (icd10Loading) {
    return (
      <Dialog open={isOpen}>
        <DialogContent>
          <div className="flex items-center justify-center h-40">
            <div className="text-gray-600">Loading ICD-10 codes...</div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  if (icd10Error) {
    return (
      <Dialog open={isOpen}>
        <DialogContent>
          <Alert variant="destructive">
            <AlertDescription>
              Error loading ICD-10 codes. Please try again later.
            </AlertDescription>
          </Alert>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-xl font-bold">Edit Medical Information</DialogTitle>
        </DialogHeader>

        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <Label htmlFor="icd10">ICD10 Codes</Label>
            <Select
              isMulti
              id="icd10"
              name="icd10Codes"
              options={icd10Options}
              value={formData.icd10Codes}
              onChange={handleIcd10Change}
              placeholder="Search and select ICD10 codes..."
              className="w-full"
              isDisabled={isSubmitting}
              classNames={{
                control: () => "!bg-background",
                input: () => "!text-sm",
                option: () => "!text-sm",
              }}
            />
          </div>

          {formFields.map((field) => (
            <div key={field.name} className="space-y-2">
              <Label htmlFor={field.name}>{field.label}</Label>
              <Textarea
                id={field.name}
                name={field.name}
                value={formData[field.name as keyof FormData] as string}
                onChange={handleInputChange}
                className="min-h-[100px]"
                disabled={isSubmitting}
              />
            </div>
          ))}

          <div className="flex justify-end space-x-2 pt-4">
            <Button 
              variant="outline"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button 
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Updating..." : "Update"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditMedical;