import React from "react";
import { Edit } from "lucide-react";
import { isoDateToMDY } from "../../../utils/common";
import { Button } from '@/components/ui/button';


interface ProgramItemDetailsProps {
  program: any;
  isActive: boolean;
  showAll: boolean;
  style: string;
  setSelectedProgramForModal: (program: any) => void;
  setEditParticipant: (participant: any) => void;
  showEdit: boolean;
  className: string;
}

export const ProgramItemHeader= ({className}: {className: string})=>{
  return (
    <div className={className}>
      <div className="text-sm">Active</div>
      <div className="text-sm">Program</div>
      <div className="text-sm">Entry</div>
      <div className="text-sm">Exit</div>
      <div className="text-sm">Disposition</div>
    </div>
  )
}

export const ProgramItemDetails = ({program, isActive, showAll, style, setSelectedProgramForModal, setEditParticipant, showEdit, className}: ProgramItemDetailsProps) => {
return (
    <div
      key={program.programParticipantNumber}
      className={className}
    >
      <div className="text-sm">
        {isActive ? (
          <span className="text-green-600 font-medium">Active</span>
        ) : (
          <span className="text-gray-500">Inactive</span>
        )}
      </div>
      <div
        className={`text-sm  cursor-pointer text-blue-600 hover:text-blue-800`}
        onClick={() =>
          setSelectedProgramForModal(
            program.programParticipantNumber
          )
        }
      >
        {program.programDescription.toUpperCase()}
      </div>

      <div className="text-sm text-gray-600">
        {isoDateToMDY(program.entryDate)}
      </div>
      <div className="text-sm text-gray-600">
        {isoDateToMDY(program.exitDate)}
      </div>
      <div className="text-sm text-gray-900">
        {program.disposition}
      </div>
      <div className="text-sm text-gray-900">
        {program.provider}
      </div>
      <div className="text-sm text-gray-900">{program.pathway}</div>
      <div className="text-sm text-gray-600">
        {isoDateToMDY(program.releaseDate)}
      </div>
      <div className="flex items-center gap-2">
        {showEdit && (
          <button
            onClick={() => {
              setEditParticipant({
                show: true,
              program: { ...program },
              active: isActive,
            });
            setSelectedProgramForModal(null);
          }}
          className="inline-flex items-center text-blue-600 hover:text-white text-blue-600 hover:bg-blue-800 px-2 py-1 rounded-md"
        >
          <Edit className="mr-2 h-4 w-4" />
          <div className="text-xs">Edit</div>
        </button>
        )}
        {/*showAll && (
          <button
            onClick={() => {
              setShowAll(false);
              setSelectedProgram({id: program.programParticipantNumber, description: program.programDescription});
            }}
            className="inline-flex items-center text-blue-600 hover:text-white text-blue-600 hover:bg-blue-800 px-2 py-1 rounded-md"
          >
            <MousePointer2 className="h-4 w-4 mr-1" />
            <span className="text-xs">Select</span>
          </button>
        )*/}
      </div>
    </div>
    )
}






interface Program {
  programParticipantNumber: string;
  programDescription: string;
  entryDate: string;
  exitDate: string;
  disposition: string;
  provider: string;
  pathway?: string;
  releaseDate?: string;
  active: boolean;
}

interface EditParticipant {
  show: boolean;
  program: Program;
  active: boolean;
}

interface ProgramTableProps {
  programs: any;
  onSelectProgram: (program: Program) => void;
  setEditParticipant: (edit: EditParticipant) => void;
  setSelectedProgramForModal: (id: string | null) => void;
  showEdit?: boolean;
}

const ProgramTable: React.FC<ProgramTableProps> = ({ 
  programs, 
  onSelectProgram, 
  setEditParticipant, 
  setSelectedProgramForModal, 
  showEdit = false 
}) => {
  return (
    <div className="w-full border rounded-lg overflow-hidden">
      {/* Header */}
      <div className="grid grid-cols-7 bg-gray-50 p-3 ">
        <div className="text-sm font-medium text-gray-700">Active</div>
        <div className="text-sm font-medium text-gray-700">Program</div>
        <div className="text-sm font-medium text-gray-700">Entry</div>
        <div className="text-sm font-medium text-gray-700">Exit</div>
        <div className="text-sm font-medium text-gray-700">Disposition</div>
        <div className="text-sm font-medium text-gray-700">Provider</div>
        <div className="text-sm font-medium text-gray-700">Actions</div>
      </div>

      {/* Table Body */}
      <div className="divide-y">
        {programs.map((program: any) => (
          <div 
            key={program.programParticipantNumber}
            className="grid grid-cols-7 p-2 hover:bg-gray-50 items-center"
          >
            <div className="text-sm">
              {program.active ? (
                <span className="text-green-600 font-medium">Active</span>
              ) : (
                <span className="text-gray-500">Inactive</span>
              )}
            </div>
            
            <div
              className="text-sm text-blue-600 hover:text-blue-800 cursor-pointer"
              onClick={() => setSelectedProgramForModal(program.programParticipantNumber)}
            >
              {program.programDescription.toUpperCase()}
            </div>
            
            <div className="text-sm text-gray-600">
              {isoDateToMDY(program.entryDate)}
            </div>
            
            <div className="text-sm text-gray-600">
              {isoDateToMDY(program.exitDate)}
            </div>
            
            <div className="text-sm text-gray-900">
              {program.disposition}
            </div>
            
            <div className="text-sm text-gray-900">
              {program.provider}
            </div>

            <div className="flex items-center gap-2">
              {showEdit && (
                <button
                  onClick={() => {
                    setEditParticipant({
                      show: true,
                      program: { ...program },
                      active: program.active,
                    });
                    setSelectedProgramForModal(null);
                  }}
                  className="inline-flex items-center text-blue-600 hover:text-white hover:bg-blue-800 px-2 py-1 rounded-md"
                >
                  <Edit className="mr-2 h-4 w-4" />
                  <div className="text-xs">Edit</div>
                </button>
              )}
              
              <Button
                variant="default"
                className="text-xs bg-blue-600 hover:bg-blue-800 text-white"
                onClick={() => onSelectProgram(program)}
              >
                Select
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgramTable;