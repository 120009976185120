import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { toast } from "react-toastify";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { format } from "date-fns";
import { Checkbox } from "@/components/ui/checkbox";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useTrackedState } from '@/utils/useTrackedState';
import { CheckCircle, XCircle , Ban} from "lucide-react";
import { useProgramColor } from '@/store/useProgramColor'; 

interface Program {
  programDescription: string;
  entryDate: string;
  exitDate: string;
  provider: string;
  disposition: string;
}

interface NameHistory {
  entrydate: string;
  firstName: string;
  lastName: string;
  program: string;
}

interface DispositionHistory {
  dispositionDate: string;
  disposition: string;
  program: string;
}

interface Scan {
  filedate: string;
  filetype: string;
  filelabel: string;
  filename: string;
}

interface Participant {
  activeProgram?: {
    programDescription: string;
  };
  active?: boolean;
  programs?: string[];
  mrun?: string;
  champid?: string;
  disposition?: string;
  providerHistory?: Array<{ value: string }>;
  genderHistory?: Array<{ value: string }>;
  releaseDate?: string;
  pathwayHistory?: Array<{ value: string }>;
  nameHistory?: NameHistory[];
  dispositionHistory?: DispositionHistory[];
  programDetails?: Program[];
  scans?: Scan[];
  name?: any;
  probation?: string;
  cii?: string;
  ssn?: string;
  isNumber?: string;
  booking?: string;
  maxDate?: string;
  completionDate?: string;
  dischargeDate?: string;
  commitmentDate?: string;
  jcit?: string;
  diversionDate?: string;
  notes?: string;
  incidents?: string;
  dob?: string;
  adherence?: string;
  champId?: string;
  fin?: string;
  arrestDate?: string;
  rso?:string,
  cases?: string;
}

interface EditParticipantProps {
  refetch: () => void;
  isOpen: boolean;
  onClose: () => void;
  program: any;
  odrnumber: number;
  data?: {
    getParticipantByODRNumber: Participant;
  };
  programOnly?: boolean;
  isActive: boolean;
}

interface FormState {
  mrun: string;
  champid: string;
  disposition: string;
  provider: string;
  gender: string;
  active: boolean;
  releaseDate: string;
  pathway: string;
  program: string;
  names: NameHistory[];
  isOpen: boolean;
  race: string;
  dob: string;
  isReal: boolean;
  fivePercent: boolean;
  isNumbers: string[];
  ab109Funded: string;
  dispositionDate: string;
  programTransfers: string[];
  name: any;
  probation: string;
  adherence: string;
  champId: string;
  fin: string;
  arrestDate: string;
  rso:string;
  cases: string;
  medicalProblems: string;
  psychotropicMeds: string;
  otherMeds: string;
  clinicalPresentation: string;
  currentMeds: string;
  substanceHistory: string;
  istStatus: string;
  ist: string;
  fso: string;
  booking: string;
  bookingNumber: string;
  maxDate: string;
  completionDate: string;
  isNumber: string;
  dischargeDate: string;
  commitmentDate: string;  
  jcit: string;
  diversionDate: string;
  notes: string;
  incidents: string;
  firstName: string;
  lastName: string;
  cii: string;
  ssn: string;
  mrunNumber: string;
  mrunStatus: 'open' | 'closed';
}

const UPDATE_PARTICIPANT = gql`
  mutation UpdateParticipantByODRNumber($odrnumber: Integer!, $participant: Integer, $updates: UpdateParticipantInput!) {
    updateParticipantByODRNumber(odrnumber: $odrnumber, participant: $participant, updates: $updates) {
      message
    }
  }
`;

const PROGRAM_DISPOSITIONS = {
  mist: [
    'CBR Placement',
    'CBR to Diversion Placement',
    'Diversion Placement',
    'Maxed Out',
    'Diversion Completed',
    'Unrestorable',
    'Restored',
    'Lost to Follow-Up',
    'Conserved',
    'Cancelled',
    'DMH',
    'Other'
  ],
  fist: [
    'Conditionally released',
    'In placement',
    'AWOL',
    'Re-incarcerated',
    'Conserved',
    'Restored',
    'Revoked',
    'Maxed out',
    'Other'
  ],
  odr: [
    'Conditionally released unassigned',
    'Conditionally released assigned',
    'Released to housing/services',
    'In community pending assignment',
    'Re-incarcerated ICMS retained',
    'Deactivated',
    'Deceased',
    'Conserved',
    'Cancelled',
    'Other'
  ],
  dsh: [
    'Conditionally released',
    'In placement',
    'AWOL',
    'Re-incarcerated',
    'Conserved',
    'Restored',
    'Revoked',
    'Other'
  ],
  prg_wmn: [
    'Conditionally released unassigned',
    'Conditionally released assigned',
    'Released to housing/services',
    'In community pending assignment',
    'Re-incarcerated ICMS retained',
    'Deactivated',
    'Deceased',
    'Conserved',
    'Cancelled',
    'Other'
  ],
  lead: [
    'Active',
    'Deactivated-no contact',
    'Deactivated-moved out of LA County',
    'Deactivated-voluntary withdrawal/refusal',
    'Deactivated-sentence > 16 mo, 1 day',
    'Deactivated-drug court/other diversion',
    'Deactivated-transferred to another program',
    'Deactivated-danger to staff/participants',
    'Deactivated-transferred to ICE custody',
    'Deceased',
    'Other'
  ]
};

const PROGRAM_PATHWAYS = {
  mist: [], // hidden for MIST
  fist: ['CBR', 'Diversion (1001.36)'],
  prg_wmn: {
    'PRE-TRIAL123': ['ICMS', 'ICMS +', 'FSP', 'None'],
    'PRE-TRIAL003': ['ICMS', 'ICMS +', 'FSP', 'None'],
    'PRE-TRIAL044': ['ICMS', 'ICMS +', 'FSP', 'None'],
    'COURT LINKAGE': ['ICMS', 'ICMS +', 'FSP', 'None'],
    'JAIL LINKAGE': ['ICMS', 'ICMS +', 'FSP', 'None']
  },
  lead: ['SOCIAL CONTACT', 'PRE-BOOKING', 'COMMUNITY REFERRAL']
};

const PROGRAM_PROVIDERS = {
  mist: ['CCM', 'HR360', 'OVMC', 'SERENITY', 'ALCOTT', 'AP RECOVERY', 'BUILDING LIVES', 'NONE'],
  fist: [
    'ALCOTT',
    'CCM',
    'EXODUS',
    'GATEWAYS',
    'IMD',
    'LAS ENCINAS',
    'OVMC',
    'PROJECT 180/SSG',
    'VSN',
    'NONE'
  ],
  dsh: [
    'EXODUS',
    'CCM',
    'OVMC',
    'HHH',
    'VSN',
    'P180',
    'NONE'
  ],
  odr: [
    'ALCOTT',
    'ALCOTT FISHER IH+',
    'AMITY FOUNDATION',
    'BUILDING LIVES IH+',
    'CCM ICMS',
    'CCM IH+',
    'EXODUS',
    'HHCLA',
    'HOUSING WORKS',
    'LIFESTEPS',
    'MHALA',
    'PROJECT 180/SSG',
    'ST. JOSEPHS',
    'STEP UP ON SECOND',
    'TELECARE',
    'TPC',
    'VOA',
    'VSN ALLIANCE IH+',
    'VSN PROSPERITY IH+'
  ],
  prg_wmn: [
    'ALCOTT',
    'AMITY FOUNDATION',
    'HHCLA',
    'TPC',
    'PROJECT 180/SSG',
    'VOA'
  ],
  lead: [
    'CHPLA',
    'HOPICS',
    'ALMA ELA',
    'ALMA SLA',
    'PATH',
    'TPC',
    'Velnonart'
  ]
};

export const EditParticipant: React.FC<EditParticipantProps> = ({ isOpen, onClose, program, odrnumber, data, programOnly, isActive , refetch} : EditParticipantProps ) => {
  const programNameDescription = data?.getParticipantByODRNumber?.activeProgram?.programDescription
  const participant = data?.getParticipantByODRNumber;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { programColor } = useProgramColor();
  
  
  const [activeProgram, setActiveProgram] = useState<string>(
    participant?.activeProgram?.programDescription || ""
  );

  const [formState, handleInputChange, getModifiedValues] = useTrackedState<FormState>({
    firstName: participant?.name?.firstName || "",
    lastName: participant?.name?.lastName || "",
    program: participant?.activeProgram?.programDescription || "",
    names: participant?.nameHistory || [],
    isOpen: false,
    isReal: false,
    champid: participant?.champid || "",
    gender: participant?.genderHistory?.[0]?.value || "",
    disposition: participant?.disposition || "",
    releaseDate: participant?.releaseDate || "",
    pathway: participant?.pathwayHistory?.[0]?.value || "",
    provider: participant?.providerHistory?.[0]?.value || "",
    mrun: participant?.mrun || "",
    adherence: participant?.adherence || "",
    champId: participant?.champId || "",
    fin: participant?.fin || "",
    arrestDate: participant?.arrestDate || "", 
    name: "",
    rso: participant?.rso || '0', 
    probation: participant?.probation || "",
    cases: participant?.cases || "",
    medicalProblems: "",
    psychotropicMeds: "",
    otherMeds: "",
    clinicalPresentation: "",
    currentMeds: "",
    substanceHistory: "",
    istStatus: "",
    ist: "",
    fso: "",
    booking: participant?.booking || "",
    bookingNumber: "",
    maxDate: "",
    dob: participant?.dob || "",
    completionDate: "",    
    dischargeDate: "",
    commitmentDate: "",
    jcit: "",
    diversionDate: "",
    notes: "",    
    incidents: "",
    fivePercent: false,
    isNumbers: [],
    ab109Funded: "",
    dispositionDate: "",
    programTransfers: [],    
    isNumber: participant?.isNumber || "",
    race: "",
    cii: participant?.cii || "",
    ssn: participant?.ssn || "",
    mrunNumber: participant?.mrun ? participant.mrun.split('-')[0] : '',
    mrunStatus: participant?.mrun?.includes('open') ? 'open' : 'closed',
    active: isActive || false, 
  });

  const [updateParticipant] = useMutation(UPDATE_PARTICIPANT, {
    onCompleted: () => {
      toast.success("Participant updated successfully");
      onClose();
    },
    onError: (error) => {
      toast.error(`Error updating participant: ${error.message}`);
    },
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const modifiedValues = getModifiedValues();
      if (Object.keys(modifiedValues).length === 0) {
        toast.info("No changes to save");
        return;
      }

      // Check if any name-related fields were modified
      const nameFieldsModified = ['firstName', 'lastName', 'isReal'].some(
        field => field in modifiedValues
      );

      // Create the final updates object
      const updates = { ...modifiedValues };

      if (modifiedValues.mrun) {
        updates.mrun = formState.mrunNumber + '-' + formState.mrunStatus
        delete updates.mrunNumber;
        delete updates.mrunStatus;
      }
      
      // If any name field was modified, group them together
      if (nameFieldsModified) {
        // Remove individual name fields
        delete updates.firstName;
        delete updates.lastName;
        delete updates.isReal;                
        // Add grouped name object with correct field names
        updates.name = {
          firstName: formState.firstName,
          lastName: formState.lastName,
          isName: Boolean(formState.isReal),
          isAlias: !formState.isReal
        };
      }

      await updateParticipant({
        variables: {
          odrnumber: odrnumber,
          //participant: program.programNumber,
          updates: updates,
        },
      });
      refetch();
    } catch (error) {
      console.error("Submit error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatDateString = (dateString: string) => {
    try {
      return format(new Date(dateString), "MM/dd/yyyy");
    } catch {
      return dateString;
    }
  };

  const programs = ['MIST', 'FIST', 'ODR Housing', 'Pregnant Woman', 'LEAD'];
  const providers = ['CCM', 'HR360', 'OVMC', 'SERENITY', 'ALCOTT', 'AP RECOVERY', 'BUILDING LIVES', 'NONE'];
  const dispositions = [
    'CBR Placement',
    'CBR to Diversion Placement',
    'Diversion Placement',
    'Maxed Out',
    'Diversion Completed',
    'Unrestorable',
    'Restored',
    'Lost to Follow-Up',
    'Conserved',
    'Cancelled',
    'DMH',
    'Other'
  ];
  const raceOptions = ['American Indian', 'Asian', 'Black', 'Hispanic', 'Middle Eastern', 'Pacific Islander', 'White', 'Doesn\'t Know', 'Refused', 'Unknown'];

  const getAvailableDispositions = () => {
    const program = programNameDescription || '';
    if (program.includes('mist')) return PROGRAM_DISPOSITIONS.mist;
    if (program.includes('fist')) return PROGRAM_DISPOSITIONS.fist;
    if (program.includes('odr')) return PROGRAM_DISPOSITIONS.odr;
    if (program.includes('dsh')) return PROGRAM_DISPOSITIONS.dsh;
    if (program.includes('prg_wmn')) return PROGRAM_DISPOSITIONS.prg_wmn;
    if (program.includes('lead')) return PROGRAM_DISPOSITIONS.lead;
    return [];
  };

  const getProgramType = (programDescription: string | undefined): 'mist' | 'fist' | 'odr' | 'lead' | 'prg_wmn' => {
    if (!programDescription) return 'mist';
    if (programDescription.toLowerCase().includes('mist')) return 'mist';
    if (programDescription.toLowerCase().includes('fist')) return 'fist';
    if (programDescription.toLowerCase().includes('odr')) return 'odr';
    if (programDescription.toLowerCase().includes('lead')) return 'lead';
    if (programDescription.toLowerCase().includes('prg_wmn')) return 'prg_wmn';
    return 'mist'; // default
  };

  const handleMrunChange = (type: 'number' | 'status', value: string) => {
    if (type === 'number') {
      // Only allow digits and limit to 9 characters
      const sanitizedValue = value.replace(/\D/g, '').slice(0, 9);
      handleInputChange('mrunNumber', sanitizedValue);
    } else {
      handleInputChange('mrunStatus', value as 'open' | 'closed');
    }
    
    // Combine both values to update the actual mrun field
    const number = type === 'number' ? value : formState.mrunNumber;
    const status = type === 'status' ? value : formState.mrunStatus;
    if (number) {
      handleInputChange('mrun', `${number}-${status}`);
    }
  };

  const [primaryPathway, setPrimaryPathway] = useState(
    Object.keys(PROGRAM_PATHWAYS.prg_wmn || {}).find(key => 
      formState.pathway?.startsWith(key)
    ) || ''
  );

  const renderPathwaySelects = () => {
    const programType = getProgramType(programNameDescription);
    
    if (programType === 'prg_wmn') {
      return (
        <div className="flex gap-4">
          <Select
            value={primaryPathway}
            onValueChange={(value) => {
              setPrimaryPathway(value);
              handleInputChange('pathway', value);
            }}
          >
            <SelectTrigger className="h-10 w-[250px]">
              <SelectValue placeholder="Select primary pathway" />
            </SelectTrigger>
            <SelectContent>
              {Object.keys(PROGRAM_PATHWAYS.prg_wmn).map((path) => (
                <SelectItem key={path} value={path}>
                  {path}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          {primaryPathway && (
            <Select
              value={formState.pathway?.split(' - ')[1] || ''}
              onValueChange={(value) => {
                handleInputChange('pathway', `${primaryPathway} - ${value}`);
              }}
            >
              <SelectTrigger className="h-10 w-[250px]">
                <SelectValue placeholder="Select secondary pathway" />
              </SelectTrigger>
              <SelectContent>
                {PROGRAM_PATHWAYS.prg_wmn[primaryPathway as keyof typeof PROGRAM_PATHWAYS.prg_wmn].map((subPath) => (
                  <SelectItem key={subPath} value={subPath}>
                    {subPath}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        </div>
      );
    }

    // Default single pathway select for other programs
    return (
      <Select
        value={formState.pathway}
        onValueChange={(value) => handleInputChange('pathway', value)}
      >
        <SelectTrigger className="h-10 w-[250px]">
          <SelectValue placeholder="Select pathway" />
        </SelectTrigger>
        <SelectContent>
          {Object.values(PROGRAM_PATHWAYS[programType as keyof typeof PROGRAM_PATHWAYS] || []).map((path: string) => (
            <SelectItem key={path} value={path}>
              {path}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="min-w-[80vw] max-w-[90vw] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Edit Participant Details</DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>{programOnly==true ? `${program.programDescription?.toUpperCase()} - ${program.programParticipantNumber}` : ''}</CardTitle>
            </CardHeader>
            <CardContent>
              <div>
              { programOnly!=true && <div className="space-y-8">
                <div className="grid grid-cols-3 gap-6">
                  <div className="space-y-2.5">
                    <Label htmlFor="firstName" className="text-sm font-medium">
                      First Name
                    </Label>
                    <Input
                      id="firstName"
                      value={formState.firstName}
                      onChange={(e) => handleInputChange('firstName', e.target.value)}
                      className="h-10 w-[250px]"
                    />
                  </div>
                  <div className="space-y-2.5">
                    <Label htmlFor="lastName" className="text-sm font-medium">
                      Last Name
                    </Label>
                    <Input
                      id="lastName"
                      value={formState.lastName}
                      onChange={(e) => handleInputChange('lastName', e.target.value)}
                      className="h-10 w-[250px]"
                    />
                  </div>
                  <div className="space-y-2.5">
                    <Label htmlFor="nameReal" className="text-sm font-medium">
                      Real Name
                    </Label>
                    <div className="flex items-center space-x-2">
                      <RadioGroup
                        value={formState.isReal ? "realname" : "alias"}
                        onValueChange={(value) => {
                          handleInputChange('isReal', value === "realname" ? true : false);
                        }}
                        className="flex gap-10"
                      >
                        <div key="realname" className="flex items-center space-x-2">
                          <RadioGroupItem value="realname" id="realname" />
                          <Label htmlFor="realname" className="text-sm">
                            Real Name
                          </Label>
                        </div>
                        <div key="alias" className="flex items-center space-x-2">
                          <RadioGroupItem value="alias" id="alias" />
                          <Label htmlFor="alias" className="text-sm">
                            Alias
                          </Label>
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-6">
                  <div className="space-y-2.5">
                    <Label htmlFor="race" className="text-sm font-medium">
                      Race
                    </Label>
                    <Select
                      value={formState.race}
                      onValueChange={(value) => handleInputChange('race', value)}
                    >
                      <SelectTrigger className="h-10 w-[250px]">
                        <SelectValue placeholder="Select race" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="American Indian">American Indian, Alaska Native or Indigenous</SelectItem>
                        <SelectItem value="Asian">Asian or Asian American</SelectItem>
                        <SelectItem value="Black">Black, African American or African</SelectItem>
                        <SelectItem value="Hispanic">Hispanic/Latinx</SelectItem>
                        <SelectItem value="Middle Eastern">Middle Eastern or North African</SelectItem>
                        <SelectItem value="Pacific Islander">Native Hawaiian or Pacific Islander</SelectItem>
                        <SelectItem value="White">White</SelectItem>
                        <SelectItem value="Doesn't Know">Doesn't Know</SelectItem>
                        <SelectItem value="Refused">Refused</SelectItem>
                        <SelectItem value="Unknown">Unknown</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="space-y-2.5">
                    <Label htmlFor="dob" className="text-sm font-medium">
                      Date of Birth
                    </Label>
                    <div className="w-fit">
                      <Input
                        id="dob"
                        type="date"
                        value={formState.dob}
                        onChange={(e) => handleInputChange('dob', e.target.value)}
                        className="h-10 w-[160px]"
                      />
                    </div>
                  </div>

                  <div className="space-y-2.5">
                    <Label className="text-sm font-medium">Gender</Label>
                    <RadioGroup
                      value={formState.gender}
                      onValueChange={(value: string) => handleInputChange('gender', value)}
                      className="flex gap-10"
                    >
                      {['Male', 'Female', 'Transgender'].map(g => (
                        <div key={g} className="flex items-center space-x-2">
                          <RadioGroupItem value={g} id={g} />
                          <Label htmlFor={g} className="text-sm">{g}</Label>
                        </div>
                      ))}
                    </RadioGroup>
                  </div>
                </div>

                <div className="border-t border-gray-200 my-6"></div>

                <div className="space-y-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="space-y-2.5">
                      <Label htmlFor="champid" className="text-sm font-medium">
                        CHAMP ID
                      </Label>
                      <Input
                        id="champid"
                        value={formState.champid}
                        onChange={(e) => handleInputChange('champid', e.target.value)}
                        className="h-10 w-[250px]"
                        maxLength={6}
                        placeholder="Enter 6-digit CHAMP ID"
                      />
                    </div>

                    <div className="space-y-2.5">
                      <Label htmlFor="mrun" className="text-sm font-medium">
                        MRUN
                      </Label>
                      <div className="flex gap-2">
                        <Input
                          id="mrunNumber"
                          value={formState.mrunNumber}
                          onChange={(e) => handleMrunChange('number', e.target.value)}
                          maxLength={9}
                          placeholder="Enter 9-digit MRUN"
                          className="h-10 w-[150px]"
                        />
                        <Select
                          value={formState.mrunStatus}
                          onValueChange={(value) => handleMrunChange('status', value)}
                        >
                          <SelectTrigger className="h-10 w-[95px]">
                            <SelectValue placeholder="Status" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="open">open</SelectItem>
                            <SelectItem value="closed">closed</SelectItem>
                          </SelectContent>
                        </Select>
                       
                      </div>
                    </div>

                    <div className="space-y-2.5">
                      <Label htmlFor="isNumber" className="text-sm font-medium">
                        IS #
                      </Label>
                      <Input
                        id="isNumber"
                        value={formState.isNumber}
                        onChange={(e) => handleInputChange('isNumber', e.target.value)}
                        className="h-10 w-[250px]"
                        maxLength={15}
                        placeholder="Enter IS number"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="space-y-2.5">
                      <Label htmlFor="ssn" className="text-sm font-medium">
                        SSN
                      </Label>
                      <Input
                        id="ssn"
                        value={formState.ssn}
                        onChange={(e) => handleInputChange('ssn', e.target.value)}
                        className="h-10 w-[250px]"
                        maxLength={11}
                        placeholder="Enter SSN (XXX-XX-XXXX)"
                      />
                    </div>

                    <div className="space-y-2.5">
                      <Label htmlFor="cii" className="text-sm font-medium">
                        CII
                      </Label>
                      <Input
                        id="cii"
                        value={formState.cii}
                        onChange={(e) => handleInputChange('cii', e.target.value)}
                        className="h-10 w-[250px]"
                        maxLength={9}
                        placeholder="Enter 9-digit CII"
                      />
                    </div>
                  </div>

                  <div className="border-t border-gray-200 my-6"></div>

                  <div className="grid grid-cols-3 gap-6">
                  <div className="space-y-2.5">
                      <Label htmlFor="rso" className="text-sm font-medium">
                        RSO
                      </Label>
                      <div className="w-fit">
                         <Select
                          value={formState.rso == '1' ? "Yes" : "No"
                          }
                          onValueChange={(e) => handleInputChange('rso', e)}
                        >
                          <SelectTrigger className="h-10 w-[95px]">
                            <SelectValue/>
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value={'1'}>Yes</SelectItem>
                            <SelectItem value={'0'}>No</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>

                  {/*  <div className="space-y-2.5">
                      <Label htmlFor="arrestDate" className="text-sm font-medium">
                        Arrest Date
                      </Label>
                      <div className="w-fit">
                        <Input
                          id="arrestDate"
                          type="date"
                          value={formState.arrestDate}
                          onChange={(e) => handleInputChange('arrestDate', e.target.value)}
                          className="h-10 w-[160px]"
                        />
                      </div>
                    </div>

                    <div className="space-y-2.5">
                      <Label htmlFor="releaseDate" className="text-sm font-medium">
                        Release Date
                      </Label>
                      <div className="w-fit">
                        <Input
                          id="releaseDate"
                          type="date"
                          value={formState.releaseDate}
                          onChange={(e) => handleInputChange('releaseDate', e.target.value)}
                          className="h-10 w-[160px]"
                        />
                      </div>
                    </div>*/}

                    <div className="space-y-2.5">
                      <Label htmlFor="probation" className="text-sm font-medium">
                        Probation Status
                      </Label>
                      <Select
                        value={formState.probation}
                        onValueChange={(value) => handleInputChange('probation', value)}
                      >
                        <SelectTrigger className="h-10 w-[250px]">
                          <SelectValue placeholder="Select probation status" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="Yes">
                            <div className="flex items-center gap-2">
                              <CheckCircle className="h-4 w-4" />
                              <span>Yes</span>
                            </div>
                          </SelectItem>
                          <SelectItem value="No">
                            <div className="flex items-center gap-2">
                              <XCircle className="h-4 w-4" />
                              <span>No</span>
                            </div>
                          </SelectItem>
                          <SelectItem value="Terminated">
                            <div className="flex items-center gap-2">
                              <Ban className="h-4 w-4" />
                              <span>Terminated</span>
                            </div>
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                  </div> </div>}

                  
                
                
                
              </div>
            </CardContent>
          </Card>

          <div className="flex justify-end gap-4 mt-6">
            <Button 
              type="submit" 
              disabled={isSubmitting}
              className="w-[100px]"
              variant="outline"
            >
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
            <Button 
              type="button" 
              variant="outline"
              onClick={onClose}
              className="w-[100px]"
            >
              Cancel
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditParticipant;
