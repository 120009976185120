export interface ReportFormat {
    value: 'pdf' | 'doc' | 'xls' | 'csv';
    label: string;
  }
  
  export interface Report {
    id: string;
    name: string;
    programs: string[];
    formats: ReportFormat['value'][];
  }
  
  export const FORMATS: ReportFormat[] = [
    { value: 'pdf', label: 'PDF' },
    { value: 'xls', label: 'Excel' },
    { value: 'csv', label: 'CSV' },
    { value: 'doc', label: 'Word' },
  ];
export const REPORTS: Report[] = [
    {
      id: 'all_mist',
      name: 'All',
      programs: ['MIST'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'all_fist',
      name: 'All',
      programs: ['FIST'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'all_dsh',
      name: 'All',
      programs: ['DSH'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'all_odr',
      name: 'All',
      programs: ['ODR'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'client_releases_mist',
      name: 'Client Releases',
      programs: ['MIST'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'client_releases_odr',
      name: 'Client Releases',
      programs: ['ODR'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'client_releases_fist',
      name: 'Client Releases',
      programs: ['FIST'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'releases_cbr_mist',
      name: 'Releases to CBR',
      programs: ['MIST'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'charges_mist',
      name: 'Charges',
      programs: ['MIST'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'profile_review_mist',
      name: 'Profile review by entry date',
      programs: ['MIST'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'profile_review_fist',
      name: 'Profile review by entry date',
      programs: ['FIST'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'profile_review_dsh',
      name: 'Profile review by entry date',
      programs: ['DSH'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'profile_review_odr',
      name: 'Profile review by entry date',
      programs: ['ODR'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'profile_review_prg_wmn',
      name: 'Profile review by entry date',
      programs: ['PRG_WMN'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'profile_review_lead',
      name: 'Profile review by entry date',
      programs: ['LEAD'],
      formats: ['pdf', 'xls']
    },
    
    {
      id: 'fist_cbr',
      name: 'FIST-CBR Participants',
      programs: ['FIST'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'awol_report',
      name: 'AWOL report',
      programs: ['FIST'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'fist_linkage',
      name: 'FIST Linkage',
      programs: ['FIST'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'quarterly_report',
      name: 'Quarterly Report',
      programs: ['FIST'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'ab109_referrals',
      name: 'AB109 referrals by entry date',
      programs: ['ODR', 'PRG_WMN', 'LEAD'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'disposition',
      name: 'Disposition by entry date',
      programs: ['ODR', 'PRG_WMN', 'LEAD'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'odr_housing_metrics',
      name: 'ODR Housing metrics Court and Jail Linkage',
      programs: ['ODR'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'odr_housing_weekly',
      name: 'ODR Housing Weekly List',
      programs: ['ODR'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'odr_housing_metrics_released',
      name: 'ODR Housing Metrics released Clients',
      programs: ['ODR'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'odr_housing_ih_list',
      name: 'ODR Housing IH List',
      programs: ['ODR'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'psh_search_status',
      name: 'PSH Search Status',
      programs: ['ODR', 'PRG_WMN', 'LEAD'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'face_sheet',
      name: 'Face Sheet Report',
      programs: ['ODR'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'legal_report',
      name: 'Legal Report',
      programs: ['ODR', 'PRG_WMN'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'prg_wmn_metrics',
      name: 'Pregnant Women metrics Court and Jail Linkage',
      programs: ['PRG_WMN'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'prg_wmn_weekly',
      name: 'Pregnant Women Weekly List',
      programs: ['PRG_WMN'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'prg_wmn_metrics_released',
      name: 'Pregnant Women Metrics released Clients',
      programs: ['PRG_WMN'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'prg_wmn_ih_list',
      name: 'Pregnant Women IH List',
      programs: ['PRG_WMN'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'lead_participant_list',
      name: 'LEAD participant list',
      programs: ['LEAD'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'lead_participant_south_lb',
      name: 'LEAD Participant list South LA/Long Beach',
      programs: ['LEAD'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'lead_participant_east_la',
      name: 'LEAD Participant list East LA',
      programs: ['LEAD'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'lead_participant_hollywood',
      name: 'LEAD Participant list Hollywood and Rampart',
      programs: ['LEAD'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'lead_participant_dtla',
      name: 'LEAD Participant list DTLA',
      programs: ['LEAD'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'lead_participant_venice',
      name: 'LEAD Participant list Venice',
      programs: ['LEAD'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'lead_participant_av',
      name: 'LEAD Participant list AV',
      programs: ['LEAD'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'lead_metrics',
      name: 'LEAD metrics Court and Jail Linkage',
      programs: ['LEAD'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'lead_weekly',
      name: 'LEAD Weekly List',
      programs: ['LEAD'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'lead_metrics_released',
      name: 'LEAD Metrics released Clients',
      programs: ['LEAD'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'lead_ih_list',
      name: 'LEAD IH List',
      programs: ['LEAD'],
      formats: ['pdf', 'xls']
    },
    {
      id: 'quarterly_state',
      name: 'Quarterly Report To State',
      programs: ['DSH'],
      formats: ['pdf', 'xls']
    }
  ];
   
