// duplicateQueries.ts - GraphQL Query Definition
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

import { DuplicateClientsModal} from './modals/duplicates';

import React from 'react';
import ReactModal from 'react-modal';
export const GET_DUPLICATE_COUNT = gql`
  query GetDuplicateCount($odrNumber: Integer!) {
    findDuplicate(odrNumber: $odrNumber) {
      totalCount      
      entries {
        participants
        cin
        mrun
        ssn
        cii
        fin
        cid
      }
    }
  }
`;


interface DuplicateCountProps {
  odrNumber: number;
}


const Alert = ({children}:any)=>{
  return <div className="bg-red-500 text-white p-2 rounded-md mr-4">{children}</div>
}

export const DuplicateCount: React.FC<DuplicateCountProps> = ({ odrNumber }: DuplicateCountProps) => {
const [showReport, setShowReport] = React.useState(false);
  const { loading, error, data } = useQuery(GET_DUPLICATE_COUNT, {
    variables: { odrNumber },
    // Skip the query if no ODR number is provided
    skip: !odrNumber,
    // Don't cache this data as it might change frequently
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="animate-spin h-6 w-6 border-2 border-blue-500 rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive" className="mt-2">
        <p>Error checking for duplicates: {error.message}</p>
      </Alert>
    );
  }

  if (!data) {
    return null;
  }

  const  duplicates  = data.findDuplicate;

  // Show any errors from the backend
  

  // If there are duplicates, show a warning with the count
  if (duplicates.totalCount > 0) {
    return (    
      <Alert variant="warning" className="mt-2 mr-4">
        <div className="flex items-center space-x-2">
          <span className="font-semibold text-orange-600 text-sm text-black">
            <button className="text-white" onClick={() => setShowReport(true)}>{duplicates.totalCount === 1 ? 'Duplicate' : 'Duplicates'} found</button>
          </span>
          {duplicates.entries.length > 0 && (
            <div className="text-sm text-gray-600">              
            </div>
          )}
          {showReport && <DuplicateClientsModal isOpen={showReport} onClose={() => setShowReport(false)} report={duplicates} />}
        </div>
      </Alert>
    );
  }

  // If no duplicates found, show a success message
  return (    
      <div></div>    
  );
};

