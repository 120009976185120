 
import { useState, useCallback } from 'react';

export function useTrackedState<T extends object>(initialState: T) {
  const [state, setState] = useState<T>(initialState);
  const [modifiedFields, setModifiedFields] = useState<Set<keyof T>>(new Set());

  const updateState = useCallback((field: keyof T, value: T[keyof T]) => {
    setState(prev => ({ ...prev, [field]: value }));
    setModifiedFields(prev => {
      const newSet = new Set(prev);
      newSet.add(field);
      return newSet;
    });
  }, []);

  const getModifiedValues = useCallback(() => {
    return Array.from(modifiedFields).reduce((acc, field) => {
      acc[field] = state[field];
      return acc;
    }, {} as Partial<T>);
  }, [state, modifiedFields]);

  return [state, updateState, getModifiedValues] as const;
}